@charset "UTF-8";
#social-sharing {
  width: 100%;
  z-index: 100;
  text-align: left;
  color: #fff;
  background: #F4F4F4; }
  #social-sharing.float {
    position: fixed;
    height: 90px;
    bottom: 0; }
  #social-sharing.static {
    position: static; }
    #social-sharing.static .service-elements-header {
      padding: 1rem 0 0 0;
      font-size: 1rem; }
  #social-sharing .service-elements-header {
    color: #000;
    margin: 10px 0 0 5px;
    font-size: 12px; }
  #social-sharing .service-element a {
    color: transparent;
    display: inline-block;
    width: 25px;
    height: 25px;
    overflow: hidden;
    margin: .5rem 1rem 1rem 1rem;
    position: relative;
    background-color: #000;
    padding: .5rem;
    white-space: nowrap; }
    #social-sharing .service-element a[class*="icon"]:hover::before {
      color: #555555; }
    #social-sharing .service-element a[class*="icon"]::before {
      color: #fff;
      position: absolute;
      top: .3rem;
      left: .6rem;
      font-size: 20px;
      speak: none;
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      font-family: "oticon-icon-www"; }
  #social-sharing .service-element a:first-of-type {
    margin-left: 0; }
  #social-sharing .service-element [class*="facebook"] {
    background-color: #225799; }
  #social-sharing .service-element [class*="linkedin"] {
    background-color: #2C80A5; }
  #social-sharing .service-element [class*="twitter"] {
    background-color: #00ACEE; }
  #social-sharing .service-element [class*="google-plus"] {
    background-color: #FC4935; }
  #social-sharing .service-element [class*="mail"] {
    background-color: #000; }

@media only screen and (min-width: 992px) {
  #social-sharing.float {
    height: auto;
    bottom: 30%;
    width: auto;
    background-color: transparent; }
    #social-sharing.float .service-element a {
      float: none;
      display: block;
      margin: 0; } }

.grid-1,
.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7,
.grid-8,
.grid-9,
.grid-10,
.grid-11,
.grid-12 {
  display: inline;
  float: left;
  position: relative; }

.alpha {
  margin-left: 0 !important; }

img {
  height: auto;
  max-width: 100%; }

#wrapper {
  width: 960px;
  margin: auto; }

.main {
  margin-left: auto;
  margin-right: auto;
  width: 940px;
  padding-left: 10px;
  padding-right: 10px; }

.row {
  width: 100%; }

.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0; }

.row:after {
  clear: both; }

.row [class*="grid"] {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.127659574468085%; }

.row [class*="grid"]:first-child {
  margin-left: 0; }

.row .grid-12 {
  width: 100%; }

.row .grid-11 {
  width: 91.48936170212765%; }

.row .grid-10 {
  width: 82.97872340425532%; }

.row .grid-9 {
  width: 74.46808510638297%; }

.row .grid-8 {
  width: 65.95744680851064%; }

.row .grid-7 {
  width: 57.44680851063829%; }

.row .grid-6 {
  width: 48.93617021276595%; }

.row .grid-5 {
  width: 40.42553191489362%; }

.row .grid-4 {
  width: 31.914893617021278%; }

.row .grid-3 {
  width: 23.404255319148934%; }

.row .grid-2 {
  width: 14.893617021276595%; }

.row .grid-1 {
  width: 6.382978723404255%; }

.row .grid-11 .grid-11 {
  width: 100%; }

.row .grid-11 .grid-10 {
  width: 90.71564%; }

.row .grid-11 .grid-9 {
  width: 81.43131%; }

.row .grid-11 .grid-8 {
  width: 72.14698%; }

.row .grid-11 .grid-7 {
  width: 62.86265%; }

.row .grid-11 .grid-6 {
  width: 53.57832%; }

.row .grid-11 .grid-5 {
  width: 44.29399%; }

.row .grid-11 .grid-4 {
  width: 35.00966%; }

.row .grid-11 .grid-3 {
  width: 25.72533%; }

.row .grid-11 .grid-2 {
  width: 16.44100%; }

.row .grid-11 .grid-1 {
  width: 7.15667%; }

.row .grid-10 .grid-10 {
  width: 100%; }

.row .grid-10 .grid-9 {
  width: 89.78723%; }

.row .grid-10 .grid-8 {
  width: 79.57447%; }

.row .grid-10 .grid-7 {
  width: 69.36170%; }

.row .grid-10 .grid-6 {
  width: 59.14894%; }

.row .grid-10 .grid-5 {
  width: 48.93617%; }

.row .grid-10 .grid-4 {
  width: 38.72340%; }

.row .grid-10 .grid-3 {
  width: 28.51064%; }

.row .grid-10 .grid-2 {
  width: 18.29787%; }

.row .grid-10 .grid-1 {
  width: 8.08511%; }

.row .grid-9 .grid-9 {
  width: 100%; }

.row .grid-9 .grid-8 {
  width: 88.65248%; }

.row .grid-9 .grid-7 {
  width: 77.30496%; }

.row .grid-9 .grid-6 {
  width: 65.95745%; }

.row .grid-9 .grid-5 {
  width: 54.60993%; }

.row .grid-9 .grid-4 {
  width: 43.26241%; }

.row .grid-9 .grid-3 {
  width: 31.91489%; }

.row .grid-9 .grid-2 {
  width: 20.56738%; }

.row .grid-9 .grid-1 {
  width: 9.21986%; }

.row .grid-8 .grid-8 {
  width: 100%; }

.row .grid-8 .grid-7 {
  width: 87.23404%; }

.row .grid-8 .grid-6 {
  width: 74.46809%; }

.row .grid-8 .grid-5 {
  width: 61.70213%; }

.row .grid-8 .grid-4 {
  width: 48.93617%; }

.row .grid-8 .grid-3 {
  width: 36.17021%; }

.row .grid-8 .grid-2 {
  width: 23.40426%; }

.row .grid-8 .grid-1 {
  width: 10.63830%; }

.row .grid-7 .grid-7 {
  width: 100%; }

.row .grid-7 .grid-6 {
  width: 85.41033%; }

.row .grid-7 .grid-5 {
  width: 70.82067%; }

.row .grid-7 .grid-4 {
  width: 56.23100%; }

.row .grid-7 .grid-3 {
  width: 41.64134%; }

.row .grid-7 .grid-2 {
  width: 27.05167%; }

.row .grid-7 .grid-1 {
  width: 12.46201%; }

.row .grid-6 .grid-6 {
  width: 100%; }

.row .grid-6 .grid-5 {
  width: 82.97872%; }

.row .grid-6 .grid-4 {
  width: 65.95745%; }

.row .grid-6 .grid-3 {
  width: 48.93617%; }

.row .grid-6 .grid-2 {
  width: 31.91489%; }

.row .grid-6 .grid-1 {
  width: 14.89362%; }

.row .grid-5 .grid-5 {
  width: 100%; }

.row .grid-5 .grid-4 {
  width: 79.57447%; }

.row .grid-5 .grid-3 {
  width: 59.14894%; }

.row .grid-5 .grid-2 {
  width: 38.72340%; }

.row .grid-5 .grid-1 {
  width: 18.29787%; }

.row .grid-4 .grid-4 {
  width: 100%; }

.row .grid-4 .grid-3 {
  width: 74.46809%; }

.row .grid-4 .grid-2 {
  width: 48.93617%; }

.row .grid-4 .grid-1 {
  width: 23.40426%; }

.row .grid-3 .grid-3 {
  width: 100%; }

.row .grid-3 .grid-2 {
  width: 65.95745%; }

.row .grid-3 .grid-1 {
  width: 31.91489%; }

.row .grid-2 .grid-2 {
  width: 100%; }

.row .grid-2 .grid-1 {
  width: 48.93617%; }

.row .prefix-12 {
  margin-left: 104.25531914893617%; }

.row .prefix-11 {
  margin-left: 95.74468085106382%; }

.row .prefix-10 {
  margin-left: 87.23404255319149%; }

.row .prefix-9 {
  margin-left: 78.72340425531914%; }

.row .prefix-8 {
  margin-left: 70.2127659574468%; }

.row .prefix-7 {
  margin-left: 61.70212765957446%; }

.row .prefix-6 {
  margin-left: 53.191489361702125%; }

.row .prefix-5 {
  margin-left: 44.68085106382979%; }

.row .prefix-4 {
  margin-left: 36.170212765957444%; }

.row .prefix-3 {
  margin-left: 27.659574468085104%; }

.row .prefix-2 {
  margin-left: 19.148936170212764%; }

.row .prefix-1 {
  margin-left: 10.638297872340425%; }

.row .prefix-12:first-child {
  margin-left: 102.12765957446808%; }

.row .prefix-11:first-child {
  margin-left: 93.61702127659574%; }

.row .prefix-10:first-child {
  margin-left: 85.1063829787234%; }

.row .prefix-9:first-child {
  margin-left: 76.59574468085106%; }

.row .prefix-8:first-child {
  margin-left: 68.08510638297872%; }

.row .prefix-7:first-child {
  margin-left: 59.574468085106375%; }

.row .prefix-6:first-child {
  margin-left: 51.063829787234035%; }

.row .prefix-5:first-child {
  margin-left: 42.5531914893617%; }

.row .prefix-4:first-child {
  margin-left: 34.04255319148936%; }

.row .prefix-3:first-child {
  margin-left: 25.53191489361702%; }

.row .prefix-2:first-child {
  margin-left: 17.02127659574468%; }

.row .prefix-1:first-child {
  margin-left: 8.51063829787234%; }

.row .grid-11 .prefix-11 {
  margin-left: 104.25532%; }

.row .grid-11 .prefix-10 {
  margin-left: 94.97096%; }

.row .grid-11 .prefix-9 {
  margin-left: 85.68663%; }

.row .grid-11 .prefix-8 {
  margin-left: 76.40230%; }

.row .grid-11 .prefix-7 {
  margin-left: 67.11797%; }

.row .grid-11 .prefix-6 {
  margin-left: 57.833642%; }

.row .grid-11 .prefix-5 {
  margin-left: 48.54931%; }

.row .grid-11 .prefix-4 {
  margin-left: 39.26498%; }

.row .grid-11 .prefix-3 {
  margin-left: 29.98065%; }

.row .grid-11 .prefix-2 {
  margin-left: 20.69632%; }

.row .grid-11 .prefix-1 {
  margin-left: 11.41199%; }

.row .grid-11 .prefix-11:first-child {
  margin-left: 102.12766%; }

.row .grid-11 .prefix-10:first-child {
  margin-left: 92.84330%; }

.row .grid-11 .prefix-9:first-child {
  margin-left: 83.55897%; }

.row .grid-11 .prefix-8:first-child {
  margin-left: 74.27464%; }

.row .grid-11 .prefix-7:first-child {
  margin-left: 64.99031%; }

.row .grid-11 .prefix-6:first-child {
  margin-left: 55.70598%; }

.row .grid-11 .prefix-5:first-child {
  margin-left: 46.42165%; }

.row .grid-11 .prefix-4:first-child {
  margin-left: 37.13732%; }

.row .grid-11 .prefix-3:first-child {
  margin-left: 27.85299%; }

.row .grid-11 .prefix-2:first-child {
  margin-left: 18.56866%; }

.row .grid-11 .prefix-1:first-child {
  margin-left: 9.284330%; }

.row .grid-10 .prefix-10 {
  margin-left: 104.25532%; }

.row .grid-10 .prefix-9 {
  margin-left: 94.04255%; }

.row .grid-10 .prefix-8 {
  margin-left: 83.82979%; }

.row .grid-10 .prefix-7 {
  margin-left: 73.61702%; }

.row .grid-10 .prefix-6 {
  margin-left: 63.40426%; }

.row .grid-10 .prefix-5 {
  margin-left: 53.191497%; }

.row .grid-10 .prefix-4 {
  margin-left: 42.97872%; }

.row .grid-10 .prefix-3 {
  margin-left: 32.76596%; }

.row .grid-10 .prefix-2 {
  margin-left: 22.55319%; }

.row .grid-10 .prefix-1 {
  margin-left: 12.34043%; }

.row .grid-10 .prefix-10:first-child {
  margin-left: 102.12766%; }

.row .grid-10 .prefix-9:first-child {
  margin-left: 91.91489%; }

.row .grid-10 .prefix-8:first-child {
  margin-left: 81.70213%; }

.row .grid-10 .prefix-7:first-child {
  margin-left: 71.48936%; }

.row .grid-10 .prefix-6:first-child {
  margin-left: 61.27660%; }

.row .grid-10 .prefix-5:first-child {
  margin-left: 51.06383%; }

.row .grid-10 .prefix-4:first-child {
  margin-left: 40.85106%; }

.row .grid-10 .prefix-3:first-child {
  margin-left: 30.63830%; }

.row .grid-10 .prefix-2:first-child {
  margin-left: 20.42553%; }

.row .grid-10 .prefix-1:first-child {
  margin-left: 10.21277%; }

.row .grid-9 .prefix-9 {
  margin-left: 104.25532%; }

.row .grid-9 .prefix-8 {
  margin-left: 92.90780%; }

.row .grid-9 .prefix-7 {
  margin-left: 81.56028%; }

.row .grid-9 .prefix-6 {
  margin-left: 70.21277%; }

.row .grid-9 .prefix-5 {
  margin-left: 58.86525%; }

.row .grid-9 .prefix-4 {
  margin-left: 47.51773%; }

.row .grid-9 .prefix-3 {
  margin-left: 36.17021%; }

.row .grid-9 .prefix-2 {
  margin-left: 24.822708%; }

.row .grid-9 .prefix-1 {
  margin-left: 13.475186%; }

.row .grid-9 .prefix-9:first-child {
  margin-left: 102.12766%; }

.row .grid-9 .prefix-8:first-child {
  margin-left: 90.78014%; }

.row .grid-9 .prefix-7:first-child {
  margin-left: 79.43262%; }

.row .grid-9 .prefix-6:first-child {
  margin-left: 68.08511%; }

.row .grid-9 .prefix-5:first-child {
  margin-left: 56.73759%; }

.row .grid-9 .prefix-4:first-child {
  margin-left: 45.39007%; }

.row .grid-9 .prefix-3:first-child {
  margin-left: 34.04255%; }

.row .grid-9 .prefix-2:first-child {
  margin-left: 22.69504%; }

.row .grid-9 .prefix-1:first-child {
  margin-left: 11.34752%; }

.row .grid-8 .prefix-8 {
  margin-left: 104.25532%; }

.row .grid-8 .prefix-7 {
  margin-left: 91.48936%; }

.row .grid-8 .prefix-6 {
  margin-left: 78.72341%; }

.row .grid-8 .prefix-5 {
  margin-left: 65.95745%; }

.row .grid-8 .prefix-4 {
  margin-left: 53.19149%; }

.row .grid-8 .prefix-3 {
  margin-left: 40.42553%; }

.row .grid-8 .prefix-2 {
  margin-left: 27.65958%; }

.row .grid-8 .prefix-1 {
  margin-left: 14.89362%; }

.row .grid-8 .prefix-8:first-child {
  margin-left: 102.12766%; }

.row .grid-8 .prefix-7:first-child {
  margin-left: 89.36170%; }

.row .grid-8 .prefix-6:first-child {
  margin-left: 76.59575%; }

.row .grid-8 .prefix-5:first-child {
  margin-left: 63.82979%; }

.row .grid-8 .prefix-4:first-child {
  margin-left: 51.06383%; }

.row .grid-8 .prefix-3:first-child {
  margin-left: 38.29787%; }

.row .grid-8 .prefix-2:first-child {
  margin-left: 25.53192%; }

.row .grid-8 .prefix-1:first-child {
  margin-left: 12.76596%; }

.row .grid-7 .prefix-7 {
  margin-left: 104.25532%; }

.row .grid-7 .prefix-6 {
  margin-left: 89.66565%; }

.row .grid-7 .prefix-5 {
  margin-left: 75.07599%; }

.row .grid-7 .prefix-4 {
  margin-left: 60.48632%; }

.row .grid-7 .prefix-3 {
  margin-left: 45.89666%; }

.row .grid-7 .prefix-2 {
  margin-left: 31.30699%; }

.row .grid-7 .prefix-1 {
  margin-left: 16.71733%; }

.row .grid-7 .prefix-7:first-child {
  margin-left: 102.12766%; }

.row .grid-7 .prefix-6:first-child {
  margin-left: 87.53799%; }

.row .grid-7 .prefix-5:first-child {
  margin-left: 72.94833%; }

.row .grid-7 .prefix-4:first-child {
  margin-left: 58.35866%; }

.row .grid-7 .prefix-3:first-child {
  margin-left: 43.76900%; }

.row .grid-7 .prefix-2:first-child {
  margin-left: 29.17933%; }

.row .grid-7 .prefix-1:first-child {
  margin-left: 14.58967%; }

.row .grid-6 .prefix-6 {
  margin-left: 104.25532%; }

.row .grid-6 .prefix-5 {
  margin-left: 87.23404%; }

.row .grid-6 .prefix-4 {
  margin-left: 70.21277%; }

.row .grid-6 .prefix-3 {
  margin-left: 53.19149%; }

.row .grid-6 .prefix-2 {
  margin-left: 36.17021%; }

.row .grid-6 .prefix-1 {
  margin-left: 19.14894%; }

.row .grid-6 .prefix-6:first-child {
  margin-left: 102.12766%; }

.row .grid-6 .prefix-5:first-child {
  margin-left: 85.10638%; }

.row .grid-6 .prefix-4:first-child {
  margin-left: 68.08511%; }

.row .grid-6 .prefix-3:first-child {
  margin-left: 51.06383%; }

.row .grid-6 .prefix-2:first-child {
  margin-left: 34.04255%; }

.row .grid-6 .prefix-1:first-child {
  margin-left: 17.02128%; }

.row .grid-5 .prefix-5 {
  margin-left: 104.25532%; }

.row .grid-5 .prefix-4 {
  margin-left: 83.82979%; }

.row .grid-5 .prefix-3 {
  margin-left: 63.40426%; }

.row .grid-5 .prefix-2 {
  margin-left: 42.97872%; }

.row .grid-5 .prefix-1 {
  margin-left: 22.55319%; }

.row .grid-5 .prefix-5:first-child {
  margin-left: 102.12766%; }

.row .grid-5 .prefix-4:first-child {
  margin-left: 81.70213%; }

.row .grid-5 .prefix-3:first-child {
  margin-left: 61.27660%; }

.row .grid-5 .prefix-2:first-child {
  margin-left: 40.85106%; }

.row .grid-5 .prefix-1:first-child {
  margin-left: 20.42553%; }

.row .grid-4 .prefix-4 {
  margin-left: 104.25532%; }

.row .grid-4 .prefix-3 {
  margin-left: 78.72341%; }

.row .grid-4 .prefix-2 {
  margin-left: 53.19149%; }

.row .grid-4 .prefix-1 {
  margin-left: 27.65958%; }

.row .grid-4 .prefix-4:first-child {
  margin-left: 102.12766%; }

.row .grid-4 .prefix-3:first-child {
  margin-left: 76.59575%; }

.row .grid-4 .prefix-2:first-child {
  margin-left: 51.06383%; }

.row .grid-4 .prefix-1:first-child {
  margin-left: 25.53192%; }

.row .grid-3 .prefix-3 {
  margin-left: 104.25532%; }

.row .grid-3 .prefix-2 {
  margin-left: 70.21277%; }

.row .grid-3 .prefix-1 {
  margin-left: 36.17021%; }

.row .grid-3 .prefix-3:first-child {
  margin-left: 102.12766%; }

.row .grid-3 .prefix-2:first-child {
  margin-left: 68.08511%; }

.row .grid-3 .prefix-1:first-child {
  margin-left: 34.04255%; }

.row .grid-2 .prefix-2 {
  margin-left: 104.25532%; }

.row .grid-2 .prefix-1 {
  margin-left: 53.19149%; }

.row .grid-2 .prefix-2:first-child {
  margin-left: 102.12766%; }

.row .grid-2 .prefix-1:first-child {
  margin-left: 51.06383%; }

.row .suffix-12 {
  margin-right: 102.12765957446808%; }

.row .suffix-11 {
  margin-right: 93.61702127659574%; }

.row .suffix-10 {
  margin-right: 85.1063829787234%; }

.row .suffix-9 {
  margin-right: 76.59574468085106%; }

.row .suffix-8 {
  margin-right: 68.08510638297872%; }

.row .suffix-7 {
  margin-right: 59.574468085106375%; }

.row .suffix-6 {
  margin-right: 51.063829787234035%; }

.row .suffix-5 {
  margin-right: 42.5531914893617%; }

.row .suffix-4 {
  margin-right: 34.04255319148936%; }

.row .suffix-3 {
  margin-right: 25.53191489361702%; }

.row .suffix-2 {
  margin-right: 17.02127659574468%; }

.row .suffix-1 {
  margin-right: 8.51063829787234%; }

.row .grid-11 .suffix-11 {
  margin-right: 102.12766%; }

.row .grid-11 .suffix-10 {
  margin-right: 92.84330%; }

.row .grid-11 .suffix-9 {
  margin-right: 83.55897%; }

.row .grid-11 .suffix-8 {
  margin-right: 74.27464%; }

.row .grid-11 .suffix-7 {
  margin-right: 64.99031%; }

.row .grid-11 .suffix-6 {
  margin-right: 55.70598%; }

.row .grid-11 .suffix-5 {
  margin-right: 46.42165%; }

.row .grid-11 .suffix-4 {
  margin-right: 37.13732%; }

.row .grid-11 .suffix-3 {
  margin-right: 27.85299%; }

.row .grid-11 .suffix-2 {
  margin-right: 18.56866%; }

.row .grid-11 .suffix-1 {
  margin-right: 9.284330%; }

.row .grid-10 .suffix-10 {
  margin-right: 102.12766%; }

.row .grid-10 .suffix-9 {
  margin-right: 91.91489%; }

.row .grid-10 .suffix-8 {
  margin-right: 81.70213%; }

.row .grid-10 .suffix-7 {
  margin-right: 71.48936%; }

.row .grid-10 .suffix-6 {
  margin-right: 61.27660%; }

.row .grid-10 .suffix-5 {
  margin-right: 51.06383%; }

.row .grid-10 .suffix-4 {
  margin-right: 40.85106%; }

.row .grid-10 .suffix-3 {
  margin-right: 30.63830%; }

.row .grid-10 .suffix-2 {
  margin-right: 20.42553%; }

.row .grid-10 .suffix-1 {
  margin-right: 10.21277%; }

.row .grid-9 .suffix-9 {
  margin-right: 102.12766%; }

.row .grid-9 .suffix-8 {
  margin-right: 90.78014%; }

.row .grid-9 .suffix-7 {
  margin-right: 79.43262%; }

.row .grid-9 .suffix-6 {
  margin-right: 68.08511%; }

.row .grid-9 .suffix-5 {
  margin-right: 56.73759%; }

.row .grid-9 .suffix-4 {
  margin-right: 45.39007%; }

.row .grid-9 .suffix-3 {
  margin-right: 34.04255%; }

.row .grid-9 .suffix-2 {
  margin-right: 22.69504%; }

.row .grid-9 .suffix-1 {
  margin-right: 11.34752%; }

.row .grid-8 .suffix-8 {
  margin-right: 102.12766%; }

.row .grid-8 .suffix-7 {
  margin-right: 89.36170%; }

.row .grid-8 .suffix-6 {
  margin-right: 76.59575%; }

.row .grid-8 .suffix-5 {
  margin-right: 63.82979%; }

.row .grid-8 .suffix-4 {
  margin-right: 51.06383%; }

.row .grid-8 .suffix-3 {
  margin-right: 38.29787%; }

.row .grid-8 .suffix-2 {
  margin-right: 25.53192%; }

.row .grid-8 .suffix-1 {
  margin-right: 12.76596%; }

.row .grid-7 .suffix-7 {
  margin-right: 102.12766%; }

.row .grid-7 .suffix-6 {
  margin-right: 87.53799%; }

.row .grid-7 .suffix-5 {
  margin-right: 72.94833%; }

.row .grid-7 .suffix-4 {
  margin-right: 58.35866%; }

.row .grid-7 .suffix-3 {
  margin-right: 43.76900%; }

.row .grid-7 .suffix-2 {
  margin-right: 29.17933%; }

.row .grid-7 .suffix-1 {
  margin-right: 14.58967%; }

.row .grid-6 .suffix-6 {
  margin-right: 102.12766%; }

.row .grid-6 .suffix-5 {
  margin-right: 85.10638%; }

.row .grid-6 .suffix-4 {
  margin-right: 68.08511%; }

.row .grid-6 .suffix-3 {
  margin-right: 51.06383%; }

.row .grid-6 .suffix-2 {
  margin-right: 34.04255%; }

.row .grid-6 .suffix-1 {
  margin-right: 17.02128%; }

.row .grid-5 .suffix-5 {
  margin-right: 102.12766%; }

.row .grid-5 .suffix-4 {
  margin-right: 81.70213%; }

.row .grid-5 .suffix-3 {
  margin-right: 61.27660%; }

.row .grid-5 .suffix-2 {
  margin-right: 40.85106%; }

.row .grid-5 .suffix-1 {
  margin-right: 20.42553%; }

.row .grid-4 .suffix-4 {
  margin-right: 102.12766%; }

.row .grid-4 .suffix-3 {
  margin-right: 76.59575%; }

.row .grid-4 .suffix-2 {
  margin-right: 51.06383%; }

.row .grid-4 .suffix-1 {
  margin-right: 25.53192%; }

.row .grid-3 .suffix-3 {
  margin-right: 102.12766%; }

.row .grid-3 .suffix-2 {
  margin-right: 68.08511%; }

.row .grid-3 .suffix-1 {
  margin-right: 34.04255%; }

.row .grid-2 .suffix-2 {
  margin-right: 102.12766%; }

.row .grid-2 .suffix-1 {
  margin-right: 51.06383%; }

@media (max-width: 767px) {
  #wrapper {
    width: 100%; }
  .main {
    width: auto; }
  .row {
    width: 100%; }
  .row {
    margin-left: 0; }
  [class*="grid"],
  .row [class*="grid"] {
    float: none;
    display: block;
    width: 100% !important;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; } }

@media (min-width: 768px) and (max-width: 979px) {
  .main {
    width: 724px; }
  #wrapper {
    width: 744px;
    margin: auto; } }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.lg-outer.fb-comments .lg-img-wrap, .lg-outer.fb-comments .lg-video-cont {
  padding-right: 400px !important; }

.lg-outer.fb-comments .fb-comments {
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 420px;
  z-index: 99999;
  background: #fff url("../img/loading.gif") no-repeat scroll center center; }
  .lg-outer.fb-comments .fb-comments.fb_iframe_widget {
    background-image: none; }
    .lg-outer.fb-comments .fb-comments.fb_iframe_widget.fb_iframe_widget_loader {
      background: #fff url("../img/loading.gif") no-repeat scroll center center; }

.lg-outer.fb-comments .lg-toolbar {
  right: 420px;
  width: auto; }

.lg-outer.fb-comments .lg-actions .lg-next {
  right: 420px; }

.lg-outer.fb-comments .lg-item {
  background-image: none; }
  .lg-outer.fb-comments .lg-item.lg-complete .lg-img-wrap, .lg-outer.fb-comments .lg-item.lg-complete .lg-video-cont {
    background-image: none; }

.lg-outer.fb-comments .lg-img-wrap, .lg-outer.fb-comments .lg-video-cont {
  background: url("../img/loading.gif") no-repeat scroll transparent;
  background-position: center center;
  background-position: calc((100% - 400px) / 2) center; }

.lg-outer.fb-comments .lg-sub-html {
  padding: 0;
  position: static; }

.lg-outer.fb-comments .lg-video-cont {
  max-width: 1250px !important; }

.lg-css3.lg-zoom-in .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  .lg-css3.lg-zoom-in .lg-item.lg-next-slide {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  .lg-css3.lg-zoom-in .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-in .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-big .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-in-big .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
    transform: scale3d(0.7, 0.7, 0.7); }
  .lg-css3.lg-zoom-out .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
    transform: scale3d(0.7, 0.7, 0.7); }
  .lg-css3.lg-zoom-out .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-out .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-big .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-out-big .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-in .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-out-in .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-out .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-in-out .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-soft-zoom .lg-item {
  opacity: 0; }
  .lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  .lg-css3.lg-soft-zoom .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  .lg-css3.lg-soft-zoom .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-soft-zoom .lg-item.lg-prev-slide, .lg-css3.lg-soft-zoom .lg-item.lg-next-slide, .lg-css3.lg-soft-zoom .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-scale-up .lg-item {
  opacity: 0; }
  .lg-css3.lg-scale-up .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0); }
  .lg-css3.lg-scale-up .lg-item.lg-next-slide {
    -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0); }
  .lg-css3.lg-scale-up .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-scale-up .lg-item.lg-prev-slide, .lg-css3.lg-scale-up .lg-item.lg-next-slide, .lg-css3.lg-scale-up .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide-circular .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }
  .lg-css3.lg-slide-circular .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular .lg-item.lg-next-slide, .lg-css3.lg-slide-circular .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-up .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }
  .lg-css3.lg-slide-circular-up .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }
  .lg-css3.lg-slide-circular-up .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-down .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }
  .lg-css3.lg-slide-circular-down .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }
  .lg-css3.lg-slide-circular-down .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(0, -100%, 0); }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(0, 100%, 0); }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-left .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-down .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-vertical .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  .lg-css3.lg-slide-vertical .lg-item.lg-next-slide {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  .lg-css3.lg-slide-vertical .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-vertical-growth .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0); }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide {
    -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0); }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
    -moz-transform: skew(10deg, 0deg);
    -o-transform: skew(10deg, 0deg);
    -ms-transform: skew(10deg, 0deg);
    -webkit-transform: skew(10deg, 0deg);
    transform: skew(10deg, 0deg); }
  .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide {
    -moz-transform: skew(10deg, 0deg);
    -o-transform: skew(10deg, 0deg);
    -ms-transform: skew(10deg, 0deg);
    -webkit-transform: skew(10deg, 0deg);
    transform: skew(10deg, 0deg); }
  .lg-css3.lg-slide-skew-only .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(-10deg, 0deg);
    -o-transform: skew(-10deg, 0deg);
    -ms-transform: skew(-10deg, 0deg);
    -webkit-transform: skew(-10deg, 0deg);
    transform: skew(-10deg, 0deg); }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide {
    -moz-transform: skew(-10deg, 0deg);
    -o-transform: skew(-10deg, 0deg);
    -ms-transform: skew(-10deg, 0deg);
    -webkit-transform: skew(-10deg, 0deg);
    transform: skew(-10deg, 0deg); }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, 10deg);
    -o-transform: skew(0deg, 10deg);
    -ms-transform: skew(0deg, 10deg);
    -webkit-transform: skew(0deg, 10deg);
    transform: skew(0deg, 10deg); }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, 10deg);
    -o-transform: skew(0deg, 10deg);
    -ms-transform: skew(0deg, 10deg);
    -webkit-transform: skew(0deg, 10deg);
    transform: skew(0deg, 10deg); }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, -10deg);
    -o-transform: skew(0deg, -10deg);
    -ms-transform: skew(0deg, -10deg);
    -webkit-transform: skew(0deg, -10deg);
    transform: skew(0deg, -10deg); }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, -10deg);
    -o-transform: skew(0deg, -10deg);
    -ms-transform: skew(0deg, -10deg);
    -webkit-transform: skew(0deg, -10deg);
    transform: skew(0deg, -10deg); }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
    -moz-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew .lg-item.lg-next-slide {
    -moz-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew .lg-item.lg-next-slide, .lg-css3.lg-slide-skew .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide {
    -moz-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
    -moz-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    -o-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    transform: skew(60deg, 0deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide {
    -moz-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    -o-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    transform: skew(60deg, 0deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    -o-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide {
    -moz-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    -o-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    -o-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    transform: skew(0deg, 20deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    -o-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    transform: skew(0deg, 20deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    -o-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    transform: skew(0deg, -20deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    -o-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    transform: skew(0deg, -20deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop .lg-item {
  opacity: 0; }
  .lg-css3.lg-lollipop .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-lollipop .lg-item.lg-next-slide {
    -moz-transform: translate3d(0, 0, 0) scale(0.5);
    -o-transform: translate3d(0, 0, 0) scale(0.5);
    -ms-transform: translate3d(0, 0, 0) scale(0.5);
    -webkit-transform: translate3d(0, 0, 0) scale(0.5);
    transform: translate3d(0, 0, 0) scale(0.5); }
  .lg-css3.lg-lollipop .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-lollipop .lg-item.lg-prev-slide, .lg-css3.lg-lollipop .lg-item.lg-next-slide, .lg-css3.lg-lollipop .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
    -moz-transform: translate3d(0, 0, 0) scale(0.5);
    -o-transform: translate3d(0, 0, 0) scale(0.5);
    -ms-transform: translate3d(0, 0, 0) scale(0.5);
    -webkit-transform: translate3d(0, 0, 0) scale(0.5);
    transform: translate3d(0, 0, 0) scale(0.5); }
  .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-lollipop-rev .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate .lg-item {
  opacity: 0; }
  .lg-css3.lg-rotate .lg-item.lg-prev-slide {
    -moz-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); }
  .lg-css3.lg-rotate .lg-item.lg-next-slide {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  .lg-css3.lg-rotate .lg-item.lg-current {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1; }
  .lg-css3.lg-rotate .lg-item.lg-prev-slide, .lg-css3.lg-rotate .lg-item.lg-next-slide, .lg-css3.lg-rotate .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  .lg-css3.lg-rotate-rev .lg-item.lg-next-slide {
    -moz-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); }
  .lg-css3.lg-rotate-rev .lg-item.lg-current {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1; }
  .lg-css3.lg-rotate-rev .lg-item.lg-prev-slide, .lg-css3.lg-rotate-rev .lg-item.lg-next-slide, .lg-css3.lg-rotate-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-tube .lg-item {
  opacity: 0; }
  .lg-css3.lg-tube .lg-item.lg-prev-slide {
    -moz-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    -o-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    -ms-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    -webkit-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0); }
  .lg-css3.lg-tube .lg-item.lg-next-slide {
    -moz-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    -o-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    -ms-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    -webkit-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    transform: scale3d(1, 0, 1) translate3d(100%, 0, 0); }
  .lg-css3.lg-tube .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-tube .lg-item.lg-prev-slide, .lg-css3.lg-tube .lg-item.lg-next-slide, .lg-css3.lg-tube .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.eot?n1z373");
  src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal; }

.lg-icon {
  font-family: 'lg';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: none; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar .lg-download:after {
    content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      -o-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }
  .lg-outer .lg-toogle-thumb:after {
    content: "\e1ff"; }
  .lg-outer .lg-toogle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e901"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e904"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e902"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e903"; }

.lg-group:after {
  content: "";
  display: table;
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("../img/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTa-j2U0lmluP9RWlSytm3ho.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTZX5f-9o1vgP2EXwfjgl7AY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRWV49_lSm1NYrwo-zkhivY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTaaRobkAwv3vxw3jMhVENGA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTf8zf_FOSsgRmwsS7Aa9k2w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTT0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/LWCjsQkB6EMdfHrEVqA1KRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/xozscpT2726on7jbcb_pAhJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/59ZRklaO5bWGqF5A9baEERJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSq-j2U0lmluP9RWlSytm3ho.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNShWV49_lSm1NYrwo-zkhivY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSqaRobkAwv3vxw3jMhVENGA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSv8zf_FOSsgRmwsS7Aa9k2w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzK-j2U0lmluP9RWlSytm3ho.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzJX5f-9o1vgP2EXwfjgl7AY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzBWV49_lSm1NYrwo-zkhivY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKaRobkAwv3vxw3jMhVENGA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzP8zf_FOSsgRmwsS7Aa9k2w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzD0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBjTOQ_MqJVwkKsUn0wKzc2I.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBjUj_cnvWIuuBMVgbX098Mw.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBkbcKLIaa1LC45dFaAfauRA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBmo_sUJ8uO4YLWRInS22T3Y.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBr6up8jxqWt8HVA3mDhkV_0.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBiYE0-AqJ3nfInTTiDXDjU4.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Open Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* Cognireset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  /* COG: this is known to cause problems in CQ4 and SharePoint */ }

/* remember to define focus styles! */
:focus {
  outline: 0; }

body {
  line-height: 1;
  color: black;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  position: relative;
  overflow: auto; }

ol, ul {
  list-style: none; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ""; }

blockquote, q {
  quotes: "" ""; }

/* COG: make sup and sup working again */
sub {
  vertical-align: sub;
  font-size: 0.9em; }

sup {
  vertical-align: super;
  font-size: 0.9em; }

.scEnabledChrome {
  opacity: 1 !important; }

/************* COMPONENT VARS *************/
/* Submenu variation */
/************* COMPONENT VARS END *************/
/*media query proposal - for refactoring 15-06-2016*/
/********************* global slider animations ******************/
#wrapper {
  /* global slider style that can be applied to virtaually any component via Sitecore */ }
  #wrapper > #content .component.padding2.component {
    padding-bottom: 2em; }
  #wrapper > #content .component.padding4.component {
    padding-bottom: 4em; }
  #wrapper > #content .component.padding7.component {
    padding-bottom: 7em; }
  #wrapper > #content .component.padding-top2.component {
    padding-top: 2em; }
  #wrapper > #content .component.padding-top4.component {
    padding-top: 4em; }
  #wrapper > #content .component.padding-top7.component {
    padding-top: 7em; }
  #wrapper > #content .component.component-failed {
    display: none !important; }
  #wrapper .disclaimer-index {
    line-height: 1.6em; }
  #wrapper .center-text {
    text-align: center; }
  #wrapper .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat; }
  #wrapper .lightdark-bg, #wrapper .light-grey-bg {
    background-color: #7f7f7f;
    color: #ffffff; }
  #wrapper .dark-bg {
    background-color: #2d2d2d;
    color: #ffffff; }
  #wrapper .white-bg {
    background-color: #ffffff;
    color: #2d2d2d; }
  #wrapper .magenta-bg, #wrapper .corporate-bg {
    background-color: #c6168d; }
  #wrapper .lighter-grey-bg {
    background-color: #f5f5f5; }
  #wrapper :not(.content-overflow).white-txt, #wrapper :not(.content-overflow).white-txt a, #wrapper :not(.content-overflow).white-txt a:hover {
    color: #ffffff; }
  #wrapper .light-grey-txt {
    color: #aaaaaa !important; }
  #wrapper .dark-grey-txt {
    color: #2d2d2d !important; }
  #wrapper .corporate-txt {
    color: #c6168d !important; }
  #wrapper .header-break > .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    line-height: 1.5em;
    overflow: hidden; }
    #wrapper .header-break > .component-content:after {
      position: absolute;
      bottom: 3px;
      overflow: hidden;
      width: 48%;
      height: 1px;
      content: '\a0';
      background-color: #2d2d2d;
      margin-left: 2%; }
    #wrapper .header-break > .component-content:before {
      position: absolute;
      bottom: 3px;
      overflow: hidden;
      width: 48%;
      height: 1px;
      content: '\a0';
      background-color: #2d2d2d;
      margin-left: -50%;
      text-align: right; }
  #wrapper .margin2.component:not(.content-overflow) {
    margin-bottom: 2rem; }
  #wrapper .margin4.component:not(.content-overflow) {
    margin-bottom: 4rem; }
  #wrapper .margin7.component:not(.content-overflow) {
    margin-bottom: 7rem; }
  #wrapper .margin2.component.content-overflow {
    margin-bottom: 1em; }
  #wrapper .margin4.component.content-overflow {
    margin-bottom: 1em; }
  #wrapper .margin7.component.content-overflow {
    margin-bottom: 2em; }
  #wrapper .minheight10.component > .component-content {
    min-height: 10em; }
  #wrapper .minheight20.component > .component-content {
    min-height: 20em; }
  #wrapper .minheight30.component > .component-content {
    min-height: 30em; }
  #wrapper .minheight40.component > .component-content {
    min-height: 40em; }
  #wrapper .w10.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w10 div.inner > *, #wrapper .w10 .generic-text-wrapper > * {
    max-width: 10%; }
  #wrapper .w10.two-column-spot > .component-content, #wrapper .w10.image-spot > .component-content {
    max-width: 10%; }
  #wrapper .w20.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w20 div.inner > *, #wrapper .w20 .generic-text-wrapper > * {
    max-width: 20%; }
  #wrapper .w20.two-column-spot > .component-content, #wrapper .w20.image-spot > .component-content {
    max-width: 20%; }
  #wrapper .w30.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w30 div.inner > *, #wrapper .w30 .generic-text-wrapper > * {
    max-width: 30%; }
  #wrapper .w30.two-column-spot > .component-content, #wrapper .w30.image-spot > .component-content {
    max-width: 30%; }
  #wrapper .w40.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w40 div.inner > *, #wrapper .w40 .generic-text-wrapper > * {
    max-width: 40%; }
  #wrapper .w40.two-column-spot > .component-content, #wrapper .w40.image-spot > .component-content {
    max-width: 40%; }
  #wrapper .w50.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w50 div.inner > *, #wrapper .w50 .generic-text-wrapper > * {
    max-width: 50%; }
  #wrapper .w50.two-column-spot > .component-content, #wrapper .w50.image-spot > .component-content {
    max-width: 50%; }
  #wrapper .w60.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w60 div.inner > *, #wrapper .w60 .generic-text-wrapper > * {
    max-width: 60%; }
  #wrapper .w60.two-column-spot > .component-content, #wrapper .w60.image-spot > .component-content {
    max-width: 60%; }
  #wrapper .w70.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w70 div.inner > *, #wrapper .w70 .generic-text-wrapper > * {
    max-width: 70%; }
  #wrapper .w70.two-column-spot > .component-content, #wrapper .w70.image-spot > .component-content {
    max-width: 70%; }
  #wrapper .w80.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w80 div.inner > *, #wrapper .w80 .generic-text-wrapper > * {
    max-width: 80%; }
  #wrapper .w80.two-column-spot > .component-content, #wrapper .w80.image-spot > .component-content {
    max-width: 80%; }
  #wrapper .w90.full-component > .component-content > .intro-banner-content > .intro-banner-content-align,
  #wrapper .w90 div.inner > *, #wrapper .w90 .generic-text-wrapper > * {
    max-width: 90%; }
  #wrapper .w90.two-column-spot > .component-content, #wrapper .w90.image-spot > .component-content {
    max-width: 90%; }
  #wrapper .icon, #wrapper .box-icon {
    font-family: "Oticon-icon-www";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1; }
  #wrapper .box-icon {
    font-size: 1.5em;
    float: right;
    margin: -0.1em 0 -0.1em 0.3em; }
  #wrapper .singlesignon fieldset {
    border: none; }
    #wrapper .singlesignon fieldset label {
      line-height: 2; }
  #wrapper .slider-stack-wrapper {
    position: relative;
    height: 250px;
    overflow: hidden; }
    #wrapper .slider-stack-wrapper > .component.slider-stack {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      box-sizing: border-box; }
    #wrapper .slider-stack-wrapper > .slider-tabs {
      position: absolute;
      z-index: 1; }
      #wrapper .slider-stack-wrapper > .slider-tabs .slider-tab {
        display: inline-block;
        cursor: pointer;
        user-select: none; }
    #wrapper .slider-stack-wrapper > .slider-arrow-left,
    #wrapper .slider-stack-wrapper > .slider-arrow-right {
      position: absolute;
      z-index: 100;
      display: inline-block;
      cursor: pointer;
      user-select: none; }
    #wrapper .slider-stack-wrapper .slider-spinner {
      position: absolute;
      z-index: 999;
      height: 2em;
      width: 2em;
      overflow: show;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
      #wrapper .slider-stack-wrapper .slider-spinner:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: rgba($black,.1);*/ }
      #wrapper .slider-stack-wrapper .slider-spinner:not(:required) {
        /* hide "loading..." text */
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0; }
      #wrapper .slider-stack-wrapper .slider-spinner:not(:required):after {
        content: '';
        display: block;
        font-size: 10px;
        width: 1em;
        height: 1em;
        margin-top: -0.5em;
        animation: spinner 1500ms infinite linear;
        border-radius: 0.5em;
        box-shadow: #c6168d 1.5em 0 0 0, #c6168d 1.1em 1.1em 0 0, #c6168d 0 1.5em 0 0, #c6168d -1.1em 1.1em 0 0, #c6168d -1.5em 0 0 0, #c6168d -1.1em -1.1em 0 0, #c6168d 0 -1.5em 0 0, #c6168d 1.1em -1.1em 0 0; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media screen and (max-width: 600px) {
  #wrapper .margin2.component:not(.content-overflow) {
    margin-bottom: 1em; }
  #wrapper .margin4.component:not(.content-overflow) {
    margin-bottom: 2em; }
  #wrapper .margin7.component:not(.content-overflow) {
    margin-bottom: 2em; } }

#wrapper #header {
  top: auto\9; }

#wrapper *::-ms-backdrop, #wrapper #header {
  top: auto; }

* {
  outline: none; }

html, body {
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
  font-size: 16px; }

body {
  margin: 0;
  padding: 0;
  color: #404040;
  line-height: 1;
  font-size: 16px;
  background-color: #fff;
  font-family: "Soho Gothic W01 Light", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.inner:before, .banner-text:before, .clearfix:before, .clearfix:after, section:before, section:after, .container:before, .container:after, article:before, article:after {
  content: '';
  display: table; }

.inner:after, .clearfix:after, section:after, div.containter:after, article:after, .inner:after {
  clear: both; }

.clearfix {
  clear: both; }

.clearfix, section, .container, article, .inner {
  zoom: 1; }

.main {
  margin: 0;
  width: 100%;
  padding: 0; }

a, .like-a {
  color: #c6168d;
  text-decoration: none; }

a.link, .like-a-link {
  display: inline-block;
  position: relative; }

#wrapper {
  margin: 0;
  width: 100%;
  padding: 0;
  /* p:not(.cookie-index .image-spot-element-link) {
        line-height: 1.5;
        //max-width: 40em;
        padding: 0;
        margin:0 0 1.5em 0;
        color: inherit;
    }*/
  /* SG
# Style: Base / slim
Makes a components width change to the defined slim target width of the site.
See $slim_target_width
 */ }
  #wrapper .full-blur {
    filter: blur(10px);
    filter: progid:DXImageTransform.Microsoft.Blur( PixelRadius=10 ); }
  #wrapper .cover h1, #wrapper .cover h2.like-h1, #wrapper .cover h3.like-h1, #wrapper .cover .like-h1 {
    font-size: 4.5em; }
  #wrapper .component {
    /* SG
# Style: Base / top-margin
 Adds top margin to a component. Value defined in base and overridden
*/ }
    #wrapper .component.top-margin {
      margin-top: 7rem; }
    #wrapper .component.component-ready {
      visibility: visible; }
  #wrapper .overflow-hidden {
    overflow: hidden; }
  #wrapper .feature .banner-text:not(.button) {
    color: #c6168d;
    margin-right: 1em; }
  #wrapper .has-background .intro-banner-text * {
    text-shadow: 0 0 1em #ffffff, 0 0 1em #ffffff; }
  #wrapper .text-black-blur .intro-banner-text * {
    text-shadow: 0 0 1em rgba(0, 0, 0, 0.15); }
  #wrapper .left {
    float: left; }
  #wrapper .right {
    float: right; }
  #wrapper .full {
    width: 100%; }
  #wrapper .threefourth {
    width: 75%; }
  #wrapper .twothird {
    width: 66.6666%; }
  #wrapper .half {
    width: 50%; }
  #wrapper .third {
    width: 33.3333%; }
  #wrapper .fourth {
    width: 25%; }
  #wrapper .slim .component-content {
    width: 60rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper .custom-selector, #wrapper .market-selector {
    position: relative;
    background: #fff;
    border-radius: 2em; }
    #wrapper .custom-selector input, #wrapper .custom-selector select, #wrapper .market-selector input, #wrapper .market-selector select {
      background: transparent;
      position: relative;
      z-index: 3;
      padding-left: 3.5em;
      padding-right: 3.5em;
      margin: 0; }
    #wrapper .custom-selector:before, #wrapper .market-selector:before {
      content: 'Q';
      font-family: "Oticon-icon-www";
      top: 0;
      left: 0;
      font-size: 1.3em;
      color: #c6168d;
      padding: .9em 1.2em;
      position: absolute;
      z-index: 1; }
    #wrapper .custom-selector:after, #wrapper .market-selector:after {
      content: 'Q';
      font-family: "Oticon-icon-www";
      top: 0;
      right: 0;
      font-size: 1.3em;
      color: inherit;
      padding: .9em 1.2em;
      position: absolute;
      z-index: 1; }
  #wrapper input::-ms-clear {
    display: none; }
  #wrapper #footer .component[class*="-section-grid"],
  #wrapper #content .component[class*="-section-grid"] {
    margin-left: 0;
    margin-right: 0;
    float: none; }
  #wrapper #content .component.hide {
    display: none; }
  #wrapper #content .component[class*="-section-grid"] > .component-content {
    font-size: 0; }
    #wrapper #content .component[class*="-section-grid"] > .component-content div[class*="section-"] {
      display: inline-block;
      font-size: 1rem;
      vertical-align: top;
      width: 100%;
      margin: 0;
      margin-bottom: 1rem; }
      @media only screen and (min-width: 768px) {
        #wrapper #content .component[class*="-section-grid"] > .component-content div[class*="section-"] {
          margin-left: 2.127659574468085%; }
          #wrapper #content .component[class*="-section-grid"] > .component-content div[class*="section-"]:first-child {
            margin: 0; } }
    #wrapper #content .component[class*="-section-grid"] > .component-content .component-content {
      width: 100%;
      max-width: 100%; }
  @media only screen and (min-width: 768px) {
    #wrapper #content .component.two-section-grid.two-col-padding {
      padding: 5rem 3rem;
      box-sizing: border-box; }
    #wrapper #content .component.two-section-grid > .component-content div[class*="section-"] {
      width: 48.93617021276595%; }
    #wrapper #content .component.three-section-grid > .component-content div[class*="section-"] {
      width: 31.914893617021278%; }
    #wrapper #content .component.four-section-grid > .component-content div[class*="section-"] {
      width: 23.404255319148934%; }
    #wrapper #content .component.five-section-grid > .component-content div[class*="section-"] {
      width: 18.29787234042553%; } }
  #wrapper .component:not(.appframe-component) img {
    border: none;
    max-width: 100%;
    height: auto; }

/* big resolutions */
@media only screen and (min-width: 1900px) {
  html, body {
    font-size: 18px; } }

@media only screen and (min-width: 2200px) {
  html, body {
    font-size: 22px; }
  #header .nav {
    display: block; } }

/* small resolutions */
@media only screen and (max-width: 1250px) {
  html, body {
    font-size: 14px; } }

@media only screen and (max-width: 1020px) {
  #wrapper .third, #wrapper .third.left {
    width: auto;
    float: none; }
  #wrapper .fourth {
    width: 50%; }
  #wrapper .twothird {
    width: 100%; }
  #wrapper .half {
    width: 100%; }
  #wrapper .half.left > * {
    margin-right: 0%; }
  #wrapper .half.right > * {
    margin-left: 0%; }
  #wrapper div.inner {
    padding: 5em 0; }
  #wrapper .component.mag-container.cover {
    padding-bottom: 3em; }
  #wrapper div.inner.no-padding-top {
    padding-top: 0; }
  #wrapper div.inner.extra-padding-top {
    padding-top: 5em; }
  #wrapper div.inner.extra-padding-bottom {
    padding-bottom: 8em; }
  #wrapper div.inner.no-padding-bottom {
    padding-bottom: 0; }
  #wrapper section.cover {
    padding-bottom: 3em; }
  #wrapper div.banner-boxes {
    /*position: relative;
            margin-top: 3px;*/
    position: absolute;
    bottom: 0; }
  #wrapper div.banner-boxes ul li {
    margin-bottom: 1px; }
  #wrapper div.banner-boxes ul li .image-spot-element-link {
    margin-top: 0.65em; }
  #wrapper img.bg.index-hand-held-from-right {
    top: -2%;
    left: 54%;
    max-width: 100%; }
  #wrapper img.bg.index-hand-held-from-left {
    top: -2%;
    right: 54%;
    max-width: 100%; } }

@media only screen and (max-width: 800px) {
  html, body {
    font-size: 13px; } }

@media only screen and (max-width: 600px) {
  #wrapper .fourth, #wrapper .fourth.left {
    width: auto;
    float: none; }
  #wrapper div.inner {
    padding: 4em 0; }
  #wrapper #footer div.inner > div > * {
    margin-right: 0; }
  #wrapper div.cover-background {
    background-position: 64% 50%; }
  #wrapper #footer div.omega .component.rich-text .component-content {
    margin-bottom: 0; }
  #wrapper #footer div.alpha .component.rich-text .component-content {
    width: auto; } }

@media only screen and (max-width: 400px) {
  html, body {
    font-size: 12px; }
  /*.nav-expander span:first-child {
		-webkit-transform: rotate(-45deg) translate(-0.5em, 0.5em);
		-moz-transform: rotate(-45deg) translate(-0.5em, 0.5em);
		-ms-transform: rotate(-45deg) translate(-0.5em, 0.5em);
		-o-transform: rotate(-45deg) translate(-0.5em, 0.5em);
		transform: rotate(-45deg) translate(-0.5em, 0.5em);
	}*/ }

/* Portrait */
/* Landscape */
/* TRANSITIONS */
@keyframes cta-down {
  0% {
    transform: translateY(0%);
    opacity: 1.0; }
  100% {
    transform: translateY(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateY(-120%);
    opacity: 0.0; }
  50.1% {
    transform: translateY(120%);
    opacity: 0.0; }
  50.2% {
    transform: translateY(120%);
    opacity: 1.0; } }

@-webkit-keyframes cta-down {
  0% {
    transform: translateY(0%);
    opacity: 1.0; }
  100% {
    transform: translateY(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateY(-120%);
    opacity: 0.0; }
  50.1% {
    transform: translateY(120%);
    opacity: 0.0; }
  50.2% {
    transform: translateY(120%);
    opacity: 1.0; } }

@keyframes cta-down-hover {
  0% {
    transform: translateY(0%);
    opacity: 1.0; }
  100% {
    transform: translateY(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateY(120%);
    opacity: 0.0; }
  50.1% {
    transform: translateY(-120%);
    opacity: 0.0; }
  50.2% {
    transform: translateY(-120%);
    opacity: 1.0; } }

@-webkit-keyframes cta-down-hover {
  0% {
    transform: translateY(0%);
    opacity: 1.0; }
  100% {
    transform: translateY(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateY(120%);
    opacity: 0.0; }
  50.1% {
    transform: translateY(-120%);
    opacity: 0.0; }
  50.2% {
    transform: translateY(-120%);
    opacity: 1.0; } }

@keyframes cta-right-hover {
  0%, 100% {
    transform: translateX(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateX(120%);
    opacity: 0.0; }
  50.1% {
    transform: translateX(-120%);
    opacity: 0.0; }
  50.2% {
    transform: translateX(-120%);
    opacity: 1.0; } }

@-webkit-keyframes cta-right-hover {
  0%, 100% {
    transform: translateX(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateX(120%);
    opacity: 0.0; }
  50.1% {
    transform: translateX(-120%);
    opacity: 0.0; }
  50.2% {
    transform: translateX(-120%);
    opacity: 1.0; } }

@keyframes cta-right {
  0%, 100% {
    transform: translateX(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateX(-120%);
    opacity: 0.0; }
  50.1% {
    transform: translateX(120%);
    opacity: 0.0; }
  50.2% {
    transform: translateX(120%);
    opacity: 1.0; } }

@-webkit-keyframes cta-right {
  0%, 100% {
    transform: translateX(0%);
    opacity: 1.0; }
  49% {
    opacity: 1.0; }
  50% {
    transform: translateX(-120%);
    opacity: 0.0; }
  50.1% {
    transform: translateX(120%);
    opacity: 0.0; }
  50.2% {
    transform: translateX(120%);
    opacity: 1.0; } }

/* TRANSITIONS */
@media only screen and (max-width: 767px) {
  /* SG
# Style: Base / hide-on-mobile
Hides component on mobile sized screens
*/
  .hide-on-mobile {
    display: none; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* SG
# Style: Base / hide-on-tablet
Hides component on tablet sized screens
*/
  .hide-on-tablet {
    display: none; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hide-on-tablet {
    display: none; } }

/* Secondary Btn Blue */
/************* COMPONENT VARS *************/
/************* COMPONENT VARS END *************/
#wrapper #footer .component.three-section-grid {
  position: relative;
  background-color: #005da9;
  padding-top: 5.1em;
  color: #ffffff;
  /*column titles */
  /*limited row width */ }
  #wrapper #footer .component.three-section-grid * {
    box-sizing: border-box; }
  #wrapper #footer .component.three-section-grid h2, #wrapper #footer .component.three-section-grid h3, #wrapper #footer .component.three-section-grid h4, #wrapper #footer .component.three-section-grid a:not([class*="btn"]) {
    color: #ffffff;
    padding-top: 0; }
  #wrapper #footer .component.three-section-grid p {
    padding: 0; }
  #wrapper #footer .component.three-section-grid h4 {
    padding-bottom: .8em; }
  #wrapper #footer .component.three-section-grid a:not([class*="btn"]) {
    color: #e40049;
    font-family: "Proxima N W01 Bold", sans-serif;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s;
    color: #a7d3ac; }
    #wrapper #footer .component.three-section-grid a:not([class*="btn"]):hover {
      opacity: 0.7; }
  #wrapper #footer .component.three-section-grid .phone-link-footer {
    font-family: "Proxima N W01 Bold", sans-serif;
    color: #ffffff !important;
    margin-left: 1.5em; }
    #wrapper #footer .component.three-section-grid .phone-link-footer:before {
      font-family: "retail-icon-font";
      display: inline-block;
      content: "";
      color: #ffffff;
      font-size: 0.75em;
      font-weight: normal;
      position: absolute;
      margin-left: -1.5em;
      margin-top: 0.3em;
      speak: none; }
  #wrapper #footer .component.three-section-grid .email-link-footer {
    font-family: "Proxima N W01 Bold", sans-serif;
    color: #ffffff !important;
    margin-left: 1.5em; }
    #wrapper #footer .component.three-section-grid .email-link-footer:before {
      font-family: "retail-icon-font";
      display: inline-block;
      content: "";
      color: #ffffff;
      font-size: 0.75em;
      font-weight: normal;
      position: absolute;
      margin-left: -1.5em;
      margin-top: 0.3em;
      speak: none; }
    #wrapper #footer .component.three-section-grid .email-link-footer:before {
      margin-top: 0.35em; }
  #wrapper #footer .component.three-section-grid .fax-link-footer {
    font-family: "Proxima N W01 Bold", sans-serif;
    color: #ffffff !important;
    margin-left: 1.5em; }
    #wrapper #footer .component.three-section-grid .fax-link-footer:before {
      font-family: "retail-icon-font";
      display: inline-block;
      content: "";
      color: #ffffff;
      font-size: 0.75em;
      font-weight: normal;
      position: absolute;
      margin-left: -1.5em;
      margin-top: 0.3em;
      speak: none; }
  #wrapper #footer .component.three-section-grid .marker-link-footer {
    font-family: "Proxima N W01 Bold", sans-serif;
    color: #ffffff !important;
    margin-left: 1.5em; }
    #wrapper #footer .component.three-section-grid .marker-link-footer:before {
      font-family: "retail-icon-font";
      display: inline-block;
      content: "";
      color: #ffffff;
      font-size: 0.75em;
      font-weight: normal;
      position: absolute;
      margin-left: -1.5em;
      margin-top: 0.3em;
      speak: none; }
  #wrapper #footer .component.three-section-grid .component.rich-text-list,
  #wrapper #footer .component.three-section-grid .row.omega > .row {
    opacity: 1;
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    overflow: hidden; }
  #wrapper #footer .component.three-section-grid .rich-text-list {
    padding-bottom: 1.1em; }
    #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li {
      position: relative;
      float: left;
      display: inline-block;
      width: 100%;
      margin-bottom: 3em;
      padding-bottom: 4.6em;
      /* space for absolutely positioned button */
      /* link list */ }
      #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li:last-child {
        margin-right: 0; }
      #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li [class*="btn"], #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li [class*="handwriting"] {
        position: absolute;
        bottom: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 90%; }
      #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul li, #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul.checkmark-list li {
        padding-bottom: 0.8em;
        padding-top: 0.3em; }
        #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul li:not(:last-child), #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul.checkmark-list li:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
        #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul li:not(:first-child), #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul.checkmark-list li:not(:first-child) {
          padding-top: 0.6em; }
        #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul li a, #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li ul.checkmark-list li a {
          color: #ffffff;
          font-size: 1.125rem; }
  #wrapper #footer .component.three-section-grid .section-two {
    background-color: #001c32;
    padding: 1.6rem 0; }
    #wrapper #footer .component.three-section-grid .section-two .rich-text {
      color: rgba(255, 255, 255, 0.5);
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      font-size: 0.9rem;
      line-height: 1.3rem;
      margin: 0;
      padding: 0; }
      @media only screen and (max-width: 479px) {
        #wrapper #footer .component.three-section-grid .section-two .rich-text {
          font-size: 1.1rem; } }
  #wrapper #footer .component.three-section-grid .section-three {
    background-color: #001c32;
    padding-bottom: 1.4rem; }
    #wrapper #footer .component.three-section-grid .section-three img {
      margin-left: 1rem; }

@media only screen and (min-width: 768px) {
  #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul {
    padding-bottom: 2em; }
    #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li {
      width: 47.5%;
      padding-right: 5%; }
      #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li:nth-child(even) {
        margin-right: 0; }
      #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li:nth-child(odd) {
        margin-right: 5%; }
  #wrapper #footer .component.three-section-grid .section-two .rich-text {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
    #wrapper #footer .component.three-section-grid .section-two .rich-text .component-content {
      width: 65.95744680851064%;
      max-width: 65.95744680851064%;
      margin: 0;
      z-index: 2; }
  #wrapper #footer .component.three-section-grid .section-three {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0; }
    #wrapper #footer .component.three-section-grid .section-three .rich-text {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      z-index: initial; }
      #wrapper #footer .component.three-section-grid .section-three .rich-text .component-content {
        width: 30%;
        max-width: 30%;
        margin: 0;
        z-index: 3;
        float: right; }
        #wrapper #footer .component.three-section-grid .section-three .rich-text .component-content a:hover {
          opacity: 1; }
  #wrapper #footer .three-section-grid.full-width-footer .section-two .rich-text .component-content {
    width: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1024px) {
  #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul {
    padding-bottom: 2em; }
    #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li {
      width: 22.75%;
      margin-bottom: 2em;
      /*&:not(:first-child) {
                                a {
                                    width: 100%;
                                }
                            }*/ }
      #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li:nth-child(even), #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li:nth-child(odd) {
        clear: none;
        margin-right: 3%; }
      #wrapper #footer .component.three-section-grid .rich-text-list .component-content > ul > li:last-child {
        margin-right: 0; }
  #wrapper #footer .component.three-section-grid .section-three .rich-text .component-content {
    text-align: right;
    padding-right: 3.5%; } }

@media only screen and (max-width: 767px) {
  .section-three .rich-text .component-content {
    text-align: center; } }

@font-face {
  font-display: swap; }

body {
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 16px;
  color: #27251F;
  background-color: #ffffff; }
  body > img {
    display: none; }

@media only screen and (max-width: 400px) {
  body.mobilFont {
    font-size: 16px; }
    body.mobilFont #wrapper li [class*="btn"],
    body.mobilFont #wrapper .component.rich-text li [class*="btn"],
    body.mobilFont #wrapper .component.intro-banner li [class*="btn"],
    body.mobilFont #wrapper .component.screening-test-spot li [class*="btn"],
    body.mobilFont #wrapper .component.form li [class*="btn"],
    body.mobilFont #wrapper .component.text-image-spot li [class*="btn"] {
      margin-left: 13vw; } }

@media only screen and (max-width: 800px) {
  body.mobilFont {
    font-size: 16px;
    line-height: 1.5; }
    body.mobilFont #wrapper h1, body.mobilFont #wrapper .like-h1,
    body.mobilFont #wrapper .component.rich-text h1,
    body.mobilFont #wrapper .component.rich-text .like-h1,
    body.mobilFont #wrapper .component.intro-banner h1,
    body.mobilFont #wrapper .component.intro-banner .like-h1,
    body.mobilFont #wrapper .component.screening-test-spot h1,
    body.mobilFont #wrapper .component.screening-test-spot .like-h1,
    body.mobilFont #wrapper .component.form h1,
    body.mobilFont #wrapper .component.form .like-h1,
    body.mobilFont #wrapper .component.text-image-spot h1,
    body.mobilFont #wrapper .component.text-image-spot .like-h1,
    body.mobilFont #wrapper .component.image-spot .image-spot-container h1,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .like-h1,
    body.mobilFont #wrapper .component.blog h1,
    body.mobilFont #wrapper .component.blog .like-h1,
    body.mobilFont #wrapper .component.related-blogs h1,
    body.mobilFont #wrapper .component.related-blogs .like-h1 {
      font-size: 36px;
      line-height: 1.2; }
    body.mobilFont #wrapper h2, body.mobilFont #wrapper .like-h2,
    body.mobilFont #wrapper .component.rich-text h2,
    body.mobilFont #wrapper .component.rich-text .like-h2,
    body.mobilFont #wrapper .component.intro-banner h2,
    body.mobilFont #wrapper .component.intro-banner .like-h2,
    body.mobilFont #wrapper .component.screening-test-spot h2,
    body.mobilFont #wrapper .component.screening-test-spot .like-h2,
    body.mobilFont #wrapper .component.form h2,
    body.mobilFont #wrapper .component.form .like-h2,
    body.mobilFont #wrapper .component.text-image-spot h2,
    body.mobilFont #wrapper .component.text-image-spot .like-h2,
    body.mobilFont #wrapper .component.image-spot .image-spot-container h2,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .like-h2,
    body.mobilFont #wrapper .component.blog h2,
    body.mobilFont #wrapper .component.blog .like-h2,
    body.mobilFont #wrapper .component.related-blogs h2,
    body.mobilFont #wrapper .component.related-blogs .like-h2 {
      font-size: 32px;
      line-height: 1.2; }
    body.mobilFont #wrapper h3, body.mobilFont #wrapper .like-h3,
    body.mobilFont #wrapper .component.rich-text h3,
    body.mobilFont #wrapper .component.rich-text .like-h3,
    body.mobilFont #wrapper .component.intro-banner h3,
    body.mobilFont #wrapper .component.intro-banner .like-h3,
    body.mobilFont #wrapper .component.screening-test-spot h3,
    body.mobilFont #wrapper .component.screening-test-spot .like-h3,
    body.mobilFont #wrapper .component.form h3,
    body.mobilFont #wrapper .component.form .like-h3,
    body.mobilFont #wrapper .component.text-image-spot h3,
    body.mobilFont #wrapper .component.text-image-spot .like-h3,
    body.mobilFont #wrapper .component.image-spot .image-spot-container h3,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .like-h3,
    body.mobilFont #wrapper .component.blog h3,
    body.mobilFont #wrapper .component.blog .like-h3,
    body.mobilFont #wrapper .component.related-blogs h3,
    body.mobilFont #wrapper .component.related-blogs .like-h3 {
      font-size: 28px;
      line-height: 1.2; }
    body.mobilFont #wrapper h4, body.mobilFont #wrapper .like-h4,
    body.mobilFont #wrapper .component.rich-text h4,
    body.mobilFont #wrapper .component.rich-text .like-h4,
    body.mobilFont #wrapper .component.intro-banner h4,
    body.mobilFont #wrapper .component.intro-banner .like-h4,
    body.mobilFont #wrapper .component.screening-test-spot h4,
    body.mobilFont #wrapper .component.screening-test-spot .like-h4,
    body.mobilFont #wrapper .component.form h4,
    body.mobilFont #wrapper .component.form .like-h4,
    body.mobilFont #wrapper .component.text-image-spot h4,
    body.mobilFont #wrapper .component.text-image-spot .like-h4,
    body.mobilFont #wrapper .component.image-spot .image-spot-container h4,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .like-h4,
    body.mobilFont #wrapper .component.blog h4,
    body.mobilFont #wrapper .component.blog .like-h4,
    body.mobilFont #wrapper .component.related-blogs h4,
    body.mobilFont #wrapper .component.related-blogs .like-h4 {
      font-size: 24px;
      line-height: 1.2; }
    body.mobilFont #wrapper h5, body.mobilFont #wrapper .like-h5,
    body.mobilFont #wrapper .component.rich-text h5,
    body.mobilFont #wrapper .component.rich-text .like-h5,
    body.mobilFont #wrapper .component.intro-banner h5,
    body.mobilFont #wrapper .component.intro-banner .like-h5,
    body.mobilFont #wrapper .component.screening-test-spot h5,
    body.mobilFont #wrapper .component.screening-test-spot .like-h5,
    body.mobilFont #wrapper .component.form h5,
    body.mobilFont #wrapper .component.form .like-h5,
    body.mobilFont #wrapper .component.text-image-spot h5,
    body.mobilFont #wrapper .component.text-image-spot .like-h5,
    body.mobilFont #wrapper .component.image-spot .image-spot-container h5,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .like-h5,
    body.mobilFont #wrapper .component.blog h5,
    body.mobilFont #wrapper .component.blog .like-h5,
    body.mobilFont #wrapper .component.related-blogs h5,
    body.mobilFont #wrapper .component.related-blogs .like-h5 {
      font-size: 21px;
      line-height: 1.2; }
    body.mobilFont #wrapper h6, body.mobilFont #wrapper .like-h6,
    body.mobilFont #wrapper .component.rich-text h6,
    body.mobilFont #wrapper .component.rich-text .like-h6,
    body.mobilFont #wrapper .component.intro-banner h6,
    body.mobilFont #wrapper .component.intro-banner .like-h6,
    body.mobilFont #wrapper .component.screening-test-spot h6,
    body.mobilFont #wrapper .component.screening-test-spot .like-h6,
    body.mobilFont #wrapper .component.form h6,
    body.mobilFont #wrapper .component.form .like-h6,
    body.mobilFont #wrapper .component.text-image-spot h6,
    body.mobilFont #wrapper .component.text-image-spot .like-h6,
    body.mobilFont #wrapper .component.image-spot .image-spot-container h6,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .like-h6,
    body.mobilFont #wrapper .component.blog h6,
    body.mobilFont #wrapper .component.blog .like-h6,
    body.mobilFont #wrapper .component.related-blogs h6,
    body.mobilFont #wrapper .component.related-blogs .like-h6 {
      font-size: 18px;
      line-height: 1.2; }
    body.mobilFont #wrapper p, body.mobilFont #wrapper a,
    body.mobilFont #wrapper .component.rich-text p,
    body.mobilFont #wrapper .component.rich-text a,
    body.mobilFont #wrapper .component.intro-banner p,
    body.mobilFont #wrapper .component.intro-banner a,
    body.mobilFont #wrapper .component.screening-test-spot p,
    body.mobilFont #wrapper .component.screening-test-spot a,
    body.mobilFont #wrapper .component.form p,
    body.mobilFont #wrapper .component.form a,
    body.mobilFont #wrapper .component.text-image-spot p,
    body.mobilFont #wrapper .component.text-image-spot a,
    body.mobilFont #wrapper .component.image-spot .image-spot-container p,
    body.mobilFont #wrapper .component.image-spot .image-spot-container a,
    body.mobilFont #wrapper .component.blog p,
    body.mobilFont #wrapper .component.blog a,
    body.mobilFont #wrapper .component.related-blogs p,
    body.mobilFont #wrapper .component.related-blogs a {
      font-size: 18px;
      white-space: initial; }
    body.mobilFont #wrapper .bigger,
    body.mobilFont #wrapper .component.rich-text .bigger,
    body.mobilFont #wrapper .component.intro-banner .bigger,
    body.mobilFont #wrapper .component.screening-test-spot .bigger,
    body.mobilFont #wrapper .component.form .bigger,
    body.mobilFont #wrapper .component.text-image-spot .bigger,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .bigger,
    body.mobilFont #wrapper .component.blog .bigger,
    body.mobilFont #wrapper .component.related-blogs .bigger {
      font-size: 21px; }
    body.mobilFont #wrapper .smaller,
    body.mobilFont #wrapper .component.rich-text .smaller,
    body.mobilFont #wrapper .component.intro-banner .smaller,
    body.mobilFont #wrapper .component.screening-test-spot .smaller,
    body.mobilFont #wrapper .component.form .smaller,
    body.mobilFont #wrapper .component.text-image-spot .smaller,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .smaller,
    body.mobilFont #wrapper .component.blog .smaller,
    body.mobilFont #wrapper .component.related-blogs .smaller {
      font-size: 14px; }
    body.mobilFont #wrapper li [class*="btn"],
    body.mobilFont #wrapper .component.rich-text li [class*="btn"],
    body.mobilFont #wrapper .component.intro-banner li [class*="btn"],
    body.mobilFont #wrapper .component.screening-test-spot li [class*="btn"],
    body.mobilFont #wrapper .component.form li [class*="btn"],
    body.mobilFont #wrapper .component.text-image-spot li [class*="btn"],
    body.mobilFont #wrapper .component.image-spot .image-spot-container li [class*="btn"],
    body.mobilFont #wrapper .component.blog li [class*="btn"],
    body.mobilFont #wrapper .component.related-blogs li [class*="btn"] {
      font-size: 1em;
      padding-left: 2em;
      padding-right: 2em; }
    body.mobilFont #wrapper a.btn--corporate, body.mobilFont #wrapper a.btn-phone, body.mobilFont #wrapper a.btn-calendar, body.mobilFont #wrapper a.btn-marker, body.mobilFont #wrapper .btn--inverted, body.mobilFont #wrapper a.btn, body.mobilFont #wrapper a.address-book,
    body.mobilFont #wrapper .component.rich-text a.btn--corporate,
    body.mobilFont #wrapper .component.rich-text a.btn-phone,
    body.mobilFont #wrapper .component.rich-text a.btn-calendar,
    body.mobilFont #wrapper .component.rich-text a.btn-marker,
    body.mobilFont #wrapper .component.rich-text .btn--inverted,
    body.mobilFont #wrapper .component.rich-text a.btn,
    body.mobilFont #wrapper .component.rich-text a.address-book,
    body.mobilFont #wrapper .component.intro-banner a.btn--corporate,
    body.mobilFont #wrapper .component.intro-banner a.btn-phone,
    body.mobilFont #wrapper .component.intro-banner a.btn-calendar,
    body.mobilFont #wrapper .component.intro-banner a.btn-marker,
    body.mobilFont #wrapper .component.intro-banner .btn--inverted,
    body.mobilFont #wrapper .component.intro-banner a.btn,
    body.mobilFont #wrapper .component.intro-banner a.address-book,
    body.mobilFont #wrapper .component.screening-test-spot a.btn--corporate,
    body.mobilFont #wrapper .component.screening-test-spot a.btn-phone,
    body.mobilFont #wrapper .component.screening-test-spot a.btn-calendar,
    body.mobilFont #wrapper .component.screening-test-spot a.btn-marker,
    body.mobilFont #wrapper .component.screening-test-spot .btn--inverted,
    body.mobilFont #wrapper .component.screening-test-spot a.btn,
    body.mobilFont #wrapper .component.screening-test-spot a.address-book,
    body.mobilFont #wrapper .component.form a.btn--corporate,
    body.mobilFont #wrapper .component.form a.btn-phone,
    body.mobilFont #wrapper .component.form a.btn-calendar,
    body.mobilFont #wrapper .component.form a.btn-marker,
    body.mobilFont #wrapper .component.form .btn--inverted,
    body.mobilFont #wrapper .component.form a.btn,
    body.mobilFont #wrapper .component.form a.address-book,
    body.mobilFont #wrapper .component.text-image-spot a.btn--corporate,
    body.mobilFont #wrapper .component.text-image-spot a.btn-phone,
    body.mobilFont #wrapper .component.text-image-spot a.btn-calendar,
    body.mobilFont #wrapper .component.text-image-spot a.btn-marker,
    body.mobilFont #wrapper .component.text-image-spot .btn--inverted,
    body.mobilFont #wrapper .component.text-image-spot a.btn,
    body.mobilFont #wrapper .component.text-image-spot a.address-book,
    body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn--corporate,
    body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn-phone,
    body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn-calendar,
    body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn-marker,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .btn--inverted,
    body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn,
    body.mobilFont #wrapper .component.image-spot .image-spot-container a.address-book,
    body.mobilFont #wrapper .component.blog a.btn--corporate,
    body.mobilFont #wrapper .component.blog a.btn-phone,
    body.mobilFont #wrapper .component.blog a.btn-calendar,
    body.mobilFont #wrapper .component.blog a.btn-marker,
    body.mobilFont #wrapper .component.blog .btn--inverted,
    body.mobilFont #wrapper .component.blog a.btn,
    body.mobilFont #wrapper .component.blog a.address-book,
    body.mobilFont #wrapper .component.related-blogs a.btn--corporate,
    body.mobilFont #wrapper .component.related-blogs a.btn-phone,
    body.mobilFont #wrapper .component.related-blogs a.btn-calendar,
    body.mobilFont #wrapper .component.related-blogs a.btn-marker,
    body.mobilFont #wrapper .component.related-blogs .btn--inverted,
    body.mobilFont #wrapper .component.related-blogs a.btn,
    body.mobilFont #wrapper .component.related-blogs a.address-book {
      font-size: 1em; }
      body.mobilFont #wrapper a.btn--corporate:before, body.mobilFont #wrapper a.btn-phone:before, body.mobilFont #wrapper a.btn-calendar:before, body.mobilFont #wrapper a.btn-marker:before, body.mobilFont #wrapper .btn--inverted:before, body.mobilFont #wrapper a.btn:before, body.mobilFont #wrapper a.address-book:before,
      body.mobilFont #wrapper .component.rich-text a.btn--corporate:before,
      body.mobilFont #wrapper .component.rich-text a.btn-phone:before,
      body.mobilFont #wrapper .component.rich-text a.btn-calendar:before,
      body.mobilFont #wrapper .component.rich-text a.btn-marker:before,
      body.mobilFont #wrapper .component.rich-text .btn--inverted:before,
      body.mobilFont #wrapper .component.rich-text a.btn:before,
      body.mobilFont #wrapper .component.rich-text a.address-book:before,
      body.mobilFont #wrapper .component.intro-banner a.btn--corporate:before,
      body.mobilFont #wrapper .component.intro-banner a.btn-phone:before,
      body.mobilFont #wrapper .component.intro-banner a.btn-calendar:before,
      body.mobilFont #wrapper .component.intro-banner a.btn-marker:before,
      body.mobilFont #wrapper .component.intro-banner .btn--inverted:before,
      body.mobilFont #wrapper .component.intro-banner a.btn:before,
      body.mobilFont #wrapper .component.intro-banner a.address-book:before,
      body.mobilFont #wrapper .component.screening-test-spot a.btn--corporate:before,
      body.mobilFont #wrapper .component.screening-test-spot a.btn-phone:before,
      body.mobilFont #wrapper .component.screening-test-spot a.btn-calendar:before,
      body.mobilFont #wrapper .component.screening-test-spot a.btn-marker:before,
      body.mobilFont #wrapper .component.screening-test-spot .btn--inverted:before,
      body.mobilFont #wrapper .component.screening-test-spot a.btn:before,
      body.mobilFont #wrapper .component.screening-test-spot a.address-book:before,
      body.mobilFont #wrapper .component.form a.btn--corporate:before,
      body.mobilFont #wrapper .component.form a.btn-phone:before,
      body.mobilFont #wrapper .component.form a.btn-calendar:before,
      body.mobilFont #wrapper .component.form a.btn-marker:before,
      body.mobilFont #wrapper .component.form .btn--inverted:before,
      body.mobilFont #wrapper .component.form a.btn:before,
      body.mobilFont #wrapper .component.form a.address-book:before,
      body.mobilFont #wrapper .component.text-image-spot a.btn--corporate:before,
      body.mobilFont #wrapper .component.text-image-spot a.btn-phone:before,
      body.mobilFont #wrapper .component.text-image-spot a.btn-calendar:before,
      body.mobilFont #wrapper .component.text-image-spot a.btn-marker:before,
      body.mobilFont #wrapper .component.text-image-spot .btn--inverted:before,
      body.mobilFont #wrapper .component.text-image-spot a.btn:before,
      body.mobilFont #wrapper .component.text-image-spot a.address-book:before,
      body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn--corporate:before,
      body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn-phone:before,
      body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn-calendar:before,
      body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn-marker:before,
      body.mobilFont #wrapper .component.image-spot .image-spot-container .btn--inverted:before,
      body.mobilFont #wrapper .component.image-spot .image-spot-container a.btn:before,
      body.mobilFont #wrapper .component.image-spot .image-spot-container a.address-book:before,
      body.mobilFont #wrapper .component.blog a.btn--corporate:before,
      body.mobilFont #wrapper .component.blog a.btn-phone:before,
      body.mobilFont #wrapper .component.blog a.btn-calendar:before,
      body.mobilFont #wrapper .component.blog a.btn-marker:before,
      body.mobilFont #wrapper .component.blog .btn--inverted:before,
      body.mobilFont #wrapper .component.blog a.btn:before,
      body.mobilFont #wrapper .component.blog a.address-book:before,
      body.mobilFont #wrapper .component.related-blogs a.btn--corporate:before,
      body.mobilFont #wrapper .component.related-blogs a.btn-phone:before,
      body.mobilFont #wrapper .component.related-blogs a.btn-calendar:before,
      body.mobilFont #wrapper .component.related-blogs a.btn-marker:before,
      body.mobilFont #wrapper .component.related-blogs .btn--inverted:before,
      body.mobilFont #wrapper .component.related-blogs a.btn:before,
      body.mobilFont #wrapper .component.related-blogs a.address-book:before {
        margin-top: 0; }
    body.mobilFont #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.rich-text .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.intro-banner .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.screening-test-spot .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.form .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.text-image-spot .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.blog .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button,
    body.mobilFont #wrapper .component.related-blogs .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button {
      font-size: 1.25rem;
      padding: 1rem; }
    body.mobilFont #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.rich-text .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.intro-banner .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.screening-test-spot .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.form .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.text-image-spot .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.blog .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book,
    body.mobilFont #wrapper .component.related-blogs .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book {
      font-size: 1.25rem; }
    body.mobilFont #wrapper ul.checkmark-list li, body.mobilFont #wrapper .checkmark-list ul li, body.mobilFont #wrapper ul li,
    body.mobilFont #wrapper .component.rich-text ul.checkmark-list li,
    body.mobilFont #wrapper .component.rich-text .checkmark-list ul li,
    body.mobilFont #wrapper .component.rich-text ul li,
    body.mobilFont #wrapper .component.intro-banner ul.checkmark-list li,
    body.mobilFont #wrapper .component.intro-banner .checkmark-list ul li,
    body.mobilFont #wrapper .component.intro-banner ul li,
    body.mobilFont #wrapper .component.screening-test-spot ul.checkmark-list li,
    body.mobilFont #wrapper .component.screening-test-spot .checkmark-list ul li,
    body.mobilFont #wrapper .component.screening-test-spot ul li,
    body.mobilFont #wrapper .component.form ul.checkmark-list li,
    body.mobilFont #wrapper .component.form .checkmark-list ul li,
    body.mobilFont #wrapper .component.form ul li,
    body.mobilFont #wrapper .component.text-image-spot ul.checkmark-list li,
    body.mobilFont #wrapper .component.text-image-spot .checkmark-list ul li,
    body.mobilFont #wrapper .component.text-image-spot ul li,
    body.mobilFont #wrapper .component.image-spot .image-spot-container ul.checkmark-list li,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .checkmark-list ul li,
    body.mobilFont #wrapper .component.image-spot .image-spot-container ul li,
    body.mobilFont #wrapper .component.blog ul.checkmark-list li,
    body.mobilFont #wrapper .component.blog .checkmark-list ul li,
    body.mobilFont #wrapper .component.blog ul li,
    body.mobilFont #wrapper .component.related-blogs ul.checkmark-list li,
    body.mobilFont #wrapper .component.related-blogs .checkmark-list ul li,
    body.mobilFont #wrapper .component.related-blogs ul li {
      font-size: 16px; }
    body.mobilFont #wrapper .handwriting--medium,
    body.mobilFont #wrapper .component.rich-text .handwriting--medium,
    body.mobilFont #wrapper .component.intro-banner .handwriting--medium,
    body.mobilFont #wrapper .component.screening-test-spot .handwriting--medium,
    body.mobilFont #wrapper .component.form .handwriting--medium,
    body.mobilFont #wrapper .component.text-image-spot .handwriting--medium,
    body.mobilFont #wrapper .component.image-spot .image-spot-container .handwriting--medium,
    body.mobilFont #wrapper .component.blog .handwriting--medium,
    body.mobilFont #wrapper .component.related-blogs .handwriting--medium {
      font-size: 20px; }
    body.mobilFont #wrapper > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.rich-text > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.intro-banner > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.screening-test-spot > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.form > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.text-image-spot > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.image-spot .image-spot-container > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.blog > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
    body.mobilFont #wrapper .component.related-blogs > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit] {
      font-size: 1em; }
    body.mobilFont #wrapper .component.related-blogs .component-content ul li .news-title {
      font-size: 2.5rem;
      line-height: 1; }
    body.mobilFont #wrapper .component.related-blogs .component-content ul li .news-date {
      font-size: 1.25rem; }
    body.mobilFont #wrapper .component.related-blogs .component-content ul li .news-text {
      font-size: 1.3rem;
      padding: .5rem 1rem 1rem 1rem; }
    body.mobilFont #wrapper .component.center-locator .single-center .center-general-information,
    body.mobilFont #wrapper .component.center-locator .single-center .center-specific-information,
    body.mobilFont #wrapper .component.center-locator .single-center .center-address,
    body.mobilFont #wrapper .component.center-locator .single-center .center-directions,
    body.mobilFont #wrapper .component.center-locator .single-center .center-Details a,
    body.mobilFont #wrapper .component.center-locator .single-center div.center-Consultation a {
      font-size: 18px; }
    body.mobilFont #wrapper .component.center-locator .single-center div.center-Consultation a {
      padding: 1.2rem 0; }
    body.mobilFont #wrapper .component.center-locator .single-center .center-name a {
      font-size: 24px; }
    body.mobilFont #wrapper .component.breadcrumb ol li:not(.home):before {
      top: 0.7rem; }
    body.mobilFont #wrapper .component.breadcrumb ol li:not(.home) a {
      margin-left: 1.5em; }
  .flex-header #wrapper #header {
    line-height: 1; } }

@media only screen and (max-width: 1400px) {
  html, body {
    font-size: 14px; } }

@media only screen and (min-width: 1950px) {
  html, body {
    font-size: 18px; } }

@media only screen and (min-width: 2200px) {
  html, body {
    font-size: 22px; } }

#wrapper h1, #wrapper .like-h1 {
  font-family: "Proxima N W01 Bold", sans-serif;
  margin: 0;
  padding: 0 0 0.2em 0;
  font-size: 4rem;
  line-height: 1;
  color: #005da9;
  display: block; }
  @media only screen and (max-width: 479px) {
    #wrapper h1, #wrapper .like-h1 {
      font-size: 3.36rem; } }

#wrapper h1.bigger, #wrapper .like-h1.bigger {
  font-family: "Proxima N W01 Bold", sans-serif;
  margin: 0;
  padding: 0 0 0.2em 0;
  font-size: 4rem;
  line-height: 1;
  color: #005da9;
  display: block;
  font-size: 7rem; }
  @media only screen and (max-width: 479px) {
    #wrapper h1.bigger, #wrapper .like-h1.bigger {
      font-size: 3.36rem; } }

#wrapper h2, #wrapper .like-h2 {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 3rem;
  margin: 0;
  padding: 0 0 0.4em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper h3, #wrapper .like-h3 {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 2.5rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper h4, #wrapper .like-h4 {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 2rem;
  margin: 0;
  padding: 0 0 0.8em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper h5, #wrapper .like-h5 {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 1.5rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper h6, #wrapper .like-h6 {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 1.25rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper p {
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0; }
  @media only screen and (max-width: 479px) {
    #wrapper p {
      font-size: 1.1rem; } }

#wrapper p .smaller {
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.3rem; }
  @media only screen and (max-width: 479px) {
    #wrapper p .smaller {
      font-size: 1.1rem; } }

#wrapper em, #wrapper i {
  font-family: "Proxima N W01SC Reg It", sans-serif; }

@media only screen and (max-width: 479px) {
  #wrapper .component.rich-text h1, #wrapper .component.rich-text .like-h1,
  #wrapper .component.intro-banner h1,
  #wrapper .component.intro-banner .like-h1,
  #wrapper .component.screening-test-spot h1,
  #wrapper .component.screening-test-spot .like-h1,
  #wrapper .component.form h1,
  #wrapper .component.form .like-h1,
  #wrapper .component.text-image-spot h1,
  #wrapper .component.text-image-spot .like-h1 {
    font-size: 3.5rem; } }

@media only screen and (max-width: 479px) {
  #wrapper .component.rich-text h2, #wrapper .component.rich-text .like-h2,
  #wrapper .component.intro-banner h2,
  #wrapper .component.intro-banner .like-h2,
  #wrapper .component.screening-test-spot h2,
  #wrapper .component.screening-test-spot .like-h2,
  #wrapper .component.form h2,
  #wrapper .component.form .like-h2,
  #wrapper .component.text-image-spot h2,
  #wrapper .component.text-image-spot .like-h2 {
    font-size: 2.5rem; } }

@media only screen and (max-width: 479px) {
  #wrapper .component.rich-text h3, #wrapper .component.rich-text .like-h3,
  #wrapper .component.intro-banner h3,
  #wrapper .component.intro-banner .like-h3,
  #wrapper .component.screening-test-spot h3,
  #wrapper .component.screening-test-spot .like-h3,
  #wrapper .component.form h3,
  #wrapper .component.form .like-h3,
  #wrapper .component.text-image-spot h3,
  #wrapper .component.text-image-spot .like-h3 {
    font-size: 1.7rem; } }

@media only screen and (max-width: 479px) {
  #wrapper .component.rich-text h4, #wrapper .component.rich-text .like-h4,
  #wrapper .component.intro-banner h4,
  #wrapper .component.intro-banner .like-h4,
  #wrapper .component.screening-test-spot h4,
  #wrapper .component.screening-test-spot .like-h4,
  #wrapper .component.form h4,
  #wrapper .component.form .like-h4,
  #wrapper .component.text-image-spot h4,
  #wrapper .component.text-image-spot .like-h4 {
    font-size: 1.3rem; } }

#wrapper .component.rich-text ol,
#wrapper .component.intro-banner ol,
#wrapper .component.screening-test-spot ol,
#wrapper .component.form ol,
#wrapper .component.text-image-spot ol {
  font-size: 1.125rem; }

#wrapper h1.grey-heading, #wrapper h1 .grey-heading, #wrapper .like-h1.grey-heading, #wrapper .like-h1 .grey-heading, #wrapper h2.grey-heading, #wrapper h2 .grey-heading, #wrapper .like-h2.grey-heading, #wrapper .like-h2 .grey-heading, #wrapper h3.grey-heading, #wrapper h3 .grey-heading, #wrapper .like-h3.grey-heading, #wrapper .like-h3 .grey-heading, #wrapper h4.grey-heading, #wrapper h4 .grey-heading, #wrapper .like-h4.grey-heading, #wrapper .like-h4 .grey-heading,
#wrapper h5.grey-heading,
#wrapper h5 .grey-heading, #wrapper .like-h5.grey-heading, #wrapper .like-h5 .grey-heading,
#wrapper h6.grey-heading,
#wrapper h6 .grey-heading, #wrapper .like-h6.grey-heading, #wrapper .like-h6 .grey-heading {
  color: #404040; }

#wrapper .bigger {
  font-family: "Proxima N W01 Light", sans-serif;
  font-size: 1.5rem;
  line-height: 1.4;
  padding: 0 0 1.2em 0;
  margin: 0; }

#wrapper strong, #wrapper b {
  font-family: "Proxima N W01 Bold", sans-serif; }

#wrapper .footnote, #wrapper .footnote a {
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0;
  font-family: "Proxima N W01SC Reg It", sans-serif;
  font-size: 1rem; }
  @media only screen and (max-width: 479px) {
    #wrapper .footnote, #wrapper .footnote a {
      font-size: 1.1rem; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #wrapper .footnote, #wrapper .footnote a {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    font-family: "Proxima N W01SC Reg It", sans-serif;
    font-size: 1.2rem; } }
  @media only screen and (-ms-high-contrast: active) and (max-width: 479px) {
    #wrapper .footnote, #wrapper .footnote a {
      font-size: 1.1rem; } }

#wrapper .light {
  font-family: "Proxima N W01 Light", sans-serif; }

#wrapper a, #wrapper .like-a {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s; }
  #wrapper a:hover, #wrapper .like-a:hover {
    opacity: 0.7; }

#wrapper .like-a--inverted {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  color: #a7d3ac; }
  #wrapper .like-a--inverted:hover {
    opacity: 0.7; }

#wrapper .like-a--corporate {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  color: #005da9; }
  #wrapper .like-a--corporate:hover {
    opacity: 0.7; }

#wrapper .like-a--arrow {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  transition: padding 0.1s;
  position: relative;
  padding-right: 1.5em; }
  #wrapper .like-a--arrow:hover {
    opacity: 0.7; }
  #wrapper .like-a--arrow:after {
    font-family: "retail-icon-font";
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    content: "";
    font-size: 1.1em;
    margin: 0;
    position: absolute;
    right: 0;
    top: .1em; }
  #wrapper .like-a--arrow:hover {
    opacity: 0.7;
    padding-right: 1.7em; }
  #wrapper .like-a--arrow:not(p)::after {
    top: auto; }

#wrapper .like-a--inverted--arrow {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  transition: padding 0.1s;
  position: relative;
  padding-right: 1.5em;
  color: #a7d3ac; }
  #wrapper .like-a--inverted--arrow:hover {
    opacity: 0.7; }
  #wrapper .like-a--inverted--arrow:after {
    font-family: "retail-icon-font";
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    content: "";
    font-size: 1.1em;
    margin: 0;
    position: absolute;
    right: 0;
    top: .1em; }
  #wrapper .like-a--inverted--arrow:hover {
    opacity: 0.7;
    padding-right: 1.7em; }
  #wrapper .like-a--inverted--arrow:not(p)::after {
    top: auto; }

#wrapper .like-a--corporate--arrow {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  transition: padding 0.1s;
  position: relative;
  padding-right: 1.5em;
  color: #005da9; }
  #wrapper .like-a--corporate--arrow:hover {
    opacity: 0.7; }
  #wrapper .like-a--corporate--arrow:after {
    font-family: "retail-icon-font";
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    content: "";
    font-size: 1.1em;
    margin: 0;
    position: absolute;
    right: 0;
    top: .1em; }
  #wrapper .like-a--corporate--arrow:hover {
    opacity: 0.7;
    padding-right: 1.7em; }
  #wrapper .like-a--corporate--arrow * {
    color: #005da9; }
  #wrapper .like-a--corporate--arrow:not(p)::after {
    top: auto; }

#wrapper .btn {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none; }
  #wrapper .btn:hover {
    opacity: 0.7; }

#wrapper .btn--inverted {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #a7d3ac;
  color: #005da9; }
  #wrapper .btn--inverted:hover {
    opacity: 0.7; }

#wrapper .btn-btn--inverted {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #a7d3ac;
  color: #005da9; }
  #wrapper .btn-btn--inverted:hover {
    opacity: 0.7; }
  #wrapper .btn-btn--inverted:hover {
    opacity: 0.7; }

#wrapper .btn-btn--large {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none; }
  #wrapper .btn-btn--large:hover {
    opacity: 0.7; }

#wrapper .btn-btn--large--inverted {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #a7d3ac;
  color: #005da9; }
  #wrapper .btn-btn--large--inverted:hover {
    opacity: 0.7; }
  #wrapper .btn-btn--large--inverted:hover {
    opacity: 0.7; }

#wrapper .btn-btn--large--image {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none; }
  #wrapper .btn-btn--large--image:hover {
    opacity: 0.7; }

#wrapper .btn-btn--small {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  padding: 0.7em 1.9em; }
  #wrapper .btn-btn--small:hover {
    opacity: 0.7; }

#wrapper .btn--corporate {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #005da9; }
  #wrapper .btn--corporate:hover {
    opacity: 0.7; }

#wrapper .btn--navy {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #003765;
  color: #ffffff; }
  #wrapper .btn--navy:hover {
    opacity: 0.7; }

#wrapper .btn-light--inverted {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #a7d3ac;
  color: #ffffff; }
  #wrapper .btn-light--inverted:hover {
    opacity: 0.7; }

#wrapper .btn-marker {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  padding-left: 3.2em;
  padding-right: 1.4em; }
  #wrapper .btn-marker:hover {
    opacity: 0.7; }
  #wrapper .btn-marker::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1.3rem;
    speak: none;
    font-weight: normal;
    position: absolute;
    margin-left: -1.45em;
    margin-top: -0.22em; }
  #wrapper .btn-marker::before {
    margin-top: -0.2em; }

#wrapper .btn-phone {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  padding-left: 3.2em;
  padding-right: 1.4em; }
  #wrapper .btn-phone:hover {
    opacity: 0.7; }
  #wrapper .btn-phone::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1.3rem;
    speak: none;
    font-weight: normal;
    position: absolute;
    margin-left: -1.45em;
    margin-top: -0.22em; }
  #wrapper .btn-phone::before {
    margin-top: -0.21em; }

#wrapper .btn-mail {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  padding-left: 3.2em;
  padding-right: 1.4em; }
  #wrapper .btn-mail:hover {
    opacity: 0.7; }
  #wrapper .btn-mail::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1.3rem;
    speak: none;
    font-weight: normal;
    position: absolute;
    margin-left: -1.45em;
    margin-top: -0.22em; }

#wrapper .btn-calendar {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  padding-left: 3.2em;
  padding-right: 1.4em; }
  #wrapper .btn-calendar:hover {
    opacity: 0.7; }
  #wrapper .btn-calendar::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1.3rem;
    speak: none;
    font-weight: normal;
    position: absolute;
    margin-left: -1.45em;
    margin-top: -0.22em; }

#wrapper .button {
  margin-top: 1rem; }
  #wrapper .button.decline-cookies {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #005da9; }
    #wrapper .button.decline-cookies:hover {
      opacity: 0.7; }
  #wrapper .button.reopen-cookies {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #005da9; }
    #wrapper .button.reopen-cookies:hover {
      opacity: 0.7; }

#wrapper .highlight {
  color: #a7d3ac; }

#wrapper .phone-link {
  padding: 0 0 0 0.7em;
  font-family: "Proxima N W01 Light", sans-serif;
  font-size: 2.5em;
  white-space: nowrap; }
  #wrapper .phone-link * {
    color: #ffffff;
    font-family: "Proxima N W01 Light", sans-serif; }
  #wrapper .phone-link::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 0.6em;
    font-weight: normal;
    position: absolute;
    margin-left: -1.2em;
    margin-top: 0.3em;
    speak: none; }

#wrapper .handwriting--small {
  font-family: "Manus W00 Manus";
  color: #a7d3ac;
  font-size: 1rem; }

#wrapper .handwriting--medium {
  font-family: "Manus W00 Manus";
  color: #a7d3ac;
  font-size: 1.5rem; }

#wrapper .handwriting--large {
  font-family: "Manus W00 Manus";
  color: #a7d3ac;
  font-size: 2rem; }

#wrapper ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  #wrapper ul li {
    margin: 0; }

#wrapper ol {
  padding: 0 0 1.2em 1.75rem;
  list-style: decimal outside; }
  #wrapper ol li {
    line-height: 1.5;
    padding: 0 0 0 .5rem; }
    #wrapper ol li ol {
      padding-bottom: 0; }

#wrapper .two-column-list {
  white-space: nowrap; }
  #wrapper .two-column-list li {
    list-style: none;
    width: 48%;
    white-space: normal;
    display: inline-block;
    vertical-align: top; }
    #wrapper .two-column-list li:nth-child(1) {
      font-family: "Proxima N W01 Light", sans-serif;
      font-size: 1.5rem;
      line-height: 1.4;
      padding: 0 0 1.2em 0;
      margin: 0;
      padding-bottom: 0;
      margin-right: 2%;
      text-align: right; }
    #wrapper .two-column-list li:nth-child(2) {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      padding-bottom: 0;
      margin-left: 2%; }
      @media only screen and (max-width: 479px) {
        #wrapper .two-column-list li:nth-child(2) {
          font-size: 1.1rem; } }
    #wrapper .two-column-list li:nth-child(3) {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      padding-bottom: 0;
      padding-top: 1.5em;
      display: block;
      margin-left: 52%; }
      @media only screen and (max-width: 479px) {
        #wrapper .two-column-list li:nth-child(3) {
          font-size: 1.1rem; } }
    #wrapper .two-column-list li:nth-child(n+4) {
      display: none; }

#wrapper ul.bullet-list,
#wrapper .bullet-list ul {
  list-style-type: none;
  display: table; }
  #wrapper ul.bullet-list li,
  #wrapper .bullet-list ul li {
    margin: 0 0 1.5em 0;
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    display: table-row;
    font-family: "Proxima N W01 Reg", sans-serif;
    line-height: 1.5; }
    @media only screen and (max-width: 479px) {
      #wrapper ul.bullet-list li,
      #wrapper .bullet-list ul li {
        font-size: 1.1rem; } }
    #wrapper ul.bullet-list li p,
    #wrapper .bullet-list ul li p {
      padding-bottom: .5em; }
    #wrapper ul.bullet-list li:last-child,
    #wrapper .bullet-list ul li:last-child {
      padding-bottom: 0; }
    #wrapper ul.bullet-list li:before,
    #wrapper .bullet-list ul li:before {
      width: .8em;
      content: "•";
      font-family: "retail-icon-font";
      color: #005da9;
      font-size: 1.3em;
      display: table-cell;
      text-align: right;
      speak: none;
      padding-right: .7em;
      vertical-align: top;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

#wrapper ul.bullet-list-dark,
#wrapper .bullet-list-dark ul {
  list-style-type: none;
  display: table; }
  #wrapper ul.bullet-list-dark li,
  #wrapper .bullet-list-dark ul li {
    margin: 0 0 1.5em 0;
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    display: table-row;
    font-family: "Proxima N W01 Reg", sans-serif;
    line-height: 1.5; }
    @media only screen and (max-width: 479px) {
      #wrapper ul.bullet-list-dark li,
      #wrapper .bullet-list-dark ul li {
        font-size: 1.1rem; } }
    #wrapper ul.bullet-list-dark li p,
    #wrapper .bullet-list-dark ul li p {
      padding-bottom: .5em; }
    #wrapper ul.bullet-list-dark li:last-child,
    #wrapper .bullet-list-dark ul li:last-child {
      padding-bottom: 0; }
    #wrapper ul.bullet-list-dark li:before,
    #wrapper .bullet-list-dark ul li:before {
      width: .8em;
      content: "•";
      font-family: "retail-icon-font";
      color: #27251F;
      font-size: 1.3em;
      display: table-cell;
      text-align: right;
      speak: none;
      padding-right: .7em;
      vertical-align: top;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

#wrapper ul.bullet-normal,
#wrapper .bullet-normal ul {
  list-style-type: none;
  display: table; }
  #wrapper ul.bullet-normal li,
  #wrapper .bullet-normal ul li {
    margin: 0 0 1.5em 0;
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    display: table-row;
    font-family: "retail-icon-font", "Proxima N W01 Reg", sans-serif; }
    @media only screen and (max-width: 479px) {
      #wrapper ul.bullet-normal li,
      #wrapper .bullet-normal ul li {
        font-size: 1.1rem; } }
    #wrapper ul.bullet-normal li p,
    #wrapper .bullet-normal ul li p {
      padding-bottom: .5em; }
    #wrapper ul.bullet-normal li:last-child,
    #wrapper .bullet-normal ul li:last-child {
      padding-bottom: 0; }
    #wrapper ul.bullet-normal li:before,
    #wrapper .bullet-normal ul li:before {
      content: "\2022";
      font-family: "retail-icon-font";
      color: #005da9;
      display: table-cell;
      text-align: right;
      speak: none;
      padding-right: .4em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

#wrapper ul.checkmark-list,
#wrapper .checkmark-list ul {
  list-style-type: none;
  display: table; }
  #wrapper ul.checkmark-list li,
  #wrapper .checkmark-list ul li {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    display: table-row;
    font-family: "Proxima N W01 Bold", sans-serif; }
    @media only screen and (max-width: 479px) {
      #wrapper ul.checkmark-list li,
      #wrapper .checkmark-list ul li {
        font-size: 1.1rem; } }
    #wrapper ul.checkmark-list li p,
    #wrapper .checkmark-list ul li p {
      padding-bottom: 0; }
    #wrapper ul.checkmark-list li:last-child,
    #wrapper .checkmark-list ul li:last-child {
      padding-bottom: 0; }
    #wrapper ul.checkmark-list li:before,
    #wrapper .checkmark-list ul li:before {
      font-family: "Proxima N W01 Reg", sans-serif;
      content: "✓";
      color: #a7d3ac;
      display: table-cell;
      text-align: right;
      speak: none;
      padding-right: .4em;
      padding-bottom: 1.1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

#wrapper ul li .bullet-normal {
  list-style-type: none;
  margin: 0 0 1.5em 0;
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0;
  display: table-row; }
  @media only screen and (max-width: 479px) {
    #wrapper ul li .bullet-normal {
      font-size: 1.1rem; } }
  #wrapper ul li .bullet-normal p {
    padding-bottom: .5em; }
  #wrapper ul li .bullet-normal:last-child {
    padding-bottom: 0; }
  #wrapper ul li .bullet-normal:before {
    width: .8em;
    content: "•";
    font-family: "retail-icon-font";
    color: #005da9;
    font-size: 1.3em;
    display: table-cell;
    text-align: right;
    speak: none;
    padding-right: .7em;
    vertical-align: top;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

#wrapper ul li .bullet-normal ~ ul {
  list-style-type: none;
  margin: 0 0 1.5em 0;
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0;
  display: table-row; }
  #wrapper ul li .bullet-normal ~ ul:before {
    content: " " !important; }
  @media only screen and (max-width: 479px) {
    #wrapper ul li .bullet-normal ~ ul {
      font-size: 1.1rem; } }
  #wrapper ul li .bullet-normal ~ ul p {
    padding-bottom: .5em; }
  #wrapper ul li .bullet-normal ~ ul:last-child {
    padding-bottom: 0; }
  #wrapper ul li .bullet-normal ~ ul:before {
    width: .8em;
    content: "•";
    font-family: "retail-icon-font";
    color: #005da9;
    font-size: 1.3em;
    display: table-cell;
    text-align: right;
    speak: none;
    padding-right: .7em;
    vertical-align: top;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

#wrapper .quote {
  font-family: "Proxima N W01 Reg", sans-serif;
  margin: 1.5em 0 1.5em 0;
  position: relative;
  display: inline-block;
  font-size: 2em;
  padding: 0;
  line-height: 1.3;
  color: #005da9;
  font-style: italic; }
  #wrapper .quote::before, #wrapper .quote::after {
    speak: none;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
    content: '"';
    color: #005da9;
    speak: none; }

#wrapper .quote-bubble {
  font-family: "Proxima N W01 Light", sans-serif;
  font-size: 1.5rem;
  line-height: 1.4;
  padding: 0 0 1.2em 0;
  margin: 0;
  padding: 4rem 0 1.6rem 4rem !important;
  position: relative;
  background-image: url("/design/build/retail/assets/quote.svg");
  background-size: 2.5em;
  background-repeat: no-repeat;
  background-position: 0 0; }

#wrapper .quote-bubble-author {
  color: rgba(39, 37, 31, 0.6);
  display: block;
  padding-left: 4rem;
  padding-bottom: 0; }

#wrapper .two-section-grid-text-color {
  color: #005da9; }

#wrapper .component:not(.appframe-component):not(.rich-content-spot) input,
#wrapper .component:not(.appframe-component):not(.rich-content-spot) textarea,
#wrapper .component:not(.appframe-component):not(.rich-content-spot) select {
  box-sizing: border-box;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%; }
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) input:focus,
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) textarea:focus,
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) select:focus {
    border-color: #a7d3ac; }
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) input option,
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) textarea option,
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) select option {
    color: #333333; }

#wrapper .component:not(.appframe-component):not(.rich-content-spot) input[type=text],
#wrapper .component:not(.appframe-component):not(.rich-content-spot) input[type=tel],
#wrapper .component:not(.appframe-component):not(.rich-content-spot) input[type=file],
#wrapper .component:not(.appframe-component):not(.rich-content-spot) textarea {
  border: none;
  background-color: #ffffff;
  color: #333333;
  font-size: 16px;
  padding: 16px 19px 14px 19px;
  font-family: "Proxima N W01SC Reg It", sans-serif; }

#wrapper .component:not(.appframe-component):not(.rich-content-spot) input[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  height: 22px;
  width: 22px;
  padding: 0;
  border-radius: 0;
  margin: 0;
  background: white url("/design/build/retail/assets/check.png") 50% 500% no-repeat;
  /*this is temporary until we setup the icon as a part of icon font */
  background-size: 16px;
  transition: all 0.2s;
  cursor: pointer;
  float: left;
  margin-right: 13px;
  border: 2px solid #7f7f7f; }
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) input[type="checkbox"]:checked {
    background-color: #333333;
    border: 2px solid #333333;
    background-position: 50% 50%; }

#wrapper .component:not(.appframe-component):not(.rich-content-spot) input[type="radio"] {
  border-width: 0;
  box-shadow: inset 0px 0px 0px 1px #333333;
  height: 27px;
  width: 27px;
  padding: 0;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0;
  transition: all 0.2s;
  cursor: pointer;
  float: left;
  margin-right: 13px; }
  #wrapper .component:not(.appframe-component):not(.rich-content-spot) input[type="radio"]:checked {
    background-color: #005da9;
    border-color: #333333; }

#wrapper .component:not(.appframe-component):not(.rich-content-spot) select {
  -webkit-appearance: none;
  -moz-appearance: none;
  apperance: none; }

#wrapper .component:not(.appframe-component):not(.rich-content-spot) .booking-form-field-radiobutton span,
#wrapper .component:not(.appframe-component):not(.rich-content-spot) .booking-form-field-checkbox span,
#wrapper .component:not(.appframe-component):not(.rich-content-spot) .booking-form-field-multiline span {
  display: block;
  margin-bottom: 6px; }

#wrapper .component:not(.appframe-component):not(.rich-content-spot) input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  -webkit-text-fill-color: #333333 !important; }

#wrapper select, #wrapper .ui-autocomplete-input {
  border: none;
  background-color: #ffffff;
  color: #333333;
  font-size: 16px;
  padding: 16px 19px 14px 19px;
  font-family: "Proxima N W01 Reg", sans-serif;
  font-style: italic;
  width: 100%; }
  #wrapper select::-ms-expand, #wrapper .ui-autocomplete-input::-ms-expand {
    display: none; }

#wrapper textarea {
  font-size: 16px; }

#wrapper .top-margin2.component {
  margin-top: 2em; }

#wrapper .top-margin4.component {
  margin-top: 4em; }

#wrapper .corporate-bg *:not(.highlight):not(a):not(textarea):not(.appframe-component):not(.rich-content-spot):not(.scfSingleLineTextBox):not(.scfEmailTextBox):not(.scfTelephoneTextBox):not(option):not(select),
#wrapper .grey-bg *:not(.highlight):not(a):not(textarea):not(.appframe-component):not(.rich-content-spot):not(.scfSingleLineTextBox):not(.scfEmailTextBox):not(.scfTelephoneTextBox):not(option):not(select),
#wrapper .grey-bg-dark *:not(.highlight):not(a):not(textarea):not(.appframe-component):not(.rich-content-spot):not(.scfSingleLineTextBox):not(.scfEmailTextBox):not(.scfTelephoneTextBox):not(option):not(select),
#wrapper .navy-bg *:not(.highlight):not(a):not(textarea):not(.appframe-component):not(.rich-content-spot):not(.scfSingleLineTextBox):not(.scfEmailTextBox):not(.scfTelephoneTextBox):not(option):not(select),
#wrapper .blue-bg *:not(.highlight):not(a):not(textarea):not(.appframe-component):not(.rich-content-spot):not(.scfSingleLineTextBox):not(.scfEmailTextBox):not(.scfTelephoneTextBox):not(option):not(select) {
  color: #ffffff; }

#wrapper .corporate-bg blockquote, #wrapper .corporate-bg .quotes,
#wrapper .grey-bg blockquote,
#wrapper .grey-bg .quotes,
#wrapper .grey-bg-dark blockquote,
#wrapper .grey-bg-dark .quotes,
#wrapper .navy-bg blockquote,
#wrapper .navy-bg .quotes,
#wrapper .blue-bg blockquote,
#wrapper .blue-bg .quotes {
  font-family: "Proxima N W01 Reg", sans-serif;
  margin: 1.5em 0 1.5em 0;
  position: relative;
  display: inline-block;
  font-size: 2em;
  padding: 0;
  line-height: 1.3;
  color: #005da9;
  font-style: italic;
  color: #ffffff; }
  #wrapper .corporate-bg blockquote::before, #wrapper .corporate-bg blockquote::after, #wrapper .corporate-bg .quotes::before, #wrapper .corporate-bg .quotes::after,
  #wrapper .grey-bg blockquote::before,
  #wrapper .grey-bg blockquote::after,
  #wrapper .grey-bg .quotes::before,
  #wrapper .grey-bg .quotes::after,
  #wrapper .grey-bg-dark blockquote::before,
  #wrapper .grey-bg-dark blockquote::after,
  #wrapper .grey-bg-dark .quotes::before,
  #wrapper .grey-bg-dark .quotes::after,
  #wrapper .navy-bg blockquote::before,
  #wrapper .navy-bg blockquote::after,
  #wrapper .navy-bg .quotes::before,
  #wrapper .navy-bg .quotes::after,
  #wrapper .blue-bg blockquote::before,
  #wrapper .blue-bg blockquote::after,
  #wrapper .blue-bg .quotes::before,
  #wrapper .blue-bg .quotes::after {
    speak: none;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
    content: '"';
    color: #005da9;
    speak: none; }
  #wrapper .corporate-bg blockquote::before, #wrapper .corporate-bg blockquote::after, #wrapper .corporate-bg .quotes::before, #wrapper .corporate-bg .quotes::after,
  #wrapper .grey-bg blockquote::before,
  #wrapper .grey-bg blockquote::after,
  #wrapper .grey-bg .quotes::before,
  #wrapper .grey-bg .quotes::after,
  #wrapper .grey-bg-dark blockquote::before,
  #wrapper .grey-bg-dark blockquote::after,
  #wrapper .grey-bg-dark .quotes::before,
  #wrapper .grey-bg-dark .quotes::after,
  #wrapper .navy-bg blockquote::before,
  #wrapper .navy-bg blockquote::after,
  #wrapper .navy-bg .quotes::before,
  #wrapper .navy-bg .quotes::after,
  #wrapper .blue-bg blockquote::before,
  #wrapper .blue-bg blockquote::after,
  #wrapper .blue-bg .quotes::before,
  #wrapper .blue-bg .quotes::after {
    color: #ffffff; }

#wrapper .green-bg {
  background-color: #a7d3ac; }
  #wrapper .green-bg .highlight {
    color: #ffffff; }
  #wrapper .green-bg .bullet-list li:before,
  #wrapper .green-bg .bullet-list-dark li:before {
    color: #a7d3ac; }

#wrapper .grey-bg {
  background-color: #b0b0b0; }

#wrapper .grey-bg-dark {
  background-color: #515151; }

#wrapper .lightgrey-bg {
  background-color: #f1f1f1; }

#wrapper .light-grey-bg {
  background-color: #f1f1f1; }

#wrapper .navy-bg {
  background-color: #001c32; }

#wrapper .blue-bg {
  background-color: #003765; }

#wrapper .nuance-green2-bg {
  background-color: #d3e9d5; }

#wrapper .nuance-blue2-bg {
  background-color: #68afd6; }

#wrapper .dark-blue-bg {
  background-color: #001c32; }

#wrapper .audika-blue-bg {
  background-color: #005da9; }

@media only screen and (min-width: 768px) {
  #wrapper .hide-background-on-desktop {
    background: none; } }

#wrapper .margin4.component.content-overflow {
  margin-bottom: 2em; }

#wrapper .margin7.component.content-overflow {
  margin-bottom: 3.5em; }

@media only screen and (max-width: 1023px) {
  #wrapper .slider-stack-wrapper:not(.slide-on-mobile) {
    padding-bottom: 3rem; } }

#wrapper .slider-stack-wrapper > .slider-tabs {
  bottom: 1rem;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 1.3s; }
  @media only screen and (max-width: 1023px) {
    #wrapper .slider-stack-wrapper > .slider-tabs {
      bottom: .8rem; } }
  #wrapper .slider-stack-wrapper > .slider-tabs .tabs-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    text-align: center; }
    #wrapper .slider-stack-wrapper > .slider-tabs .tabs-content .slider-tab {
      width: .7rem;
      height: .7rem;
      margin-left: .75rem;
      border-radius: 50%;
      background-color: #005da9;
      border: 0.13rem solid #ffffff;
      transition: background-color 0.2s; }
      @media only screen and (max-width: 1023px) {
        #wrapper .slider-stack-wrapper > .slider-tabs .tabs-content .slider-tab {
          width: 1rem;
          height: 1rem;
          margin-left: 1rem; } }
      #wrapper .slider-stack-wrapper > .slider-tabs .tabs-content .slider-tab.active-tab, #wrapper .slider-stack-wrapper > .slider-tabs .tabs-content .slider-tab:hover {
        background-color: #ffffff;
        border: 0.13rem solid #005da9; }

#wrapper .slider-stack-wrapper > .slider-arrow-left,
#wrapper .slider-stack-wrapper > .slider-arrow-right {
  width: 3rem;
  height: 3rem;
  top: 50%;
  transform: translateY(-50%);
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center; }
  @media only screen and (max-width: 1023px) {
    #wrapper .slider-stack-wrapper > .slider-arrow-left:not(.show-on-mobile),
    #wrapper .slider-stack-wrapper > .slider-arrow-right:not(.show-on-mobile) {
      display: none; } }

#wrapper .slider-stack-wrapper > .slider-arrow-left {
  left: .8rem;
  background-image: url("/design/build/retail/assets/left_arrow.png"); }

#wrapper .slider-stack-wrapper > .slider-arrow-right {
  right: .8rem;
  background-image: url("/design/build/retail/assets/right_arrow.png"); }

@media only screen and (min-width: 768px) {
  #wrapper .rt-3-col .component-content {
    column-count: 2; } }

@media only screen and (min-width: 1024px) {
  #wrapper .rt-3-col .component-content {
    column-count: 3; } }

#wrapper .component.link[class*="header-btn"] {
  float: right;
  display: inline-block;
  margin-left: 1em; }
  #wrapper .component.link[class*="header-btn"].btn-corporate-marker a {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #005da9;
    padding-left: 3.2em;
    padding-right: 1.4em; }
    #wrapper .component.link[class*="header-btn"].btn-corporate-marker a:hover {
      opacity: 0.7; }
    #wrapper .component.link[class*="header-btn"].btn-corporate-marker a::before {
      font-family: "retail-icon-font";
      display: inline-block;
      content: "";
      color: #ffffff;
      font-size: 1.3rem;
      speak: none;
      font-weight: normal;
      position: absolute;
      margin-left: -1.45em;
      margin-top: -0.22em; }

#wrapper .component a.btn-corporate-marker {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #005da9;
  padding-left: 3.2em;
  padding-right: 1.4em; }
  #wrapper .component a.btn-corporate-marker:hover {
    opacity: 0.7; }
  #wrapper .component a.btn-corporate-marker::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1.3rem;
    speak: none;
    font-weight: normal;
    position: absolute;
    margin-left: -1.45em;
    margin-top: -0.22em; }

#wrapper .component span.btn-corporate-marker a {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #005da9;
  padding-left: 3.2em;
  padding-right: 1.4em; }
  #wrapper .component span.btn-corporate-marker a:hover {
    opacity: 0.7; }
  #wrapper .component span.btn-corporate-marker a::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1.3rem;
    speak: none;
    font-weight: normal;
    position: absolute;
    margin-left: -1.45em;
    margin-top: -0.22em; }

#wrapper .component a span.btn-corporate-marker {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #005da9;
  padding-left: 3.2em;
  padding-right: 1.4em; }
  #wrapper .component a span.btn-corporate-marker:hover {
    opacity: 0.7; }
  #wrapper .component a span.btn-corporate-marker::before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1.3rem;
    speak: none;
    font-weight: normal;
    position: absolute;
    margin-left: -1.45em;
    margin-top: -0.22em; }

#wrapper > #content .slider-stack-wrapper > .component.slider-stack {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.3s ease-in-out 0s, visibility 0s linear 1.3s; }
  #wrapper > #content .slider-stack-wrapper > .component.slider-stack.active-slide {
    opacity: 1;
    visibility: visible;
    transition: opacity 1.3s ease-in-out 0s, visibility 0s linear 0s; }

@media only screen and (max-width: 479px) {
  #wrapper > #content .component .two-column-list li:nth-child(-n+3) {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    display: block;
    text-align: center !important; }
  #wrapper > #content .component .two-column-list li:nth-child(1),
  #wrapper > #content .component .two-column-list li:nth-child(2) {
    margin-bottom: 1em; }
  #wrapper > #content .component .two-column-list li:nth-child(3) {
    padding-top: 0; }
  #wrapper > #content .component.top-margin2 {
    margin-top: 1rem; }
  #wrapper > #content .component.top-margin4 {
    margin-top: 1rem; } }

#wrapper .corporate-bg, #wrapper .magenta-bg {
  background-color: #005da9; }

#Coi-Renew {
  display: none !important; }

body.cvi #wrapper h1, body.cvi #wrapper .like-h1,
body.cvi #wrapper h2, body.cvi #wrapper .like-h2,
body.cvi #wrapper h3, body.cvi #wrapper .like-h3,
body.cvi #wrapper h4, body.cvi #wrapper .like-h4,
body.cvi #wrapper h5, body.cvi #wrapper .like-h5,
body.cvi #wrapper h6, body.cvi #wrapper .like-h6 {
  color: #0F2B54; }

body.cvi #wrapper #header .flex-header-box .header-section-bottom {
  background: #0F2B54; }
  body.cvi #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a.header-icon-calendar {
    background: #D93B30; }
  body.cvi #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a {
    border-left: 1px solid rgba(255, 255, 255, 0.2); }

body.cvi #wrapper #content a:not(.btn),
body.cvi #wrapper #content .like-a {
  color: #D93B30; }

body.cvi #wrapper #content .component.form .component-content .scfSubmitButtonBorder input[type=submit],
body.cvi #wrapper #content .btn {
  background-color: #D93B30; }

body.cvi #wrapper #content .component.form .component-content .scfTitleBorder {
  color: #0F2B54; }

body.cvi #wrapper #footer .component.three-section-grid .section-one .btn--navy {
  background-color: #0F2B54; }

body.cvi #wrapper #footer .component.three-section-grid .section-two,
body.cvi #wrapper #footer .component.three-section-grid .section-three {
  background-color: #0F2B54; }

#social-sharing {
  height: 100px;
  background: transparent; }
  #social-sharing.float {
    height: 4.5rem; }
  #social-sharing .share-services {
    margin-top: 0.3rem; }
    #social-sharing .share-services .inner-container {
      padding-top: 0.1rem; }
  #social-sharing .service-elements-header {
    margin: 10px 0 0 0.3rem; }
    @media only screen and (min-width: 992px) {
      #social-sharing .service-elements-header {
        margin: 0; } }
  @media only screen and (min-width: 992px) {
    #social-sharing.float {
      bottom: 60%; } }
  #social-sharing .service-element a {
    background-color: transparent; }
    #social-sharing .service-element a[class*="icon"]:hover {
      opacity: 0.5; }
    #social-sharing .service-element a[class*="icon"]::before {
      color: #005da9;
      position: absolute;
      top: .3rem;
      left: .6rem;
      font-size: 20px;
      speak: none;
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      font-family: "retail-icon-font"; }
  #social-sharing .service-element a:first-of-type {
    margin-left: -0.8rem; }
  #social-sharing .service-element [class*="facebook"] {
    background-color: transparent; }
  #social-sharing .service-element [class*="linkedin"] {
    background-color: transparent; }
  #social-sharing .service-element [class*="twitter"] {
    background-color: transparent; }

#wrapper .modal-container {
  display: block !important; }
  #wrapper .modal-container .modal-container-content {
    padding: 2rem 0 2rem 2rem;
    box-sizing: border-box;
    max-width: 50vw;
    max-height: 60vh;
    margin: 20vh auto 0 auto;
    position: relative;
    background: #ffffff;
    border-radius: .25rem;
    box-shadow: 0 0 3rem #404040; }
    #wrapper .modal-container .modal-container-content .component-content {
      overflow: auto;
      height: 50vh;
      padding-right: 2rem; }
    #wrapper .modal-container .modal-container-content .lightbox-close {
      background-color: #005da9;
      color: #ffffff;
      border-radius: 50%;
      display: block;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      position: absolute;
      right: 2rem;
      top: 2rem;
      text-align: center; }

@media only screen and (max-width: 767px) {
  #wrapper .modal-container {
    padding: 0; }
    #wrapper .modal-container .modal-container-content {
      max-width: 96vw;
      max-height: 98vh;
      margin: 1vh auto; }
      #wrapper .modal-container .modal-container-content .component-content {
        height: 92vh; }
        #wrapper .modal-container .modal-container-content .component-content h1 {
          font-size: 2.4rem; } }

.inin-chat {
  box-sizing: border-box;
  top: 99%;
  left: auto;
  right: 16px;
  bottom: 0;
  transition: none;
  position: fixed;
  z-index: 9999;
  width: 16rem;
  height: 12rem;
  font-family: "Proxima N W01 Reg", sans-serif; }
  .inin-chat * {
    box-sizing: border-box; }
  .inin-chat .inin-chat-heading {
    padding: .5rem .75rem;
    background: #005da9;
    color: #ffffff;
    font-size: 1.125rem;
    display: flex;
    flex-direction: row;
    height: 2.2rem;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    margin-top: 4.9rem;
    position: fixed;
    width: 264px;
    background: #e40049;
    left: auto;
    bottom: 0;
    right: 16px;
    transition: none;
    transform: none; }
    .inin-chat .inin-chat-heading h4 {
      flex-grow: 1; }
    .inin-chat .inin-chat-heading .inin-chat-close {
      font-size: 1.5rem; }
    .inin-chat .inin-chat-heading:hover {
      cursor: pointer; }
  .inin-chat .inin-chat-body {
    height: 12rem;
    width: 13.8rem;
    background: #ffffff;
    border: 1px solid #005da9;
    border-left: none;
    padding: .75rem; }
    .inin-chat .inin-chat-body input[type="text"] {
      font-size: 1rem;
      width: 100%;
      margin-top: 1rem;
      padding: .25rem .5rem;
      font-family: "Proxima N W01 Reg", sans-serif; }
    .inin-chat .inin-chat-body .btn {
      background: #e40049;
      border: none;
      color: #ffffff;
      font-size: 1rem;
      padding: .5rem 1rem;
      border-radius: 1.25rem;
      font-family: "Proxima N W01 Bold", sans-serif;
      margin-top: 1rem;
      width: 100%; }
    .inin-chat .inin-chat-body .btn:hover {
      cursor: pointer;
      background: rgba(228, 0, 73, 0.7); }

.inin-chat.inin-chat-active {
  left: 0; }
  .inin-chat.inin-chat-active .inin-chat-heading {
    left: 8.8rem; }

.inin-chat.inin-chat-active {
  width: 264px;
  left: auto;
  right: 16px;
  bottom: 0;
  top: auto; }

@media screen and (max-width: 991px) {
  .inin-chat .inin-chat-heading {
    bottom: 63px;
    right: 0; }
  .inin-chat {
    right: 0; }
  .inin-chat.inin-chat-active {
    right: 0;
    bottom: 63px; }
  .inin-chat.inin-chat-active .inin-chat-heading {
    bottom: 16rem !important; } }

@media screen and (max-width: 800px) {
  .inin-chat .inin-chat-heading {
    bottom: 58px; }
  .inin-chat.inin-chat-active .inin-chat-heading {
    bottom: 16.3rem !important; }
  .inin-chat.inin-chat-active {
    bottom: 58px; } }

@media screen and (max-width: 539px) {
  .inin-chat .inin-chat-heading {
    bottom: 72px; } }

@media screen and (max-width: 416px) {
  .inin-chat .inin-chat-heading {
    bottom: 84px; } }

@media screen and (max-width: 400px) {
  .inin-chat .inin-chat-heading {
    bottom: 66px; }
  .inin-chat.inin-chat-active .inin-chat-heading {
    bottom: 16.7rem !important; } }

@media screen and (max-width: 384px) {
  .inin-chat .inin-chat-heading {
    bottom: 79px; } }

.inin-chat.inin-chat-active .inin-chat-heading {
  left: auto;
  bottom: 11.9rem; }

.inin-chat .inin-chat-body {
  width: 264px;
  margin-right: 0px;
  margin-left: auto;
  border-left: 1px solid #005da9;
  opacity: 0; }

.inin-chat.inin-chat-active .inin-chat-body {
  opacity: 1; }

.code-snippet .code-snippet-container {
  display: none; }

.code-snippet .code-snippet-btn {
  cursor: pointer; }

.code-snippet.code-snippet-is-open .code-snippet-container {
  display: block; }
  .code-snippet.code-snippet-is-open .code-snippet-container pre {
    background: #ffffff;
    border: 2px solid #005da9;
    margin-top: 1rem;
    padding: .5rem;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word; }
    .code-snippet.code-snippet-is-open .code-snippet-container pre code {
      font-family: monospace; }

.component.accordion-spot {
  margin-top: 5rem; }
  .component.accordion-spot .component-content {
    position: relative; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all,
    .component.accordion-spot .component-content .accordion-spot-element__title {
      cursor: pointer; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }
  .component.accordion-spot .accordion-spot-element__content {
    display: none; }
  .component.accordion-spot .accordion-spot-element.expand .accordion-spot-element__content {
    display: inherit; }
  .component.accordion-spot .accordion-spot-element.shrink {
    cursor: pointer; }

.component.accordion-spot.tabs .accordion-spot-tabs {
  display: flex;
  overflow-x: auto; }

.component.accordion-spot.tabs .accordion-spot-tab {
  cursor: pointer; }

.component.accordion-spot.tabs .accordion-spot-element + .accordion-spot-element {
  margin-top: 0; }

/* REGULAR STYLING */
.component.accordion-spot {
  margin-top: 50px;
  margin-bottom: 30px; }
  .component.accordion-spot .component-content {
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  .component.accordion-spot .accordion-spot-elements__expand-all {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 3rem;
    margin: 0;
    padding: 0 0 0.4em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    padding-right: 30px; }
    .component.accordion-spot .accordion-spot-elements__expand-all::after {
      position: absolute;
      top: 0.1em;
      right: 0;
      content: "";
      background: url(asset_path("expand-all.svg")) no-repeat;
      background-size: contain;
      background-position: center;
      width: 1em;
      height: 1em;
      display: block;
      transform: rotate(90deg);
      transform-origin: 50% 50%; }
    .component.accordion-spot .accordion-spot-elements__expand-all.shrink-all::after {
      transform: rotate(-90deg); }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element__title {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 1.5rem;
    margin: 0;
    padding: 0 0 0.6em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    padding-left: 0;
    padding-right: 2rem;
    border-top: 1px solid #D5D5D5;
    display: flex;
    align-items: center;
    min-height: 3.75rem;
    box-sizing: border-box;
    line-height: 1.4;
    user-select: none; }
    .component.accordion-spot .accordion-spot-elements .accordion-spot-element__title::before {
      content: "";
      -webkit-mask: url("/design/build/retail/assets/plus.svg") no-repeat 50% 50%;
      mask: url("/design/build/retail/assets/plus.svg") no-repeat 50% 50%;
      -webkit-mask-size: cover;
      mask-size: cover;
      background: #005da9;
      width: 1em;
      height: 1em;
      position: absolute;
      right: 0; }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element.expand .accordion-spot-element__title {
    border-bottom: 1px solid #D5D5D5; }
    .component.accordion-spot .accordion-spot-elements .accordion-spot-element.expand .accordion-spot-element__title::before {
      content: "";
      -webkit-mask: url("/design/build/retail/assets/minus.svg") no-repeat 50% 50%;
      mask: url("/design/build/retail/assets/minus.svg") no-repeat 50% 50%; }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element__content {
    padding: 1rem 0; }
    @media only screen and (min-width: 768px) {
      .component.accordion-spot .accordion-spot-elements .accordion-spot-element__content {
        padding: 1.25rem 0; } }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element__body ul {
    margin-left: 1.25rem !important;
    font-size: 1.125rem;
    line-height: 1.5; }
    .component.accordion-spot .accordion-spot-elements .accordion-spot-element__body ul:last-child {
      padding-bottom: 0; }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element__body p {
    padding-bottom: 0.5em !important; }
    .component.accordion-spot .accordion-spot-elements .accordion-spot-element__body p:last-child {
      padding-bottom: 0 !important; }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element__body img {
    max-width: 100%; }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element__header {
    font-family: "Proxima N W01 Bold", sans-serif; }
  .component.accordion-spot .accordion-spot-elements .accordion-spot-element:last-of-type .accordion-spot-element__title {
    border-bottom: 1px solid #D5D5D5; }

/* GENERAL TAB STYLING */
.component.accordion-spot.tabs .accordion-spot-element__title {
  padding-left: 0; }
  .component.accordion-spot.tabs .accordion-spot-element__title::before {
    display: none;
    background: none; }

.component.accordion-spot.tabs .accordion-spot-element__content {
  padding: 41px 0 0 0; }

/* BUTTON TAB STYLING */
.component.accordion-spot.tabs.button .accordion-spot-tab {
  margin-right: 10px; }
  .component.accordion-spot.tabs.button .accordion-spot-tab__title {
    border: 1px solid grey;
    background-color: transparent;
    color: grey; }
  .component.accordion-spot.tabs.button .accordion-spot-tab.passive:hover .accordion-spot-tab__title, .component.accordion-spot.tabs.button .accordion-spot-tab.active .accordion-spot-tab__title {
    border: 1px solid blue;
    background-color: blue;
    color: #ffffff; }

/* BUTTON TAB STYLING */
/* HEADLINE TAB STYLING */
.component.accordion-spot.tabs.headline .accordion-spot-tab {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  min-width: 100px;
  border-bottom: 3px solid green;
  padding-bottom: 11px; }
  @media only screen and (min-width: 992px) {
    .component.accordion-spot.tabs.headline .accordion-spot-tab {
      margin-right: 100px;
      min-width: 150px;
      border-bottom-width: 5px;
      padding-bottom: 15px; } }
  .component.accordion-spot.tabs.headline .accordion-spot-tab.passive {
    border-bottom-color: grey; }
  .component.accordion-spot.tabs.headline .accordion-spot-tab__title {
    border: 0;
    padding-bottom: 4px; }

#wrapper .component.blog .blog-image img {
  width: 100%;
  display: block; }

#wrapper .component.blog .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 2rem; }
  #wrapper .component.blog .component-content .blog-reading-time,
  #wrapper .component.blog .component-content .blog-author {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-date {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-author-value {
    color: #c6168d;
    font-family: "GillSansMTPro-Medium", sans-serif; }
  #wrapper .component.blog .component-content .blog-title {
    margin: 1rem 0 0.2rem; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    font-weight: 700; }
    @media only screen and (max-width: 479px) {
      #wrapper .component.blog .component-content .blog-introduction {
        font-size: 1.1rem; } }
  #wrapper .component.blog .component-content .blog-content {
    margin-bottom: 0.8rem; }

@media only screen and (min-width: 992px) {
  #wrapper .component.blog .component-content .blog-title {
    margin: 2rem 0; } }

.component.blog-list #blog-list li,
.component.latest-blog-posts #blog-list li {
  list-style: none;
  text-indent: 0;
  padding: 0;
  margin: 1rem; }
  .component.blog-list #blog-list li > a,
  .component.latest-blog-posts #blog-list li > a {
    display: block; }
  .component.blog-list #blog-list li .news-title,
  .component.latest-blog-posts #blog-list li .news-title {
    display: none; }
  .component.blog-list #blog-list li .news-text,
  .component.blog-list #blog-list li .news-link,
  .component.latest-blog-posts #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-link {
    margin-top: 1rem;
    text-align: center; }
  .component.blog-list #blog-list li .news-image,
  .component.latest-blog-posts #blog-list li .news-image {
    text-align: center; }
    .component.blog-list #blog-list li .news-image img,
    .component.latest-blog-posts #blog-list li .news-image img {
      max-width: 100%; }
  .component.blog-list #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-text {
    line-height: 1.5rem; }
  .component.blog-list #blog-list li .news-link span,
  .component.latest-blog-posts #blog-list li .news-link span {
    margin: 0; }
  .component.blog-list #blog-list li .news-date,
  .component.latest-blog-posts #blog-list li .news-date {
    text-align: center;
    padding: 0.5rem 0;
    color: #f5f5f5; }

@media only screen and (min-width: 768px) {
  .component.blog-list #blog-list,
  .component.latest-blog-posts #blog-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 3.6rem 0; }
    .component.blog-list #blog-list li,
    .component.latest-blog-posts #blog-list li {
      box-sizing: border-box;
      margin: 0 auto 1rem auto;
      padding: 0 2%;
      width: 33%; }
      .component.blog-list #blog-list li .news-image img,
      .component.latest-blog-posts #blog-list li .news-image img {
        max-height: 180px; }
      .component.blog-list #blog-list li .news-text,
      .component.latest-blog-posts #blog-list li .news-text {
        height: 125px;
        max-height: 125px;
        overflow: hidden; } }

@media only screen and (min-width: 992px) {
  .component.blog-list .component-content,
  .component.latest-blog-posts .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; } }

#wrapper .component.related-blogs {
  clear: both;
  background-color: #ffffff; }
  #wrapper .component.related-blogs .component-content {
    width: 60rem;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 0; }
    #wrapper .component.related-blogs .component-content .related-title {
      display: none; }
    #wrapper .component.related-blogs .component-content ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start; }
      #wrapper .component.related-blogs .component-content ul li {
        margin: 1rem 0 0 0;
        width: 100%;
        padding: 0 1%;
        box-sizing: border-box;
        list-style: none; }
        #wrapper .component.related-blogs .component-content ul li:nth-child(n + 4) {
          display: none; }
        #wrapper .component.related-blogs .component-content ul li .news-image {
          text-align: center; }
          #wrapper .component.related-blogs .component-content ul li .news-image img {
            max-width: 100%;
            height: auto; }
        #wrapper .component.related-blogs .component-content ul li .news-text,
        #wrapper .component.related-blogs .component-content ul li .news-link {
          background: #f1f1f1;
          font-size: 1.4rem;
          padding: 0.6rem 0.6rem 0;
          text-align: center; }
        #wrapper .component.related-blogs .component-content ul li .news-date {
          display: none; }
        #wrapper .component.related-blogs .component-content ul li .news-text {
          max-height: 75px;
          height: 75px;
          overflow: hidden;
          line-height: 1.4; }
        #wrapper .component.related-blogs .component-content ul li .news-link {
          padding-bottom: 10px; }
          #wrapper .component.related-blogs .component-content ul li .news-link a.link {
            margin: 0;
            padding-left: 0; }
            #wrapper .component.related-blogs .component-content ul li .news-link a.link:hover {
              padding-left: 1rem; }
          #wrapper .component.related-blogs .component-content ul li .news-link a::before {
            content: ""; }

@media only screen and (min-width: 768px) {
  #wrapper .component.related-blogs .component-content ul li {
    width: 33%;
    padding: 0 2%; }
    #wrapper .component.related-blogs .component-content ul li .news-text,
    #wrapper .component.related-blogs .component-content ul li .news-link {
      font-size: 1.1rem; }
    #wrapper .component.related-blogs .component-content ul li .news-image img {
      max-height: 150px;
      max-width: 100%; } }

#wrapper .component.blog .blog-image {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  max-width: 100%; }

#wrapper .component.blog .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.blog .component-content .blog-title {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 3rem;
    margin: 0;
    padding: 0 0 0.4em 0;
    line-height: 1;
    color: #005da9;
    display: block; }
  #wrapper .component.blog .component-content .blog-time-wrap {
    font-size: 1.2rem;
    margin: 0 0 1rem 0; }
    #wrapper .component.blog .component-content .blog-time-wrap:after {
      content: "";
      display: table;
      clear: both; }
  #wrapper .component.blog .component-content .blog-date,
  #wrapper .component.blog .component-content .blog-reading-time {
    color: #a7d3ac;
    float: left;
    padding-right: .5rem; }
  #wrapper .component.blog .component-content .blog-reading-time:before {
    content: "• ";
    display: inline-block;
    padding-right: .5rem; }
  #wrapper .component.blog .component-content .blog-author,
  #wrapper .component.blog .component-content .blog-author-wrap {
    display: none; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 500; }
  #wrapper .component.blog .component-content .blog-content {
    font-size: 1.3rem; }
    #wrapper .component.blog .component-content .blog-content .quote {
      color: #27251F;
      font-size: inherit;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 1rem; }
      #wrapper .component.blog .component-content .blog-content .quote:before, #wrapper .component.blog .component-content .blog-content .quote:after {
        display: none; }
    #wrapper .component.blog .component-content .blog-content .quote-bubble-author {
      position: relative;
      text-align: center;
      margin: 0 auto 3rem auto;
      color: #005da9;
      font-weight: 700;
      padding-left: 0; }
      #wrapper .component.blog .component-content .blog-content .quote-bubble-author:after {
        text-align: center;
        content: " ";
        display: block;
        position: absolute;
        top: 40px;
        width: 40px;
        left: 50%;
        margin-left: -20px;
        border-bottom: solid 4px #a7d3ac; }
  #wrapper .component.blog .component-content p {
    font-size: 1rem; }

@media only screen and (min-width: 1020px) {
  #wrapper .component.blog .component-content .blog-time-wrap {
    font-size: .9rem; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-size: 1.3rem; }
  #wrapper .component.blog .component-content .blog-content .quote {
    max-width: 90%;
    display: block;
    margin: 1.5rem auto .5rem auto; } }

.blog-image--top {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  max-width: 100%; }
  .blog-image--top img {
    width: 100%; }

#wrapper div.component.breadcrumb {
  padding: 1em 0 1em 0 !important; }
  #wrapper div.component.breadcrumb .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper div.component.breadcrumb a {
    text-decoration: none; }
  #wrapper div.component.breadcrumb ol {
    list-style: none;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  #wrapper div.component.breadcrumb .separator {
    padding: 0 .5em;
    display: none; }
  #wrapper div.component.breadcrumb li {
    float: left;
    font-size: 0.9em;
    line-height: 2;
    position: relative; }
    #wrapper div.component.breadcrumb li:not(.home)::before {
      font-family: "Oticon-icon-www";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      content: "\e60c";
      font-size: 1.5em;
      padding-left: 0.2em;
      position: absolute;
      top: 0.2em; }
    #wrapper div.component.breadcrumb li:not(.home) a {
      /*                position: relative;
                          top: -1px;*/
      margin-left: 2em; }
    #wrapper div.component.breadcrumb li.last .separator {
      display: none; }
  #wrapper div.component.breadcrumb.breadcrumb-hide li {
    white-space: nowrap; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide + li.hide {
      display: none; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide a {
      display: none; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide:before {
      content: "...";
      display: inline; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide-full {
      display: none; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li span.icon {
      font-size: 2em;
      width: 2em;
      color: aqua;
      margin: -0.05em -0.5em;
      float: left;
      display: block; }

#wrapper .component.breadcrumb ol li {
  padding: 0;
  font-size: 1em; }
  #wrapper .component.breadcrumb ol li.home::before {
    content: " "; }
  #wrapper .component.breadcrumb ol li:not(.home)::before {
    font-family: "retail-icon-font";
    font-size: 1.4rem;
    content: "";
    color: #b0b0b0;
    top: 0.5rem; }
  #wrapper .component.breadcrumb ol li a {
    color: #005da9; }
  #wrapper .component.breadcrumb ol li > .field-title > span {
    margin-left: 2em;
    font-family: "Proxima N W01 Bold", sans-serif; }

#wrapper > #content .component.color-spot {
  overflow: hidden; }
  #wrapper > #content .component.color-spot .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 2; }
  #wrapper > #content .component.color-spot .colors-container {
    position: relative;
    text-align: center; }
    #wrapper > #content .component.color-spot .colors-container .color-picker {
      padding: 0; }
      #wrapper > #content .component.color-spot .colors-container .color-picker li {
        display: inline-block;
        margin: 0 0.4em 0.4em;
        height: 1.3em;
        width: 1.3em; }
        @media only screen and (min-width: 768px) {
          #wrapper > #content .component.color-spot .colors-container .color-picker li {
            height: 2.3em;
            width: 2.3em; } }
        #wrapper > #content .component.color-spot .colors-container .color-picker li a {
          background-color: #000;
          border-radius: 50%;
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4) inset;
          cursor: pointer;
          display: block;
          height: 1.3em;
          width: 1.3em;
          text-decoration: none;
          transform: scale(1); }
          @media only screen and (min-width: 768px) {
            #wrapper > #content .component.color-spot .colors-container .color-picker li a {
              height: 2.3em;
              width: 2.3em; } }
        #wrapper > #content .component.color-spot .colors-container .color-picker li.current a {
          cursor: default;
          transform: scale(0.6); }
        #wrapper > #content .component.color-spot .colors-container .color-picker li:before {
          content: "";
          left: -50%;
          opacity: 0;
          position: absolute;
          right: 0;
          top: -19%;
          width: 200%;
          transition: opacity, 1s; }
    #wrapper > #content .component.color-spot .colors-container .images {
      display: inline-block;
      list-style-type: none;
      padding: 0;
      position: relative;
      width: 100%;
      height: 100%; }
      #wrapper > #content .component.color-spot .colors-container .images li {
        left: 0;
        opacity: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 2;
        height: 100%;
        display: none; }
        #wrapper > #content .component.color-spot .colors-container .images li img {
          left: auto;
          opacity: 1;
          position: relative;
          top: auto;
          z-index: 1;
          width: 100%;
          height: 100%; }
        #wrapper > #content .component.color-spot .colors-container .images li.current {
          opacity: 1;
          z-index: 3;
          display: inherit; }
    #wrapper > #content .component.color-spot .colors-container .color-picker-name {
      display: none;
      list-style-type: none;
      padding: 1rem 0rem 0rem 0rem;
      position: relative;
      width: 100%;
      height: 100%; }
      #wrapper > #content .component.color-spot .colors-container .color-picker-name li {
        left: 0;
        opacity: 0;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 2;
        height: 1.2rem;
        display: none; }
        #wrapper > #content .component.color-spot .colors-container .color-picker-name li.current {
          opacity: 1;
          z-index: 3;
          display: inherit; }
        #wrapper > #content .component.color-spot .colors-container .color-picker-name li a {
          color: #f0f0f0;
          font-family: inherit;
          cursor: auto; }

#wrapper .component.content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.content .field-location, #wrapper .component.content .field-date {
    margin-bottom: 0.5em; }
  #wrapper .component.content.top-margin {
    margin-top: 7rem; }

#wrapper .component.content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden; }
  #wrapper .component.content * {
    white-space: normal; }
  #wrapper .component.content .field-title {
    /*@include like-h2();*/
    font-size: 4.7em;
    width: 80%;
    padding-bottom: 0.1em; }
  #wrapper .component.content .field-location {
    display: none; }
  #wrapper .component.content .field-date {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    font-family: "Proxima N W01SC Reg It", sans-serif; }
    @media only screen and (max-width: 479px) {
      #wrapper .component.content .field-date {
        font-size: 1.1rem; } }
  #wrapper .component.content .field-content {
    width: 68%;
    float: left;
    margin-top: 3.1rem; }
  #wrapper .component.content .field-summaryicon {
    width: 28%;
    float: right;
    margin-top: 3.1rem; }
    #wrapper .component.content .field-summaryicon img {
      width: 100%; }

.cookie-warning {
  display: none;
  position: fixed;
  top: 0;
  bottom: auto;
  width: 100%;
  z-index: 1030;
  background-color: #333333;
  border-bottom: 1px solid #bbbbbb; }
  .cookie-warning .component-content {
    color: #f0f0f0;
    padding: .5em; }
    .cookie-warning .component-content .info {
      margin: 0;
      font-size: .8em;
      padding: 0 1.5em 0 .5em; }
    .cookie-warning .component-content .submit {
      font-family: "Oticon-icon-www";
      position: absolute;
      top: .2em;
      right: 0.5em;
      cursor: pointer; }
      .cookie-warning .component-content .submit * {
        line-height: 0;
        margin: 0;
        font-size: .8em; }
      .cookie-warning .component-content .submit::after {
        content: "\eae3";
        position: absolute;
        top: .2rem;
        right: 0;
        color: #005da9; }
      .cookie-warning .component-content .submit a p {
        opacity: 0; }
  .cookie-warning.initialized {
    display: block;
    animation: myAniTop 2s;
    -moz-animation: myAniTop 2s;
    -webkit-animation: myAniTop 2s; }

@media only screen and (max-width: 1020px) {
  .cookie-warning {
    top: auto;
    bottom: 0; }
    .cookie-warning.initialized {
      display: block;
      animation: myAniBottom 2s;
      -moz-animation: myAniBottom 2s;
      -webkit-animation: myAniBottom 2s; } }

@keyframes myAniTop {
  from {
    top: -300px; }
  to {
    top: 0px; } }

@-moz-keyframes myAniTop {
  from {
    top: -300px; }
  to {
    top: 0px; } }

@-webkit-keyframes myAniTop {
  from {
    top: -300px; }
  to {
    top: 0px; } }

@keyframes myAniBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0px; } }

@-moz-keyframes myAniBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0px; } }

@-webkit-keyframes myAniBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0px; } }

@keyframes myAniheader {
  from {
    top: 0px; }
  to {
    top: 42px; } }

@-moz-keyframes myAniheader {
  from {
    top: 0px; }
  to {
    top: 42px; } }

@-webkit-keyframes myAniheader {
  from {
    top: 0px; }
  to {
    top: 42px; } }

.on-page-editor #footer .component.cookie-warning.initialized {
  display: none !important; }

/***************************************************************
 COOKIE INFORMATION BANNER

 Should have the same styling, but shouldn't have the X in the after of the a tag,
 but rather an OK text in the a tag.
 *****************************************************************/
#cookie .cookie-warning .submit::after {
  content: ''; }

#wrapper .component.cookie-warning .component-content .submit {
  font-family: "retail-icon-font"; }
  #wrapper .component.cookie-warning .component-content .submit:after {
    color: #ffffff; }

#wrapper .component.cookie-warning .component-content .info p:last-child {
  padding-bottom: 0; }

#wrapper #content .component.appframe-component .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper #content .component.appframe-component p {
  margin: inherit; }

#wrapper #content .component.appframe-component span {
  margin: 0; }

#wrapper #content .component.appframe-component img {
  max-width: initial; }

#wrapper #content .component.appframe-component.full-width .component-content {
  max-width: none;
  width: 100%; }

#wrapper #content .component.appframe-component.autoscale .desktop-section {
  overflow: hidden; }
  #wrapper #content .component.appframe-component.autoscale .desktop-section .inner-container {
    transform-origin: top left; }

.component.data-hierarchy-spot.dataset {
  padding: 2rem 0; }

.component.data-hierarchy-spot.comparison-table .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto; }

.component.data-hierarchy-spot.comparison-table .group {
  border: solid 1px #000;
  border-bottom: none;
  margin-bottom: 2rem; }
  .component.data-hierarchy-spot.comparison-table .group__title {
    text-align: center;
    padding: 1rem; }

.component.data-hierarchy-spot.comparison-table .entry {
  border-bottom: solid 1px #000;
  padding: 1rem 0;
  margin: 0 1rem; }
  .component.data-hierarchy-spot.comparison-table .entry.inactive {
    text-decoration: line-through;
    font-size: 0.9rem; }
  .component.data-hierarchy-spot.comparison-table .entry:last-child {
    margin: 0;
    padding: 1rem; }

.component.data-hierarchy-spot.comparison-table .entry-group {
  color: #fff;
  background: #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
  text-align: center;
  padding: .4rem 0 .4rem 0; }
  .component.data-hierarchy-spot.comparison-table .entry-group .entry {
    border-bottom: none;
    padding: .4rem 0; }
    .component.data-hierarchy-spot.comparison-table .entry-group .entry.price {
      font-size: 2rem;
      padding-bottom: 0;
      color: #fff; }
    .component.data-hierarchy-spot.comparison-table .entry-group .entry.divider-above {
      display: inline-block;
      position: relative; }
      .component.data-hierarchy-spot.comparison-table .entry-group .entry.divider-above:before {
        content: "";
        display: inline-block;
        width: 100%;
        background-color: #fff;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0; }

.component.data-hierarchy-spot.grid-data {
  float: none;
  margin: 0; }
  .component.data-hierarchy-spot.grid-data .component-content .group {
    text-align: center;
    width: 100%;
    border: none;
    border-bottom: solid 1px #b0b0b0; }
    .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(4) {
      border-bottom: none; }
    .component.data-hierarchy-spot.grid-data .component-content .group .inner-container {
      padding: 2rem; }
    .component.data-hierarchy-spot.grid-data .component-content .group .rich-text {
      margin-bottom: 1rem; }
      .component.data-hierarchy-spot.grid-data .component-content .group .rich-text p {
        font-size: 1.5rem;
        color: #c6168d;
        font-weight: bold; }

@media only screen and (min-width: 992px) {
  .component.data-hierarchy-spot.comparison-table .group {
    float: left;
    border-right: none; }
    .component.data-hierarchy-spot.comparison-table .group:last-child {
      border-right: solid 1px #000; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(2),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(2) ~ .group {
      width: 47%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(3),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(3) ~ .group {
      width: 33%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(4),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(4) ~ .groupr {
      width: 22%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(5),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(5) ~ .group {
      width: 18%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(6),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(6) ~ .group {
      width: 15%; }
  .component.data-hierarchy-spot.grid-data .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
    .component.data-hierarchy-spot.grid-data .component-content .group {
      box-sizing: border-box;
      border: solid 1px #b0b0b0;
      float: left;
      width: 50%; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(1), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(2) {
        border-top: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(1), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(3) {
        border-left: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(2), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(4) {
        border-right: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(3), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(4) {
        border-bottom: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group > .inner-container {
        padding: 0 25%; }
      .component.data-hierarchy-spot.grid-data .component-content .group .entry:not(.rich-text) {
        text-align: left; } }

.component.data-hierarchy-spot.comparison-table .group {
  border-color: #b0b0b0; }

.component.data-hierarchy-spot.comparison-table .entry {
  border-color: #b0b0b0; }

.component.data-hierarchy-spot.comparison-table .entry-group {
  color: #ffffff;
  background: #005da9;
  border-color: #005da9; }
  .component.data-hierarchy-spot.comparison-table .entry-group.highlight-group {
    background: #e40049;
    border-color: #e40049; }
  .component.data-hierarchy-spot.comparison-table .entry-group .entry.price {
    color: #ffffff; }
  .component.data-hierarchy-spot.comparison-table .entry-group .entry.divider-above:before {
    background-color: #ffffff; }

@media only screen and (min-width: 992px) {
  .component.data-hierarchy-spot.comparison-table .group:last-child {
    border-color: #b0b0b0; } }

#wrapper > #content .component.data-hierarchy-spot.loader {
  opacity: 1; }
  #wrapper > #content .component.data-hierarchy-spot.loader.remove {
    display: none; }

#wrapper .component.data-hierarchy-spot.resource-center .filters .nice-select.resource-center-filter .list {
  box-sizing: content-box; }

#wrapper .component.data-hierarchy-spot.resource-center .filters .filter-label {
  color: #2d2d2d;
  padding-right: 2rem; }

#wrapper .component.data-hierarchy-spot.resource-center .filters .filter-label, #wrapper .component.data-hierarchy-spot.resource-center .filters .filter, #wrapper .component.data-hierarchy-spot.resource-center .filters .clear-filters-label {
  vertical-align: middle;
  display: inline-block; }

#wrapper .component.data-hierarchy-spot.resource-center .filters .clear-filters-label {
  margin: 0 0.2rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .clear-filters-label .clear-filters-button {
    padding-bottom: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .clear-filters-label.show {
    opacity: 1;
    visibility: visible; }

#wrapper .component.data-hierarchy-spot.resource-center .filters .selected-filters {
  background-color: #ffffff; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .selected-filters .inner-container {
    padding: 0.5rem 0; }
    #wrapper .component.data-hierarchy-spot.resource-center .filters .selected-filters .inner-container span {
      color: #7f7f7f;
      padding: 0.5rem;
      display: inline-block;
      background-color: #e1e1e1;
      margin: 0.4rem; }

#wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section {
  padding: 1rem 0;
  background-color: #bfbfbf; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section > .inner-container {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section .selected-filters {
    display: none;
    float: left;
    margin-top: 1.5rem; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section .filters-selectors .inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    #wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section .filters-selectors .inner-container .filter {
      margin: 0.5rem 1rem 0.5rem 0; }
      #wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section .filters-selectors .inner-container .filter:last-child {
        margin-right: 0; }
      #wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section .filters-selectors .inner-container .filter .select-label {
        margin: 0;
        font-weight: bold; }
      #wrapper .component.data-hierarchy-spot.resource-center .filters .desktop-section .filters-selectors .inner-container .filter .selected .current {
        font-weight: bold; }

#wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section {
  padding-top: 2rem;
  min-height: 3rem;
  background-color: #bfbfbf; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .filter-label {
    font-size: 1.3rem;
    margin-left: 2rem; }
    #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .filter-label.collapsed-filters ~ .filters-selectors {
      max-height: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .clear-filters-label {
    float: right;
    margin-right: 2.2rem;
    font-size: 1.3rem; }
    #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .clear-filters-label .clear-filters-button {
      padding: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .filters-selectors {
    max-height: 500px;
    overflow: hidden;
    margin-top: 1.6rem;
    padding: 0 1.8rem;
    background-color: #e1e1e1;
    transition: max-height 0.7s ease; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .filter {
    margin: 2rem 1% 0; }
    #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .filter .select-label {
      margin: 0;
      font-weight: bold;
      font-size: 1.1rem; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .show-filter-results {
    text-align: center;
    padding: 2rem 0; }
    #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .show-filter-results #show-filter-results-button {
      color: #7f7f7f;
      margin: 1rem 0;
      font-size: 1.5rem; }
  #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .selected-filters .inner-container {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }

#wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .content-type {
  display: inline-block;
  width: 50%;
  padding-bottom: 2rem; }
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .content-type .viewallitems-label, #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .content-type .rollbackup-label {
    margin-top: 1rem; }

#wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .content-type-hide {
  display: none; }

#wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .file-link {
  color: #404040;
  display: inline-block;
  box-sizing: border-box;
  width: 90%;
  padding: 0.5rem; }
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .file-link:nth-of-type(even) {
    background-color: #ffffff; }
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .file-link:nth-of-type(odd) {
    background-color: #f5f5f5; }
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .file-link span {
    margin-bottom: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .file-link:hover {
    background-color: #e1e1e1; }
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .file-link.file-locked::after {
    font-family: "DGS-WebProgram";
    margin-left: 0.5rem;
    content: ""; }

#wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .content-type-icon {
  display: inline-block;
  font-family: "DGS-WebProgram";
  margin-right: .5rem; }

#wrapper .component.data-hierarchy-spot.resource-center.grid-layout {
  float: none;
  margin-left: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type-files {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.66%; }
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type-hide {
    display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .file-link {
    display: block;
    width: 30%;
    margin: 0 1.66%;
    box-sizing: border-box;
    text-align: center; }
    #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .file-link .image {
      display: inline-block;
      max-width: 100%; }
      #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .file-link .image img {
        max-width: 100%;
        height: auto; }
    #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .file-link p {
      max-width: 90%;
      display: inline-block; }

#wrapper .component.data-hierarchy-spot.resource-center .resources {
  padding: 2rem 0;
  clear: both;
  /* "Generic" lightbox styling*/
  /* Desktop specific lightbox styling*/ }
  @media only screen and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center .resources > .inner-container {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto; } }
  #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .content-type-count {
    padding-left: 1rem;
    color: #878787;
    line-height: initial; }
  #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .viewallitems-label, #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .rollbackup-label {
    user-select: none;
    font-size: 0.8rem;
    cursor: pointer;
    display: none; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .viewallitems-label.show, #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .rollbackup-label.show {
      display: inline-block; }
  #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .content-type-expander {
    display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center .resources .file-link {
    color: #404040; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .file-link.file-link-hidden {
      display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox {
    display: none; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container {
      border: 1px solid #aaaaaa;
      padding: 2rem;
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container .lighbox-close {
        display: inline-block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        font-family: "DGS-WebProgram";
        user-select: none; }
        #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container .lighbox-close:hover {
          color: #404040; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container .lighbox-fileimg {
        display: none;
        margin-bottom: 2rem;
        user-select: none; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container .lighbox-download {
        user-select: none; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container .lighbox-filedescription {
        font-family: "Proxima N W01 Reg", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        padding: 0 0 1.2em 0;
        margin: 0; }
        @media only screen and (max-width: 479px) {
          #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container .lighbox-filedescription {
            font-size: 1.1rem; } }
  #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(191, 191, 191, 0.3);
    z-index: 9999; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .lighbox-fileimg {
      text-align: center; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .detail-lightbox .inner-container {
      background-color: #f5f5f5;
      max-height: 75%;
      top: 50%;
      transform: translatey(-50%);
      overflow-y: auto;
      width: 40rem; }

#wrapper .component.data-hierarchy-spot.resource-center .cssload-container {
  width: 100%;
  height: 24px;
  text-align: center;
  opacity: 0; }

#wrapper .component.data-hierarchy-spot.resource-center .cssload-inner-container {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  border: 2px double;
  border-radius: 50%;
  border-color: rgba(0, 0, 0, 0.9) transparent;
  animation: cssload-spin 960ms infinite linear; }
  #wrapper .component.data-hierarchy-spot.resource-center .cssload-inner-container::before {
    border-left: 5px solid rgba(0, 0, 0, 0.9);
    border-right: 5px solid transparent;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    width: 0;
    height: 0;
    position: relative;
    top: 13px;
    left: -6px;
    transform: rotate(225deg);
    content: '';
    display: block; }
  #wrapper .component.data-hierarchy-spot.resource-center .cssload-inner-container::after {
    border-left: 5px solid rgba(0, 0, 0, 0.9);
    border-right: 5px solid transparent;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    width: 0;
    height: 0;
    position: relative;
    top: -5px;
    left: 20px;
    transform: rotate(45deg);
    content: '';
    display: block; }

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg); } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type-files {
    margin: 0 -1.5%; }
    #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type-files .file-link {
      width: 47%;
      margin: 0 1.5%; }
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .content-type {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  #wrapper .component.data-hierarchy-spot.resource-center.list-layout .resources .content-type {
    width: 100%;
    padding-bottom: 0.3rem; }
  #wrapper .component.data-hierarchy-spot.resource-center .resources {
    padding-top: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type.expanded .content-type-files {
      display: block; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type > h4 {
      position: relative;
      padding: 1rem 2rem;
      font-size: 1rem;
      background-color: #7f7f7f;
      margin: 0;
      color: #ffffff; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type > h4 .content-type-count {
        color: #ffffff;
        font-weight: bold; }
        #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type > h4 .content-type-count::before {
          content: "[ "; }
        #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type > h4 .content-type-count::after {
          content: " ]"; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type > h4 .content-type-expander {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline;
        margin: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .content-type-files {
      display: none; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .content-type-files .file-link {
        width: 100%;
        padding: 0.6rem 2rem;
        font-size: 1rem; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .viewallitems-label, #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .viewallitems-label.show, #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .rollbackup-label, #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .rollbackup-label.show {
      display: none; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .detail-lightbox {
      position: relative;
      padding: 1rem 0;
      background-color: #ffffff; }
      #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .detail-lightbox .inner-container {
        background-color: #ffffff;
        width: 100%;
        max-height: none;
        transform: none;
        margin: 0 2rem;
        box-sizing: border-box; }
        #wrapper .component.data-hierarchy-spot.resource-center .resources .content-type .detail-lightbox .inner-container .lighbox-download {
          display: block;
          width: 16rem;
          margin: 1.5rem auto 0;
          padding: 1rem; }
    #wrapper .component.data-hierarchy-spot.resource-center .resources .empty-error {
      margin: 2rem; }
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type > h4 {
    display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .content-type-files {
    display: block;
    margin: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .content-type-files .file-link {
      width: 100%;
      margin: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .viewallitems-label, #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .rollbackup-label {
    margin-left: 2em; }
    #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .viewallitems-label.show, #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .rollbackup-label.show {
      display: inline-block; } }

@media only screen and (max-width: 568px) {
  #wrapper .component.data-hierarchy-spot.resource-center .filters .mobile-section .filter {
    width: 100%;
    margin: 1.5rem 0 0; }
  #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .content-type-files {
    margin: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center.grid-layout .resources .content-type .content-type-files .file-link {
      width: 100%;
      margin: 0; } }

#wrapper .component.event-calendar {
  background: #fff;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px; }
  #wrapper .component.event-calendar .fc-view-basicWeek .fc-day > div {
    min-height: 100px !important; }
  #wrapper .component.event-calendar .fc-header .fc-button {
    background: none;
    border-radius: 0;
    border: 1px solid #dedede; }
  #wrapper .component.event-calendar .fc-header {
    margin-bottom: 10px; }
    #wrapper .component.event-calendar .fc-header h2 {
      font-weight: bold; }
    #wrapper .component.event-calendar .fc-header td {
      vertical-align: middle;
      white-space: normal; }
  #wrapper .component.event-calendar .fc-header-left {
    width: 25%;
    vertical-align: bottom; }
  #wrapper .component.event-calendar .fc-header-center {
    width: 40%; }
  #wrapper .component.event-calendar .fc-header-right {
    width: 35%;
    white-space: normal;
    padding: 0px 2px; }
    #wrapper .component.event-calendar .fc-header-right .fc-button {
      margin-bottom: 0px; }
  #wrapper .component.event-calendar .fc-header-title {
    padding: 0px 10px; }
    #wrapper .component.event-calendar .fc-header-title h2 {
      white-space: normal; }
  #wrapper .component.event-calendar .fc-widget-content {
    background: #F5F5F5;
    border: 1px solid white;
    padding: 1px; }
    #wrapper .component.event-calendar .fc-widget-content.fc-other-month {
      background: #E8E8E8; }
  #wrapper .component.event-calendar .fc-day-header {
    background: #E8E8E8;
    border: 1px solid white;
    border-top-width: 3px;
    padding: 1px; }
  #wrapper .component.event-calendar .fc-event {
    border: #736F6E;
    padding: 3px;
    background: #4187ae;
    color: #FFF;
    font-weight: normal; }
  #wrapper .component.event-calendar.compact-mode .fc-header-title h2 {
    margin-bottom: 0;
    padding: 5px 0 0 5px; }
  #wrapper .component.event-calendar.compact-mode .selected-day {
    background: #95bcf2; }
  #wrapper .component.event-calendar.compact-mode .fc-day .fc-day-content {
    padding: 0; }

.calendar-tooltip {
  z-index: 9999;
  position: absolute;
  width: 250px;
  background: White;
  border: 1px solid #736f6e;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  background: #F5F5F5;
  -moz-box-shadow: 3px 3px 8px #736f6e;
  -webkit-box-shadow: 3px 3px 8px #736f6e;
  box-shadow: 3px 3px 8px #736f6e; }
  .calendar-tooltip .arrow {
    position: absolute;
    top: -10px;
    left: 70px;
    height: 10px;
    width: 30px;
    overflow: hidden; }
    .calendar-tooltip .arrow:before {
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      background: #fff;
      border: 1px solid #736f6e;
      position: absolute;
      top: 6px;
      z-index: 0;
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -o-transform: rotate(45deg); }
  .calendar-tooltip a {
    font-size: 12px; }
  .calendar-tooltip p {
    margin-top: 0px; }
  .calendar-tooltip .compact-event {
    margin-bottom: 10px;
    border-top: 1px dashed #736f6e;
    padding-top: 5px; }
    .calendar-tooltip .compact-event:first-child {
      border-top: none;
      padding: 0; }
  .calendar-tooltip .title {
    display: block;
    font-weight: bold;
    font-size: 13px; }
  .calendar-tooltip .description {
    display: block; }
  .calendar-tooltip .link {
    display: block;
    margin-top: 5px; }

#wrapper .component.event-calendar .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.event-calendar .component-content h3 {
    padding-bottom: 0.3em; }
  #wrapper .component.event-calendar .component-content .fc-button {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #a7d3ac;
    color: #005da9;
    background-color: transparent;
    border: 2px solid #a7d3ac; }
    #wrapper .component.event-calendar .component-content .fc-button:hover {
      opacity: 0.7; }
    #wrapper .component.event-calendar .component-content .fc-button:not(:last-child) {
      margin-right: .2em; }
  #wrapper .component.event-calendar .component-content .fc-day-number {
    padding: 0.3em; }
  #wrapper .component.event-calendar .component-content .fc-event {
    padding: 0.3em;
    background-color: #005da9;
    overflow: hidden;
    border-top: 1px solid #f6f6f6; }
    #wrapper .component.event-calendar .component-content .fc-event .fc-event-title {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  #wrapper .component.event-calendar .component-content .fc-day-header {
    background-color: #f0f0f0;
    padding: 0.3em; }
  #wrapper .component.event-calendar .component-content .fc-widget-content {
    background-color: #f6f6f6; }
    #wrapper .component.event-calendar .component-content .fc-widget-content.fc-other-month {
      background-color: #f0f0f0; }
  #wrapper .component.event-calendar .component-content .fc-header-left,
  #wrapper .component.event-calendar .component-content .fc-header-center,
  #wrapper .component.event-calendar .component-content .fc-header-right {
    width: 100%;
    display: block; }
  #wrapper .component.event-calendar .component-content .fc-view-basicWeek .fc-day > div {
    min-height: 4.5em !important; }
  #wrapper .component.event-calendar .component-content .fc-view-month .fc-day > div {
    min-height: 5.5em !important; }
  #wrapper .component.event-calendar .component-content .fc-state-highlight {
    background-color: rgba(167, 211, 172, 0.5); }

.calendar-tooltip {
  border-radius: 0;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  box-shadow: 3px 3px 8px #eeeeee;
  width: 10em; }
  .calendar-tooltip.mobile-left .arrow {
    left: 1em; }
  .calendar-tooltip.mobile-right .arrow {
    left: 8em; }
  .calendar-tooltip .arrow:before {
    border: 1px solid #eeeeee; }
  .calendar-tooltip .description p {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0; }
    @media only screen and (max-width: 479px) {
      .calendar-tooltip .description p {
        font-size: 1.1rem; } }
    .calendar-tooltip .description p:last-child {
      padding-bottom: 0; }
  .calendar-tooltip .title {
    padding-bottom: 0.3em; }
  .calendar-tooltip .link {
    margin-top: 0; }
    .calendar-tooltip .link a {
      padding-top: 1em;
      display: inline-block;
      color: #e40049;
      font-family: "Proxima N W01 Bold", sans-serif;
      text-decoration: none;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s; }
      .calendar-tooltip .link a:hover {
        opacity: 0.7; }

@media only screen and (min-width: 1024px) {
  #wrapper .component.event-calendar .component-content .fc-header-left,
  #wrapper .component.event-calendar .component-content .fc-header-center,
  #wrapper .component.event-calendar .component-content .fc-header-right {
    width: 33.333%;
    display: table-cell; }
  #wrapper .component.event-calendar .component-content .fc-header-right {
    text-align: right; }
  #wrapper .component.event-calendar .component-content .fc-view-basicDay {
    margin-left: 0; }
  #wrapper .component.event-calendar .component-content .fc-view-basicWeek {
    margin-left: 0; }
    #wrapper .component.event-calendar .component-content .fc-view-basicWeek .fc-day > div {
      min-height: 6.5em !important; }
  #wrapper .component.event-calendar .component-content .fc-view-month {
    margin-left: 0; }
    #wrapper .component.event-calendar .component-content .fc-view-month .fc-day > div {
      min-height: 7.5em !important; }
  .calendar-tooltip {
    width: 15em; } }

.event-list ul {
  margin: 10px 0; }

.event-list li {
  background: #ffffff;
  display: block;
  min-width: 40%;
  margin: 10px 0;
  margin-right: 5px; }
  .event-list li:after {
    content: "";
    clear: both;
    display: block; }

.event-list .event-list-time {
  width: 100px;
  padding-left: 5px;
  display: block;
  margin-right: 15px;
  float: left;
  font-weight: bold; }

.event-list .event-list-place {
  padding-left: 18px;
  float: right; }

.event-list .event-list-name,
.event-list .event-list-description,
.event-list .event-list-link,
.event-list .event-list-place {
  margin-left: 120px; }

.event-list .event-list-name a {
  font-size: 16px;
  font-weight: bold; }

.event-list .event-list-link {
  float: right; }

#wrapper .component.event-list .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.event-list .component-content > ul > li {
    white-space: nowrap;
    background-color: #f1f1f1;
    border-top: 1px solid #a7d3ac; }
    #wrapper .component.event-list .component-content > ul > li .event-list-time,
    #wrapper .component.event-list .component-content > ul > li .event-list-place {
      width: 50%;
      padding: 1rem;
      margin: 0;
      box-sizing: border-box;
      float: none;
      display: inline-block;
      white-space: normal; }
    #wrapper .component.event-list .component-content > ul > li .event-list-place {
      text-align: right;
      font-family: "Proxima N W01 Bold", sans-serif;
      vertical-align: top; }
    #wrapper .component.event-list .component-content > ul > li .event-list-place,
    #wrapper .component.event-list .component-content > ul > li .event-list-name,
    #wrapper .component.event-list .component-content > ul > li .event-list-description,
    #wrapper .component.event-list .component-content > ul > li .event-list-link {
      margin-left: 0; }
    #wrapper .component.event-list .component-content > ul > li .event-list-name {
      float: none;
      font-family: "Proxima N W01 Bold", sans-serif;
      font-size: 2rem;
      margin: 0;
      padding: 0 0 0.8em 0;
      line-height: 1;
      color: #005da9;
      display: block;
      background-color: #f6f6f6;
      padding: 1rem 1rem 0 1rem;
      white-space: normal; }
      #wrapper .component.event-list .component-content > ul > li .event-list-name a {
        font-family: "Proxima N W01 Bold", sans-serif;
        font-size: 2rem;
        margin: 0;
        padding: 0 0 0.8em 0;
        line-height: 1;
        color: #005da9;
        display: block;
        font-size: 1em; }
    #wrapper .component.event-list .component-content > ul > li .event-list-description {
      background-color: #f6f6f6;
      padding: .6rem 1rem 0 1rem;
      white-space: normal; }
    #wrapper .component.event-list .component-content > ul > li .event-list-link {
      float: none;
      padding: 0 1em 1em 1rem;
      width: 100%;
      background-color: #f6f6f6;
      box-sizing: border-box; }

#wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-text, #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-header {
  max-width: 80%;
  margin: auto; }

#wrapper .image-spot:not(.flex-layout) {
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat; }
  #wrapper .image-spot:not(.flex-layout) .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper .image-spot:not(.flex-layout) .component-content {
    overflow: hidden; }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
      clear: both;
      display: none;
      width: 74rem;
      max-width: 90vw; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 691.2px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 921.6px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 288px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 432px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 288px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 511.2px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 337.5px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 600.3px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 372.6px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 662.4px; } }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-content {
      width: 100%;
      text-align: center;
      border-top: 2px solid #000000;
      padding-top: 2rem; }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-selector {
      background-color: #000000;
      width: 2px;
      height: 20px;
      position: relative;
      left: 50px; }
    #wrapper .image-spot:not(.flex-layout) .component-content .context-container {
      clear: both;
      margin-top: 1rem; }
  #wrapper .image-spot:not(.flex-layout) .image-spot-elements {
    text-align: center;
    position: relative;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element::before {
      display: none; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-text {
      margin-bottom: 1em; }
      #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-text * {
        margin: 0; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-image {
      display: block;
      padding: 0 0 1.3em 0;
      overflow: hidden;
      position: relative; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-header {
      margin-top: 1em;
      line-height: 1em;
      margin-bottom: .5em;
      display: block; }
      #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-header * {
        margin: 0; }
  #wrapper .image-spot:not(.flex-layout).round-spot .image-spot-element-image {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: all, 0.2s;
    -moz-transition: all, 0.2s;
    -o-transition: all, 0.2s;
    transition: all, 0.2s;
    position: relative;
    height: auto; }
  #wrapper .image-spot:not(.flex-layout).round-spot .image-spot-element-image img {
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all, 0.2s;
    -moz-transition: all, 0.2s;
    -o-transition: all, 0.2s;
    transition: all, 0.2s;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative; }
  #wrapper .image-spot:not(.flex-layout).round-spot .image-spot-element-image:hover img {
    /*@include scale(1.1);
        @include rotate(-10);
        @include translate(0,0);
        Not used. AFAIK Note. The three statements override eachother so only the third remains... Making the image make a small flicker.
        */ }
  #wrapper .image-spot:not(.flex-layout).square-spot {
    box-sizing: border-box; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-image img {
      display: inline-block;
      border-bottom: 8px solid #005da9;
      box-sizing: border-box;
      transition: all .2s linear; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-image:hover img {
      -webkit-transform: scale(1.1, 1.1);
      -moz-transform: scale(1.1, 1.1);
      -ms-transform: scale(1.1, 1.1);
      -o-transform: scale(1.1, 1.1);
      transform: scale(1.1, 1.1);
      transform-origin: center;
      border-bottom: 8px solid transparent; }
  #wrapper .image-spot:not(.flex-layout) .image-spot-element {
    padding: 0 0.4em .1em 0.4em;
    box-sizing: border-box; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-element:first-child {
      padding: 0 0.8em 0 0; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-element:last-child {
      padding: 0 0 0 0.8em; }
  #wrapper .image-spot:not(.flex-layout).has-active .image-spot-elements .image-spot-element.active {
    color: #005da9; }
    #wrapper .image-spot:not(.flex-layout).has-active .image-spot-elements .image-spot-element.active .image-spot-element-image img {
      background-color: #005da9; }
  #wrapper .image-spot:not(.flex-layout).slim .component-content {
    width: 60rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper .image-spot:not(.flex-layout).accordion {
    overflow: hidden; }
    #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-elements {
      text-align: inherit; }
    #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) {
      clear: both;
      float: none;
      margin-bottom: 2rem;
      position: relative;
      padding: 0;
      width: 100%; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-header {
        font-family: "Proxima N W01 Bold", sans-serif;
        font-size: 2.5rem;
        margin: 0;
        padding: 0 0 0.6em 0;
        line-height: 1;
        color: #005da9;
        display: block;
        max-width: none;
        text-align: left;
        color: #005da9;
        padding: .5rem 0;
        border-bottom: thin solid #005da9;
        margin-bottom: 2rem;
        cursor: pointer; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .spacer, #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .mask {
        display: none; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-image {
        margin-bottom: 1rem; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-content {
        position: absolute;
        left: 200%;
        opacity: 0; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-text {
        margin: 0;
        max-width: none; }
        #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-text * {
          margin: 0; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n).open {
        margin-bottom: 3rem; }
        #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n).open .image-spot-element-content {
          position: relative;
          left: 0;
          opacity: 1; }
    @media only screen and (min-width: 992px) {
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .spacer {
        display: block;
        width: 0px;
        float: left; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .image-spot-element-image {
        float: left;
        clear: left;
        margin: 0 1rem 0 0; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .image-spot-element-content {
        transition: opacity 600ms ease-in; }
        #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .image-spot-element-content .mask {
          position: absolute;
          top: -1rem;
          left: 0;
          bottom: -2rem;
          right: 0;
          background-color: #ffffff;
          transition: top 450ms ease-in, left 450ms ease-in; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element.open .mask {
        left: 100%; }
      #wrapper .image-spot:not(.flex-layout).accordion.image-right .component-content .image-spot-element .spacer,
      #wrapper .image-spot:not(.flex-layout).accordion.image-right .component-content .image-spot-element .image-spot-element-image {
        float: right; }
      #wrapper .image-spot:not(.flex-layout).accordion.image-right .component-content .image-spot-element .image-spot-element-image {
        clear: right;
        margin: 1rem 0 0 1rem; } }

@media only screen and (min-width: 601px) {
  #wrapper .image-spot:not(.flex-layout) .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
    display: none !important; } }

@media only screen and (max-width: 600px) {
  #wrapper .image-spot:not(.flex-layout) .component-content > ul > li {
    width: 100%;
    margin-bottom: 3rem;
    float: none; }
  #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:last-child {
    margin-bottom: 0; }
  #wrapper .image-spot:not(.flex-layout) .component-content > ul .image-spot-element {
    padding: 0; }
  #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
  #wrapper .image-spot.no-mobile-image .image-spot-element-image {
    display: none; }
  #wrapper .image-spot:not(.no-stack):not(.flex-layout) {
    background-image: none !important; }
    #wrapper .image-spot:not(.no-stack):not(.flex-layout) .context-container {
      display: none !important; }
  #wrapper .image-spot-element-link a {
    margin-top: 0;
    margin-bottom: 4em; } }

#wrapper .image-spot:not(.flex-layout) .clickable:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity, 0.2s; }

#wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-selector {
  height: 0; }

#wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-content {
  border: 0; }

#wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements {
  text-align: left; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
    padding: 0.5em; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-header,
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
    margin-top: 0;
    margin-bottom: 0; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-header * {
    padding: 0.5em 0; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
    text-align: center;
    padding-top: .7em; }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
      position: relative;
      padding-right: 1em;
      font-size: 1.25rem; }
      #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link a::after {
        position: absolute;
        font-family: "retail-icon-font";
        content: "";
        font-weight: bold;
        font-size: 1.2em;
        line-height: 1;
        vertical-align: bottom;
        color: #e40049;
        transition: right 0.1s;
        right: -0.5em; }
      #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link a:hover::after {
        right: -0.8em; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element-context-selector {
    background-color: transparent; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element-context-content {
    border-top: 0; }

#wrapper .image-spot:not(.flex-layout):not(.as-slider) .image-spot-element-text * {
  padding: 0; }

#wrapper .image-spot:not(.flex-layout):not(.as-slider) .image-spot-element-image {
  text-align: center; }

#wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-link a, #wrapper .image-spot:not(.flex-layout).link-under-image .image-spot-element-link a {
  text-indent: -600em;
  display: inline-block;
  height: 2.7em;
  width: 2.7em; }
  #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-link a::before, #wrapper .image-spot:not(.flex-layout).link-under-image .image-spot-element-link a::before {
    font-family: "retail-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 2.2em;
    display: inline-block;
    content: "";
    font-size: 1.2em;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 2.2em;
    height: 2.2em;
    background-color: #a7d3ac;
    border-radius: 50%;
    color: #ffffff;
    text-indent: 0.3em;
    transition: all 0.2s; }

#wrapper .image-spot:not(.flex-layout).square-spot {
  text-align: left; }
  #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element {
    position: relative; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-image {
      padding: 0; }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-image img {
        border-bottom: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-text {
      margin-left: 0;
      max-width: 80%; }
      @media only screen and (min-width: 1200px) {
        #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-text {
          max-width: 85%; } }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-link {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      bottom: -1em;
      padding-top: 0; }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-link a {
        margin-bottom: 0;
        padding-right: 0; }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-link * {
        padding: 0.5em 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header {
      margin-left: 0;
      display: flex;
      align-items: center;
      max-width: 80%; }
      @media only screen and (min-width: 1200px) {
        #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header {
          max-width: 85%; } }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h3,
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h4,
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h5,
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h6 {
        margin-top: 0.5em; }

@media only screen and (max-width: 479px) {
  #wrapper .image-spot:not(.flex-layout):not(.square-spot):not(.as-slider):not(.as-tab):not(.as-faq):not(.isizeoriginal) .image-spot-element-image img {
    max-width: 16rem;
    max-height: 16rem; } }

#wrapper .image-spot:not(.flex-layout).link-under-image a > .image-spot-element-image {
  text-align: center; }
  #wrapper .image-spot:not(.flex-layout).link-under-image a > .image-spot-element-image::before {
    font-family: "retail-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 2.2em;
    display: inline-block;
    content: "";
    font-size: 1.2em;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 2.2em;
    height: 2.2em;
    background-color: #005da9;
    border-radius: 50%;
    color: #ffffff;
    text-indent: 0.3em;
    transition: all 0.2s;
    bottom: 0;
    top: auto;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1; }
  #wrapper .image-spot:not(.flex-layout).link-under-image a > .image-spot-element-image:hover {
    opacity: 0.7; }

#wrapper .image-spot:not(.flex-layout).link-under-image .image-spot-element-link {
  display: none; }

#wrapper .image-spot:not(.flex-layout).as-tab, #wrapper .image-spot:not(.flex-layout).as-faq {
  background-color: #f0f0f0; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element {
    text-align: center;
    background-color: #dddddd;
    border-right: 1px solid #f0f0f0;
    opacity: 0.4;
    filter: grayscale(100%);
    position: relative; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element > a, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element > a {
      width: 100%;
      box-sizing: border-box;
      opacity: 1;
      display: block;
      padding: 1em; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element.active, #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element:hover, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element.active, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element:hover {
      background-color: #ffffff;
      opacity: 1;
      filter: grayscale(0%);
      transition: .25s ease-in-out; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element:last-child, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element:last-child {
      border-right: none; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
      display: inline-block;
      caption-side: bottom;
      padding-bottom: .5em; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header *, #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header *, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
        font-family: "Proxima N W01 Bold", sans-serif;
        color: #333333; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-text, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
      display: none; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-image, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
      padding: 0 !important;
      width: 100%;
      vertical-align: middle;
      text-align: center; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-image img, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-image img {
        position: relative; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-link, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
      display: none; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .context-container, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .context-container {
    background-color: #ffffff;
    margin-top: 0;
    position: relative;
    top: -1px; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content {
    margin-top: 0;
    padding: 4.6em 0 4.6em 6em;
    box-sizing: border-box; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content img.image-spot-context-img-centered, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content img.image-spot-context-img-centered {
      margin: 0 2rem; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table {
      width: 100%;
      table-layout: fixed; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr {
        width: 100%; }
        #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td {
          /* text */
          /* image */ }
          #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(1), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(1) {
            vertical-align: middle;
            padding-right: 4em; }
            #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(1) h3, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(1) h3 {
              padding-top: 0; }
          #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(2), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(2) {
            text-align: right; }
            #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(2) img, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(2) img {
              width: 100% !important;
              height: auto !important; }

#wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element {
  opacity: 0.8; }

#wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content {
  padding: 2em; }

#wrapper .image-spot:not(.flex-layout).as-slider {
  padding: 0; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content {
    width: 100%;
    max-width: 100%; }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul {
      display: block;
      overflow: hidden; }
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element {
        width: 100% !important;
        padding: 0;
        margin: 0; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element:not(:first-child) {
          display: none; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-image {
          padding: 0; }
          #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-image img {
            display: block;
            width: 100%; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-header,
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-link {
          display: none; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0; }
          #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
            position: relative;
            width: 74rem;
            max-width: 90%;
            margin: 0 auto;
            padding-right: 37rem;
            box-sizing: border-box; }
            @media only screen and (min-width: 992px) and (max-width: 1199px) {
              #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
                padding-right: 31rem; } }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs {
      position: absolute;
      bottom: 3.625em;
      left: 0;
      right: 0; }
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content {
        position: relative;
        width: 74rem;
        max-width: 90%;
        margin: 0 auto;
        text-align: center; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-left: .75em;
          border-radius: 50%;
          background-color: #003765;
          cursor: pointer;
          user-select: none; }
          #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab.active-tab, #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab:hover {
            background-color: #ffffff; }
  #wrapper .image-spot:not(.flex-layout).as-slider.slider-white-text .component-content > ul > li.image-spot-element .slide-content p {
    color: white; }
  #wrapper .image-spot:not(.flex-layout).as-slider a, #wrapper .image-spot:not(.flex-layout).as-slider a span {
    transition: none !important; }

#wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
  padding: 0;
  text-align: center; }

#wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
  margin-top: -2.5em;
  margin-bottom: 1em;
  position: relative; }
  #wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #005da9; }
    #wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a:hover {
      opacity: 0.7; }
    @media only screen and (min-width: 0px) {
      #wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
        margin-bottom: 0; } }

#wrapper .image-spot:not(.flex-layout).corporate-link .image-spot-elements .image-spot-element .image-spot-element-link a,
#wrapper .image-spot:not(.flex-layout).corporate-link .image-spot-elements .image-spot-element .image-spot-element-link a::after {
  color: #005da9;
  font-size: 1.25rem; }

#wrapper .image-spot:not(.flex-layout).green-link .image-spot-elements .image-spot-element .image-spot-element-link a,
#wrapper .image-spot:not(.flex-layout).green-link .image-spot-elements .image-spot-element .image-spot-element-link a::after {
  color: #a7d3ac; }

#wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a::after {
  content: none; }

#wrapper .image-spot:not(.flex-layout).image-text-left-aligned .image-spot-element-text {
  text-align: center; }

#wrapper .image-spot.image-description-bg-corporate:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #005da9;
  color: #ffffff; }

#wrapper .image-spot.image-description-bg-green:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #a7d3ac;
  color: inherit; }

#wrapper .image-spot.image-description-bg-green-dark:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #96bd9a;
  color: inherit; }

#wrapper .image-spot.image-description-bg-nounce-green:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #d3e9d5;
  color: inherit; }

#wrapper .image-spot.image-description-bg-nounce-blue:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #68afd6;
  color: inherit; }

#wrapper .image-spot.image-description-bg-light-grey:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #f1f1f1;
  color: inherit; }

#wrapper .image-spot.image-description-bg-light-red:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #ec4d80;
  color: inherit; }

@media only screen and (min-width: 601px) {
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2) ~ li {
    width: 47%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2) ~ li:not(:last-child) {
      margin-right: 6%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li {
    width: 29%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li:not(:last-child) {
      margin-right: 6.5%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li {
    width: 29%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li:not(:last-child) {
      margin-right: 6.5%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4) ~ li {
    width: 22%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4) ~ li:not(:last-child) {
      margin-right: 4%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5) ~ li {
    width: 18%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5) ~ li:not(:last-child) {
      margin-right: 2.5%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6) ~ li {
    width: 15%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6) ~ li:not(:last-child) {
      margin-right: 1.6666%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li {
    width: 12.5%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li:not(:last-child) {
      margin-right: 2%; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) .image-spot-element-header,
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) .image-spot-element-text,
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li .image-spot-element-header,
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li .image-spot-element-text {
      max-width: 80%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li .image-spot-element-image {
    padding: 0; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li .image-spot-element-text {
    margin-bottom: 0; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
    padding-bottom: 0; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header *, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header * {
      padding-top: 0; } }

@media only screen and (min-width: 601px) and (max-width: 1020px) {
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content {
    padding: 3em 0 3em 3em; } }

@media only screen and (max-width: 1020px) {
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
    padding-right: 0;
    /** {
                                    max-width: 100%;
                                }*/ }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slider-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(221, 221, 221, 0.4); }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    margin-top: .8em; }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab.active-tab, #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab:hover {
      background-color: #a7d3ac; }
  #wrapper .image-spot:not(.flex-layout).as-slider.slider-white-text .component-content > ul > li.image-spot-element .slider-overlay {
    background-color: rgba(51, 51, 51, 0.6); } }

@media only screen and (max-width: 479px) {
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-image .image-spot-element-image-description {
    margin-top: -2px; }
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-context-content table,
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-context-content tr,
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-context-content td {
    display: block; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context {
    width: 100%; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content {
      padding: 2em 2em 2em 2em;
      box-sizing: border-box;
      width: 100%;
      background-color: #ffffff; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content img.image-spot-context-img-centered, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content img.image-spot-context-img-centered {
        margin: 0 0 2rem 0; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(1), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(1) {
        vertical-align: top;
        padding: 0 0 1em 0; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(2), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(2) {
        margin-right: -2em; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-image img {
    display: none; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content {
    position: static;
    top: auto;
    transform: none; }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
      padding-top: 1em;
      padding-bottom: 1em; }
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text .btn,
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text [class*="btn-"] {
        margin-bottom: 1rem; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slider-overlay {
    background-color: rgba(221, 221, 221, 0.4); }
  #wrapper .image-spot:not(.flex-layout).as-slider.slider-white-text .component-content > ul > li.image-spot-element .slider-overlay {
    background-color: rgba(51, 51, 51, 0.7); } }

@media only screen and (min-width: 601px) {
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(-n+10),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(-n+10) ~ li {
    float: left;
    width: auto; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(1) {
    max-width: 100%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(2),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(2) ~ li {
    max-width: 50%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(3),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(3) ~ li {
    max-width: 33.3333%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(4),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(4) ~ li {
    max-width: 25%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(5),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(5) ~ li {
    max-width: 20%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(6),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(6) ~ li {
    max-width: 16.6666%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(7),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(7) ~ li {
    max-width: 14.2857%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(8),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(8) ~ li {
    max-width: 12.5%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(9),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(9) ~ li {
    max-width: 11.1111%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(10),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(10) ~ li {
    max-width: 10%; } }

#wrapper .intro-banner:not(.flex-layout) {
  padding: 0;
  margin: 0;
  width: 100% !important;
  max-width: 100% !important;
  position: relative;
  overflow: hidden; }
  #wrapper .intro-banner:not(.flex-layout) .component-content {
    position: relative; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-image {
    display: none; }
    #wrapper .intro-banner:not(.flex-layout) .intro-banner-image img {
      top: 0;
      left: 0;
      width: 100%;
      background-size: cover;
      background-position: 50% 0%;
      background-repeat: no-repeat;
      display: block; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-video {
    width: 100%;
    height: auto; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    #wrapper .intro-banner:not(.flex-layout) .intro-banner-content .intro-banner-text > *:first-child {
      margin-top: 0;
      padding-top: 0; }
  #wrapper .intro-banner:not(.flex-layout).img-fallback .intro-banner-image {
    display: inherit; }
  #wrapper .intro-banner:not(.flex-layout).img-fallback .intro-banner-video {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout).img-fallback .intro-banner-video-external {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout) .yt-player.hidden {
    visibility: hidden; }
  #wrapper .intro-banner:not(.flex-layout) .sticky-downlink {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }
    #wrapper .intro-banner:not(.flex-layout) .sticky-downlink p {
      margin-bottom: 0;
      margin-top: 0; }
    #wrapper .intro-banner:not(.flex-layout) .sticky-downlink a {
      margin-bottom: 2rem;
      margin-top: 0; }
  #wrapper .intro-banner:not(.flex-layout).slim .component-content {
    width: 100%;
    max-width: 100%;
    margin: 0; }
    #wrapper .intro-banner:not(.flex-layout).slim .component-content .outer-pos-wrapper {
      width: 60rem;
      max-width: 90%;
      margin: 0 auto; }
  #wrapper .intro-banner:not(.flex-layout):not(.has-list-spot) .intro-banner-list-spot-container {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 29.6rem;
    max-width: 36%;
    -webkit-transform: translateX(7.4rem);
    -moz-transform: translateX(7.4rem);
    -ms-transform: translateX(7.4rem);
    -o-transform: translateX(7.4rem);
    transform: translateX(7.4rem);
    color: #000000 !important; }
    #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
      box-sizing: border-box;
      border-left: 1em solid #005da9;
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding: 3rem;
      right: 0; }
      #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content ul {
        padding: 0; }
        #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content ul li:last-child {
          margin-bottom: 0;
          padding-bottom: 0; }
          #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content ul li:last-child *:last-child {
            margin-bottom: 0; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-text {
    display: inline-block;
    width: 100%; }
  #wrapper .intro-banner:not(.flex-layout).wide-image .component-content .intro-banner-image img {
    height: auto;
    width: 100%; }
  #wrapper .intro-banner:not(.flex-layout).tall-image .component-content .intro-banner-image img {
    height: 100%;
    width: auto; }
  #wrapper .intro-banner:not(.flex-layout).wide-video .component-content .intro-banner-video {
    height: auto;
    width: 100%; }
  #wrapper .intro-banner:not(.flex-layout).tall-video .component-content .intro-banner-video {
    height: 100%;
    width: auto; }
  #wrapper .intro-banner:not(.flex-layout).slide-index::after {
    font-size: 2rem;
    background-color: #ffffff;
    opacity: .8;
    padding: 2rem;
    content: attr(data-index);
    position: absolute;
    bottom: 0;
    right: 0; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-content, #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-link, #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .sticky-downlink {
    position: static; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-content {
    margin-top: 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .sticky-downlink {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: 1rem 5% 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 90%; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    position: static;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: 1rem 5% 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper {
    width: 74rem !important;
    max-width: 90% !important; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > * {
      margin-bottom: 0; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      max-width: 100% !important;
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      color: black;
      position: static;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none; }
      #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text * {
        margin-top: 0;
        margin-bottom: 1rem; }
        #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:first-child:not(li):not(a) {
          margin-top: 1rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    position: static; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
      position: relative;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
      width: 100%;
      background-color: #f5f5f5;
      margin-bottom: 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-text td {
    width: 100% !important;
    display: block; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-text li {
    text-align: left; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down {
    font-size: 1.8em;
    width: 1em;
    height: 1em;
    line-height: 1;
    border: 2px solid #f0f0f0;
    display: inline-block;
    overflow: hidden;
    color: #f0f0f0;
    padding: 0.4em;
    border-radius: 50%;
    position: relative;
    margin: 0.7em auto; }
    @media only screen and (min-width: 992px) {
      #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right,
      #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down {
        border: 3px solid #f0f0f0; } }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right:hover,
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down:hover {
      background-color: #f0f0f0;
      color: #ffffff; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right span,
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down span {
      font-family: "Oticon-icon-www";
      color: #f0f0f0;
      text-decoration: none;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      position: absolute;
      top: 0.1em;
      left: 0;
      bottom: 0;
      right: 0;
      width: 1em;
      height: 1em;
      line-height: 1;
      margin: auto;
      animation: cta-down 0.4s; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right:hover span,
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down:hover span {
      color: #ffffff;
      animation: cta-down-hover 0.4s; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-link a {
    color: #005da9 !important; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).hide-image-mobile .intro-banner-image {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout).content-overflow.hide-text-mobile .intro-banner-content {
    display: none; }

#wrapper .intro-banner:not(.flex-layout).parallax .parallax-image {
  overflow: hidden; }
  #wrapper .intro-banner:not(.flex-layout).parallax .parallax-image .parallax-image-back {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    transform: translateZ(-1px) scale(1.1);
    z-index: -1;
    width: 100%;
    height: 100%; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-content, #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-link, #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .sticky-downlink {
  position: static; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-content {
  margin-top: 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .sticky-downlink {
  display: none; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: 1rem 5% 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 90%; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  position: static;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: 1rem 5% 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper {
  width: 74rem !important;
  max-width: 70% !important; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > * {
    margin-bottom: 0; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100% !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: black;
    position: static;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
    #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text * {
      margin-top: 0;
      margin-bottom: 1rem; }
      #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:first-child:not(li):not(a) {
        margin-top: 1rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  position: static; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
    position: relative;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-text td {
  width: 100% !important;
  display: block; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-text li {
  text-align: left; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down {
  font-size: 1.8em;
  width: 1em;
  height: 1em;
  line-height: 1;
  border: 2px solid #f0f0f0;
  display: inline-block;
  overflow: hidden;
  color: #f0f0f0;
  padding: 0.4em;
  border-radius: 50%;
  position: relative;
  margin: 0.7em auto; }
  @media only screen and (min-width: 992px) {
    #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right,
    #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down {
      border: 3px solid #f0f0f0; } }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right:hover,
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down:hover {
    background-color: #f0f0f0;
    color: #ffffff; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right span,
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down span {
    font-family: "Oticon-icon-www";
    color: #f0f0f0;
    text-decoration: none;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    position: absolute;
    top: 0.1em;
    left: 0;
    bottom: 0;
    right: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    margin: auto;
    animation: cta-down 0.4s; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right:hover span,
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down:hover span {
    color: #ffffff;
    animation: cta-down-hover 0.4s; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-link a {
  color: #005da9 !important; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).hide-image-mobile .intro-banner-image {
  display: none; }

#wrapper .intro-banner .component-content .outer-pos-wrapper {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  min-height: 100%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    width: 100%; }

#wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 10%;
  width: 10%; }

#wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 20%;
  width: 20%; }

#wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 30%;
  width: 30%; }

#wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 40%;
  width: 40%; }

#wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 50%;
  width: 50%; }

#wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 60%;
  width: 60%; }

#wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 70%;
  width: 70%; }

#wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 80%;
  width: 80%; }

#wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 90%;
  width: 90%; }

#wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  left: 0;
  padding: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute; }

#wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  left: inherit; }

#wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  right: 0; }

#wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 45%; }

#wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 40%; }

#wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 35%; }

#wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 30%; }

#wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 25%; }

#wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 20%; }

#wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 15%; }

#wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 10%; }

#wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 5%; }

#wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  top: 2rem;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

#wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  bottom: 2rem;
  top: inherit;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

@media only screen and (max-width: 1150px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 19%;
    width: 19%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 28%;
    width: 28%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 37%;
    width: 37%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 46%;
    width: 46%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 55%;
    width: 55%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 64%;
    width: 64%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 73%;
    width: 73%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 91%;
    width: 91%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 40.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 36%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 31.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 27%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 22.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 18%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 13.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 1100px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 28%;
    width: 28%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 36%;
    width: 36%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 44%;
    width: 44%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 52%;
    width: 52%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 60%;
    width: 60%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 68%;
    width: 68%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 84%;
    width: 84%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 36%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 32%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 28%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 24%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 20%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 16%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 8%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 1050px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 37%;
    width: 37%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 44%;
    width: 44%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 51%;
    width: 51%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 58%;
    width: 58%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 65%;
    width: 65%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 72%;
    width: 72%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 79%;
    width: 79%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 86%;
    width: 86%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 93%;
    width: 93%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 31.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 28%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 24.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 21%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 17.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 14%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 1000px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 46%;
    width: 46%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 52%;
    width: 52%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 58%;
    width: 58%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 64%;
    width: 64%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 70%;
    width: 70%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 27%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 24%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 21%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 18%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 15%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 950px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 55%;
    width: 55%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 60%;
    width: 60%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 65%;
    width: 65%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 70%;
    width: 70%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 75%;
    width: 75%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 80%;
    width: 80%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 85%;
    width: 85%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 90%;
    width: 90%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 95%;
    width: 95%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 22.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 20%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 17.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 15%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 5%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 900px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 64%;
    width: 64%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 68%;
    width: 68%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 72%;
    width: 72%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 80%;
    width: 80%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 84%;
    width: 84%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 96%;
    width: 96%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 18%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 16%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 14%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 8%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 950px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 73%;
    width: 73%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 79%;
    width: 79%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 85%;
    width: 85%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 91%;
    width: 91%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 97%;
    width: 97%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 13.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 800px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 84%;
    width: 84%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 86%;
    width: 86%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 90%;
    width: 90%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 96%;
    width: 96%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 98%;
    width: 98%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 8%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 750px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 91%;
    width: 91%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 93%;
    width: 93%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 95%;
    width: 95%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 96%;
    width: 96%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 97%;
    width: 97%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 98%;
    width: 98%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 99%;
    width: 99%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 700px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

#wrapper .intro-banner.force-width .component-content .outer-pos-wrapper {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  min-height: 100%; }
  #wrapper .intro-banner.force-width .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    width: 100%; }

#wrapper .intro-banner.force-width.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 10%;
  width: 10%; }

#wrapper .intro-banner.force-width.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 20%;
  width: 20%; }

#wrapper .intro-banner.force-width.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 30%;
  width: 30%; }

#wrapper .intro-banner.force-width.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 40%;
  width: 40%; }

#wrapper .intro-banner.force-width.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 50%;
  width: 50%; }

#wrapper .intro-banner.force-width.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 60%;
  width: 60%; }

#wrapper .intro-banner.force-width.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 70%;
  width: 70%; }

#wrapper .intro-banner.force-width.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 80%;
  width: 80%; }

#wrapper .intro-banner.force-width.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 90%;
  width: 90%; }

#wrapper .intro-banner.force-width .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  left: 0;
  padding: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute; }

#wrapper .intro-banner.force-width.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .intro-banner.force-width.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  left: inherit; }

#wrapper .intro-banner.force-width.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  right: 0; }

#wrapper .intro-banner.force-width.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 45%; }

#wrapper .intro-banner.force-width.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 40%; }

#wrapper .intro-banner.force-width.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 35%; }

#wrapper .intro-banner.force-width.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 30%; }

#wrapper .intro-banner.force-width.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 25%; }

#wrapper .intro-banner.force-width.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 20%; }

#wrapper .intro-banner.force-width.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 15%; }

#wrapper .intro-banner.force-width.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 10%; }

#wrapper .intro-banner.force-width.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 5%; }

#wrapper .intro-banner.force-width.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  top: 2rem;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

#wrapper .intro-banner.force-width.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  bottom: 2rem;
  top: inherit;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

#wrapper > #content .slider-stack-wrapper > .component.component-ready.animate.slider-stack {
  opacity: 0;
  left: 100%;
  transition: opacity 1.3s, left 0s linear 1.5s; }

#wrapper > #content .slider-stack-wrapper > .component.component-ready.animate.slider-stack.active-slide {
  opacity: 1;
  left: 0;
  transition: opacity 1.3s, left 0s linear 0s; }

/*
@media only screen and (max-width: 400px) {
    #wrapper {
        .intro-banner {
            // REQUIRED BY OTICON US. MOVE TO OTICON IF CONFLICTS WITH OTHER BRANDS
            @include content-overflow();
        }
    }
}
*/
@media screen and (min-width: 1025px) {
  #wrapper .intro-banner:not(.flex-layout).semi-full-height {
    height: 90vh; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 921.6px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 691.2px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 432px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 288px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 511.2px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 288px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 600.3px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 337.5px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 662.4px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 372.6px; } }

@media screen and (min-width: 1025px) {
  #wrapper .intro-banner:not(.flex-layout).full-height {
    height: 100vh; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 1024px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 768px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 480px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 320px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 568px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 320px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 667px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 375px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 736px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 414px; } }

@media screen and (min-width: 1025px) {
  #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content, #wrapper .intro-banner:not(.flex-layout).full-height .component-content {
    height: 100%; }
    #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content .intro-banner-image, #wrapper .intro-banner:not(.flex-layout).full-height .component-content .intro-banner-image {
      height: 100%; }
      #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content .intro-banner-image img, #wrapper .intro-banner:not(.flex-layout).full-height .component-content .intro-banner-image img {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50.01%);
        -moz-transform: translateX(-50.01%);
        -ms-transform: translateX(-50.01%);
        -o-transform: translateX(-50.01%);
        transform: translateX(-50.01%);
        max-width: none;
        bottom: 0; }
    #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content .intro-banner-video, #wrapper .intro-banner:not(.flex-layout).full-height .component-content .intro-banner-video {
      height: 100%;
      width: auto;
      position: relative;
      left: 50%;
      -webkit-transform: translatex(-50.01%);
      -moz-transform: translatex(-50.01%);
      -ms-transform: translatex(-50.01%);
      -o-transform: translatex(-50.01%);
      transform: translatex(-50.01%); } }

#wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text {
  background-color: transparent; }
  #wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text a, #wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text a span {
    transition: none !important; }

#wrapper .component.intro-banner:not(.flex-layout) .intro-banner-link p {
  margin: 0;
  padding: 0;
  line-height: 1; }

#wrapper .component.intro-banner:not(.flex-layout) .intro-banner-link br {
  display: none; }

#wrapper .component.intro-banner:not(.flex-layout).content-overflow .intro-banner-content,
#wrapper .component.intro-banner:not(.flex-layout).content-overflow .intro-banner-link,
#wrapper .component.intro-banner:not(.flex-layout).content-overflow .sticky-downlink {
  position: static; }

#wrapper .component.intro-banner:not(.flex-layout).pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  top: 6em; }

#wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text * {
  margin-bottom: 0; }
  #wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:first-child:not(li):not(a) {
    margin-top: 1rem; }

#wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text .btn,
#wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text [class*="btn-"] {
  margin-bottom: 1rem; }

#wrapper .component.intro-banner:not(.flex-layout).force-center .inner-pos-wrapper {
  margin: 0 auto;
  transform: translate(-50%, -50%);
  left: 50%; }

#wrapper .component.intro-banner:not(.flex-layout).force-center.pos-top .inner-pos-wrapper {
  transform: translate(-50%, 0%); }

#wrapper .component.intro-banner:not(.flex-layout).negative-margin {
  overflow: visible; }
  #wrapper .component.intro-banner:not(.flex-layout).negative-margin .inner-pos-wrapper {
    bottom: -15em;
    top: auto;
    transform: translate(0%, 0%);
    z-index: 2; }
    #wrapper .component.intro-banner:not(.flex-layout).negative-margin .inner-pos-wrapper .negative-image {
      max-width: 90%; }
  #wrapper .component.intro-banner:not(.flex-layout).negative-margin.force-center .inner-pos-wrapper {
    transform: translate(-50%, 0%); }
  #wrapper .component.intro-banner:not(.flex-layout).negative-margin.content-overflow {
    margin-bottom: -16%;
    position: relative;
    z-index: 100; }

#wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content {
  position: static; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    position: static;
    transform: none;
    left: auto;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 100%; }
    #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container .intro-banner-list-spot-content {
      position: static;
      transform: none;
      top: 0;
      padding: 2.2rem; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
    position: static;
    transform: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    /*.intro-banner-text {

								*:last-child:not(a):not(span) {
									margin-bottom: 0;
									padding-bottom: 0;
								}
							}*/ }

#wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot.list-left .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
  margin-bottom: 2rem; }

#wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot.list-left .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin-bottom: 0; }

#wrapper .component.intro-banner:not(.flex-layout).has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:last-child:not(a):not(span) {
  margin-bottom: 0;
  padding-bottom: 0; }

#wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
  border-left: none;
  background-color: #a7d3ac; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul {
    list-style-type: none;
    display: table; }
    #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li {
      margin: 0 0 1.5em 0;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      display: table-row;
      font-family: "Proxima N W01 Reg", sans-serif;
      line-height: 1.5; }
      @media only screen and (max-width: 479px) {
        #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li {
          font-size: 1.1rem; } }
      #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li p {
        padding-bottom: .5em; }
      #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:last-child {
        padding-bottom: 0; }
      #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:before {
        width: .8em;
        content: "•";
        font-family: "retail-icon-font";
        color: #27251F;
        font-size: 1.3em;
        display: table-cell;
        text-align: right;
        speak: none;
        padding-right: .7em;
        vertical-align: top;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:first-child * {
      margin-left: -1em;
      padding: 0 0 .5em 0;
      color: #27251F; }
    #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:first-child:before {
      content: " "; }

#wrapper .component.intro-banner:not(.flex-layout).has-list-spot.content-overflow .intro-banner-list-spot-container {
  max-width: 100%; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot.content-overflow .intro-banner-list-spot-container .intro-banner-list-spot-content {
    margin-bottom: 0; }

#wrapper .component.intro-banner:not(.flex-layout) table td {
  vertical-align: middle;
  padding: .4em; }

@media only screen and (max-width: 767px) {
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper {
    padding-bottom: 1.2rem; } }

@media only screen and (min-width: 1024px) {
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    width: 47%;
    max-width: 47%;
    left: 43%; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
    width: 47%;
    max-width: 47%; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content {
    position: static; }
    #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper {
      white-space: nowrap; }
      #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
        width: 47%;
        max-width: 47%;
        margin-left: 6%;
        white-space: normal;
        margin-bottom: 0; }
      #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
        position: static;
        transform: none;
        width: 47%;
        max-width: 47%;
        white-space: normal; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot.list-left .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    margin-left: 0%;
    margin-right: 6%; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot.list-left:not(.no-image) .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    left: 0;
    transform: none; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot.list-left:not(.no-image) .component-content .intro-banner-content .outer-pos-wrapper .inner-pos-wrapper {
    left: 53%; }
  #wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text *:last-child:not(a):not(span) {
    margin-bottom: 0;
    padding-bottom: 0; } }

@media only screen and (max-width: 1023px) {
  #wrapper .component.intro-banner:not(.flex-layout).hide-image-mobile:not(.hide-text-mobile) .intro-banner-image {
    display: none; } }

#wrapper #header .component.language-selector-spot .popup--choose-language {
  display: none; }

#wrapper .lattice-spot .lattice-spot-item {
  max-width: 50%;
  width: 100%;
  float: left; }
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-image {
    max-width: 50%;
    width: 100%;
    height: 100%; }
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text {
    background-color: #f5f5f5;
    padding: 2rem;
    position: relative;
    box-sizing: border-box;
    /** {
            margin-top: 0;
            padding-bottom: 0;
            margin-bottom: 1rem;
        }*/ }
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-image {
    background-position: center center;
    background-size: cover; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-image, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-image {
    float: left; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-text::before, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-text::before {
    border-bottom: 12px solid transparent;
    border-left: 12px solid #f5f5f5;
    border-top: 12px solid transparent;
    border-right: none;
    content: "";
    height: 0;
    position: absolute;
    right: -12px;
    top: 3em;
    width: 0; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-image, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-image {
    float: right; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-text::before, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-text::before {
    border-bottom: 12px solid transparent;
    border-right: 12px solid #f5f5f5;
    border-top: 12px solid transparent;
    border-left: none;
    content: "";
    height: 0;
    left: -12px;
    position: absolute;
    top: 3em;
    width: 0; }

#wrapper .lattice-spot .lattice-spot-clear {
  clear: both; }

#wrapper .lattice-spot ul {
  list-style: disc; }

@media only screen and (max-width: 750px) {
  #wrapper .lattice-spot .lattice-spot-item {
    max-width: 100%; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+1) .lattice-spot-text,
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+1) .lattice-spot-image {
      float: left; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+1) .lattice-spot-text::before {
      border-bottom: 12px solid transparent;
      border-left: 12px solid #f5f5f5;
      border-top: 12px solid transparent;
      border-right: none;
      content: "";
      height: 0;
      position: absolute;
      right: -12px;
      left: unset;
      top: 3em;
      width: 0; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+2) .lattice-spot-text,
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+2) .lattice-spot-image {
      float: right; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+2) .lattice-spot-text::before {
      border-bottom: 12px solid transparent;
      border-right: 12px solid #f5f5f5;
      border-top: 12px solid transparent;
      border-left: none;
      content: "";
      height: 0;
      left: -12px;
      right: unset;
      position: absolute;
      top: 3em;
      width: 0; } }

@media only screen and (max-width: 480px) {
  #wrapper .lattice-spot .lattice-spot-item {
    height: auto !important; }
    #wrapper .lattice-spot .lattice-spot-item .lattice-spot-image {
      display: none; }
    #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text {
      max-width: 100%; }
      #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text::before {
        display: none; } }

#wrapper .component.lattice-spot ul {
  list-style: disc;
  margin-left: 0;
  line-height: 1.5; }
  #wrapper .component.lattice-spot ul li:before {
    color: #ffffff !important; }

#wrapper .component.lattice-spot .lattice-spot-item .lattice-spot-text {
  background-color: #005da9; }
  #wrapper .component.lattice-spot .lattice-spot-item .lattice-spot-text *:not(a) {
    color: #ffffff !important; }

#wrapper .component.lattice-spot a {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  color: #a7d3ac; }
  #wrapper .component.lattice-spot a:hover {
    opacity: 0.7; }

#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-text::before,
#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-text::before {
  border-right: 12px solid #005da9; }

#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-text::before,
#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-text::before {
  border-left: 12px solid #005da9; }

#wrapper .news-archive-spot {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  #wrapper .news-archive-spot .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
    #wrapper .news-archive-spot .component-content ul {
      padding: 0; }
      #wrapper .news-archive-spot .component-content ul li {
        background-color: #f5f5f5;
        padding: 2em 0 2em 2.4em;
        margin-bottom: 1rem;
        border-left: 0.6em solid #005da9; }
        #wrapper .news-archive-spot .component-content ul li div {
          margin-right: 1em;
          line-height: 1.5; }
        #wrapper .news-archive-spot .component-content ul li h3, #wrapper .news-archive-spot .component-content ul li .news-header {
          font-family: "Proxima N W01 Bold", sans-serif;
          font-size: 2.5rem;
          margin: 0;
          padding: 0 0 0.6em 0;
          line-height: 1;
          color: #005da9;
          display: block;
          margin: 0 1em 1em 0; }
        #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date, #wrapper .news-archive-spot .component-content ul li .news-text {
          margin-bottom: 0.5em; }
        #wrapper .news-archive-spot .component-content ul li .news-link {
          margin-top: 1.5em; }

#wrapper .news-archive-spot .component-content ul li {
  /*background-color: $grey-1000;*/
  padding: 2em 2em 2em 2.4em;
  border-left: 0;
  border-top: 1px solid #a7d3ac; }
  #wrapper .news-archive-spot .component-content ul li h3, #wrapper .news-archive-spot .component-content ul li .news-header {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2.5rem;
    margin: 0;
    padding: 0 0 0.6em 0;
    line-height: 1;
    color: #005da9;
    display: block; }
  #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date, #wrapper .news-archive-spot .component-content ul li .news-text {
    margin-bottom: 0; }
  #wrapper .news-archive-spot .component-content ul li .news-text, #wrapper .news-archive-spot .component-content ul li .news-text * {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0; }
    @media only screen and (max-width: 479px) {
      #wrapper .news-archive-spot .component-content ul li .news-text, #wrapper .news-archive-spot .component-content ul li .news-text * {
        font-size: 1.1rem; } }
  #wrapper .news-archive-spot .component-content ul li .news-text *:last-child:not(a) {
    padding-bottom: 0; }
  #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0; }
    @media only screen and (max-width: 479px) {
      #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date {
        font-size: 1.1rem; } }
    #wrapper .news-archive-spot .component-content ul li .news-location:empty, #wrapper .news-archive-spot .component-content ul li .news-date:empty {
      display: none; }
  #wrapper .news-archive-spot .component-content ul li .news-link {
    margin-top: 0.5em; }
    #wrapper .news-archive-spot .component-content ul li .news-link .link {
      color: #e40049;
      font-family: "Proxima N W01 Bold", sans-serif;
      text-decoration: none;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s;
      color: #a7d3ac; }
      #wrapper .news-archive-spot .component-content ul li .news-link .link:hover {
        opacity: 0.7; }

#wrapper .news-list-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .news-list-spot:not(.jobs) {
  padding-bottom: 2rem;
  padding-top: 2rem; }
  #wrapper .news-list-spot:not(.jobs) .component-content > ul {
    padding: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul > li {
      position: relative;
      visibility: hidden;
      vertical-align: top;
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      text-align: left;
      margin-bottom: 1.5rem;
      padding-bottom: 2rem;
      background-color: #f1f1f1;
      cursor: pointer;
      overflow: hidden; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        box-shadow: none;
        width: 0px; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li:hover .news-header-wrapper::after {
        width: 1.5rem; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-item-wrapper {
        padding: 1.5rem 0; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-image {
        overflow: hidden;
        background-size: cover;
        background-position: center; }
        #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-image img {
          display: none; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link {
        text-align: center;
        z-index: 100; }
        #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link {
          color: #e40049;
          font-family: "Proxima N W01 Bold", sans-serif;
          text-decoration: none;
          cursor: pointer;
          opacity: 1;
          transition: opacity 0.2s;
          transition: padding 0.1s;
          position: relative;
          padding-right: 1.5em;
          color: #005da9; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link:hover {
            opacity: 0.7; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link:after {
            font-family: "retail-icon-font";
            speak: none;
            font-style: normal;
            font-weight: bold;
            font-variant: normal;
            text-transform: none;
            display: inline-block;
            content: "";
            font-size: 1.1em;
            margin: 0;
            position: absolute;
            right: 0;
            top: .1em; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link:hover {
            opacity: 0.7;
            padding-right: 1.7em; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link * {
            color: #005da9; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-header .news-header-wrapper {
      font-family: "Proxima N W01 Bold", sans-serif;
      font-size: 2rem;
      margin: 0;
      padding: 0 0 0.8em 0;
      line-height: 1;
      color: #005da9;
      display: block;
      line-height: 1.1;
      padding: 0 2rem 1.5rem 2rem;
      text-align: center;
      position: relative;
      width: 100%;
      box-sizing: border-box; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-header .news-header-wrapper::after {
        width: 0rem;
        content: " ";
        display: inline-block;
        height: 2px;
        background: #005da9;
        transition: width .2s;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
        bottom: .9rem; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-location {
      display: none; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-date h4 {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      font-size: 0.9rem;
      line-height: 1.3rem;
      padding-bottom: 0;
      color: #b0b0b0;
      text-align: center;
      text-transform: uppercase; }
      @media only screen and (max-width: 479px) {
        #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-date h4 {
          font-size: 1.1rem; } }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-text {
      position: relative;
      padding: .2rem 2rem; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-text *:last-child:not(a) {
        padding-bottom: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul::after {
      content: '';
      display: inline-block;
      width: 100%;
      line-height: 0;
      font-size: 0;
      margin: 0;
      padding: 0;
      height: 0; }
  #wrapper .news-list-spot:not(.jobs) .component-content .news-list-link {
    margin: 4rem 0 0; }

#wrapper .news-list-spot.jobs .news-header {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 2.5rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block; }
  #wrapper .news-list-spot.jobs .news-header a {
    color: #005da9; }

#wrapper .news-list-spot.jobs .component-content > ul > li {
  display: block;
  vertical-align: top;
  margin-bottom: 4rem; }

#wrapper .news-list-spot.jobs .news-date h4 {
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  color: #b0b0b0; }
  @media only screen and (max-width: 479px) {
    #wrapper .news-list-spot.jobs .news-date h4 {
      font-size: 1.1rem; } }

#wrapper .news-list-spot.jobs .news-location h4 {
  color: #a7d3ac;
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0; }
  @media only screen and (max-width: 479px) {
    #wrapper .news-list-spot.jobs .news-location h4 {
      font-size: 1.1rem; } }

#wrapper .news-list-spot.jobs .news-link a {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #a7d3ac;
  color: #005da9; }
  #wrapper .news-list-spot.jobs .news-link a:hover {
    opacity: 0.7; }

@media only screen and (min-width: 768px) {
  #wrapper .news-list-spot:not(.jobs) .component-content ul li {
    width: 29%;
    margin-bottom: 3rem;
    white-space: nowrap;
    float: left;
    margin-right: 6.5%;
    padding: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li:nth-child(3n+3) {
      margin-right: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li * {
      white-space: normal; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li::after {
      box-shadow: none;
      width: 0px; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li:first-child::after {
      box-shadow: none; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-item-wrapper {
      position: absolute;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      overflow: hidden;
      padding: 0; }
      #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-item-wrapper:before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 65%;
        pointer-events: none;
        background: linear-gradient(to top, #f1f1f1 0.5rem, transparent);
        z-index: 99; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%; }
      #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-image + .news-item-wrapper {
        transform: none;
        bottom: 3.5rem; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-link {
      position: absolute;
      bottom: 1.7rem;
      left: 0;
      right: 0; }
  #wrapper .news-list-spot.jobs .component-content > ul > li {
    display: inline-block;
    width: 45%;
    vertical-align: top;
    margin-bottom: 4rem; }
    #wrapper .news-list-spot.jobs .component-content > ul > li:nth-child(odd) {
      margin-right: 5%; } }

#wrapper .product-tool-conntainer {
  /*resetting css on this specific instance*/
  /*-----------------------------------------------------*/
  /*This is the main container. If you need to add margin, do it here :) */
  /*------------------personal resetter------------------*/
  /* .model-selector .head_nav.selected .nav_head_container .nav_head_container_right::after{
         top: 0;
    }
    .model-selector .head_nav.selected .nav_head_container .header_btn_border
    .nav_head_container_right{
        border-bottom: 7px solid $corporate;
        border-top: none;
        top: 32px;
    }*/
  /*******************Done************************/
  /*----Add Corrections after this block----------/
    /***********************************************/
  /*resetting css on this specific instance*/
  /*-----------------------------------------------------*/
  /*This is the main container. If you need to add margin, do it here :) */
  /*------------------personal resetter------------------*/
  /*buttons*/
  /*brightness enabled*/
  /*---------------------------------*/
  /*preview*/
  /*both*/
  /*popup*/
  /*
    .internal_full_popup_content_colourselector .colour_nav_item:hover{
        content: "";
        position: absolute;
        margin-left: 10px;
        margin-top: 22px;
        border-left: 10px solid $corporate;
        border-right: none;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
    }*/
  /*.internal_full_popup_content_colourselector .colour_nav_item.selected:after{
        content:"";
        position: absolute;
        margin-left: 180px;
        margin-top: -25px;
        border-left: 10px solid $corporate;
        border-right: none;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
    }*/
  /*******************Done************************/
  /*----Add Corrections after this block----------/
    /***********************************************/
  /************** ERLA ************************/
  /*    .ProductToolDescription{
            float: right;
       }*/
  /************** ERLA ************************/
  /*.colour-selector .internal_full_popup {
        display: none;
        position: fixed;
        top: 0px;
        width: 100%;
        height: 100%;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAMUlEQVQIW2M0MjLazIAEGBkZjRmRBUEC////PwsXhAmANIEFkQXAgsbGxs9AWpDNBQAYLxapFZ9EfgAAAABJRU5ErkJggg==") repeat scroll 0 0 transparent;
        z-index: 15;
    }*/ }
  #wrapper .product-tool-conntainer .model-selector div, #wrapper .product-tool-conntainer .model-selector span, #wrapper .product-tool-conntainer .model-selector h1, #wrapper .product-tool-conntainer .model-selector h2,
  #wrapper .product-tool-conntainer .model-selector h3, #wrapper .product-tool-conntainer .model-selector h4, #wrapper .product-tool-conntainer .model-selector h5, #wrapper .product-tool-conntainer .model-selector h6,
  #wrapper .product-tool-conntainer .model-selector p, #wrapper .product-tool-conntainer .model-selector sub, #wrapper .product-tool-conntainer .model-selector sup, #wrapper .product-tool-conntainer .model-selector b,
  #wrapper .product-tool-conntainer .model-selector u, #wrapper .product-tool-conntainer .model-selector i {
    margin: 0;
    padding: 0;
    border: 0;
    letter-spacing: 0px; }
  #wrapper .product-tool-conntainer .model-selector {
    margin: 0px;
    padding: 0px;
    line-height: 1; }
  #wrapper .product-tool-conntainer .model-selector a {
    text-decoration: none; }
  #wrapper .product-tool-conntainer .model-selector p {
    line-height: 16px; }
  #wrapper .product-tool-conntainer .model-selector * {
    outline: none; }
  #wrapper .product-tool-conntainer .model-selector h1, #wrapper .product-tool-conntainer .model-selector h2 {
    letter-spacing: 1px; }
  #wrapper .product-tool-conntainer .model-selector, #wrapper .product-tool-conntainer .ProductToolSpinningHead {
    width: 506px;
    /*tried to incorporate the site font*/
    font-family: "Soho Gothic W01 Regular", "Arial", sans-serif; }
  #wrapper .product-tool-conntainer .model-selector .drag_text {
    width: 92%;
    position: absolute;
    background-position: center top;
    height: 50px;
    top: 467px;
    text-align: center; }
  #wrapper .product-tool-conntainer .model-selector .drag_text .head_slider {
    margin-top: -10px;
    width: 460px;
    height: 5px;
    margin-left: 20px;
    border: none !important;
    background: #27251F; }
  #wrapper .product-tool-conntainer .model-selector .drag_text .head_slider a {
    top: -25px;
    width: 33px !important;
    height: 34px !important;
    border: none;
    background-color: transparent;
    z-index: 2;
    position: absolute; }
  #wrapper .product-tool-conntainer .model-selector .drag_text p {
    font-size: 10px;
    left: 43px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: -4rem;
    z-index: 4;
    font-size: 12px; }
  #wrapper .product-tool-conntainer .model-selector .drag_text img {
    margin-top: 10px; }
  #wrapper .product-tool-conntainer .model-selector .heads {
    position: absolute;
    top: 68px;
    cursor: pointer;
    width: 249px;
    /*display: none;*/
    z-index: 1;
    background-color: #f5f5f5;
    margin-left: -20px;
    border: 1px solid #27251F;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  #wrapper .product-tool-conntainer .model-selector .head_nav .heads_arrow_up_bg {
    display: inherit;
    margin-left: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #27251F;
    border-top: none;
    position: absolute;
    top: -10px;
    left: 204px; }
  #wrapper .product-tool-conntainer .model-selector .head_nav .heads_arrow_up {
    display: inherit;
    margin-left: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f5f5f5;
    border-top: none;
    position: absolute;
    top: -9px;
    left: 204px; }
  #wrapper .product-tool-conntainer .model-selector .head_nav .nav_head_container .header_btn_border {
    height: 40px;
    width: 182px; }
  #wrapper .product-tool-conntainer .model-selector .head_nav .nav_head_container .nav_head_container_right {
    position: relative; }
    #wrapper .product-tool-conntainer .model-selector .head_nav .nav_head_container .nav_head_container_right::after {
      font-family: "Oticon-icon-www";
      content: '\e601';
      font-size: 1em;
      color: #eeeeee;
      transition: all 0.2s;
      position: absolute;
      right: -35px;
      top: 20px; }
  #wrapper .product-tool-conntainer .model-selector .nav_head_container {
    font-size: 14px;
    margin: 0px;
    width: 210px; }
  #wrapper .product-tool-conntainer .model-selector .nav_head_container img {
    height: 33px;
    float: left;
    margin-top: 7px;
    margin-left: -5px; }
  #wrapper .product-tool-conntainer .model-selector .nav_head_container span {
    float: left;
    margin-left: 5px;
    font-size: 15px;
    margin-top: 22px; }
  #wrapper .product-tool-conntainer .model-selector .heads .head_thumb {
    margin-top: 6px;
    margin-left: 6px;
    margin-bottom: 0px;
    float: left;
    background-color: #ffffff; }
  #wrapper .product-tool-conntainer .model-selector .heads div:last-child {
    margin-bottom: 7px; }
  #wrapper .product-tool-conntainer .model-selector .head_nav {
    display: table;
    cursor: pointer;
    line-height: 42px;
    height: 40px;
    text-align: center;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    background: #27251F;
    margin-top: 14px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;
    min-width: 100px; }
  #wrapper .product-tool-conntainer .model-selector .head_nav {
    line-height: 0px;
    margin-left: 20px; }
  #wrapper .product-tool-conntainer .model-selector .column-left {
    position: relative; }
  #wrapper .product-tool-conntainer .model-selector .head_nav .heads img:first-child {
    height: 84px;
    width: 115px;
    margin: 0px;
    border: 1px solid #27251F;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  #wrapper .product-tool-conntainer .model-selector .head_images {
    height: auto;
    position: relative;
    margin-top: 0px; }
  #wrapper .product-tool-conntainer .model-selector .head_images div:not(:only-of-type) {
    margin-bottom: 4rem; }
  #wrapper .product-tool-conntainer .model-selector .head_image {
    width: 100%;
    height: 100%;
    top: 0px;
    position: relative;
    display: none;
    background-size: contain;
    background-repeat: no-repeat;
    /*border-bottom: 1px solid $grey-1000;*/
    height: 403px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  #wrapper .product-tool-conntainer .colour-selector div, #wrapper .product-tool-conntainer .colour-selector span, #wrapper .product-tool-conntainer .colour-selector h1, #wrapper .product-tool-conntainer .colour-selector h2,
  #wrapper .product-tool-conntainer .colour-selector h3, #wrapper .product-tool-conntainer .colour-selector h4, #wrapper .product-tool-conntainer .colour-selector h5, #wrapper .product-tool-conntainer .colour-selector h6,
  #wrapper .product-tool-conntainer .colour-selector p, #wrapper .product-tool-conntainer .colour-selector sub, #wrapper .product-tool-conntainer .colour-selector sup, #wrapper .product-tool-conntainer .colour-selector b,
  #wrapper .product-tool-conntainer .colour-selector u, #wrapper .product-tool-conntainer .colour-selector i {
    margin: 0;
    padding: 0;
    border: 0;
    letter-spacing: 0px; }
  #wrapper .product-tool-conntainer .colour-selector li {
    list-style-type: none; }
  #wrapper .product-tool-conntainer .colour-selector {
    margin: 0px;
    padding: 0px;
    line-height: 1;
    /*tried to incorporate the site font*/
    font-family: "Soho Gothic W01 Regular", "Arial", sans-serif;
    position: relative; }
  #wrapper .product-tool-conntainer .colour-selector a {
    text-decoration: none; }
  #wrapper .product-tool-conntainer .colour-selector p {
    line-height: 16px; }
  #wrapper .product-tool-conntainer .colour-selector * {
    outline: none; }
  #wrapper .product-tool-conntainer .colour-selector h1, #wrapper .product-tool-conntainer .colour-selector h2 {
    letter-spacing: 1px; }
  #wrapper .product-tool-conntainer .colour-selector {
    /*overflow: hidden;*/ }
  #wrapper .product-tool-conntainer .colour-selector .colour_header {
    line-height: 50px; }
  #wrapper .product-tool-conntainer .colour-selector .colour_header p {
    line-height: 20px;
    margin-bottom: 20px; }
  #wrapper .product-tool-conntainer .colour-selector .colour_header {
    line-height: 50px;
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2rem;
    margin: 0;
    padding: 0 0 0.8em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    margin: 1rem 0 1.2rem 0; }
  #wrapper .product-tool-conntainer .colour-selector .color-buttons {
    width: 100%;
    display: table;
    padding: 0px;
    margin: 0px; }
  #wrapper .product-tool-conntainer .colour-selector .popup .color-buttons {
    display: block; }
  #wrapper .product-tool-conntainer .colour-selector .generic_ui_button {
    height: 30px;
    width: 30px;
    margin-top: 5px;
    margin-right: 13px;
    border: hidden;
    background-color: transparent;
    background-repeat: no-repeat;
    float: right;
    margin-top: -15px; }
  #wrapper .product-tool-conntainer .colour-selector .internal_full_popup {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAMUlEQVQIW2M0MjLazIAEGBkZjRmRBUEC////PwsXhAmANIEFkQXAgsbGxs9AWpDNBQAYLxapFZ9EfgAAAABJRU5ErkJggg==") repeat scroll 0 0 transparent;
    z-index: 15; }
  #wrapper .product-tool-conntainer .colour-selector .internal_full_popup_content {
    width: 845px;
    height: 625px;
    position: fixed;
    background-color: #ffffff;
    top: 50%;
    margin-top: -312px;
    left: 50%;
    margin-left: -423px;
    overflow: hidden; }
  #wrapper .product-tool-conntainer .colour-selector .selector .color-buttons li {
    float: left;
    width: 26px;
    height: 26px;
    border-radius: 25px;
    padding: 3px;
    border-width: 0px;
    border-style: solid;
    position: relative;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 6px; }
  #wrapper .product-tool-conntainer .color-buttons div > div {
    width: 26px;
    height: 26px;
    top: 0px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border-width: 0px;
    border-color: #dddddd;
    z-index: 1; }
  #wrapper .product-tool-conntainer .color-buttons .color1mask {
    height: 26px;
    width: 13px;
    top: 2px;
    left: 2px;
    overflow: hidden;
    position: absolute; }
  #wrapper .product-tool-conntainer .color-buttons .color1 {
    left: 0;
    position: relative;
    top: 0; }
  #wrapper .product-tool-conntainer .color-buttons .color2mask {
    width: 13px;
    height: 26px;
    top: 2px;
    left: 15px;
    overflow: hidden;
    position: absolute; }
  #wrapper .product-tool-conntainer .color-buttons .color2 {
    left: -13px;
    position: relative; }
  #wrapper .product-tool-conntainer .color-buttons .brightness:not(.selected) div > div {
    width: 24px;
    height: 24px;
    top: 0px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border-width: 0px;
    border-color: #dddddd;
    z-index: 1; }
  #wrapper .product-tool-conntainer .color-buttons .brightness:not(.selected) .color1mask {
    height: 26px;
    left: 2px;
    overflow: hidden;
    position: absolute;
    top: 1px;
    width: 14px; }
  #wrapper .product-tool-conntainer .color-buttons .brightness:not(.selected) .color1 {
    left: 1px;
    position: relative;
    top: 2px; }
  #wrapper .product-tool-conntainer .color-buttons .brightness:not(.selected) .color2mask {
    height: 26px;
    left: 15px;
    overflow: hidden;
    position: absolute;
    top: 1px;
    width: 13px; }
  #wrapper .product-tool-conntainer .color-buttons .brightness:not(.selected) .color2 {
    left: -12px;
    top: 2px;
    position: relative; }
  #wrapper .product-tool-conntainer .color-buttons .color_background {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 2px;
    left: 2px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background-color: transparent;
    z-index: 0; }
  #wrapper .product-tool-conntainer .colour-selector .selector .color-buttons .selected {
    border-width: 1px !important;
    padding: 2px !important;
    margin-left: 3px !important;
    margin-right: 3px !important; }
  #wrapper .product-tool-conntainer .colour-selector .color-buttons .selected .color_background {
    background-color: transparent; }
  #wrapper .product-tool-conntainer .colour-selector .column_right_colours {
    width: 300px;
    margin-left: 30px; }
  #wrapper .product-tool-conntainer .colour-selector .preview_box {
    width: 148px;
    height: 100%;
    margin-top: 2px; }
  #wrapper .product-tool-conntainer .colour-selector .zoom_in {
    width: 30px;
    position: absolute; }
  #wrapper .product-tool-conntainer .colour-selector .generic_ui_button, #wrapper .product-tool-conntainer .colour-selector .internal_full_popup .internal_full_popup_content_video .close_btn {
    height: 30px;
    width: 30px;
    margin-top: 5px;
    margin-right: 13px;
    border: hidden;
    background-color: transparent;
    background-repeat: no-repeat;
    float: right;
    margin-top: -15px; }
  #wrapper .product-tool-conntainer .colour-selector .preview_box .zoom_in {
    left: 15px;
    top: 135px !important;
    z-index: 3;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    position: relative;
    background-size: contain; }
  #wrapper .product-tool-conntainer .colour-selector .image_color {
    /* add clear:both; and remove width for full length!*/
    width: 150px;
    text-align: center; }
  #wrapper .product-tool-conntainer .colour-selector .variation_preview, #wrapper .product-tool-conntainer .colour-selector .variation_preview2 {
    width: 148px;
    height: 148px;
    position: absolute;
    z-index: 1; }
  #wrapper .product-tool-conntainer .colour-selector .variation_preview2 {
    z-index: 2; }
  #wrapper .product-tool-conntainer .colour-selector .column_left_preview {
    height: 175px;
    width: 148px; }
  #wrapper .product-tool-conntainer .column_left_preview img {
    margin-top: 17px !important; }
  #wrapper .product-tool-conntainer .colour-selector .column_right_colours, #wrapper .product-tool-conntainer .colour-selector .column_left_preview {
    float: left; }
  #wrapper .product-tool-conntainer .colour-selector .imageBox .close_btn, #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .close_btn,
  #wrapper .product-tool-conntainer .colour-selector .techBox_infoBox .close_btn, #wrapper .product-tool-conntainer .internal_full_popup_content_portfolio .close_btn {
    bottom: 0px;
    right: 0px;
    z-index: 1;
    height: 30px;
    width: 30px;
    margin-top: 10px;
    margin-right: 10px;
    border: hidden;
    background-color: transparent;
    background-repeat: no-repeat; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .close_btn, #wrapper .product-tool-conntainer .internal_full_popup_content_portfolio .close_btn {
    position: absolute;
    margin-right: 0px;
    margin-top: 0px;
    top: 15px;
    right: 18px;
    background-size: contain; }
  #wrapper .product-tool-conntainer .arrow_nav_container {
    height: 50px;
    width: 173px;
    background-color: #f5f5f5; }
    #wrapper .product-tool-conntainer .arrow_nav_container.bottom {
      bottom: 0px;
      position: absolute;
      z-index: 10; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .arrow_nav {
    position: absolute;
    height: 20px;
    width: 38px;
    margin-top: 0px;
    margin-right: 0px;
    left: 67px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .arrow_nav_bottom {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAUCAYAAADhj08IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2lpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpCRURDREExNTIyMjA2ODExODA4M0U3NzgzNTEyQUQ0RCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4RkREODdCNjg0NDQxMUU0OUIyMUUxRTBEODU3NjA1MyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4RkREODdCNTg0NDQxMUU0OUIyMUUxRTBEODU3NjA1MyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Mzg0NzkyZTItMmNhZi1jNzQ3LTg0MjUtNjBlNGQxNzA5NjkzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkJFRENEQTE1MjIyMDY4MTE4MDgzRTc3ODM1MTJBRDREIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+aVF2OgAAAblJREFUeNrElTtLA0EURsd1VYxCKhuxtBM7wUIUsYmFWFlroyCJjRCCFpIFC20sDEgqxUJRUQQfYFQS0tr56EQQ0cYfoIUY9bvwLYQlm2Rf7oWTnWQms2fn3pltSKfTr0qpBDhVIYRhGNbv47hsaPhIgn0wokIOSI3icgDmNTZmwEmYcpCSex+CabSPdP6+C1rAGeVuQpCShZlFW1yUXta/RblLEPsvOYgMcUESppRVTCILmsEVGAT3AXv1c9MlIbVd3qFXGLwOWkGeaX0IUEqyswiprLVTt/nTKmgCBTAAHn2W6mNWlipJVROTWAZtlBsGTz5J9YILsAIydoP0GpMsgEZQ5Mq9+CAlD7rGrCi3YhIp0M6ak5V7cynVQ6lMLSkJrY4Jf0EcXHPlOl1IdbPQN1kiyg8xU26OZ5vcoMOhlDzQDktD+SkmUQKTPNsKdcqZUntOpJyKmXJT4Bmcg2iVsV0gx1M95TT3mot6+QYT4J1nUdRGqsi6jLMUAheT+KLcB1++kQpSebdSXsRMuTHww7RGWHc5bhLXUl7FJD4pJ1LH3BR33CQlLxN7FTPlYqy1Wz+kJP4EGAA7/G7bPBLxpgAAAABJRU5ErkJggg==") !important;
    bottom: 12px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .arrow_nav_top {
    top: 15px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAUCAYAAADhj08IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2lpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpCRURDREExNTIyMjA2ODExODA4M0U3NzgzNTEyQUQ0RCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo5OTIyQ0ZDQTg0NDQxMUU0OEJCOUVBOTRDMjM0N0MxNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo5OTIyQ0ZDOTg0NDQxMUU0OEJCOUVBOTRDMjM0N0MxNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Mzg0NzkyZTItMmNhZi1jNzQ3LTg0MjUtNjBlNGQxNzA5NjkzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkJFRENEQTE1MjIyMDY4MTE4MDgzRTc3ODM1MTJBRDREIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Z/FjKgAAAZxJREFUeNrElb9Lw0AUx6+tOOgf4Cw4q5urKFUHf4AoVeogOnWpgwjtIIk6KFIQUUTQQZ0dHXQpIjgUnCwukkUILp0K6ib4ffANBKk26V3SBx84muT6uffe3SUsy1IGIgUuQB+YAPWgH9q23fD3pCGpKzBIoTvQpTtp0pDUABgBs+AL3OjK6YglwAkYYvlqlJrkvCLXGbeYJzUKhoHreyZyM6AbXLcql9SQSjeQ8kJ6bQz0UK4jDrF9MMXyuf+8V2dZe8El+zEysT2wyEw5Ad6vcVP0c5OkohATqaUQUn65cW6SY7aCMbFNsMo/cFoo/zsXlGZ/NpUL0pQFkGdJXjSOF5e7+BF8gA0dMZFap1TVwC3xxszdg2/OH1pMslTkKk1IeeFwoZK5T7ATpsdyYJtn0ZMyH6+Uy+MSLwQVE6ldNnpFRRdVVqMIubVmYsugBKYjlvLimVXZglzuL7EsOOKp/qBiCghVWJ0Sxiu/xUTqlJdvWcUclJOEHGKc9cTmwDmYb4eUT67MxJxhnBGxA5ABt6rNQbkFKeuPAAMAYH9hE6BM7t0AAAAASUVORK5CYII=") !important; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .color-buttons span.selected {
    border-width: 1px !important;
    padding: 2px !important;
    margin-left: 3px !important;
    margin-right: 3px !important; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .column-left, #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .column-right {
    position: absolute;
    float: left; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .column-left {
    height: 625px;
    width: 173px;
    background-color: #ffffff;
    border-left: 5px solid #f5f5f5;
    border-right: 5px solid #f5f5f5;
    z-index: 10;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour {
    color: #f0f0f0; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .column-right {
    min-height: 625px;
    width: 665px;
    left: 180px;
    /*background: url("../en/Tools/product_tool/standard_assets/Close_Button_Small.png") no-repeat;
      background-position:625px 10px;*/ }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .column-right .style {
    font-family: "Soho Gothic W01 Regular", "Arial", sans-serif;
    font-size: 30px;
    bottom: 50px;
    position: absolute;
    right: 50px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item.selected .bg,
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item.selected .bg_arrow {
    visibility: visible; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item .bg_arrow {
    visibility: hidden; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .bg {
    position: absolute;
    background-color: #005da9;
    height: 100%;
    width: 5px;
    right: 12px;
    visibility: hidden; }
  #wrapper .product-tool-conntainer .color-buttons .nav {
    height: 35px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item.selected .rightContainer .arrow_box {
    border-right: solid 5px #005da9;
    position: absolute;
    width: 10px;
    height: 64px;
    left: 166px;
    top: -2px;
    z-index: 5;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: 5px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item.selected .rightContainer .arrow_box:after {
    content: "";
    position: absolute;
    margin-left: 10px;
    margin-top: 22px;
    border-left: 10px solid #005da9;
    border-right: none;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item:hover .columns {
    background-color: #f5f5f5; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .column-right img {
    width: 665px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .color-buttons-container {
    height: 525px;
    width: 178px;
    background-color: #f5f5f5; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .column-left ul {
    font-family: "Soho Gothic W01 Regular", "Arial", sans-serif;
    font-size: 12px;
    overflow: hidden;
    height: 525px;
    width: 195px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item {
    position: relative;
    overflow: hidden;
    /*padding: 13px 0px 4px 0;*/
    width: 190px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item .arrow_box {
    background-color: #F00;
    width: 10px;
    height: 100%;
    display: block; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour_nav_item:last-child {
    /*border-bottom: 1px solid $grey-1000;*/
    padding-bottom: 1px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .id {
    margin-top: 3px;
    color: #bbbbbb; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .rightContainer {
    width: 100% !important;
    height: auto !important; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .columns {
    overflow: hidden;
    display: inline-block;
    float: left;
    background-color: #ffffff;
    padding: 14px 0 5px 0;
    border-top: 1px solid #27251F;
    width: 173px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .left_column_zoom {
    width: 35px;
    overflow: hidden;
    height: 32px;
    float: left;
    margin-left: 8px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .right_column_zoom {
    overflow: hidden;
    width: 129px;
    float: left; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .image_colour_large_bg {
    height: 100%;
    left: 0;
    position: absolute; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .image_colour_large, #wrapper .product-tool-conntainer .image_colour_large2 {
    position: absolute; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .selected .arrow {
    width: 0px;
    height: 0px;
    border-color: transparent transparent transparent #c6168d;
    border-style: solid;
    border-width: 8px 0px 8px 8px;
    position: absolute;
    right: 4px;
    top: 20px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .columns span {
    float: left;
    width: 26px;
    height: 26px;
    border-radius: 25px;
    padding: 3px;
    border-width: 0px;
    border-style: solid;
    position: relative;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 6px; }
  #wrapper .product-tool-conntainer .ProductToolSpinningHead {
    float: left;
    /*.model-selector .head_nav.selected .nav_head_container .header_btn_border
      .nav_head_container_right{
          top: 20px;
      }*/ }
    #wrapper .product-tool-conntainer .ProductToolSpinningHead .model-selector .heads {
      top: 54px; }
    #wrapper .product-tool-conntainer .ProductToolSpinningHead .model-selector .head_nav {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      background-color: #ffffff;
      border: 1px solid #b0b0b0; }
    #wrapper .product-tool-conntainer .ProductToolSpinningHead span.ui-slider-handle.ui-state-default.ui-corner-all {
      background-size: contain !important; }
    #wrapper .product-tool-conntainer .ProductToolSpinningHead .ui-state-default, #wrapper .product-tool-conntainer .ProductToolSpinningHead .ui-widget-content .ui-state-default, #wrapper .product-tool-conntainer .ProductToolSpinningHead .ui-widget-header .ui-state-default {
      border: 0; }
    #wrapper .product-tool-conntainer .ProductToolSpinningHead .ui-slider-horizontal .ui-slider-handle {
      top: -.7rem; }
    #wrapper .product-tool-conntainer .ProductToolSpinningHead .ui-slider .ui-slider-handle {
      width: 30px;
      height: 30px;
      display: inline-block;
      position: relative; }
  #wrapper .product-tool-conntainer .colour-selector .popup {
    position: absolute;
    top: 0;
    z-index: 5; }
  #wrapper .product-tool-conntainer .animate {
    opacity: 1 !important; }
  #wrapper .product-tool-conntainer .ProductToolColorSpotPopUp {
    position: absolute;
    top: 0; }
  #wrapper .product-tool-conntainer .colour-selector .internal_full_popup {
    display: none;
    left: 0; }

@media only screen and (max-width: 950px) {
  #wrapper .product-tool-conntainer .ProductToolDescription, #wrapper .product-tool-conntainer .ProductToolColorSpot {
    float: left;
    position: inherit;
    margin-left: 2rem;
    margin-top: 0; }
    #wrapper .product-tool-conntainer .ProductToolDescription ul, #wrapper .product-tool-conntainer .ProductToolColorSpot ul {
      height: auto !important; }
  #wrapper .product-tool-conntainer .head_slider {
    width: 100%;
    margin-top: -40px; }
  #wrapper .product-tool-conntainer .model-selector {
    width: 170%; } }

#wrapper .handOn {
  cursor: pointer; }

#wrapper .product-tool-conntainer .ProductToolMenu {
  margin-top: 6rem;
  margin-bottom: 4rem; }
  #wrapper .product-tool-conntainer .ProductToolMenu ul {
    -webkit-transition: all, 0.8s;
    -moz-transition: all, 0.8s;
    -o-transition: all, 0.8s;
    transition: all, 0.8s;
    margin: auto;
    width: 100%;
    margin: 0;
    text-align: center; }
    #wrapper .product-tool-conntainer .ProductToolMenu ul li {
      cursor: pointer;
      display: inline-block;
      padding: 1rem 0;
      opacity: .3; }
      #wrapper .product-tool-conntainer .ProductToolMenu ul li *:not(.hide) {
        display: block; }
      #wrapper .product-tool-conntainer .ProductToolMenu ul li:first-child {
        padding-left: 0; }
      #wrapper .product-tool-conntainer .ProductToolMenu ul li:last-child {
        padding-right: 0; }
      #wrapper .product-tool-conntainer .ProductToolMenu ul li img {
        -webkit-transition: all, 0.3s;
        -moz-transition: all, 0.3s;
        -o-transition: all, 0.3s;
        transition: all, 0.3s;
        -webkit-transform: scale(0.6, 0.6);
        -moz-transform: scale(0.6, 0.6);
        -ms-transform: scale(0.6, 0.6);
        -o-transform: scale(0.6, 0.6);
        transform: scale(0.6, 0.6); }
      #wrapper .product-tool-conntainer .ProductToolMenu ul li.active, #wrapper .product-tool-conntainer .ProductToolMenu ul li:hover {
        opacity: 1; }
        #wrapper .product-tool-conntainer .ProductToolMenu ul li.active img, #wrapper .product-tool-conntainer .ProductToolMenu ul li:hover img {
          -webkit-transform: scale(1, 1);
          -moz-transform: scale(1, 1);
          -ms-transform: scale(1, 1);
          -o-transform: scale(1, 1);
          transform: scale(1, 1); }
  #wrapper .product-tool-conntainer .ProductToolMenu .product {
    background-color: #27251F;
    position: relative; }
    #wrapper .product-tool-conntainer .ProductToolMenu .product .innerWrapTing {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      overflow: hidden;
      white-space: nowrap;
      min-height: 22px; }
      #wrapper .product-tool-conntainer .ProductToolMenu .product .innerWrapTing ul {
        list-style: disc outside;
        position: relative;
        left: 1rem; }
        #wrapper .product-tool-conntainer .ProductToolMenu .product .innerWrapTing ul li {
          padding-bottom: 1rem;
          left: 0;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          user-select: none; }
          #wrapper .product-tool-conntainer .ProductToolMenu .product .innerWrapTing ul li img {
            margin: 0 auto; }
  #wrapper .product-tool-conntainer .ProductToolMenu .grouping {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
    #wrapper .product-tool-conntainer .ProductToolMenu .grouping ul li {
      opacity: 1; }
      #wrapper .product-tool-conntainer .ProductToolMenu .grouping ul li.active span {
        color: #ffffff !important;
        background-color: #f0f0f0;
        text-shadow: none; }
      #wrapper .product-tool-conntainer .ProductToolMenu .grouping ul li span:first-of-type {
        margin-right: .2em; }
      #wrapper .product-tool-conntainer .ProductToolMenu .grouping ul li span:last-of-type {
        margin-left: .2em; }
      #wrapper .product-tool-conntainer .ProductToolMenu .grouping ul li span {
        background-color: #e40049;
        color: #ffffff;
        display: inline-block;
        padding: 1em 2.5em;
        line-height: 1;
        border-radius: 2em;
        border: 0;
        font-size: 1rem;
        font-family: "Proxima N W01 Bold", sans-serif;
        cursor: pointer;
        width: auto;
        text-decoration: none;
        text-align: center;
        box-sizing: border-box;
        opacity: 1;
        transition: opacity 0.2s;
        -webkit-appearance: none;
        width: auto !important;
        white-space: nowrap;
        display: block; }
        #wrapper .product-tool-conntainer .ProductToolMenu .grouping ul li span:hover {
          opacity: 0.7; }
      #wrapper .product-tool-conntainer .ProductToolMenu .grouping ul li img {
        display: none; }
  #wrapper .product-tool-conntainer .ProductToolMenu .scroll-left, #wrapper .product-tool-conntainer .ProductToolMenu .scroll-right {
    clear: both;
    width: 40px;
    opacity: .6;
    position: absolute;
    z-index: 2;
    height: 100%;
    cursor: pointer; }
    #wrapper .product-tool-conntainer .ProductToolMenu .scroll-left::before, #wrapper .product-tool-conntainer .ProductToolMenu .scroll-right::before {
      font-family: "Oticon-icon-www";
      content: "\e602";
      font-size: 40px;
      margin: 0;
      position: absolute;
      height: 0;
      top: calc(50% - 20px); }
    #wrapper .product-tool-conntainer .ProductToolMenu .scroll-left:hover, #wrapper .product-tool-conntainer .ProductToolMenu .scroll-right:hover {
      opacity: .8; }
  #wrapper .product-tool-conntainer .ProductToolMenu .scroll-right {
    right: 0;
    top: 0; }
    #wrapper .product-tool-conntainer .ProductToolMenu .scroll-right::before {
      content: "\e603"; }
  #wrapper .product-tool-conntainer .ProductToolMenu .component-content {
    margin: 0 auto; }

#wrapper .product-tool-conntainer .ProductToolDescription, #wrapper .product-tool-conntainer .ProductToolColorSpot {
  display: block;
  /*
      span:not(.ProductToolColorSpot),
      ul:not(.colour_nav_item *)
       {
          width: 100%;
          display: block;
          float: left;
          max-width: 80%;
      }
      */ }
  #wrapper .product-tool-conntainer .ProductToolDescription .component-content #product_title, #wrapper .product-tool-conntainer .ProductToolColorSpot .component-content #product_title {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 3rem;
    margin: 0;
    padding: 0 0 0.4em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    display: block; }
  #wrapper .product-tool-conntainer .ProductToolDescription .colour-selector .column-right.selector, #wrapper .product-tool-conntainer .ProductToolColorSpot .colour-selector .column-right.selector {
    float: left; }

#wrapper .product-tool-conntainer .ProductToolDescription {
  margin-bottom: 2rem;
  line-height: 1.5; }

#wrapper .product-tool-conntainer .hide {
  display: none !important; }

#wrapper .product-tool-conntainer .productInfoDiv {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  -webkit-transition: all, 0.2s;
  -moz-transition: all, 0.2s;
  -o-transition: all, 0.2s;
  transition: all, 0.2s;
  height: 500px; }

#wrapper .colour-selector .preview_box .zoom_in {
  cursor: pointer; }

#wrapper .product-tool-conntainer .ProductToolSpinningHead .ui-slider .ui-slider-handle {
  display: block;
  position: relative; }

@media only screen and (max-width: 950px) {
  #wrapper .product-tool-conntainer .ProductToolDescription, #wrapper .product-tool-conntainer .ProductToolColorSpot {
    float: left;
    position: inherit;
    margin-left: 2rem;
    margin-top: 0;
    width: 100% !important; }
    #wrapper .product-tool-conntainer .ProductToolDescription ul, #wrapper .product-tool-conntainer .ProductToolColorSpot ul {
      height: auto !important;
      margin-left: 0 !important; } }

@media only screen and (max-width: 845px) {
  #wrapper .product-tool-conntainer .colour-selector .preview_box .zoom_in,
  #wrapper .product-tool-conntainer .colour-selector .popup {
    display: none; } }

@media only screen and (max-width: 668px) {
  .ProductToolSpinningHead {
    display: none; } }

#wrapper .component.product-tool-conntainer.slim .component-content {
  width: auto;
  max-width: none;
  margin: 0; }

#wrapper .component.product-tool-conntainer.slim > .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .component.rich-text:not(.right-side) {
  position: relative;
  z-index: 2; }
  #wrapper .component.rich-text:not(.right-side) .component-content img + img {
    margin-left: 0.7rem; }
  #wrapper .component.rich-text:not(.right-side) > .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }

#wrapper .component.rich-text p, #wrapper .component.rich-text a, #wrapper .component.rich-text span, #wrapper .component.rich-text h1, #wrapper .component.rich-text h2, #wrapper .component.rich-text h3, #wrapper .component.rich-text h4, #wrapper .component.rich-text h5, #wrapper .component.rich-text h6 {
  overflow-wrap: break-word; }

#wrapper .component.rich-text:not(.right-side).w10 > .component-content {
  max-width: 10%;
  width: 10%; }

#wrapper .component.rich-text:not(.right-side).w20 > .component-content {
  max-width: 20%;
  width: 20%; }

#wrapper .component.rich-text:not(.right-side).w30 > .component-content {
  max-width: 30%;
  width: 30%; }

#wrapper .component.rich-text:not(.right-side).w40 > .component-content {
  max-width: 40%;
  width: 40%; }

#wrapper .component.rich-text:not(.right-side).w50 > .component-content {
  max-width: 50%;
  width: 50%; }

#wrapper .component.rich-text:not(.right-side).w60 > .component-content {
  max-width: 60%;
  width: 60%; }

#wrapper .component.rich-text:not(.right-side).w70 > .component-content {
  max-width: 70%;
  width: 70%; }

#wrapper .component.rich-text:not(.right-side).w80 > .component-content {
  max-width: 80%;
  width: 80%; }

#wrapper .component.rich-text:not(.right-side).w90 > .component-content {
  max-width: 90%;
  width: 90%; }

@media only screen and (max-width: 1150px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 18%;
    width: 18%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 27%;
    width: 27%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 36%;
    width: 36%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 45%;
    width: 45%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 54%;
    width: 54%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 63%;
    width: 63%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 72%;
    width: 72%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 81%;
    width: 81%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 1100px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 26%;
    width: 26%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 34%;
    width: 34%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 42%;
    width: 42%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 50%;
    width: 50%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 58%;
    width: 58%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 74%;
    width: 74%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 1050px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 34%;
    width: 34%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 41%;
    width: 41%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 48%;
    width: 48%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 55%;
    width: 55%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 62%;
    width: 62%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 69%;
    width: 69%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 76%;
    width: 76%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 83%;
    width: 83%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 1000px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 42%;
    width: 42%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 48%;
    width: 48%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 54%;
    width: 54%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 60%;
    width: 60%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 72%;
    width: 72%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 950px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 50%;
    width: 50%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 55%;
    width: 55%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 60%;
    width: 60%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 65%;
    width: 65%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 70%;
    width: 70%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 75%;
    width: 75%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 80%;
    width: 80%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 85%;
    width: 85%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 900px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 58%;
    width: 58%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 62%;
    width: 62%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 70%;
    width: 70%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 74%;
    width: 74%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 86%;
    width: 86%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 950px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 69%;
    width: 69%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 72%;
    width: 72%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 75%;
    width: 75%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 81%;
    width: 81%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 87%;
    width: 87%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 800px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 74%;
    width: 74%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 76%;
    width: 76%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 80%;
    width: 80%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 86%;
    width: 86%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 88%;
    width: 88%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 750px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 83%;
    width: 83%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 85%;
    width: 85%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 86%;
    width: 86%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 87%;
    width: 87%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 88%;
    width: 88%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 89%;
    width: 89%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 700px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

.on-page-editor #wrapper > #content .component.rich-text {
  min-height: 100px; }

@media only screen and (max-width: 1400px) {
  #wrapper .component.rich-text .component-content img {
    margin-left: 0; } }

.ui-helper-hidden-accessible > div {
  display: none; }

#wrapper .search-result-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .blur-overlay {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: fixed;
  z-index: 15;
  background-color: #000000;
  top: 0; }

#wrapper .search-field-spot .search-field input {
  display: none;
  position: absolute;
  top: 10em;
  left: 0; }
  #wrapper .search-field-spot .search-field input::-ms-clear {
    display: none; }

#wrapper .search-field-spot.search-open .search-field input {
  display: block; }
  #wrapper .search-field-spot.search-open .search-field input::-ms-clear {
    display: none; }

#wrapper .search-field-spot.search-open .search-icon {
  display: block; }

#wrapper .component.search-result-spot:not([data-no-results="true"]) + .component.no-search-result {
  display: none; }

#wrapper .component.search-field-spot .search-field input[type="text"], #wrapper .component.search-result-spot .search-field input[type="text"] {
  border: 1px solid #005da9;
  width: 100%; }

#wrapper .component.search-field-spot .search-result, #wrapper .component.search-result-spot .search-result {
  padding-top: 3em; }
  #wrapper .component.search-field-spot .search-result h3 > a, #wrapper .component.search-field-spot .search-result .search-summary, #wrapper .component.search-result-spot .search-result h3 > a, #wrapper .component.search-result-spot .search-result .search-summary {
    color: #27251F;
    display: block;
    padding-bottom: 0.1em; }
  #wrapper .component.search-field-spot .search-result ul > li, #wrapper .component.search-result-spot .search-result ul > li {
    padding-bottom: 1.3em; }
    #wrapper .component.search-field-spot .search-result ul > li > a, #wrapper .component.search-result-spot .search-result ul > li > a {
      display: block;
      padding-bottom: 0.3em; }
    #wrapper .component.search-field-spot .search-result ul > li h3, #wrapper .component.search-result-spot .search-result ul > li h3 {
      padding: 0; }

#wrapper .component.search-result-spot .search-result-count {
  color: #bbbbbb;
  margin-top: -2.6em;
  z-index: 2;
  line-height: 2em;
  position: absolute;
  right: 3.5em; }
  #wrapper .component.search-result-spot .search-result-count span:after {
    cursor: pointer;
    transition: transform 0.2s;
    position: absolute;
    margin-left: 0.7em;
    font-family: "retail-icon-font";
    content: "";
    font-size: 1.4em; }

#wrapper .component.search-field-spot {
  float: right;
  display: inline-block;
  cursor: pointer; }
  #wrapper .component.search-field-spot::before {
    font-family: "retail-icon-font";
    content: "";
    color: #005da9;
    font-size: 1.5rem;
    position: relative;
    font-weight: 600;
    top: 1.2rem;
    opacity: 1;
    -webkit-transition: opacity, 0.2s;
    -moz-transition: opacity, 0.2s;
    -o-transition: opacity, 0.2s;
    transition: opacity, 0.2s;
    margin-left: 1em; }
  #wrapper .component.search-field-spot:hover::before {
    opacity: 0.6; }
  #wrapper .component.search-field-spot.search-open .search-field * {
    z-index: 10; }
  #wrapper .component.search-field-spot .search-field input {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0; }

#wrapper .component.no-search-result.rich-text .component-content {
  max-width: 90%;
  margin: 0 auto;
  width: 50em; }

#wrapper #header.burger-switch .row .grid-12 > div .component.search-field-spot::before {
  position: absolute;
  right: 1.2em;
  top: 0.8em; }

#wrapper #header.burger-switch .row .grid-12 > div .component.search-field-spot .search-field input {
  width: 100%; }

.ui-autocomplete {
  padding: 0;
  margin: 0;
  border: 1px solid #005da9;
  border-top: none;
  background: none;
  margin-left: 1.5em;
  z-index: 16; }
  .ui-autocomplete .ui-menu-item {
    background: #ffffff;
    font-size: 1em;
    padding: 1em 1.2em 0.9em 1.2em; }
    .ui-autocomplete .ui-menu-item.ui-state-focus {
      background-color: #f0f0f0;
      border: none;
      color: #005da9; }

#wrapper .component.screening-test-spot > .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .spot-control-spot {
  background-color: rgba(45, 45, 45, 0.9);
  padding: 2rem 0 2rem;
  color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 10;
  margin-top: 0 !important; }
  #wrapper .spot-control-spot > h3 {
    margin: 1rem;
    font-size: 1rem;
    cursor: pointer; }
    #wrapper .spot-control-spot > h3::after {
      font-family: "Oticon-icon-www";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      content: '\eb2f';
      font-size: 1rem;
      margin: 0;
      padding: 0rem 1rem;
      transition: all 0.2s;
      position: relative;
      top: 0.2rem; }
  #wrapper .spot-control-spot .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    width: 100%; }
    #wrapper .spot-control-spot .component-content .spot-controls {
      display: none; }
      #wrapper .spot-control-spot .component-content .spot-controls h4 {
        display: block;
        margin: 0 0 0.2rem; }
      #wrapper .spot-control-spot .component-content .spot-controls .spot-control {
        display: inline-block;
        margin-right: 2rem;
        margin-bottom: 1.3rem;
        width: 10%; }
        #wrapper .spot-control-spot .component-content .spot-controls .spot-control .spot-control-label {
          display: inline-block; }
        #wrapper .spot-control-spot .component-content .spot-controls .spot-control input {
          width: auto;
          margin-bottom: 0;
          -webkit-appearance: checkbox;
          -moz-appearance: checkbox;
          -ms-appearance: checkbox;
          -o-appearance: checkbox;
          appearance: checkbox; }
      #wrapper .spot-control-spot .component-content .spot-controls .stretch {
        /*                    display: inline-block;
                              width: 100%;*/ }
      #wrapper .spot-control-spot .component-content .spot-controls.transport {
        margin-top: 1rem; }
        #wrapper .spot-control-spot .component-content .spot-controls.transport .reset {
          margin: 0;
          position: absolute;
          bottom: 1rem;
          left: 20%; }
          #wrapper .spot-control-spot .component-content .spot-controls.transport .reset a {
            color: rgba(204, 17, 34, 0.6); }
            #wrapper .spot-control-spot .component-content .spot-controls.transport .reset a:hover {
              color: #cc1122; }
  #wrapper .spot-control-spot.expanded {
    left: 0;
    right: 0; }
    #wrapper .spot-control-spot.expanded > h3 {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      margin: 1rem auto;
      cursor: pointer;
      width: 100%;
      font-size: 1rem;
      position: absolute;
      bottom: 0;
      left: 5%; }
      #wrapper .spot-control-spot.expanded > h3::after {
        content: '\eae3';
        top: 0.2rem; }
    #wrapper .spot-control-spot.expanded h4 {
      font-size: 0.8rem; }
    #wrapper .spot-control-spot.expanded .spot-control input {
      margin-top: .1rem; }
    #wrapper .spot-control-spot.expanded .spot-control .spot-control-label {
      font-size: 0.8rem; }
    #wrapper .spot-control-spot.expanded .spot-controls {
      margin-top: 2rem;
      margin-bottom: 3rem; }
    #wrapper .spot-control-spot.expanded .transport {
      position: relative; }
  #wrapper .spot-control-spot.scrolled {
    position: fixed;
    margin-top: 0;
    top: 0;
    z-index: 4; }

/* Available styles
.iright

.inormal
.islim

.ialignborder

.iw60
.iw40

.iposmiddle
.iposbottom


.tslim

.tw60
.tw40

.tposmiddle
.tposbottom

.tforceheight
*/
.on-page-editor #wrapper > #content .component.text-image-spot {
  min-height: 100px; }

#wrapper > #content .component.text-image-spot .text-image-spot-image-container a {
  display: block;
  text-decoration: none; }
  #wrapper > #content .component.text-image-spot .text-image-spot-image-container a::before, #wrapper > #content .component.text-image-spot .text-image-spot-image-container a::after {
    content: ' ';
    display: none; }

#wrapper > #content .component.text-image-spot:not(.version2) {
  position: relative;
  overflow: hidden; }
  #wrapper > #content .component.text-image-spot:not(.version2) * {
    box-sizing: border-box; }
  #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
    bottom: 2rem; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container .text-image-spot-image {
      position: absolute;
      top: 0;
      bottom: auto;
      width: 50%;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden; }
  #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container {
    position: absolute;
    width: 74rem;
    max-width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
    bottom: 2rem;
    pointer-events: none; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text {
      position: absolute;
      top: 0;
      bottom: auto;
      width: 50%;
      padding-left: 2rem;
      padding-right: 0;
      left: 50%;
      pointer-events: all; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text > *:first-child {
        margin-top: 0;
        padding-top: 0; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark {
        background-color: #f0f0f0;
        color: #bbbbbb; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-light {
        background-color: #ffffff;
        color: #bbbbbb; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate {
        background-color: #005da9;
        color: #ffffff; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark,
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white,
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate {
        width: 10em;
        height: 10em;
        padding: .5em;
        margin: 2em 1em 1em 0; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark h3, #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white h3,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate h3,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate .bigger {
          padding: 1em .3em;
          margin: 0;
          font-size: 1em;
          line-height: 1.333em; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark hr,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white hr,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate hr {
          padding: 0;
          margin: 0; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate .bigger {
          font-size: 2.25em !important; }
  #wrapper > #content .component.text-image-spot:not(.version2).iright .text-image-spot-image {
    padding-left: 0;
    padding-right: 0;
    left: 50%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iright .text-image-spot-text {
    left: 0;
    padding-right: 2rem;
    padding-left: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).ialignborder .text-image-spot-image > img {
    float: left; }
  #wrapper > #content .component.text-image-spot:not(.version2).ialignborder.iright .text-image-spot-image > img {
    float: right; }
  #wrapper > #content .component.text-image-spot:not(.version2):not(.ialignborder) .text-image-spot-image > img {
    float: right; }
  #wrapper > #content .component.text-image-spot:not(.version2):not(.ialignborder).iright .text-image-spot-image > img {
    float: left; }
  #wrapper > #content .component.text-image-spot:not(.version2).inormal .text-image-spot-image-container {
    width: 74rem;
    max-width: 90%; }
  #wrapper > #content .component.text-image-spot:not(.version2).islim .text-image-spot-image-container {
    width: 60rem;
    max-width: 90%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw60 .text-image-spot-image {
    width: 60%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw60.iright .text-image-spot-image {
    left: 40%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw40 .text-image-spot-image {
    width: 40%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw40.iright .text-image-spot-image {
    left: 60%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iposmiddle .text-image-spot-image {
    bottom: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #wrapper > #content .component.text-image-spot:not(.version2).iposbottom .text-image-spot-image {
    bottom: 0;
    top: auto;
    transform: none; }
  #wrapper > #content .component.text-image-spot:not(.version2).tforceheight .text-image-spot-image-container {
    overflow: hidden; }
  @media only screen and (min-width: 992px) {
    #wrapper > #content .component.text-image-spot:not(.version2).tforceheight.small-image .text-image-spot-image {
      height: 100%; }
      #wrapper > #content .component.text-image-spot:not(.version2).tforceheight.small-image .text-image-spot-image img {
        height: 100%;
        width: auto;
        max-width: none; } }
  #wrapper > #content .component.text-image-spot:not(.version2).inoborder .text-image-spot-image-container {
    top: 0;
    bottom: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).inoborder:not(.tforceheight) .text-image-spot-text-container {
    top: 0;
    bottom: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).tslim .text-image-spot-text-container {
    width: 60rem;
    max-width: 90%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw60 .text-image-spot-text {
    width: 60%;
    left: 40%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw60.iright .text-image-spot-text {
    left: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw40 .text-image-spot-text {
    width: 40%;
    left: 60%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw40.iright .text-image-spot-text {
    left: 0%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tposmiddle .text-image-spot-text {
    bottom: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #wrapper > #content .component.text-image-spot:not(.version2).tposbottom .text-image-spot-text {
    bottom: 0;
    top: auto;
    transform: none; }

@media only screen and (max-width: 800px) {
  #wrapper > #content .component.text-image-spot:not(.version2) {
    min-height: 0 !important; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container {
      position: static;
      max-width: 90%;
      width: 100%;
      margin: auto;
      height: auto;
      transform: none;
      overflow: visible;
      margin-top: 2em; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container .text-image-spot-image {
        position: static;
        width: auto;
        padding: 0px 0px 1rem;
        transform: none;
        text-align: center; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container .text-image-spot-image img {
          float: none !important; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container {
      position: static;
      width: 100%;
      max-width: 90%;
      margin: auto;
      transform: none; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text {
        position: static;
        width: auto;
        padding: 0px;
        transform: none; }
    #wrapper > #content .component.text-image-spot:not(.version2).no-mobile-image .text-image-spot-image-container {
      display: none; } }

#wrapper > #content .component.text-image-spot.version2 .text-image-spot-image-container {
  background-size: 0px; }

#wrapper > #content .component.text-image-spot.version2 .text-image-spot-text-container {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  padding: 1rem 0; }

#wrapper > #content .component.text-image-spot.version2.readmore .text-image-spot-readmore-open {
  display: block; }
  #wrapper > #content .component.text-image-spot.version2.readmore .text-image-spot-readmore-open:after {
    transform: rotate(45deg); }

#wrapper > #content .component.text-image-spot.version2.readmore + .text-image-spot-readmore {
  display: block;
  height: 0;
  overflow: hidden;
  color: #ffffff;
  background-color: #404040; }
  #wrapper > #content .component.text-image-spot.version2.readmore + .text-image-spot-readmore .text-image-spot-readmore-text {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    padding: 5rem 0; }
  #wrapper > #content .component.text-image-spot.version2.readmore + .text-image-spot-readmore .text-image-spot-readmore-close {
    display: block;
    text-align: center; }

#wrapper > #content .component.text-image-spot.version2.readmore--open .text-image-spot-readmore-open {
  display: none; }

#wrapper > #content .component.text-image-spot.version2.readmore--open .text-image-spot-readmore-less {
  display: block; }

#wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-open,
#wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-less,
#wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-close {
  display: none; }
  .mode-editing #wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-open, .mode-editing
  #wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-less, .mode-editing
  #wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-close {
    display: inline-block;
    width: 100%; }

#wrapper > #content .text-image-spot-readmore {
  display: none; }
  .mode-editing #wrapper > #content .text-image-spot-readmore {
    display: block;
    color: #ffffff;
    background-color: #404040; }
    .mode-editing #wrapper > #content .text-image-spot-readmore .text-image-spot-readmore-text {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      padding: 5rem 0; }

#wrapper > #content .text-image-spot-readmore-less,
#wrapper > #content .text-image-spot-readmore-open,
#wrapper > #content .text-image-spot-readmore-close {
  text-align: center;
  padding-bottom: 4rem;
  margin-top: 2rem;
  font-size: 1rem; }
  #wrapper > #content .text-image-spot-readmore-less:after,
  #wrapper > #content .text-image-spot-readmore-open:after,
  #wrapper > #content .text-image-spot-readmore-close:after {
    font-family: "Oticon-icon-www";
    content: "";
    line-height: 4;
    font-size: .5rem;
    background-color: #c6168d;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    position: absolute;
    left: 50%;
    top: 2rem;
    margin-left: -20px; }

#wrapper > #content .text-image-spot-readmore-less:after {
  text-indent: 2px; }

#wrapper > #content .corporate-bg .text-image-spot-readmore-less,
#wrapper > #content .corporate-bg .text-image-spot-readmore-open,
#wrapper > #content .corporate-bg .text-image-spot-readmore-close {
  color: #c6168d; }
  #wrapper > #content .corporate-bg .text-image-spot-readmore-less:after,
  #wrapper > #content .corporate-bg .text-image-spot-readmore-open:after,
  #wrapper > #content .corporate-bg .text-image-spot-readmore-close:after {
    background-color: #ffffff;
    color: #ffffff; }

@media only screen and (min-width: 768px) {
  #wrapper > #content .component.text-image-spot.version2 {
    display: table;
    width: 100%;
    padding: 2rem 0;
    table-layout: fixed; }
    #wrapper > #content .component.text-image-spot.version2 .component-content {
      display: table-row;
      width: 100%; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image-container,
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text-container {
      display: table-cell;
      width: 50%; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text {
      position: relative; }
    #wrapper > #content .component.text-image-spot.version2:not(.iright) .text-image-spot-text {
      float: left;
      padding: 0 0 0 2rem; }
    #wrapper > #content .component.text-image-spot.version2.iright {
      direction: rtl; }
      #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-image-container {
        direction: ltr; }
      #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-text-container {
        direction: ltr; }
      #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-text {
        float: right;
        padding: 0 2rem 0 0; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text {
      box-sizing: border-box;
      width: 37rem;
      max-width: 90%; }
    #wrapper > #content .component.text-image-spot.version2.tslim .text-image-spot-text {
      width: 30rem; }
    #wrapper > #content .component.text-image-spot.version2.tw40 .text-image-spot-text {
      position: relative;
      width: 29.6rem;
      max-width: 72%;
      left: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tw40.iright .text-image-spot-text {
      left: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tw40.tslim .text-image-spot-text {
      width: 24rem;
      left: 6rem; }
    #wrapper > #content .component.text-image-spot.version2.tw60 .text-image-spot-text {
      position: relative;
      width: 44.4rem;
      max-width: 108%;
      left: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tw60.tslim .text-image-spot-text {
      width: 36rem;
      left: -6rem; }
    #wrapper > #content .component.text-image-spot.version2.tw60.iright .text-image-spot-text {
      left: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text-container {
      vertical-align: top; }
    #wrapper > #content .component.text-image-spot.version2.tposmiddle .text-image-spot-text-container {
      vertical-align: middle; }
    #wrapper > #content .component.text-image-spot.version2.tposbottom .text-image-spot-text-container {
      vertical-align: bottom; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image {
      box-sizing: border-box;
      width: 100%;
      float: left; }
      #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image img {
        float: right; }
    #wrapper > #content .component.text-image-spot.version2.inormal .text-image-spot-image img {
      width: 37rem;
      max-width: 90%; }
    #wrapper > #content .component.text-image-spot.version2.islim .text-image-spot-image img {
      width: 30rem;
      max-width: 90%; }
    #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-image img {
      float: left; }
    #wrapper > #content .component.text-image-spot.version2.ialignborder:not(.iright) .text-image-spot-image img {
      float: right; }
    #wrapper > #content .component.text-image-spot.version2.iw40 .text-image-spot-image {
      width: 80%; }
    #wrapper > #content .component.text-image-spot.version2.iw40.iright .text-image-spot-image {
      margin-left: 20%; }
    #wrapper > #content .component.text-image-spot.version2.iw40.inormal .text-image-spot-image {
      width: 29.6rem; }
    #wrapper > #content .component.text-image-spot.version2.iw40.inormal.iright .text-image-spot-image {
      margin-left: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.iw40.islim .text-image-spot-image {
      width: 24rem; }
    #wrapper > #content .component.text-image-spot.version2.iw40.islim.iright .text-image-spot-image {
      margin-left: 6rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60 .text-image-spot-image {
      width: 120%; }
    #wrapper > #content .component.text-image-spot.version2.iw60.iright .text-image-spot-image {
      margin-left: -20%; }
    #wrapper > #content .component.text-image-spot.version2.iw60.inormal .text-image-spot-image {
      width: 44.4rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60.inormal.iright .text-image-spot-image {
      margin-left: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60.islim .text-image-spot-image {
      width: 36rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60.islim.iright .text-image-spot-image {
      margin-left: -6rem; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image-container {
      vertical-align: top; }
    #wrapper > #content .component.text-image-spot.version2.iposmiddle .text-image-spot-image-container {
      vertical-align: middle; }
    #wrapper > #content .component.text-image-spot.version2.iposbottom .text-image-spot-image-container {
      vertical-align: bottom; }
    #wrapper > #content .component.text-image-spot.version2.inoborder {
      padding: 0; }
      #wrapper > #content .component.text-image-spot.version2.inoborder .text-image-spot-text-container {
        padding: 2rem 0; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight .text-image-spot-image-container {
      background-size: cover;
      background-position: center; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight .text-image-spot-image img {
      display: none; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).inormal .text-image-spot-image {
      left: auto;
      right: 0; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).inormal.iw60 .text-image-spot-image {
      right: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).inormal.iw40 .text-image-spot-image {
      right: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).islim .text-image-spot-image {
      left: auto;
      right: 0; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).islim.iw60 .text-image-spot-image {
      right: -6rem; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).islim.iw40 .text-image-spot-image {
      right: 6rem; } }

#wrapper .component.text-image-spot .text-image-spot-image .text-image-spot-image-description {
  padding: 0.5rem;
  float: left;
  width: 100%; }

#wrapper .component.text-image-spot .text-image-spot-text a, #wrapper .component.text-image-spot .text-image-spot-text a span {
  transition: none !important; }

@media only screen and (max-width: 767px) {
  #wrapper .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  #wrapper .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

#wrapper .component.text-image-spot.image-description-bg-corporate .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-corporate .text-image-spot-image .text-image-spot-image-description {
    background-color: #005da9;
    color: #ffffff; }

#wrapper .component.text-image-spot.image-description-bg-green .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-green .text-image-spot-image .text-image-spot-image-description {
    background-color: #a7d3ac;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-green-dark .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-green-dark .text-image-spot-image .text-image-spot-image-description {
    background-color: #96bd9a;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-nounce-green .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-nounce-green .text-image-spot-image .text-image-spot-image-description {
    background-color: #d3e9d5;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-nounce-blue .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-nounce-blue .text-image-spot-image .text-image-spot-image-description {
    background-color: #68afd6;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-light-grey .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-light-grey .text-image-spot-image .text-image-spot-image-description {
    background-color: #f1f1f1;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-light-red .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-light-red .text-image-spot-image .text-image-spot-image-description {
    background-color: #ec4d80;
    color: inherit; }

@media only screen and (min-width: 768px) {
  #wrapper > #content .component.text-image-spot.alignment.version2 .component-content {
    display: block;
    width: 0;
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper > #content .component.text-image-spot.alignment.version2.tw60 .text-image-spot-text {
    left: 0; }
  #wrapper > #content .component.text-image-spot.alignment.version2.tw60.iright .text-image-spot-text {
    left: 0; } }

@media only screen and (max-width: 479px) {
  #wrapper .component.text-image-spot .text-image-spot-image .text-image-spot-image-description {
    margin-top: -2px; }
  #wrapper .component.text-image-spot .component-content .text-image-spot-text .btn,
  #wrapper .component.text-image-spot .component-content .text-image-spot-text [class*="btn-"] {
    margin-bottom: 1rem; } }

#wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content {
  display: flex;
  flex-direction: row;
  align-items: stretch; }
  #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-image-container {
    margin-right: 1px;
    display: flex;
    align-items: stretch; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-image-container .text-image-spot-image img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container {
    padding: 0;
    margin-left: 1px;
    display: block; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container .text-image-spot-text {
      padding: 0;
      width: 100%;
      max-width: 100%; }
      #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container .text-image-spot-text img {
        object-fit: cover;
        display: block; }

@media only screen and (max-width: 767px) {
  #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content {
    flex-direction: column; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-image-container {
      margin-right: 0; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container {
      margin-left: 0;
      padding: 0;
      width: 100%;
      max-width: 100%;
      margin-top: 2px; }
      #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container .text-image-spot-text {
        width: 100%;
        max-width: 100%; } }

#wrapper .two-column-spot {
  text-align: center; }
  #wrapper .two-column-spot .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow: hidden;
    text-align: left; }
    #wrapper .two-column-spot .component-content .spot-text-right {
      float: left;
      width: 48%; }
      #wrapper .two-column-spot .component-content .spot-text-right > *:first-child {
        margin-top: 0;
        padding-top: 0; }
    #wrapper .two-column-spot .component-content .spot-text-left {
      float: right;
      width: 48%; }
      #wrapper .two-column-spot .component-content .spot-text-left > *:first-child {
        margin-top: 0;
        padding-top: 0; }
    #wrapper .two-column-spot .component-content .spot-text-header {
      width: 100%; }

@media only screen and (max-width: 800px) {
  #wrapper .w10.two-column-spot > .component-content, #wrapper .w20.two-column-spot > .component-content,
  #wrapper .w30.two-column-spot > .component-content, #wrapper .w40.two-column-spot > .component-content,
  #wrapper .w50.two-column-spot > .component-content, #wrapper .w60.two-column-spot > .component-content,
  #wrapper .w70.two-column-spot > .component-content, #wrapper .w80.two-column-spot > .component-content,
  #wrapper .w90.two-column-spot > .component-content {
    max-width: 90% !important; } }

@media only screen and (max-width: 600px) {
  #wrapper .two-column-spot .component-content .spot-text-right {
    float: none;
    width: 100%;
    padding-bottom: 1rem; }
  #wrapper .two-column-spot .component-content .spot-text-left {
    float: none;
    width: 100%; }
  #wrapper .two-column-spot .component-content * {
    text-align: left !important; }
  #wrapper .w10.two-column-spot > .component-content, #wrapper .w20.two-column-spot > .component-content,
  #wrapper .w30.two-column-spot > .component-content, #wrapper .w40.two-column-spot > .component-content,
  #wrapper .w50.two-column-spot > .component-content, #wrapper .w60.two-column-spot > .component-content,
  #wrapper .w70.two-column-spot > .component-content, #wrapper .w80.two-column-spot > .component-content,
  #wrapper .w90.two-column-spot > .component-content {
    max-width: 90% !important; } }

#wrapper .video-spot.no-data-source {
  display: none !important; }

#wrapper .video-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .video-spot .component-content .video-spot-container .video-spot-video {
    position: relative;
    display: inline-block;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video:not(:last-child) {
      margin-bottom: 1rem; }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video .embed-code {
      display: none; }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-image {
      cursor: pointer; }
      #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-image img {
        display: block; }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn {
      cursor: pointer;
      background-color: #005da9;
      width: 7em;
      height: 7em;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none; }
      #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn::after {
        font-family: "Oticon-icon-www";
        content: '\ea96';
        font-size: 4.5em;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
  #wrapper .video-spot .component-content .video-spot-container .inline-video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

#wrapper .video-spot.play-inline .component-content .video-spot-container .video-spot-video {
  visibility: hidden; }

#wrapper .video-spot.full-width-image .component-content .video-spot-container .video-spot-video {
  width: 100%; }
  #wrapper .video-spot.full-width-image .component-content .video-spot-container .video-spot-video .video-spot-image img {
    width: 100%;
    height: auto; }

#wrapper .video-spot-overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; }
  #wrapper .video-spot-overlay .video-spot-overlay-close {
    width: 3em;
    height: 3em;
    position: fixed;
    top: 3em;
    right: 3em;
    cursor: pointer; }
    #wrapper .video-spot-overlay .video-spot-overlay-close::after {
      content: '\eae3';
      position: absolute;
      top: 0;
      left: 0;
      font-family: "Oticon-icon-www";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 3em;
      color: rgba(255, 255, 255, 0.5); }
    #wrapper .video-spot-overlay .video-spot-overlay-close:hover::after {
      color: #ffffff; }
  #wrapper .video-spot-overlay .video-spot-overlay-video {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

#wrapper .component.video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn {
  background-color: #e40049;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem; }
  #wrapper .component.video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn:after {
    font-family: "retail-icon-font";
    left: 54%;
    transform: translate(-45%, -50%);
    font-size: 3.5rem;
    content: "";
    font-weight: 300; }

#wrapper .component.video-spot.hide-btn .video-spot-play-btn {
  display: none; }

#wrapper .video-spot-overlay {
  background-color: rgba(0, 0, 0, 0.3); }
  #wrapper .video-spot-overlay .video-spot-overlay-close::after {
    content: "";
    font-family: "retail-icon-font"; }

#wrapper #content .component.AddThis {
  position: absolute;
  right: 0;
  left: 0;
  top: 7.7em;
  z-index: 999; }
  #wrapper #content .component.AddThis .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    text-align: right; }
    #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox {
      display: inline-block; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-svc-compact {
        display: none; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-icon-wrapper {
        background-color: transparent !important;
        margin-right: 0.4rem; }
        #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-icon-wrapper span {
          text-align: center; }
          #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-icon-wrapper span::after {
            font-family: "retail-icon-font";
            display: inline-block;
            color: #8a8a8a;
            font-size: 1.6rem; }
        #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-icon-wrapper svg {
          display: none; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-svc-email span::after {
        content: ""; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-svc-print span::after {
        content: ""; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-svc-twitter span::after {
        content: ""; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-svc-facebook span::after {
        content: ""; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-svc-linkedin span::after {
        content: ""; }
      #wrapper #content .component.AddThis .component-content .addthis_sharing_toolbox .at-svc-instagram span::after {
        content: ""; }

#wrapper .component.blog .blog-image img {
  width: 100%;
  display: block; }

#wrapper .component.blog .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 2rem; }
  #wrapper .component.blog .component-content .blog-reading-time,
  #wrapper .component.blog .component-content .blog-author {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-date {
    line-height: 1.5; }
  #wrapper .component.blog .component-content .blog-author-value {
    color: #c6168d;
    font-family: "GillSansMTPro-Medium", sans-serif; }
  #wrapper .component.blog .component-content .blog-title {
    margin: 1rem 0 0.2rem; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    font-weight: 700; }
    @media only screen and (max-width: 479px) {
      #wrapper .component.blog .component-content .blog-introduction {
        font-size: 1.1rem; } }
  #wrapper .component.blog .component-content .blog-content {
    margin-bottom: 0.8rem; }

@media only screen and (min-width: 992px) {
  #wrapper .component.blog .component-content .blog-title {
    margin: 2rem 0; } }

.component.blog-list #blog-list li,
.component.latest-blog-posts #blog-list li {
  list-style: none;
  text-indent: 0;
  padding: 0;
  margin: 1rem; }
  .component.blog-list #blog-list li > a,
  .component.latest-blog-posts #blog-list li > a {
    display: block; }
  .component.blog-list #blog-list li .news-title,
  .component.latest-blog-posts #blog-list li .news-title {
    display: none; }
  .component.blog-list #blog-list li .news-text,
  .component.blog-list #blog-list li .news-link,
  .component.latest-blog-posts #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-link {
    margin-top: 1rem;
    text-align: center; }
  .component.blog-list #blog-list li .news-image,
  .component.latest-blog-posts #blog-list li .news-image {
    text-align: center; }
    .component.blog-list #blog-list li .news-image img,
    .component.latest-blog-posts #blog-list li .news-image img {
      max-width: 100%; }
  .component.blog-list #blog-list li .news-text,
  .component.latest-blog-posts #blog-list li .news-text {
    line-height: 1.5rem; }
  .component.blog-list #blog-list li .news-link span,
  .component.latest-blog-posts #blog-list li .news-link span {
    margin: 0; }
  .component.blog-list #blog-list li .news-date,
  .component.latest-blog-posts #blog-list li .news-date {
    text-align: center;
    padding: 0.5rem 0;
    color: #f5f5f5; }

@media only screen and (min-width: 768px) {
  .component.blog-list #blog-list,
  .component.latest-blog-posts #blog-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 3.6rem 0; }
    .component.blog-list #blog-list li,
    .component.latest-blog-posts #blog-list li {
      box-sizing: border-box;
      margin: 0 auto 1rem auto;
      padding: 0 2%;
      width: 33%; }
      .component.blog-list #blog-list li .news-image img,
      .component.latest-blog-posts #blog-list li .news-image img {
        max-height: 180px; }
      .component.blog-list #blog-list li .news-text,
      .component.latest-blog-posts #blog-list li .news-text {
        height: 125px;
        max-height: 125px;
        overflow: hidden; } }

@media only screen and (min-width: 992px) {
  .component.blog-list .component-content,
  .component.latest-blog-posts .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; } }

#wrapper .component.related-blogs {
  clear: both;
  background-color: #ffffff; }
  #wrapper .component.related-blogs .component-content {
    width: 60rem;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 0; }
    #wrapper .component.related-blogs .component-content .related-title {
      display: none; }
    #wrapper .component.related-blogs .component-content ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start; }
      #wrapper .component.related-blogs .component-content ul li {
        margin: 1rem 0 0 0;
        width: 100%;
        padding: 0 1%;
        box-sizing: border-box;
        list-style: none; }
        #wrapper .component.related-blogs .component-content ul li:nth-child(n + 4) {
          display: none; }
        #wrapper .component.related-blogs .component-content ul li .news-image {
          text-align: center; }
          #wrapper .component.related-blogs .component-content ul li .news-image img {
            max-width: 100%;
            height: auto; }
        #wrapper .component.related-blogs .component-content ul li .news-text,
        #wrapper .component.related-blogs .component-content ul li .news-link {
          background: #f1f1f1;
          font-size: 1.4rem;
          padding: 0.6rem 0.6rem 0;
          text-align: center; }
        #wrapper .component.related-blogs .component-content ul li .news-date {
          display: none; }
        #wrapper .component.related-blogs .component-content ul li .news-text {
          max-height: 75px;
          height: 75px;
          overflow: hidden;
          line-height: 1.4; }
        #wrapper .component.related-blogs .component-content ul li .news-link {
          padding-bottom: 10px; }
          #wrapper .component.related-blogs .component-content ul li .news-link a.link {
            margin: 0;
            padding-left: 0; }
            #wrapper .component.related-blogs .component-content ul li .news-link a.link:hover {
              padding-left: 1rem; }
          #wrapper .component.related-blogs .component-content ul li .news-link a::before {
            content: ""; }

@media only screen and (min-width: 768px) {
  #wrapper .component.related-blogs .component-content ul li {
    width: 33%;
    padding: 0 2%; }
    #wrapper .component.related-blogs .component-content ul li .news-text,
    #wrapper .component.related-blogs .component-content ul li .news-link {
      font-size: 1.1rem; }
    #wrapper .component.related-blogs .component-content ul li .news-image img {
      max-height: 150px;
      max-width: 100%; } }

#wrapper .component.blog .blog-image {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  max-width: 100%; }

#wrapper .component.blog .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.blog .component-content .blog-title {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 3rem;
    margin: 0;
    padding: 0 0 0.4em 0;
    line-height: 1;
    color: #005da9;
    display: block; }
  #wrapper .component.blog .component-content .blog-time-wrap {
    font-size: 1.2rem;
    margin: 0 0 1rem 0; }
    #wrapper .component.blog .component-content .blog-time-wrap:after {
      content: "";
      display: table;
      clear: both; }
  #wrapper .component.blog .component-content .blog-date,
  #wrapper .component.blog .component-content .blog-reading-time {
    color: #a7d3ac;
    float: left;
    padding-right: .5rem; }
  #wrapper .component.blog .component-content .blog-reading-time:before {
    content: "• ";
    display: inline-block;
    padding-right: .5rem; }
  #wrapper .component.blog .component-content .blog-author,
  #wrapper .component.blog .component-content .blog-author-wrap {
    display: none; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 500; }
  #wrapper .component.blog .component-content .blog-content {
    font-size: 1.3rem; }
    #wrapper .component.blog .component-content .blog-content .quote {
      color: #27251F;
      font-size: inherit;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 1rem; }
      #wrapper .component.blog .component-content .blog-content .quote:before, #wrapper .component.blog .component-content .blog-content .quote:after {
        display: none; }
    #wrapper .component.blog .component-content .blog-content .quote-bubble-author {
      position: relative;
      text-align: center;
      margin: 0 auto 3rem auto;
      color: #005da9;
      font-weight: 700;
      padding-left: 0; }
      #wrapper .component.blog .component-content .blog-content .quote-bubble-author:after {
        text-align: center;
        content: " ";
        display: block;
        position: absolute;
        top: 40px;
        width: 40px;
        left: 50%;
        margin-left: -20px;
        border-bottom: solid 4px #a7d3ac; }
  #wrapper .component.blog .component-content p {
    font-size: 1rem; }

@media only screen and (min-width: 1020px) {
  #wrapper .component.blog .component-content .blog-time-wrap {
    font-size: .9rem; }
  #wrapper .component.blog .component-content .blog-introduction {
    font-size: 1.3rem; }
  #wrapper .component.blog .component-content .blog-content .quote {
    max-width: 90%;
    display: block;
    margin: 1.5rem auto .5rem auto; } }

.blog-image--top {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  max-width: 100%; }
  .blog-image--top img {
    width: 100%; }

#wrapper .component.blog-categories .blog-categories-content-wrapper {
  padding-top: 1rem; }
  #wrapper .component.blog-categories .blog-categories-content-wrapper .blog-category a {
    padding: 1rem 1.5rem;
    display: block;
    text-transform: uppercase;
    position: relative;
    font-size: 1.4rem;
    font-family: "Proxima N W01 Reg", sans-serif;
    color: #27251F; }
    #wrapper .component.blog-categories .blog-categories-content-wrapper .blog-category a.active {
      font-family: "Proxima N W01 Bold", sans-serif; }

@media only screen and (min-width: 768px) {
  #wrapper .component.blog-categories .blog-categories-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #f1f1f1; }
    #wrapper .component.blog-categories .blog-categories-content .blog-categories-content-wrapper {
      display: flex;
      padding: 0.8rem 0; }
      #wrapper .component.blog-categories .blog-categories-content .blog-categories-content-wrapper .blog-category {
        margin: 0.5rem 2rem 0.5rem 0; }
        #wrapper .component.blog-categories .blog-categories-content .blog-categories-content-wrapper .blog-category a {
          font-size: 1rem;
          padding: 0.5rem 0; }
          #wrapper .component.blog-categories .blog-categories-content .blog-categories-content-wrapper .blog-category a::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 0;
            margin: 0 auto;
            border-bottom: 2px solid #005da9;
            transition: width 0.2s ease-out; }
          #wrapper .component.blog-categories .blog-categories-content .blog-categories-content-wrapper .blog-category a.active::after, #wrapper .component.blog-categories .blog-categories-content .blog-categories-content-wrapper .blog-category a:hover::after {
            width: 1.62rem; } }

#wrapper .component.blog-list .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .component.blog-list ul li {
  background-color: #f1f1f1;
  margin: 2rem 0 0 0;
  font-size: 1.4rem;
  line-height: 1.4;
  text-align: center; }
  #wrapper .component.blog-list ul li > a {
    position: relative;
    display: block; }
    #wrapper .component.blog-list ul li > a:hover {
      opacity: 1; }
  #wrapper .component.blog-list ul li .news-title {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2.5rem;
    margin: 0;
    padding: 0 0 0.6em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    display: block;
    padding: 1.5rem 1rem 1rem 1rem; }
    #wrapper .component.blog-list ul li .news-title a {
      color: #005da9; }
  #wrapper .component.blog-list ul li .news-text {
    font-family: "Proxima N W01 Reg", sans-serif;
    padding: .5rem 1rem 1rem 1rem;
    line-height: 1.5;
    margin-top: 0;
    color: #27251F; }
  #wrapper .component.blog-list ul li .news-date {
    font-family: "Proxima N W01 Reg", sans-serif;
    color: #bbbbbb; }
  #wrapper .component.blog-list ul li .news-link {
    padding: 0 0 3rem 0;
    margin-top: 0;
    z-index: 1; }
    #wrapper .component.blog-list ul li .news-link span {
      color: #005da9; }
      #wrapper .component.blog-list ul li .news-link span:after {
        font-family: "retail-icon-font";
        content: "";
        position: relative;
        top: .3rem;
        left: .3rem; }

@media only screen and (min-width: 768px) {
  #wrapper .component.blog-list #blog-list {
    margin: 3rem -1.5rem; }
    #wrapper .component.blog-list #blog-list li {
      margin: 0 0 3rem 0;
      padding: 0;
      background-color: transparent;
      width: 33.33%;
      padding: 0 2%; }
      #wrapper .component.blog-list #blog-list li > a {
        background-color: #f1f1f1; }
      #wrapper .component.blog-list #blog-list li .news-image img {
        max-height: none; }
      #wrapper .component.blog-list #blog-list li .news-title {
        height: 4rem;
        font-size: 1.4rem;
        margin-bottom: .5rem;
        padding: 0.9rem 0 0;
        overflow: hidden; }
      #wrapper .component.blog-list #blog-list li .news-date {
        font-size: .9rem; }
      #wrapper .component.blog-list #blog-list li .news-text {
        font-size: 1rem;
        padding: 0 1rem;
        height: 105px;
        position: relative; }
        #wrapper .component.blog-list #blog-list li .news-text::after {
          content: "";
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          pointer-events: none;
          background-image: linear-gradient(rgba(255, 255, 255, 0) 57%, #f1f1f1 102%, #f1f1f1);
          width: 100%;
          height: 100%; }
      #wrapper .component.blog-list #blog-list li .news-link {
        font-size: 1.1rem;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        width: 100%; } }

@media only screen and (min-width: 992px) {
  #wrapper .component.blog-list .component-content {
    max-width: 100%; } }

#wrapper .blog--two-col .component.related-blogs .component-content ul li {
  width: 100%;
  background-color: #f1f1f1;
  padding: 0;
  text-align: center; }
  #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-image img {
    max-height: none; }
  #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-title {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2rem;
    margin: 0;
    padding: 0 0 0.8em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    font-size: 1.3rem;
    padding: 1.3rem 1rem 0 1rem;
    line-height: 1.3; }
  #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-date {
    font-size: .8rem;
    display: block;
    color: #bbbbbb;
    padding: .8rem 1rem 0 1rem; }
  #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-text {
    font-size: 1rem;
    max-height: none;
    height: auto;
    padding: .8rem 1rem 0 1rem; }
  #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-link {
    padding: 2rem 1rem 3rem 1rem;
    font-size: 1rem; }
    #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-link a {
      color: #005da9; }
      #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-link a:hover {
        padding-left: 0; }
      #wrapper .blog--two-col .component.related-blogs .component-content ul li .news-link a:after {
        font-family: "retail-icon-font";
        content: "";
        position: relative;
        top: 3px; }

#wrapper .blog--two-col .component.related-blogs-header {
  padding-top: 2rem; }
  #wrapper .blog--two-col .component.related-blogs-header h4 {
    padding-bottom: 1rem; }

#wrapper .component.latest-blog-posts .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .component.latest-blog-posts .latest-blog-headline {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 3rem;
  margin: 0;
  padding: 0 0 0.4em 0;
  line-height: 1;
  color: #005da9;
  display: block;
  text-align: center;
  margin-top: 2rem; }

#wrapper .component.latest-blog-posts ul li {
  background-color: #f1f1f1;
  margin: 2rem 0 0 0;
  font-size: 1.4rem;
  line-height: 1.4;
  text-align: center; }
  #wrapper .component.latest-blog-posts ul li > a {
    position: relative;
    display: block; }
    #wrapper .component.latest-blog-posts ul li > a:hover {
      opacity: 1; }
  #wrapper .component.latest-blog-posts ul li .news-title {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2.5rem;
    margin: 0;
    padding: 0 0 0.6em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    display: block;
    padding: 1.5rem 1rem 1rem 1rem; }
    #wrapper .component.latest-blog-posts ul li .news-title a {
      color: #005da9; }
  #wrapper .component.latest-blog-posts ul li .news-text {
    font-family: "Proxima N W01 Reg", sans-serif;
    padding: .5rem 1rem 1rem 1rem;
    line-height: 1.5;
    margin-top: 0;
    color: #27251F; }
  #wrapper .component.latest-blog-posts ul li .news-date {
    font-family: "Proxima N W01 Reg", sans-serif;
    color: #bbbbbb; }
  #wrapper .component.latest-blog-posts ul li .news-link {
    padding: 0 0 3rem 0;
    margin-top: 0;
    z-index: 1; }
    #wrapper .component.latest-blog-posts ul li .news-link span {
      color: #005da9; }
      #wrapper .component.latest-blog-posts ul li .news-link span:after {
        font-family: "retail-icon-font";
        content: "";
        position: relative;
        top: .3rem;
        left: .3rem; }

@media only screen and (min-width: 768px) {
  #wrapper .component.latest-blog-posts #blog-list {
    margin: 3rem -1.5rem; }
    #wrapper .component.latest-blog-posts #blog-list li {
      margin: 0 0 3rem 0;
      padding: 0;
      background-color: transparent;
      width: 33.33%;
      padding: 0 2%; }
      #wrapper .component.latest-blog-posts #blog-list li > a {
        background-color: #f1f1f1; }
      #wrapper .component.latest-blog-posts #blog-list li .news-image img {
        max-height: none; }
      #wrapper .component.latest-blog-posts #blog-list li .news-title {
        height: 4rem;
        font-size: 1.4rem;
        margin-bottom: .5rem;
        padding: 0.9rem 0 0;
        overflow: hidden; }
      #wrapper .component.latest-blog-posts #blog-list li .news-date {
        font-size: .9rem; }
      #wrapper .component.latest-blog-posts #blog-list li .news-text {
        font-size: 1rem;
        padding: 0 1rem;
        height: 105px;
        position: relative; }
        #wrapper .component.latest-blog-posts #blog-list li .news-text::after {
          content: "";
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          pointer-events: none;
          background-image: linear-gradient(to bottom, transparent 65%, #f1f1f1);
          width: 100%;
          height: 100%; }
      #wrapper .component.latest-blog-posts #blog-list li .news-link {
        font-size: 1.1rem;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        width: 100%; } }

@media only screen and (min-width: 992px) {
  #wrapper .component.latest-blog-posts .component-content {
    max-width: 100%; } }

#wrapper div.component.breadcrumb {
  padding: 1em 0 1em 0 !important; }
  #wrapper div.component.breadcrumb .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper div.component.breadcrumb a {
    text-decoration: none; }
  #wrapper div.component.breadcrumb ol {
    list-style: none;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  #wrapper div.component.breadcrumb .separator {
    padding: 0 .5em;
    display: none; }
  #wrapper div.component.breadcrumb li {
    float: left;
    font-size: 0.9em;
    line-height: 2;
    position: relative; }
    #wrapper div.component.breadcrumb li:not(.home)::before {
      font-family: "Oticon-icon-www";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      content: "\e60c";
      font-size: 1.5em;
      padding-left: 0.2em;
      position: absolute;
      top: 0.2em; }
    #wrapper div.component.breadcrumb li:not(.home) a {
      /*                position: relative;
                          top: -1px;*/
      margin-left: 2em; }
    #wrapper div.component.breadcrumb li.last .separator {
      display: none; }
  #wrapper div.component.breadcrumb.breadcrumb-hide li {
    white-space: nowrap; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide + li.hide {
      display: none; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide a {
      display: none; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide:before {
      content: "...";
      display: inline; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li.hide-full {
      display: none; }
    #wrapper div.component.breadcrumb.breadcrumb-hide li span.icon {
      font-size: 2em;
      width: 2em;
      color: aqua;
      margin: -0.05em -0.5em;
      float: left;
      display: block; }

#wrapper .component.breadcrumb ol li {
  padding: 0;
  font-size: 1em; }
  #wrapper .component.breadcrumb ol li.home::before {
    content: " "; }
  #wrapper .component.breadcrumb ol li:not(.home)::before {
    font-family: "retail-icon-font";
    font-size: 1.4rem;
    content: "";
    color: #b0b0b0;
    top: 0.5rem; }
  #wrapper .component.breadcrumb ol li a {
    color: #005da9; }
  #wrapper .component.breadcrumb ol li > .field-title > span {
    margin-left: 2em;
    font-family: "Proxima N W01 Bold", sans-serif; }

@media only screen and (max-width: 1023px) {
  #wrapper .component.center-list-spot .desktop-list {
    display: none; } }

@media only screen and (min-width: 1024px) {
  #wrapper .component.center-list-spot .mobile-list {
    display: none; } }

#wrapper .component.center-list-spot .mobile-list {
  background-color: #a7d3ac;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  #wrapper .component.center-list-spot .mobile-list .mobile-list-container {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
    #wrapper .component.center-list-spot .mobile-list .mobile-list-container::after {
      content: "";
      font-family: "retail-icon-font";
      position: absolute;
      right: 18px;
      top: 14px;
      font-size: 24px;
      color: #005da9;
      pointer-events: none; }
    #wrapper .component.center-list-spot .mobile-list .mobile-list-container select {
      -webkit-appearance: none;
      -moz-appearance: none; }

#wrapper .component.center-list-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.center-list-spot .component-content .back-to-top {
    float: right;
    font-size: 1rem;
    color: #005da9; }
    #wrapper .component.center-list-spot .component-content .back-to-top:before {
      font-family: "retail-icon-font";
      content: "";
      vertical-align: middle;
      margin-right: .3em;
      line-height: 1.1; }
  #wrapper .component.center-list-spot .component-content .alphabet-pager {
    margin-top: 3rem;
    background-color: #f6f6f6; }
    @media only screen and (max-width: 1023px) {
      #wrapper .component.center-list-spot .component-content .alphabet-pager {
        display: none; } }
    #wrapper .component.center-list-spot .component-content .alphabet-pager .alphabet-letter {
      display: inline-block;
      padding: .7em; }
    #wrapper .component.center-list-spot .component-content .alphabet-pager a.alphabet-letter {
      color: #005da9; }
    #wrapper .component.center-list-spot .component-content .alphabet-pager span.alphabet-letter {
      color: #bbbbbb; }
  #wrapper .component.center-list-spot .component-content .center-list-row {
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 0; }
    #wrapper .component.center-list-spot .component-content .center-list-row .center-list-region {
      font-family: "Proxima N W01 Bold", sans-serif;
      font-size: 2.5rem;
      margin: 0;
      padding: 0 0 0.6em 0;
      line-height: 1;
      color: #005da9;
      display: block;
      border-bottom: 2px solid #f1f1f1;
      padding-bottom: .6em;
      margin-bottom: .8em;
      font-size: 2rem; }
    #wrapper .component.center-list-spot .component-content .center-list-row .center-list-description {
      font-size: 1rem;
      padding-bottom: .6em;
      margin-bottom: .8em;
      line-height: 1.4; }
      #wrapper .component.center-list-spot .component-content .center-list-row .center-list-description * {
        line-height: 1.4; }
    #wrapper .component.center-list-spot .component-content .center-list-row .center-list-item {
      font-size: 1rem;
      display: inline-block;
      padding-bottom: 2rem;
      vertical-align: top;
      width: 25%; }
      @media only screen and (max-width: 1023px) {
        #wrapper .component.center-list-spot .component-content .center-list-row .center-list-item {
          width: 33.333333%; } }
      @media only screen and (max-width: 767px) {
        #wrapper .component.center-list-spot .component-content .center-list-row .center-list-item {
          width: 50%; } }
      #wrapper .component.center-list-spot .component-content .center-list-row .center-list-item .center-name {
        color: #005da9;
        line-height: 1.4; }
      #wrapper .component.center-list-spot .component-content .center-list-row .center-list-item address * {
        line-height: 1.4; }

#wrapper .component.center-locator {
  position: relative; }
  #wrapper .component.center-locator .map-nav-container {
    height: 7.5rem;
    background: #a7d3ac;
    width: 100%; }
    #wrapper .component.center-locator .map-nav-container .map-nav-content {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      width: 37rem;
      position: static; }
      #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav {
        padding-top: 2.5rem; }
        #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search {
          position: relative;
          background-color: #ffffff;
          height: 3rem;
          border-radius: 0 1.5rem 1.5rem 0; }
          @media only screen and (min-width: 1024px) {
            #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search .geo-locator {
              display: none;
              margin-top: 0.8rem;
              margin-right: 0.6rem;
              float: right;
              cursor: pointer; }
              #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search .geo-locator:before {
                font-family: "retail-icon-font";
                content: "";
                color: rgba(0, 93, 169, 0.5);
                font-size: 1.6rem; } }
          #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search input[type=text] {
            padding: 0.8rem 1rem;
            width: 90%;
            height: 3rem; }
            @media only screen and (min-width: 1024px) {
              #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search input[type=text] {
                width: 65%; } }
          #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button {
            background-color: #e40049;
            color: #ffffff;
            display: inline-block;
            padding: 1em 2.5em;
            line-height: 1;
            border-radius: 2em;
            border: 0;
            font-size: 1rem;
            font-family: "Proxima N W01 Bold", sans-serif;
            cursor: pointer;
            width: auto;
            text-decoration: none;
            text-align: center;
            box-sizing: border-box;
            opacity: 1;
            transition: opacity 0.2s;
            -webkit-appearance: none;
            float: right;
            padding: 0 1.7rem;
            letter-spacing: 0.05em;
            font-size: 1rem;
            height: 3rem;
            user-select: none;
            margin-right: -1px; }
            #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button:hover {
              opacity: 0.7; }
            @media only screen and (max-width: 1023px) {
              #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button {
                position: absolute;
                right: 0; } }
            #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button:hover {
              opacity: 1; }
        #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .no-results-message,
        #wrapper .component.center-locator .map-nav-container .map-nav-content .map-top-nav .technical-error-message {
          padding-top: .7em;
          color: #e40049;
          position: absolute;
          display: none; }
  #wrapper .component.center-locator .zoom.control {
    position: absolute;
    right: 0.7rem;
    top: 8.3rem;
    z-index: 1;
    user-select: none;
    text-align: right; }
    #wrapper .component.center-locator .zoom.control > div:not(.marker-filter) {
      width: 2.8rem;
      height: 2.8rem;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
      margin: 0;
      display: inline-block;
      transition: opacity 0.2s;
      margin-left: .2rem;
      user-select: none;
      float: right; }
      #wrapper .component.center-locator .zoom.control > div:not(.marker-filter):after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 1em;
        height: 1em;
        line-height: 1;
        font-family: "retail-icon-font";
        color: #005da9;
        font-size: 2.3em;
        text-align: center; }
      #wrapper .component.center-locator .zoom.control > div:not(.marker-filter):hover {
        background-color: rgba(255, 255, 255, 0.5); }
      #wrapper .component.center-locator .zoom.control > div:not(.marker-filter).zoom-in:after {
        content: ""; }
      #wrapper .component.center-locator .zoom.control > div:not(.marker-filter).zoom-out:after {
        content: ""; }
      #wrapper .component.center-locator .zoom.control > div:not(.marker-filter).locate {
        clear: both;
        margin-top: .2rem;
        display: none; }
        #wrapper .component.center-locator .zoom.control > div:not(.marker-filter).locate:after {
          content: ""; }
  #wrapper .component.center-locator .marker-filter {
    position: relative;
    background-color: #a7d3ac;
    display: none;
    white-space: nowrap; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.center-locator .marker-filter .marker-filter-wrapper {
        position: relative;
        width: 74rem;
        max-width: 90%;
        margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 2rem; } }
    #wrapper .component.center-locator .marker-filter .filter-center,
    #wrapper .component.center-locator .marker-filter .filter-partner {
      width: 48%;
      height: 2.8rem;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
      transition: opacity 0.2s;
      user-select: none;
      padding-left: .5rem;
      display: inline-block;
      box-sizing: border-box;
      white-space: normal; }
      #wrapper .component.center-locator .marker-filter .filter-center:hover,
      #wrapper .component.center-locator .marker-filter .filter-partner:hover {
        background-color: rgba(255, 255, 255, 0.5); }
      #wrapper .component.center-locator .marker-filter .filter-center::before,
      #wrapper .component.center-locator .marker-filter .filter-partner::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        height: 100%;
        display: inline-block;
        content: " "; }
      #wrapper .component.center-locator .marker-filter .filter-center .filter-label,
      #wrapper .component.center-locator .marker-filter .filter-partner .filter-label {
        height: 2.8rem;
        display: table-cell;
        vertical-align: middle;
        padding-left: 2.3rem;
        color: #005da9;
        position: relative;
        padding-left: 4.3rem;
        right: 2.3em; }
      #wrapper .component.center-locator .marker-filter .filter-center > img,
      #wrapper .component.center-locator .marker-filter .filter-partner > img {
        position: absolute;
        top: .6rem;
        left: .9rem;
        height: 1.6rem; }
      #wrapper .component.center-locator .marker-filter .filter-center.off .filter-checkbox,
      #wrapper .component.center-locator .marker-filter .filter-partner.off .filter-checkbox {
        background: url("/design/build/retail/assets/unchecked.png") no-repeat; }
    #wrapper .component.center-locator .marker-filter .filter-center {
      margin-right: 4%; }
      #wrapper .component.center-locator .marker-filter .filter-center:before {
        background-color: #005da9; }
    #wrapper .component.center-locator .marker-filter .filter-partner:before {
      background-color: #333333; }
    #wrapper .component.center-locator .marker-filter .filter-checkbox {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: .9rem;
      background: url("/design/build/retail/assets/checked.png") no-repeat;
      width: 19px;
      height: 23px; }
  #wrapper .component.center-locator .mobile-infowindow {
    background-color: #005da9;
    overflow: hidden; }
    #wrapper .component.center-locator .mobile-infowindow.partner,
    #wrapper .component.center-locator .mobile-infowindow.partner .center-name {
      background-color: #333333; }
      #wrapper .component.center-locator .mobile-infowindow.partner .single-center,
      #wrapper .component.center-locator .mobile-infowindow.partner .center-name .single-center {
        padding-bottom: 2rem; }
    #wrapper .component.center-locator .mobile-infowindow:not(.partner) .partner-details {
      display: none; }
    #wrapper .component.center-locator .mobile-infowindow .single-center {
      padding-top: 2rem;
      padding-bottom: 2rem;
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      margin-top: -100%;
      transition: margin-top .5s; }
    #wrapper .component.center-locator .mobile-infowindow.open .single-center {
      margin-top: 0px; }
  #wrapper .component.center-locator .single-center {
    max-width: 29rem; }
    #wrapper .component.center-locator .single-center .center-name {
      background: #005da9;
      font-size: 1.5rem;
      font-family: "Proxima N W01 Smbd", sans-serif;
      color: #ffffff;
      padding: 0.5rem 0 1.2rem 0; }
      #wrapper .component.center-locator .single-center .center-name a {
        color: #ffffff; }
    #wrapper .component.center-locator .single-center .center-address {
      font-size: 1rem;
      line-height: 1.3;
      display: inline-block;
      white-space: normal;
      color: #ffffff;
      padding-left: 3.8rem;
      position: relative; }
      #wrapper .component.center-locator .single-center .center-address:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.4rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.center-locator .single-center .center-address > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.center-locator .single-center .center-address::before {
        position: absolute;
        left: 0; }
    #wrapper .component.center-locator .single-center .center-phone {
      font-size: 2rem;
      display: block; }
      #wrapper .component.center-locator .single-center .center-phone a {
        font-family: "Proxima N W01 Bold", sans-serif;
        color: #ffffff; }
      #wrapper .component.center-locator .single-center .center-phone:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.4rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.center-locator .single-center .center-phone > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.center-locator .single-center .center-phone::before {
        margin-right: 1rem;
        margin-top: -0.2rem; }
    #wrapper .component.center-locator .single-center .center-directions {
      padding-left: 3.8rem;
      margin: 1rem 0 0.5rem 0;
      font-family: "Proxima N W01 Smbd", sans-serif;
      font-size: 1rem;
      color: #ffffff;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s;
      position: relative; }
      #wrapper .component.center-locator .single-center .center-directions:hover {
        opacity: 0.7; }
      #wrapper .component.center-locator .single-center .center-directions:after {
        font-family: "retail-icon-font";
        color: #ffffff;
        content: "";
        display: inline-block;
        font-size: 1em;
        position: absolute;
        margin-left: 0.2rem;
        top: .3rem; }
    #wrapper .component.center-locator .single-center .center-distance {
      padding-left: 3.8rem;
      margin: .6rem 0;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: .9rem;
      color: #ffffff;
      display: none; }
    #wrapper .component.center-locator .single-center .center-Details {
      position: relative;
      padding-left: 3.8rem;
      margin: 0.3rem 0 1rem 0; }
      #wrapper .component.center-locator .single-center .center-Details a {
        font-family: "Proxima N W01 Smbd", sans-serif;
        font-size: 1rem;
        color: #ffffff; }
    #wrapper .component.center-locator .single-center div.center-Consultation {
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1.5rem; }
      #wrapper .component.center-locator .single-center div.center-Consultation a {
        background-color: #e40049;
        color: #ffffff;
        display: inline-block;
        padding: 1em 2.5em;
        line-height: 1;
        border-radius: 2em;
        border: 0;
        font-size: 1rem;
        font-family: "Proxima N W01 Bold", sans-serif;
        cursor: pointer;
        width: auto;
        text-decoration: none;
        text-align: center;
        box-sizing: border-box;
        opacity: 1;
        transition: opacity 0.2s;
        -webkit-appearance: none;
        padding-left: 3.2em;
        padding-right: 1.4em;
        font-size: 1rem;
        width: 100%;
        padding: 0.8rem 0;
        text-indent: 2em; }
        #wrapper .component.center-locator .single-center div.center-Consultation a:hover {
          opacity: 0.7; }
        #wrapper .component.center-locator .single-center div.center-Consultation a::before {
          font-family: "retail-icon-font";
          display: inline-block;
          content: "";
          color: #ffffff;
          font-size: 1.3rem;
          speak: none;
          font-weight: normal;
          position: absolute;
          margin-left: -1.45em;
          margin-top: -0.22em; }
        #wrapper .component.center-locator .single-center div.center-Consultation a::before {
          font-size: 1.8rem;
          margin-left: -2.45em; }
    #wrapper .component.center-locator .single-center .partner-details {
      padding-left: 3.8rem;
      margin: .6rem 0;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: .9rem;
      color: #ffffff; }
    #wrapper .component.center-locator .single-center .center-general-information {
      font-size: 1rem;
      line-height: 1.3;
      color: #ffffff; }
    #wrapper .component.center-locator .single-center .center-specific-information {
      font-size: 1rem;
      line-height: 1.3;
      color: #ffffff; }
    #wrapper .component.center-locator .single-center .center-info-outer {
      margin-bottom: 1rem; }
      #wrapper .component.center-locator .single-center .center-info-outer:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "i";
        color: #ffffff;
        font-size: 1.3rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle;
        font-family: "Proxima N W01 Reg", sans-serif; }
      #wrapper .component.center-locator .single-center .center-info-outer > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
    #wrapper .component.center-locator .single-center .center-info {
      padding-left: 0.4rem; }
  #wrapper .component.center-locator #map-canvas {
    height: 20rem; }
    #wrapper .component.center-locator #map-canvas .gm-style .gm-style-iw-c {
      padding: 1rem !important; }
    #wrapper .component.center-locator #map-canvas .gm-style .gm-style-iw-t:after {
      background: #005da9 !important; }
    #wrapper .component.center-locator #map-canvas .gm-style.partner {
      /*
                    > div:first-child > div:nth-child(4) > div > div > div:first-child > div:last-child,
                    > div:first-child > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(3) > div > div {
                        background-color: $partners-color !important;
                    }*/ }
      #wrapper .component.center-locator #map-canvas .gm-style.partner .gm-style-iw-t:after {
        background: #333333 !important; }
      #wrapper .component.center-locator #map-canvas .gm-style.partner > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:last-child,
      #wrapper .component.center-locator #map-canvas .gm-style.partner > div:first-child > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(3) > div > div {
        background-color: #333333 !important; }
      #wrapper .component.center-locator #map-canvas .gm-style.partner .single-center {
        max-width: 20rem; }
      #wrapper .component.center-locator #map-canvas .gm-style.partner .center-name {
        background-color: #333333; }
    #wrapper .component.center-locator #map-canvas .gm-style:not(.partner) .partner-details {
      display: none; }
    #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) {
      /* New style start - seems div structure from gmap has changed */
      /* seems div structure from gmap has changed(28-Jun) */
      /* New style end */ }
      #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div {
        margin-top: -1.6rem;
        /*Commented for google map api changes*/
        /*& > button:last-child { //This is the close button

                                        width: 1rem !important;
                                        height: 1rem !important;
                                        right: 1rem !important;
                                        top: 0.5rem !important;
                                        opacity: 1 !important;
                                        @include link-transition();

                                        &:after {
                                            font-family: $retail-icons;
                                            content: $cross;
                                            color: $cl_info_window_font_color;
                                            font-size: 1rem;
                                        }

                                        &:hover {
                                            @include link-hover-important();
                                        }

                                        img {
                                            display: none;
                                        }

                                    }*/ }
        #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div .gm-style-iw {
          text-align: center; }
          #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div .gm-style-iw > div {
            text-align: left;
            overflow: hidden !important; }
            #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div .gm-style-iw > div > div {
              overflow: hidden !important;
              min-width: 19rem; }
        #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:last-child {
          background-color: #005da9 !important;
          border: 0;
          border-radius: 0 !important; }
          #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:last-child > button:last-child {
            width: 1rem !important;
            height: 1rem !important;
            right: 1rem !important;
            top: 1rem !important;
            opacity: 1 !important;
            opacity: 1;
            transition: opacity 0.2s; }
            #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:last-child > button:last-child:after {
              font-family: "retail-icon-font";
              content: "";
              color: #ffffff;
              font-size: 1rem; }
            #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:last-child > button:last-child:hover {
              opacity: 0.7 !important; }
            #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:last-child > button:last-child img {
              display: none !important; }
        #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(2) {
          background-color: transparent !important;
          border-radius: 0 !important;
          box-shadow: none !important; }
        #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(3) {
          display: block !important; }
          #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(3) > div:first-child > div {
            transform: skewX(32.6deg) !important; }
          #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(3) > div:last-child > div {
            transform: skewX(-32.6deg) !important; }
          #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(3) > div > div {
            background-color: #005da9 !important;
            box-shadow: none !important; }
    #wrapper .component.center-locator #map-canvas .gm-style > div > div:nth-child(4) {
      /* OLD styling, but saving it for future reference
                        & > div { // this is a pop-over

                            & > div {
                                margin-top: -1.6rem;

                                & > .gm-style-iw {
                                    text-align: center;
                                    & > div {
                                        text-align: left;
                                        overflow: hidden !important;
                                        & > div {
                                            overflow: hidden !important;
                                            min-width: 19rem;
                                        }
                                    }
                                }

                                & > div:first-child {
                                    & > div:last-child {
                                        //infoWindow
                                        background: $corporate !important;
                                        border: 0;
                                        border-radius: 0 !important;
                                    }
                                    & > div:nth-of-type(2) {
                                        background-color: transparent !important;
                                        border-radius: 0 !important;
                                        box-shadow: none !important;
                                    }
                                    & > div:nth-of-type(3) {
                                        display: block !important;
                                        & > div {
                                            &:first-child {
                                                & > div {
                                                    transform: skewX(32.6deg) !important;
                                                }
                                            }
                                            &:last-child {
                                                & > div {
                                                    transform: skewX(-32.6deg) !important;
                                                }
                                            }
                                            & > div {
                                                background-color: $corporate !important;
                                                box-shadow: none !important;
                                            }
                                        }
                                    }
                                }

                                & > div:last-child {

                                        width: 1rem !important;
                                        height: 1rem !important;
                                        right: 1rem !important;
                                        top: 0.5rem !important;
                                        opacity: 1 !important;
                                        @include link-transition();

                                        &:after {
                                            font-family: $retail-icons;
                                            content: $cross;
                                            color: $cl_info_window_font_color;
                                            font-size: 1rem;
                                        }

                                        &:hover {
                                            @include link-hover-important();
                                        }

                                        img {
                                            display: none;
                                        }

                                    }
                            }
                        }
                        //OLD styling end
                        */ }
  #wrapper .component.center-locator.center-locator-distance .single-center .center-distance {
    display: block; }

@media only screen and (min-width: 1024px) {
  /*desktop */
  #wrapper .component.center-locator #map-canvas {
    /*this is a minimum height, if there is space a larger one is applied with JS */
    height: 460px; }
  #wrapper .component.center-locator .mobile-infowindow {
    display: none; }
  #wrapper .component.center-locator .marker-filter {
    position: absolute;
    right: 0.7rem;
    top: 14.4rem;
    text-align: left;
    z-index: 1;
    background-color: transparent; }
    #wrapper .component.center-locator .marker-filter .filter-center,
    #wrapper .component.center-locator .marker-filter .filter-partner {
      float: left;
      clear: both;
      width: 100%; }
    #wrapper .component.center-locator .marker-filter .filter-center {
      margin-bottom: .3rem;
      margin-right: 0; }
    #wrapper .component.center-locator .marker-filter .filter-label {
      height: 2.6rem;
      font-family: "Proxima N W01 Bold", sans-serif; } }

html[lang=sv-SE] #wrapper .component.center-locator .single-center .center-phone a {
  opacity: 1; }

@media only screen and (min-width: 2000px) {
  #wrapper .component.center-locator .single-center .center-name {
    font-size: 1.2rem; }
  #wrapper .component.center-locator .single-center .center-address {
    font-size: 1rem; }
    #wrapper .component.center-locator .single-center .center-address:before {
      font-size: 1.2rem; }
  #wrapper .component.center-locator .single-center .center-phone {
    font-size: 1.5rem; }
    #wrapper .component.center-locator .single-center .center-phone:before {
      font-size: 1.2rem; }
  #wrapper .component.center-locator .single-center .center-directions {
    font-size: 1rem; }
    #wrapper .component.center-locator .single-center .center-directions:after {
      font-size: 1em; }
  #wrapper .component.center-locator .single-center .center-distance {
    font-size: 0.8rem; }
  #wrapper .component.center-locator .single-center .center-Details a {
    font-size: 1rem; }
  #wrapper .component.center-locator .single-center div.center-Consultation {
    margin-bottom: 0.5rem; }
    #wrapper .component.center-locator .single-center div.center-Consultation a {
      font-size: 1rem; }
      #wrapper .component.center-locator .single-center div.center-Consultation a::before {
        font-size: 1.4rem; } }

@media only screen and (max-width: 767px) {
  #wrapper .component.center-locator .single-center .center-directions:after {
    top: .15rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #wrapper .component.center-locator .single-center .center-directions:after {
    top: .15rem; } }

#wrapper .component.center-regions-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 2.5rem; }

#wrapper .component.center-regions-spot .regions {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  color: #005da9;
  font-weight: 600; }

#wrapper .component.center-regions-spot .region-list {
  background-color: #e6e6e6;
  padding: 1rem;
  column-count: 4;
  column-gap: 2rem; }

#wrapper .component.center-regions-spot .center-region-item {
  line-height: 1.5;
  font-weight: 400; }
  #wrapper .component.center-regions-spot .center-region-item:hover {
    opacity: .7; }

#wrapper .component.center-regions-spot .map {
  width: 20%;
  height: 20%;
  margin-left: 4.5rem;
  display: inline-block; }

@media screen and (max-width: 1024px) {
  #wrapper .component.center-regions-spot .center-regions-content {
    display: none; } }

.staff-lightbox .component-content > ul > li:hover {
  cursor: pointer; }

.staff-lightbox .staff-description-long {
  display: none; }
  @media only screen and (max-width: 601px) {
    .staff-lightbox .staff-description-long {
      display: block; } }

.staff-lightbox .staff-description-short {
  display: block; }
  @media only screen and (max-width: 601px) {
    .staff-lightbox .staff-description-short {
      display: none; } }

.staff-lightbox-container {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3rem;
  display: none; }
  @media only screen and (max-width: 601px) {
    .staff-lightbox-container {
      display: none !important; } }
  .staff-lightbox-container .staff-lightbox-content {
    width: 40rem;
    min-height: 13rem;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    max-height: 75%;
    overflow: auto; }
    .staff-lightbox-container .staff-lightbox-content .staff-lightbox-close:hover {
      cursor: pointer; }
    .staff-lightbox-container .staff-lightbox-content .staff-lightbox-close::after {
      font-family: "retail-icon-font";
      content: '\EAE3';
      color: #005da9;
      font-size: 1.5rem;
      position: absolute;
      font-weight: 600;
      top: 0;
      right: 0;
      padding: 1rem; }
    .staff-lightbox-container .staff-lightbox-content .staff-lightbox-image {
      float: left;
      width: 10rem;
      margin-right: 1rem; }
    .staff-lightbox-container .staff-lightbox-content .staff-lightbox-right-content {
      width: 26rem;
      display: inline-block;
      text-align: justify; }
      .staff-lightbox-container .staff-lightbox-content .staff-lightbox-right-content #staff-lightbox-header-name {
        padding: 0; }

#wrapper .centre-image {
  max-height: 35em;
  overflow: hidden;
  position: relative;
  margin-top: 1em; }
  #wrapper .centre-image img {
    width: 100%; }

#wrapper .component.centre-spot .component-content .centre-content {
  background-color: #ffffff;
  position: relative; }
  #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper {
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper #centre-map .gm-style > .gmnoprint, #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper #centre-map .gm-style > .gm-style-cc {
      display: none; }
    #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button {
      background-color: #e40049;
      color: #ffffff;
      display: inline-block;
      padding: 1em 2.5em;
      line-height: 1;
      border-radius: 2em;
      border: 0;
      font-size: 1rem;
      font-family: "Proxima N W01 Bold", sans-serif;
      cursor: pointer;
      width: auto;
      text-decoration: none;
      text-align: center;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      background-color: #005da9;
      position: absolute;
      left: 50%;
      top: 11rem;
      transform: translateX(-50%);
      z-index: 1;
      padding: 0.5rem 1.5rem;
      cursor: pointer; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button:hover {
        opacity: 0.7; }
    #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address {
      background-color: #005da9;
      padding: 2em;
      position: relative; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address > h4 {
        padding: 0; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-title {
        margin: 0 0 1.6em 0;
        color: #ffffff; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-link,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax {
        margin-bottom: 1em; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address *,
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone *,
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail *,
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours *,
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-link *,
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax * {
          color: #ffffff; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book {
        background-color: #e40049;
        color: #ffffff;
        display: inline-block;
        padding: 1em 2.5em;
        line-height: 1;
        border-radius: 2em;
        border: 0;
        font-size: 1rem;
        font-family: "Proxima N W01 Bold", sans-serif;
        cursor: pointer;
        width: auto;
        text-decoration: none;
        text-align: center;
        box-sizing: border-box;
        opacity: 1;
        transition: opacity 0.2s;
        -webkit-appearance: none;
        padding-left: 3.2em;
        padding-right: 1.4em;
        width: 100%;
        box-sizing: border-box; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book:hover {
          opacity: 0.7; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book::before {
          font-family: "retail-icon-font";
          display: inline-block;
          content: "";
          color: #ffffff;
          font-size: 1.3rem;
          speak: none;
          font-weight: normal;
          position: absolute;
          margin-left: -1.45em;
          margin-top: -0.22em; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.3rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address .address-content {
        max-width: 75%; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address .address-content div > span {
          float: left;
          margin-right: 0.25rem; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone:before,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.3rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone > div:first-child,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .phone-number, #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .fax-number,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .phone-number,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .fax-number {
        font-size: 2rem;
        top: 0.3571rem;
        position: relative;
        display: inline-block;
        font-family: "Proxima N W01 Bold", sans-serif; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .phone-number:not(.fax-number):hover, #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .fax-number:not(.fax-number):hover,
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .phone-number:not(.fax-number):hover,
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .fax-number:not(.fax-number):hover {
          opacity: 0.7;
          transition: opacity ease-in-out .25s; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .fax-number,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .fax-number {
        margin-bottom: 0;
        color: #ffffff; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .phone-description,
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .phone-description {
        margin-left: 3.4rem;
        display: block; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.3rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.3rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-link:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.3rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-link > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours:before {
        font-family: "retail-icon-font";
        display: inline-block;
        content: "";
        color: #ffffff;
        font-size: 1.3rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list {
        max-width: 80%; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list table td {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-top: .3em;
          padding-bottom: .3em; }
          #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list table td:first-child {
            padding-right: 0.4em; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list table tr:last-child td {
          border-bottom: none; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours li {
        padding-bottom: 0.2em; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .center-walkin-information {
        margin-top: 1em; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .center-walkin-information:before {
          font-family: "retail-icon-font";
          display: inline-block;
          content: "";
          color: #ffffff;
          font-size: 1.3rem;
          width: 1.9em;
          height: 1.9em;
          border: 1px solid #ffffff;
          border-radius: 50%;
          text-align: center;
          line-height: 1.9;
          margin-right: 0.35em;
          vertical-align: middle; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .center-walkin-information > div:first-child {
          display: inline-block;
          vertical-align: middle;
          width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .center-specific-information {
        color: white; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .center-general-information {
        color: white; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .center-info-outer {
        margin-bottom: 1rem; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .center-info-outer:before {
          font-family: "retail-icon-font";
          display: inline-block;
          content: "i";
          color: #ffffff;
          font-size: 1.3rem;
          width: 1.9em;
          height: 1.9em;
          border: 1px solid #ffffff;
          border-radius: 50%;
          text-align: center;
          line-height: 1.9;
          margin-right: 0.35em;
          vertical-align: middle;
          font-family: "Proxima N W01 Reg", sans-serif; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .center-info-outer > div:first-child {
          display: inline-block;
          vertical-align: middle;
          width: 75%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .center-info {
        padding-left: 0.4rem; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .iframe-widget {
        margin: 2em -2em -2em;
        background: #ffffff;
        padding-top: 1em; }
    #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper {
      margin-top: 1rem;
      padding: 2em; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
        font-family: "Proxima N W01 Bold", sans-serif;
        font-size: 3rem;
        margin: 0;
        padding: 0 0 0.4em 0;
        line-height: 1;
        color: #005da9;
        display: block; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .intro-description table {
        max-width: 100%; }
    #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper::after {
      clear: both;
      display: table;
      content: "";
      line-height: 0; }

#wrapper .component.centre-spot.center-name-like-h3 .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 2.5rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper .component.centre-spot.center-name-like-h4 .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 2rem;
  margin: 0;
  padding: 0 0 0.8em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper .component.staff {
  opacity: 1;
  position: relative;
  padding-bottom: 2em;
  background: #f1f1f1 url("/design/build/retail/assets/dotted-bg.png") -100px center repeat-x; }
  #wrapper .component.staff .staff-title {
    text-align: center; }
  #wrapper .component.staff .job-title {
    color: #005da9; }
  #wrapper .component.staff .image-spot-elements {
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex; }
    @media only screen and (max-width: 767px) {
      #wrapper .component.staff .image-spot-elements {
        flex-direction: column; } }
    #wrapper .component.staff .image-spot-elements .image-spot-element {
      text-align: center; }
      #wrapper .component.staff .image-spot-elements .image-spot-element .image-spot-element-header {
        margin-bottom: 0.1em; }
        #wrapper .component.staff .image-spot-elements .image-spot-element .image-spot-element-header h4 {
          line-height: 1.2; }

#wrapper .component.center-testimonials .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 4em;
  padding-bottom: 1em; }
  #wrapper .component.center-testimonials .component-content .testimonial-title {
    text-align: center;
    padding-bottom: 3em; }
  #wrapper .component.center-testimonials .component-content .testimonial-items .testimonial-item {
    padding-bottom: 3em; }

@media only screen and (min-width: 601px) {
  #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper #centre-map {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0; }
  #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper {
    display: inline-block;
    width: 50%;
    margin-right: 5%;
    margin-top: 20rem;
    float: right;
    padding: 0; }
    #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
      margin-top: 0.5em; }
  #wrapper .component.centre-spot .component-content .centre-address {
    display: inline-block;
    width: 43%;
    box-sizing: border-box;
    margin-top: 18.75rem; }
  #wrapper .component.center-testimonials .component-content {
    padding-top: 6em;
    padding-bottom: 6em; }
    #wrapper .component.center-testimonials .component-content .testimonial-title {
      padding-bottom: 4em; }
    #wrapper .component.center-testimonials .component-content .testimonial-items {
      white-space: nowrap; }
      #wrapper .component.center-testimonials .component-content .testimonial-items .testimonial-item {
        width: 45%;
        white-space: normal;
        display: inline-block;
        margin-right: 10%;
        vertical-align: top; }
        #wrapper .component.center-testimonials .component-content .testimonial-items .testimonial-item:nth-child(2n) {
          margin-right: 0; } }

@media only screen and (min-width: 1020px) {
  #wrapper .component.centre-spot .component-content .centre-content {
    margin-bottom: 6em; }
    #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper {
      width: 74rem;
      max-width: 90%; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper {
        width: 59%;
        margin-right: 0%;
        margin-top: 21.5rem; }
        #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
          margin-top: 0; }
      #wrapper .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address {
        width: 34%;
        margin-top: 14rem; } }

#wrapper > #content .component.color-spot .component-content {
  padding: 7em 0 0; }

#wrapper > #content .component.color-spot .colors-container .images li img {
  width: 60%;
  height: 60%; }

#wrapper .comments .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .comments .component-content .topCommentsRow:after {
    content: "";
    display: table;
    clear: both; }
  #wrapper .comments .component-content .joinConversation {
    float: left;
    margin: 0 0 1rem 0; }
    #wrapper .comments .component-content .joinConversation a {
      background-color: #e40049;
      color: #ffffff;
      display: inline-block;
      padding: 1em 2.5em;
      line-height: 1;
      border-radius: 2em;
      border: 0;
      font-size: 1rem;
      font-family: "Proxima N W01 Bold", sans-serif;
      cursor: pointer;
      width: auto;
      text-decoration: none;
      text-align: center;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      background-color: #005da9; }
      #wrapper .comments .component-content .joinConversation a:hover {
        opacity: 0.7; }
  #wrapper .comments .component-content .totalComments {
    float: left;
    line-height: 2;
    font-size: 1.3rem;
    padding-left: 1rem; }
  #wrapper .comments .component-content .anonymous-comment-form {
    background: #f1f1f1;
    text-align: center;
    padding: 1rem; }
    #wrapper .comments .component-content .anonymous-comment-form h3 {
      font-size: 1.5rem;
      padding: .5rem 0 1.5rem 0; }
  #wrapper .comments .component-content .comment-form-fields input[type="text"], #wrapper .comments .component-content .comment-form-fields textarea {
    margin: 0 0 1rem 0;
    font-family: "Proxima N W01 Reg", sans-serif;
    padding: 1rem; }
  #wrapper .comments .component-content .comment-form-fields textarea {
    height: 10rem; }
  #wrapper .comments .component-content .comment-form-fields .captcha {
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
    text-align: left; }
  #wrapper .comments .component-content .anonymous-comment-post {
    text-align: left; }
    #wrapper .comments .component-content .anonymous-comment-post input[type="submit"],
    #wrapper .comments .component-content .anonymous-comment-post .post-button {
      background-color: #e40049;
      color: #ffffff;
      display: inline-block;
      padding: 1em 2.5em;
      line-height: 1;
      border-radius: 2em;
      border: 0;
      font-size: 1rem;
      font-family: "Proxima N W01 Bold", sans-serif;
      cursor: pointer;
      width: auto;
      text-decoration: none;
      text-align: center;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      background-color: #005da9;
      font-size: 1.3rem;
      border-radius: 20px;
      padding: 1rem 2rem;
      margin-top: 1rem; }
      #wrapper .comments .component-content .anonymous-comment-post input[type="submit"]:hover,
      #wrapper .comments .component-content .anonymous-comment-post .post-button:hover {
        opacity: 0.7; }
  #wrapper .comments .component-content .comments-list-content .commentsItem {
    padding: 1rem;
    margin-top: 1rem;
    background: #f1f1f1;
    font-size: 1.3rem; }
    #wrapper .comments .component-content .comments-list-content .commentsItem .userNameText {
      font-style: italic;
      opacity: .5;
      display: block; }

#wrapper .component.content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.content .field-location, #wrapper .component.content .field-date {
    margin-bottom: 0.5em; }
  #wrapper .component.content.top-margin {
    margin-top: 7rem; }

#wrapper .component.content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden; }
  #wrapper .component.content * {
    white-space: normal; }
  #wrapper .component.content .field-title {
    /*@include like-h2();*/
    font-size: 4.7em;
    width: 80%;
    padding-bottom: 0.1em; }
  #wrapper .component.content .field-location {
    display: none; }
  #wrapper .component.content .field-date {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0;
    font-family: "Proxima N W01SC Reg It", sans-serif; }
    @media only screen and (max-width: 479px) {
      #wrapper .component.content .field-date {
        font-size: 1.1rem; } }
  #wrapper .component.content .field-content {
    width: 68%;
    float: left;
    margin-top: 3.1rem; }
  #wrapper .component.content .field-summaryicon {
    width: 28%;
    float: right;
    margin-top: 3.1rem; }
    #wrapper .component.content .field-summaryicon img {
      width: 100%; }

.cookie-warning {
  display: none;
  position: fixed;
  top: 0;
  bottom: auto;
  width: 100%;
  z-index: 1030;
  background-color: #333333;
  border-bottom: 1px solid #bbbbbb; }
  .cookie-warning .component-content {
    color: #f0f0f0;
    padding: .5em; }
    .cookie-warning .component-content .info {
      margin: 0;
      font-size: .8em;
      padding: 0 1.5em 0 .5em; }
    .cookie-warning .component-content .submit {
      font-family: "Oticon-icon-www";
      position: absolute;
      top: .2em;
      right: 0.5em;
      cursor: pointer; }
      .cookie-warning .component-content .submit * {
        line-height: 0;
        margin: 0;
        font-size: .8em; }
      .cookie-warning .component-content .submit::after {
        content: "\eae3";
        position: absolute;
        top: .2rem;
        right: 0;
        color: #005da9; }
      .cookie-warning .component-content .submit a p {
        opacity: 0; }
  .cookie-warning.initialized {
    display: block;
    animation: myAniTop 2s;
    -moz-animation: myAniTop 2s;
    -webkit-animation: myAniTop 2s; }

@media only screen and (max-width: 1020px) {
  .cookie-warning {
    top: auto;
    bottom: 0; }
    .cookie-warning.initialized {
      display: block;
      animation: myAniBottom 2s;
      -moz-animation: myAniBottom 2s;
      -webkit-animation: myAniBottom 2s; } }

@keyframes myAniTop {
  from {
    top: -300px; }
  to {
    top: 0px; } }

@-moz-keyframes myAniTop {
  from {
    top: -300px; }
  to {
    top: 0px; } }

@-webkit-keyframes myAniTop {
  from {
    top: -300px; }
  to {
    top: 0px; } }

@keyframes myAniBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0px; } }

@-moz-keyframes myAniBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0px; } }

@-webkit-keyframes myAniBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0px; } }

@keyframes myAniheader {
  from {
    top: 0px; }
  to {
    top: 42px; } }

@-moz-keyframes myAniheader {
  from {
    top: 0px; }
  to {
    top: 42px; } }

@-webkit-keyframes myAniheader {
  from {
    top: 0px; }
  to {
    top: 42px; } }

.on-page-editor #footer .component.cookie-warning.initialized {
  display: none !important; }

/***************************************************************
 COOKIE INFORMATION BANNER

 Should have the same styling, but shouldn't have the X in the after of the a tag,
 but rather an OK text in the a tag.
 *****************************************************************/
#cookie .cookie-warning .submit::after {
  content: ''; }

#wrapper .component.cookie-warning .component-content .submit {
  font-family: "retail-icon-font"; }
  #wrapper .component.cookie-warning .component-content .submit:after {
    color: #ffffff; }

#wrapper .component.cookie-warning .component-content .info p:last-child {
  padding-bottom: 0; }

.component.data-hierarchy-spot.dataset {
  padding: 2rem 0; }

.component.data-hierarchy-spot.comparison-table .component-content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto; }

.component.data-hierarchy-spot.comparison-table .group {
  border: solid 1px #000;
  border-bottom: none;
  margin-bottom: 2rem; }
  .component.data-hierarchy-spot.comparison-table .group__title {
    text-align: center;
    padding: 1rem; }

.component.data-hierarchy-spot.comparison-table .entry {
  border-bottom: solid 1px #000;
  padding: 1rem 0;
  margin: 0 1rem; }
  .component.data-hierarchy-spot.comparison-table .entry.inactive {
    text-decoration: line-through;
    font-size: 0.9rem; }
  .component.data-hierarchy-spot.comparison-table .entry:last-child {
    margin: 0;
    padding: 1rem; }

.component.data-hierarchy-spot.comparison-table .entry-group {
  color: #fff;
  background: #000;
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
  text-align: center;
  padding: .4rem 0 .4rem 0; }
  .component.data-hierarchy-spot.comparison-table .entry-group .entry {
    border-bottom: none;
    padding: .4rem 0; }
    .component.data-hierarchy-spot.comparison-table .entry-group .entry.price {
      font-size: 2rem;
      padding-bottom: 0;
      color: #fff; }
    .component.data-hierarchy-spot.comparison-table .entry-group .entry.divider-above {
      display: inline-block;
      position: relative; }
      .component.data-hierarchy-spot.comparison-table .entry-group .entry.divider-above:before {
        content: "";
        display: inline-block;
        width: 100%;
        background-color: #fff;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0; }

.component.data-hierarchy-spot.grid-data {
  float: none;
  margin: 0; }
  .component.data-hierarchy-spot.grid-data .component-content .group {
    text-align: center;
    width: 100%;
    border: none;
    border-bottom: solid 1px #b0b0b0; }
    .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(4) {
      border-bottom: none; }
    .component.data-hierarchy-spot.grid-data .component-content .group .inner-container {
      padding: 2rem; }
    .component.data-hierarchy-spot.grid-data .component-content .group .rich-text {
      margin-bottom: 1rem; }
      .component.data-hierarchy-spot.grid-data .component-content .group .rich-text p {
        font-size: 1.5rem;
        color: #c6168d;
        font-weight: bold; }

@media only screen and (min-width: 992px) {
  .component.data-hierarchy-spot.comparison-table .group {
    float: left;
    border-right: none; }
    .component.data-hierarchy-spot.comparison-table .group:last-child {
      border-right: solid 1px #000; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(2),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(2) ~ .group {
      width: 47%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(3),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(3) ~ .group {
      width: 33%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(4),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(4) ~ .groupr {
      width: 22%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(5),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(5) ~ .group {
      width: 18%; }
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(6),
    .component.data-hierarchy-spot.comparison-table .group:first-child:nth-last-child(6) ~ .group {
      width: 15%; }
  .component.data-hierarchy-spot.grid-data .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
    .component.data-hierarchy-spot.grid-data .component-content .group {
      box-sizing: border-box;
      border: solid 1px #b0b0b0;
      float: left;
      width: 50%; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(1), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(2) {
        border-top: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(1), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(3) {
        border-left: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(2), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(4) {
        border-right: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(3), .component.data-hierarchy-spot.grid-data .component-content .group:nth-child(4) {
        border-bottom: none; }
      .component.data-hierarchy-spot.grid-data .component-content .group > .inner-container {
        padding: 0 25%; }
      .component.data-hierarchy-spot.grid-data .component-content .group .entry:not(.rich-text) {
        text-align: left; } }

.component.data-hierarchy-spot.comparison-table .group {
  border-color: #b0b0b0; }

.component.data-hierarchy-spot.comparison-table .entry {
  border-color: #b0b0b0; }

.component.data-hierarchy-spot.comparison-table .entry-group {
  color: #ffffff;
  background: #005da9;
  border-color: #005da9; }
  .component.data-hierarchy-spot.comparison-table .entry-group.highlight-group {
    background: #e40049;
    border-color: #e40049; }
  .component.data-hierarchy-spot.comparison-table .entry-group .entry.price {
    color: #ffffff; }
  .component.data-hierarchy-spot.comparison-table .entry-group .entry.divider-above:before {
    background-color: #ffffff; }

@media only screen and (min-width: 992px) {
  .component.data-hierarchy-spot.comparison-table .group:last-child {
    border-color: #b0b0b0; } }

#wrapper > #content .component.data-hierarchy-spot {
  /* SG
# Feature:Data Hierarchy / Retail - Lexicon

Creates a navigationable list of articles.
Uses a 2-dimensional data hierarchy in Sitecore as source where the first dimension defines the labels
that go in the navigation. The second level are links to Sitecore pages.
Clicking on a label shows the articles in the corresponding group.
```html_example
<div class="component data-hierarchy-spot lexicon"></div>
```

*/
  /* SG
# Feature:Data Hierarchy / Retail - Something else

fdlsfk dsf dsf ds
 */ }
  #wrapper > #content .component.data-hierarchy-spot.lexicon .component-content .letter-row {
    text-align: center; }
    #wrapper > #content .component.data-hierarchy-spot.lexicon .component-content .letter-row .letter {
      display: inline-block;
      margin: 0 2rem;
      cursor: pointer; }
  #wrapper > #content .component.data-hierarchy-spot.lexicon .component-content .article-row {
    display: none; }

#wrapper .component.event-calendar {
  background: #fff;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px; }
  #wrapper .component.event-calendar .fc-view-basicWeek .fc-day > div {
    min-height: 100px !important; }
  #wrapper .component.event-calendar .fc-header .fc-button {
    background: none;
    border-radius: 0;
    border: 1px solid #dedede; }
  #wrapper .component.event-calendar .fc-header {
    margin-bottom: 10px; }
    #wrapper .component.event-calendar .fc-header h2 {
      font-weight: bold; }
    #wrapper .component.event-calendar .fc-header td {
      vertical-align: middle;
      white-space: normal; }
  #wrapper .component.event-calendar .fc-header-left {
    width: 25%;
    vertical-align: bottom; }
  #wrapper .component.event-calendar .fc-header-center {
    width: 40%; }
  #wrapper .component.event-calendar .fc-header-right {
    width: 35%;
    white-space: normal;
    padding: 0px 2px; }
    #wrapper .component.event-calendar .fc-header-right .fc-button {
      margin-bottom: 0px; }
  #wrapper .component.event-calendar .fc-header-title {
    padding: 0px 10px; }
    #wrapper .component.event-calendar .fc-header-title h2 {
      white-space: normal; }
  #wrapper .component.event-calendar .fc-widget-content {
    background: #F5F5F5;
    border: 1px solid white;
    padding: 1px; }
    #wrapper .component.event-calendar .fc-widget-content.fc-other-month {
      background: #E8E8E8; }
  #wrapper .component.event-calendar .fc-day-header {
    background: #E8E8E8;
    border: 1px solid white;
    border-top-width: 3px;
    padding: 1px; }
  #wrapper .component.event-calendar .fc-event {
    border: #736F6E;
    padding: 3px;
    background: #4187ae;
    color: #FFF;
    font-weight: normal; }
  #wrapper .component.event-calendar.compact-mode .fc-header-title h2 {
    margin-bottom: 0;
    padding: 5px 0 0 5px; }
  #wrapper .component.event-calendar.compact-mode .selected-day {
    background: #95bcf2; }
  #wrapper .component.event-calendar.compact-mode .fc-day .fc-day-content {
    padding: 0; }

.calendar-tooltip {
  z-index: 9999;
  position: absolute;
  width: 250px;
  background: White;
  border: 1px solid #736f6e;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  background: #F5F5F5;
  -moz-box-shadow: 3px 3px 8px #736f6e;
  -webkit-box-shadow: 3px 3px 8px #736f6e;
  box-shadow: 3px 3px 8px #736f6e; }
  .calendar-tooltip .arrow {
    position: absolute;
    top: -10px;
    left: 70px;
    height: 10px;
    width: 30px;
    overflow: hidden; }
    .calendar-tooltip .arrow:before {
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      background: #fff;
      border: 1px solid #736f6e;
      position: absolute;
      top: 6px;
      z-index: 0;
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -o-transform: rotate(45deg); }
  .calendar-tooltip a {
    font-size: 12px; }
  .calendar-tooltip p {
    margin-top: 0px; }
  .calendar-tooltip .compact-event {
    margin-bottom: 10px;
    border-top: 1px dashed #736f6e;
    padding-top: 5px; }
    .calendar-tooltip .compact-event:first-child {
      border-top: none;
      padding: 0; }
  .calendar-tooltip .title {
    display: block;
    font-weight: bold;
    font-size: 13px; }
  .calendar-tooltip .description {
    display: block; }
  .calendar-tooltip .link {
    display: block;
    margin-top: 5px; }

#wrapper .component.event-calendar .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.event-calendar .component-content h3 {
    padding-bottom: 0.3em; }
  #wrapper .component.event-calendar .component-content .fc-button {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #a7d3ac;
    color: #005da9;
    background-color: transparent;
    border: 2px solid #a7d3ac; }
    #wrapper .component.event-calendar .component-content .fc-button:hover {
      opacity: 0.7; }
    #wrapper .component.event-calendar .component-content .fc-button:not(:last-child) {
      margin-right: .2em; }
  #wrapper .component.event-calendar .component-content .fc-day-number {
    padding: 0.3em; }
  #wrapper .component.event-calendar .component-content .fc-event {
    padding: 0.3em;
    background-color: #005da9;
    overflow: hidden;
    border-top: 1px solid #f6f6f6; }
    #wrapper .component.event-calendar .component-content .fc-event .fc-event-title {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  #wrapper .component.event-calendar .component-content .fc-day-header {
    background-color: #f0f0f0;
    padding: 0.3em; }
  #wrapper .component.event-calendar .component-content .fc-widget-content {
    background-color: #f6f6f6; }
    #wrapper .component.event-calendar .component-content .fc-widget-content.fc-other-month {
      background-color: #f0f0f0; }
  #wrapper .component.event-calendar .component-content .fc-header-left,
  #wrapper .component.event-calendar .component-content .fc-header-center,
  #wrapper .component.event-calendar .component-content .fc-header-right {
    width: 100%;
    display: block; }
  #wrapper .component.event-calendar .component-content .fc-view-basicWeek .fc-day > div {
    min-height: 4.5em !important; }
  #wrapper .component.event-calendar .component-content .fc-view-month .fc-day > div {
    min-height: 5.5em !important; }
  #wrapper .component.event-calendar .component-content .fc-state-highlight {
    background-color: rgba(167, 211, 172, 0.5); }

.calendar-tooltip {
  border-radius: 0;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  box-shadow: 3px 3px 8px #eeeeee;
  width: 10em; }
  .calendar-tooltip.mobile-left .arrow {
    left: 1em; }
  .calendar-tooltip.mobile-right .arrow {
    left: 8em; }
  .calendar-tooltip .arrow:before {
    border: 1px solid #eeeeee; }
  .calendar-tooltip .description p {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0; }
    @media only screen and (max-width: 479px) {
      .calendar-tooltip .description p {
        font-size: 1.1rem; } }
    .calendar-tooltip .description p:last-child {
      padding-bottom: 0; }
  .calendar-tooltip .title {
    padding-bottom: 0.3em; }
  .calendar-tooltip .link {
    margin-top: 0; }
    .calendar-tooltip .link a {
      padding-top: 1em;
      display: inline-block;
      color: #e40049;
      font-family: "Proxima N W01 Bold", sans-serif;
      text-decoration: none;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s; }
      .calendar-tooltip .link a:hover {
        opacity: 0.7; }

@media only screen and (min-width: 1024px) {
  #wrapper .component.event-calendar .component-content .fc-header-left,
  #wrapper .component.event-calendar .component-content .fc-header-center,
  #wrapper .component.event-calendar .component-content .fc-header-right {
    width: 33.333%;
    display: table-cell; }
  #wrapper .component.event-calendar .component-content .fc-header-right {
    text-align: right; }
  #wrapper .component.event-calendar .component-content .fc-view-basicDay {
    margin-left: 0; }
  #wrapper .component.event-calendar .component-content .fc-view-basicWeek {
    margin-left: 0; }
    #wrapper .component.event-calendar .component-content .fc-view-basicWeek .fc-day > div {
      min-height: 6.5em !important; }
  #wrapper .component.event-calendar .component-content .fc-view-month {
    margin-left: 0; }
    #wrapper .component.event-calendar .component-content .fc-view-month .fc-day > div {
      min-height: 7.5em !important; }
  .calendar-tooltip {
    width: 15em; } }

.event-list ul {
  margin: 10px 0; }

.event-list li {
  background: #ffffff;
  display: block;
  min-width: 40%;
  margin: 10px 0;
  margin-right: 5px; }
  .event-list li:after {
    content: "";
    clear: both;
    display: block; }

.event-list .event-list-time {
  width: 100px;
  padding-left: 5px;
  display: block;
  margin-right: 15px;
  float: left;
  font-weight: bold; }

.event-list .event-list-place {
  padding-left: 18px;
  float: right; }

.event-list .event-list-name,
.event-list .event-list-description,
.event-list .event-list-link,
.event-list .event-list-place {
  margin-left: 120px; }

.event-list .event-list-name a {
  font-size: 16px;
  font-weight: bold; }

.event-list .event-list-link {
  float: right; }

#wrapper .component.event-list .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.event-list .component-content > ul > li {
    white-space: nowrap;
    background-color: #f1f1f1;
    border-top: 1px solid #a7d3ac; }
    #wrapper .component.event-list .component-content > ul > li .event-list-time,
    #wrapper .component.event-list .component-content > ul > li .event-list-place {
      width: 50%;
      padding: 1rem;
      margin: 0;
      box-sizing: border-box;
      float: none;
      display: inline-block;
      white-space: normal; }
    #wrapper .component.event-list .component-content > ul > li .event-list-place {
      text-align: right;
      font-family: "Proxima N W01 Bold", sans-serif;
      vertical-align: top; }
    #wrapper .component.event-list .component-content > ul > li .event-list-place,
    #wrapper .component.event-list .component-content > ul > li .event-list-name,
    #wrapper .component.event-list .component-content > ul > li .event-list-description,
    #wrapper .component.event-list .component-content > ul > li .event-list-link {
      margin-left: 0; }
    #wrapper .component.event-list .component-content > ul > li .event-list-name {
      float: none;
      font-family: "Proxima N W01 Bold", sans-serif;
      font-size: 2rem;
      margin: 0;
      padding: 0 0 0.8em 0;
      line-height: 1;
      color: #005da9;
      display: block;
      background-color: #f6f6f6;
      padding: 1rem 1rem 0 1rem;
      white-space: normal; }
      #wrapper .component.event-list .component-content > ul > li .event-list-name a {
        font-family: "Proxima N W01 Bold", sans-serif;
        font-size: 2rem;
        margin: 0;
        padding: 0 0 0.8em 0;
        line-height: 1;
        color: #005da9;
        display: block;
        font-size: 1em; }
    #wrapper .component.event-list .component-content > ul > li .event-list-description {
      background-color: #f6f6f6;
      padding: .6rem 1rem 0 1rem;
      white-space: normal; }
    #wrapper .component.event-list .component-content > ul > li .event-list-link {
      float: none;
      padding: 0 1em 1em 1rem;
      width: 100%;
      background-color: #f6f6f6;
      box-sizing: border-box; }

#wrapper > #content .grey-bg-dark .component.form .component-content .scfTitleBorder, #wrapper > #content .corporate-bg .component.form .component-content .scfTitleBorder {
  color: #ffffff; }

#wrapper > #content .component.form {
  padding-top: 2em;
  padding-bottom: 2em; }
  #wrapper > #content .component.form.component-ready.animate {
    opacity: 0; }
  #wrapper > #content .component.form .component-content {
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;
    width: 30rem;
    max-width: 90%;
    position: relative;
    /*hide labels and asteriskes */ }
    #wrapper > #content .component.form .component-content .scfSingleLineTextLabel,
    #wrapper > #content .component.form .component-content .scfSectionLegend,
    #wrapper > #content .component.form .component-content .scfEmailLabel,
    #wrapper > #content .component.form .component-content .scfTelephoneLabel,
    #wrapper > #content .component.form .component-content .scfRequired,
    #wrapper > #content .component.form .component-content .scfValidatorRequired,
    #wrapper > #content .component.form .component-content .scfMultipleLineTextLabel {
      display: none; }
    #wrapper > #content .component.form .component-content .scfTitleBorder {
      font-family: "Proxima N W01 Bold", sans-serif;
      font-size: 3rem;
      margin: 0;
      padding: 0 0 0.4em 0;
      line-height: 1;
      color: #005da9;
      display: block;
      white-space: normal;
      text-align: center; }
    #wrapper > #content .component.form .component-content .scfIntroBorder {
      text-align: center;
      padding: 0;
      white-space: normal;
      padding-top: 0.5em; }
    #wrapper > #content .component.form .component-content .scfSectionBorderAsFieldSet {
      border: 0;
      margin: 0;
      padding: 0;
      margin-top: 0.5em; }
    #wrapper > #content .component.form .component-content .scfSectionContent {
      width: auto;
      float: none;
      position: relative; }
      #wrapper > #content .component.form .component-content .scfSectionContent .scfShortDate {
        width: 100%; }
        #wrapper > #content .component.form .component-content .scfSectionContent .scfShortDate label {
          display: block;
          width: 100%; }
        #wrapper > #content .component.form .component-content .scfSectionContent .scfShortDate .scfDatePickerGeneralPanel {
          float: left;
          position: relative; }
          #wrapper > #content .component.form .component-content .scfSectionContent .scfShortDate .scfDatePickerGeneralPanel .ui-icon {
            position: absolute;
            top: 1.25rem;
            right: 1rem; }
    #wrapper > #content .component.form .component-content .scfSectionUsefulInfo {
      white-space: normal; }
    #wrapper > #content .component.form .component-content .scfSectionBorder::after {
      clear: both;
      content: "";
      display: table; }
    #wrapper > #content .component.form .component-content .thirdAvailableWidth {
      width: 36%;
      margin: 0;
      margin-right: 3%; }
      #wrapper > #content .component.form .component-content .thirdAvailableWidth:nth-child(even) {
        margin-right: 0; }
    #wrapper > #content .component.form .component-content .halfAvailableWidth {
      width: 48.5%;
      margin: 0;
      margin-right: 3%; }
      #wrapper > #content .component.form .component-content .halfAvailableWidth ~ .halfAvailableWidth {
        margin-right: 0; }
        #wrapper > #content .component.form .component-content .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth {
          margin-right: 3%; }
          #wrapper > #content .component.form .component-content .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth {
            margin-right: 0; }
            #wrapper > #content .component.form .component-content .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth {
              margin-right: 3%; }
              #wrapper > #content .component.form .component-content .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth ~ .halfAvailableWidth {
                margin-right: 0; }
    #wrapper > #content .component.form .component-content .scfValidationSummary {
      margin: 0;
      padding-top: 0.5em; }
    #wrapper > #content .component.form .component-content .scfEmailGeneralPanel,
    #wrapper > #content .component.form .component-content .scfMultipleLineGeneralPanel,
    #wrapper > #content .component.form .component-content .scfSingleLineGeneralPanel,
    #wrapper > #content .component.form .component-content .scfPasswordGeneralPanel,
    #wrapper > #content .component.form .component-content .scfNumberGeneralPanel,
    #wrapper > #content .component.form .component-content .scfDatePickerGeneralPanel,
    #wrapper > #content .component.form .component-content .scfDateGeneralPanel,
    #wrapper > #content .component.form .component-content .scfRadioButtonListGeneralPanel,
    #wrapper > #content .component.form .component-content .scfFileUploadGeneralPanel,
    #wrapper > #content .component.form .component-content .scfDateSelectorGeneralPanel,
    #wrapper > #content .component.form .component-content .scfCreditCardGeneralPanel,
    #wrapper > #content .component.form .component-content .scfConfirmPasswordGeneralPanel,
    #wrapper > #content .component.form .component-content .scfCaptchaGeneralPanel,
    #wrapper > #content .component.form .component-content .scfTelephoneGeneralPanel,
    #wrapper > #content .component.form .component-content .scfSmsTelephoneGeneralPanel,
    #wrapper > #content .component.form .component-content .scfFooterBorder {
      width: 100%;
      padding: 0;
      margin: 0.8em 0 0.4em 0;
      float: none; }
      @media only screen and (max-width: 767px) {
        #wrapper > #content .component.form .component-content .scfEmailGeneralPanel,
        #wrapper > #content .component.form .component-content .scfMultipleLineGeneralPanel,
        #wrapper > #content .component.form .component-content .scfSingleLineGeneralPanel,
        #wrapper > #content .component.form .component-content .scfPasswordGeneralPanel,
        #wrapper > #content .component.form .component-content .scfNumberGeneralPanel,
        #wrapper > #content .component.form .component-content .scfDatePickerGeneralPanel,
        #wrapper > #content .component.form .component-content .scfDateGeneralPanel,
        #wrapper > #content .component.form .component-content .scfRadioButtonListGeneralPanel,
        #wrapper > #content .component.form .component-content .scfFileUploadGeneralPanel,
        #wrapper > #content .component.form .component-content .scfDateSelectorGeneralPanel,
        #wrapper > #content .component.form .component-content .scfCreditCardGeneralPanel,
        #wrapper > #content .component.form .component-content .scfConfirmPasswordGeneralPanel,
        #wrapper > #content .component.form .component-content .scfCaptchaGeneralPanel,
        #wrapper > #content .component.form .component-content .scfTelephoneGeneralPanel,
        #wrapper > #content .component.form .component-content .scfSmsTelephoneGeneralPanel,
        #wrapper > #content .component.form .component-content .scfFooterBorder {
          display: grid; } }
    #wrapper > #content .component.form .component-content .scfDropListGeneralPanel {
      position: relative;
      width: 100%;
      margin-top: 0; }
      #wrapper > #content .component.form .component-content .scfDropListGeneralPanel .nice-select {
        max-width: 100%; }
        #wrapper > #content .component.form .component-content .scfDropListGeneralPanel .nice-select.scfDropList {
          margin-bottom: 0.8rem; }
    #wrapper > #content .component.form .component-content .scfMultipleLineGeneralPanel {
      margin-top: 0; }
      #wrapper > #content .component.form .component-content .scfMultipleLineGeneralPanel textarea {
        vertical-align: top; }
    #wrapper > #content .component.form .component-content .scfCaptcha input[type="text"] {
      margin: 0; }
    #wrapper > #content .component.form .component-content .scfCaptchaLabel {
      display: none; }
    #wrapper > #content .component.form .component-content .scfCaptchaLimitGeneralPanel,
    #wrapper > #content .component.form .component-content .scfCaptchStrongTextPanel {
      max-width: 100%;
      width: auto; }
    #wrapper > #content .component.form .component-content .scfCaptchStrongTextPanel > input[type="text"] {
      border: 1px solid #7f7f7f; }
    #wrapper > #content .component.form .component-content .scfCaptchaGeneralPanel table input[type="image"] {
      padding-left: 1.5em;
      padding-bottom: 1em;
      margin-left: 5px; }
    #wrapper > #content .component.form .component-content .scfCaptchaGeneralPanel table td:last-of-type > input[type="image"]:last-of-type {
      display: none; }
    #wrapper > #content .component.form .component-content .scfCheckBoxListGeneralPanel {
      width: 100%;
      padding: 0; }
      #wrapper > #content .component.form .component-content .scfCheckBoxListGeneralPanel > .scfCheckBoxList {
        width: 100%;
        table-layout: fixed; }
        #wrapper > #content .component.form .component-content .scfCheckBoxListGeneralPanel > .scfCheckBoxList td {
          padding: 0.2em; }
        #wrapper > #content .component.form .component-content .scfCheckBoxListGeneralPanel > .scfCheckBoxList label {
          white-space: normal;
          float: left; }
      #wrapper > #content .component.form .component-content .scfCheckBoxListGeneralPanel > .scfCheckbox {
        width: 70%; }
        #wrapper > #content .component.form .component-content .scfCheckBoxListGeneralPanel > .scfCheckbox label {
          width: 90%; }
    #wrapper > #content .component.form .component-content .scfCheckbox {
      width: 100%;
      margin-top: 1em; }
    #wrapper > #content .component.form .component-content .scfCheckbox label,
    #wrapper > #content .component.form .component-content .scfCheckBoxList label {
      display: inline-block;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      font-size: 0.9rem;
      line-height: 1.3rem;
      white-space: normal;
      margin-bottom: 0;
      padding-bottom: 0; }
      @media only screen and (max-width: 479px) {
        #wrapper > #content .component.form .component-content .scfCheckbox label,
        #wrapper > #content .component.form .component-content .scfCheckBoxList label {
          font-size: 1.1rem; } }
    #wrapper > #content .component.form .component-content .scfFileUploadLabel {
      width: 100%;
      float: none; }
    #wrapper > #content .component.form .component-content .scfFileUploadGeneralPanel {
      margin-top: 0; }
    #wrapper > #content .component.form .component-content .scfSubmitButtonBorder {
      position: absolute;
      bottom: 0;
      right: 0; }
      #wrapper > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit] {
        background-color: #e40049;
        color: #ffffff;
        display: inline-block;
        padding: 1em 2.5em;
        line-height: 1;
        border-radius: 2em;
        border: 0;
        font-size: 1rem;
        font-family: "Proxima N W01 Bold", sans-serif;
        cursor: pointer;
        width: auto;
        text-decoration: none;
        text-align: center;
        box-sizing: border-box;
        opacity: 1;
        transition: opacity 0.2s;
        -webkit-appearance: none; }
        #wrapper > #content .component.form .component-content .scfSubmitButtonBorder input[type=submit]:hover {
          opacity: 0.7; }
    #wrapper > #content .component.form .component-content .scfCheckboxBorder {
      margin: 0; }
    #wrapper > #content .component.form .component-content .scfFooterBorder {
      white-space: normal;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      font-size: 0.9rem;
      line-height: 1.3rem;
      margin: 0;
      padding: 0;
      display: block; }
      @media only screen and (max-width: 479px) {
        #wrapper > #content .component.form .component-content .scfFooterBorder {
          font-size: 1.1rem; } }
      #wrapper > #content .component.form .component-content .scfFooterBorder * {
        margin: 0;
        padding: 1.5em 0 1em 0;
        width: 50%;
        min-height: 2em; }
    #wrapper > #content .component.form .component-content .scfValidator {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      font-size: 0.9rem;
      line-height: 1.3rem;
      padding: 0.3em 0 0 0;
      margin: 0; }
      @media only screen and (max-width: 479px) {
        #wrapper > #content .component.form .component-content .scfValidator {
          font-size: 1.1rem; } }
    #wrapper > #content .component.form .component-content .scfError,
    #wrapper > #content .component.form .component-content .scfValidationSummary *,
    #wrapper > #content .component.form .component-content .scfSubmitSummary *,
    #wrapper > #content .component.form .component-content .scfValidatorRequired,
    #wrapper > #content .component.form .component-content .scfValidator {
      color: #e40049 !important;
      white-space: normal; }
    #wrapper > #content .component.form .component-content a {
      text-decoration: underline; }
    #wrapper > #content .component.form .component-content input:not([type='checkbox']),
    #wrapper > #content .component.form .component-content input:not([type='submit']),
    #wrapper > #content .component.form .component-content textarea,
    #wrapper > #content .component.form .component-content select,
    #wrapper > #content .component.form .component-content input[type="search"],
    #wrapper > #content .component.form .component-content input[type="tel"],
    #wrapper > #content .component.form .component-content .nice-select {
      font-family: "Proxima N W01 Reg", sans-serif; }
  #wrapper > #content .component.form.grey-bg .component-content .scfTitleBorder,
  #wrapper > #content .component.form.grey-bg .component-content .scfIntroBorder,
  #wrapper > #content .component.form.grey-bg .component-content .scfFooterBorder,
  #wrapper > #content .component.form.grey-bg .component-content a, #wrapper > #content .component.form.grey-bg-dark .component-content .scfTitleBorder,
  #wrapper > #content .component.form.grey-bg-dark .component-content .scfIntroBorder,
  #wrapper > #content .component.form.grey-bg-dark .component-content .scfFooterBorder,
  #wrapper > #content .component.form.grey-bg-dark .component-content a, #wrapper > #content .component.form.corporate-bg .component-content .scfTitleBorder,
  #wrapper > #content .component.form.corporate-bg .component-content .scfIntroBorder,
  #wrapper > #content .component.form.corporate-bg .component-content .scfFooterBorder,
  #wrapper > #content .component.form.corporate-bg .component-content a {
    color: #ffffff; }
  #wrapper > #content .component.form.grey-bg .component-content input[type=text], #wrapper > #content .component.form.grey-bg-dark .component-content input[type=text], #wrapper > #content .component.form.corporate-bg .component-content input[type=text] {
    color: #333333; }
  #wrapper > #content .component.form.inverted-button .component-content .scfSubmitButtonBorder input[type=submit] {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #a7d3ac;
    color: #005da9; }
    #wrapper > #content .component.form.inverted-button .component-content .scfSubmitButtonBorder input[type=submit]:hover {
      opacity: 0.7; }
  #wrapper > #content .component.form.green-bg .component-content .scfTitleBorder,
  #wrapper > #content .component.form.green-bg .component-content .scfIntroBorder,
  #wrapper > #content .component.form.green-bg .component-content .scfFooterBorder,
  #wrapper > #content .component.form.green-bg .component-content a {
    color: #005da9; }
  #wrapper > #content .component.form.green-bg .component-content input[type=text] {
    color: #333333; }
  #wrapper > #content .component.form.align-button .component-content {
    width: 38em; }
    #wrapper > #content .component.form.align-button .component-content .scfSubmitButtonBorder {
      top: 0.8em; }
  #wrapper > #content .component.form.form-wide .component-content {
    width: 38em; }
  #wrapper > #content .component.form.stack-submit-button .component-content .scfSubmitButtonBorder {
    position: static;
    margin-top: 1em;
    text-align: right; }
  #wrapper > #content .component.form.align-button:not(.form-overflow) .component-content .scfSubmitButtonBorder {
    left: 78%;
    right: auto; }
  #wrapper > #content .component.form.form-on-top .component-content {
    width: 38em; }
  #wrapper > #content .component.form.form-on-top:not(.form-overflow) {
    padding-top: 0;
    padding-bottom: 0; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfForm, #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfForm, #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfForm, #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfForm {
      margin-left: 22px; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfSubmitSummary, #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfSubmitSummary, #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfSubmitSummary, #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfSubmitSummary {
      margin-left: -22px;
      padding-left: 22px;
      margin-right: -22px;
      padding-right: 22px; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfFooterBorder, #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfFooterBorder, #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfFooterBorder, #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfFooterBorder {
      padding-bottom: 22px; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).corporate-bg .scfSubmitSummary {
      background-color: #005da9; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).green-bg .scfSubmitSummary {
      background-color: #a7d3ac; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg .scfSubmitSummary {
      background-color: #b0b0b0; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfIntroBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfSectionBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfFooterBorder,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfValidationSummary,
    #wrapper > #content .component.form.form-on-top:not(.form-overflow).grey-bg-dark .scfSubmitSummary {
      background-color: #515151; }
    #wrapper > #content .component.form.form-on-top:not(.form-overflow) .component-content {
      width: 74rem; }
      #wrapper > #content .component.form.form-on-top:not(.form-overflow) .component-content .scfForm {
        position: absolute;
        top: -24em;
        left: 0;
        width: 32em;
        height: auto;
        padding: 1em; }
        #wrapper > #content .component.form.form-on-top:not(.form-overflow) .component-content .scfForm .scfTitleBorder {
          text-align: left;
          margin: 0;
          margin-bottom: 0.6em;
          color: #005da9; }
        #wrapper > #content .component.form.form-on-top:not(.form-overflow) .component-content .scfForm .scfIntroBorder * {
          text-align: left;
          padding-top: 11px; }
        #wrapper > #content .component.form.form-on-top:not(.form-overflow) .component-content .scfForm .scfSubmitButtonBorder {
          margin-right: 22px;
          bottom: 2.4em;
          left: auto;
          right: 0; }
  #wrapper > #content .component.form .scfHiddenFieldBorder {
    display: none; }
  #wrapper > #content .component.form input[type="radio"] {
    box-shadow: inset 0 0 0 12px #ffffff;
    height: 22px;
    width: 22px;
    float: none;
    margin-bottom: 4px;
    vertical-align: middle; }
    #wrapper > #content .component.form input[type="radio"]:checked {
      background-color: #005da9;
      border-color: transparent;
      box-shadow: inset 0 0; }
  #wrapper > #content .component.form .form-spinner {
    height: 2rem;
    width: 2rem;
    margin: 0 auto;
    animation: rotate 0.8s infinite linear;
    border: 0.5rem solid #e40049;
    border-right-color: transparent;
    border-radius: 50%;
    z-index: 10000;
    display: inline-block; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media only screen and (max-width: 601px) {
  #wrapper > #content .component.form .component-content .halfAvailableWidth,
  #wrapper > #content .component.form .component-content .thirdAvailableWidth {
    width: 100%;
    margin: 0;
    float: none; }
  #wrapper > #content .component.form .component-content .scfCheckboxBorder .scfCheckbox {
    margin-top: 0.5em; }
  #wrapper > #content .component.form .component-content .scfCheckBoxListGeneralPanel {
    width: 100%; }
  #wrapper > #content .component.form .component-content .scfCheckBoxList td {
    width: 100%;
    display: block;
    overflow: auto; }
  #wrapper > #content .component.form .component-content .scfFooterBorder * {
    width: 100%;
    padding-bottom: 0; }
  #wrapper > #content .component.form .component-content .scfSubmitButtonBorder {
    position: static;
    margin-top: 0.8em;
    width: 100%;
    float: none; }
  #wrapper > #content .component.form .component-content .scfTitleBorder {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 3rem;
    margin: 0;
    padding: 0 0 0.4em 0;
    line-height: 1;
    color: #005da9;
    display: block; }
  #wrapper > #content .component.form .component-content.align-button .scfSubmitButtonBorder {
    top: auto; } }

@media only screen and (min-width: 768px) {
  #wrapper > #content .two-section-grid > .component-content .component.form {
    padding-top: 0; }
    #wrapper > #content .two-section-grid > .component-content .component.form .scfIntroBorder {
      padding-top: 0; } }

#wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form, #wrapper > #content .component.container.form-two-column .component-content .component.form {
  padding-right: 10%;
  padding-left: 10%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm {
    padding: 0; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfCheckbox, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfCheckbox {
      width: 100%; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .field-hidden, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .field-hidden {
      display: none; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .field-continue-hidden, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .field-continue-hidden {
      padding-bottom: 1rem !important; }
      #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .field-continue-hidden .wffm-continue, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .field-continue-hidden .wffm-continue {
        display: none; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .field-final, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .field-final {
      padding-bottom: 0 !important; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .wffm-continue, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .wffm-continue {
      height: 3.5rem;
      border-radius: 1.75rem;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 100%;
      color: #ffffff;
      font-size: 1.25rem;
      background: #005da9;
      text-decoration: none;
      margin-top: 1rem;
      font-family: "Proxima N W01 Bold", sans-serif; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfRadioButtonListBorder.radios-invalid .scfRadioButtonListGeneralPanel .scfRadioButtonList input, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfRadioButtonListBorder.radios-invalid .scfRadioButtonListGeneralPanel .scfRadioButtonList input {
      border: 2px solid #e40049;
      box-shadow: 0 0 10px #e40049; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDropListLabel, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDropListLabel {
      display: none; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfEmailGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfMultipleLineGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfSingleLineGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfPasswordGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfNumberGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDatePickerGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDateGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfFileUploadGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDateSelectorGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCreditCardGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfConfirmPasswordGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCaptchaGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfTelephoneGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfSmsTelephoneGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDropListGeneralPanel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfFooterBorder, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfEmailGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfMultipleLineGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfSingleLineGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfPasswordGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfNumberGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDatePickerGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDateGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfFileUploadGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDateSelectorGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCreditCardGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfConfirmPasswordGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCaptchaGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfTelephoneGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfSmsTelephoneGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfDropListGeneralPanel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfFooterBorder {
      margin: 0 0 1rem 0; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListGeneralPanel table,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCheckBoxListGeneralPanel table, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListGeneralPanel table,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCheckBoxListGeneralPanel table {
      width: auto; }
      #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListGeneralPanel table tbody tr td,
      #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCheckBoxListGeneralPanel table tbody tr td, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListGeneralPanel table tbody tr td,
      #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCheckBoxListGeneralPanel table tbody tr td {
        padding-right: 1rem; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder label,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCheckBoxListLabel,
    #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListLabel, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder label,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfCheckBoxListLabel,
    #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfForm .scfSectionBorder .scfRadioButtonListLabel {
      width: 90%;
      white-space: normal;
      float: none; }
  #wrapper > #content .component.two-section-grid.form-two-column .component-content .component.form .component-content .scfSubmitButtonBorder, #wrapper > #content .component.container.form-two-column .component-content .component.form .component-content .scfSubmitButtonBorder {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem; }

#wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm {
  background: #ffffff;
  padding: 2rem 2rem 0 2rem;
  border-radius: .5rem; }
  #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSectionContent, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSectionContent {
    font-family: "Proxima N W01 Reg", sans-serif; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSectionContent .scfValidator, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSectionContent .scfValidator {
      float: none; }
  #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder {
    box-sizing: border-box;
    width: 100%; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text],
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel],
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email],
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea,
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text],
    #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel],
    #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email],
    #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea,
    #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select {
      border-radius: .2rem;
      padding: 0 1rem;
      border: 1px solid #D5D5D5;
      background: #f1f1f1;
      height: 3.5rem;
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      font-style: normal; }
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text].field-invalid,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel].field-invalid,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email].field-invalid,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea.field-invalid,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select.field-invalid, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text].field-invalid,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel].field-invalid,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email].field-invalid,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea.field-invalid,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select.field-invalid {
        border-color: #e40049; }
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text].input-readonly,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel].input-readonly,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email].input-readonly,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea.input-readonly,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select.input-readonly, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text].input-readonly,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel].input-readonly,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email].input-readonly,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea.input-readonly,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select.input-readonly {
        background: #e5e5e5; }
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text].input-readonly:hover,
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel].input-readonly:hover,
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email].input-readonly:hover,
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea.input-readonly:hover,
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select.input-readonly:hover, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text].input-readonly:hover,
        #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel].input-readonly:hover,
        #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email].input-readonly:hover,
        #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder textarea.input-readonly:hover,
        #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder select.input-readonly:hover {
          cursor: not-allowed; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .scfDropListGeneralPanel, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .scfDropListGeneralPanel {
      position: relative; }
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .scfDropListGeneralPanel:after, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .scfDropListGeneralPanel:after {
        content: "";
        font-family: "retail-icon-font";
        position: absolute;
        color: #404040;
        font-size: 1rem;
        top: 1.25rem;
        right: 1rem;
        pointer-events: none; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="radio"],
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="checkbox"], #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="radio"],
    #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="checkbox"] {
      border: 1px solid #D5D5D5;
      box-shadow: inset 0 0 0 12px #f1f1f1;
      background-color: #f1f1f1; }
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="radio"]:checked,
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="checkbox"]:checked, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="radio"]:checked,
      #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder input[type="checkbox"]:checked {
        box-shadow: inset 0 0 0 4px #f1f1f1;
        background-color: #005da9;
        border: 1px solid #005da9; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .scfSubmitButtonBorder, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .scfSubmitButtonBorder {
      position: relative; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder *, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder * {
      color: #000000; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder.field-not-last, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder.field-not-last {
      border-radius: .5rem; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container {
      flex-wrap: wrap;
      position: relative; }
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container input[type=text], #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container input[type=text] {
        border-radius: .2rem 0 0 .2rem;
        border-right: 0;
        max-width: 50%; }
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container input[type=button], #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container input[type=button] {
        background: #005da9;
        border: 0;
        color: #ffffff;
        font-size: 1.25rem;
        font-family: "Proxima N W01 Light", sans-serif;
        border-radius: 0 .2rem .2rem 0;
        height: 3.5rem;
        max-width: 50%; }
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container input[type=button]:hover, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container input[type=button]:hover {
          cursor: pointer; }
      #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown {
        position: relative !important;
        top: initial !important;
        left: initial !important;
        padding: 0 !important;
        margin-top: .5rem;
        width: 100%; }
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown select, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown select {
          width: 100%;
          min-height: 10rem;
          font-size: 1rem;
          padding: 0; }
        #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div {
          display: flex;
          margin-top: .5rem !important;
          flex-direction: row-reverse; }
          #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div input[type=button], #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div input[type=button] {
            height: 2.5rem; }
          #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-ok, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-ok {
            margin-right: 0; }
          #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-cancel, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm > div > .scfSectionBorder .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-cancel {
            margin-right: 0;
            border-radius: .25rem 0 0 .25rem;
            background: #ffffff;
            color: #005da9;
            border: 1px solid #005da9; }
  #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSubmitButtonBorder, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSubmitButtonBorder {
    width: 100%;
    padding: .5rem 2rem 2rem 2rem;
    border-radius: 0 0 .5rem .5rem;
    background: #ffffff;
    box-sizing: border-box;
    margin-top: -.1rem; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSubmitButtonBorder input[type="submit"], #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfSubmitButtonBorder input[type="submit"] {
      height: 3.5rem;
      border-radius: 1.75rem;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 100%;
      color: #ffffff;
      font-size: 1.25rem;
      font-family: "Proxima N W01 Bold", sans-serif; }
  #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfFooterBorder, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfFooterBorder {
    background: #ffffff;
    padding: 0 2rem 1rem 2rem;
    box-sizing: border-box; }
    #wrapper > #content .component.two-section-grid.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfFooterBorder *, #wrapper > #content .component.container.form-two-column.form-white-background .component-content .component.form .component-content .scfForm .scfFooterBorder * {
      color: #000000;
      padding: 0;
      width: 100%;
      box-sizing: border-box;
      font-size: 1rem; }

@media (max-width: 991px) {
  #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form {
    padding: 2rem 1rem !important; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form .component-content .scfForm {
      padding: 0; }
      #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form .component-content .scfForm > div > .scfSectionBorder input[type=text],
      #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form .component-content .scfForm > div > .scfSectionBorder input[type=tel],
      #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form .component-content .scfForm > div > .scfSectionBorder input[type=email],
      #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form .component-content .scfForm > div > .scfSectionBorder textarea,
      #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form .component-content .scfForm > div > .scfSectionBorder select {
        font-size: 1.4rem; }
    #wrapper > #content .component.two-section-grid.form-two-column .component-content > div .component.form .component-content .scfSubmitButtonBorder {
      margin-top: 0; } }

#wrapper .component.iframe .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.iframe .component-content iframe {
    width: 100%;
    height: 4000px; }

#wrapper .component.iframe.speech-in-noise-test-size .component-content {
  max-width: 100%; }
  @media only screen and (min-width: 768px) {
    #wrapper .component.iframe.speech-in-noise-test-size .component-content {
      max-width: 90%; } }
  #wrapper .component.iframe.speech-in-noise-test-size .component-content iframe {
    height: 550px; }

#wrapper .component.iframe.hearing-test-demo iframe {
  height: 768px; }

iframe#mnd-iframe {
  margin-top: 4rem; }
  @media only screen and (min-width: 1200px) {
    iframe#mnd-iframe {
      margin-top: 8.5rem; } }

#wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-text, #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-header {
  max-width: 80%;
  margin: auto; }

#wrapper .image-spot:not(.flex-layout) {
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat; }
  #wrapper .image-spot:not(.flex-layout) .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper .image-spot:not(.flex-layout) .component-content {
    overflow: hidden; }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
      clear: both;
      display: none;
      width: 74rem;
      max-width: 90vw; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 691.2px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 921.6px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 288px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 432px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 288px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 511.2px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 337.5px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 600.3px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: portrait) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 372.6px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: landscape) {
        #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
          max-width: 662.4px; } }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-content {
      width: 100%;
      text-align: center;
      border-top: 2px solid #000000;
      padding-top: 2rem; }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-selector {
      background-color: #000000;
      width: 2px;
      height: 20px;
      position: relative;
      left: 50px; }
    #wrapper .image-spot:not(.flex-layout) .component-content .context-container {
      clear: both;
      margin-top: 1rem; }
  #wrapper .image-spot:not(.flex-layout) .image-spot-elements {
    text-align: center;
    position: relative;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element::before {
      display: none; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-text {
      margin-bottom: 1em; }
      #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-text * {
        margin: 0; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-image {
      display: block;
      padding: 0 0 1.3em 0;
      overflow: hidden;
      position: relative; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-header {
      margin-top: 1em;
      line-height: 1em;
      margin-bottom: .5em;
      display: block; }
      #wrapper .image-spot:not(.flex-layout) .image-spot-elements .image-spot-element-header * {
        margin: 0; }
  #wrapper .image-spot:not(.flex-layout).round-spot .image-spot-element-image {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: all, 0.2s;
    -moz-transition: all, 0.2s;
    -o-transition: all, 0.2s;
    transition: all, 0.2s;
    position: relative;
    height: auto; }
  #wrapper .image-spot:not(.flex-layout).round-spot .image-spot-element-image img {
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all, 0.2s;
    -moz-transition: all, 0.2s;
    -o-transition: all, 0.2s;
    transition: all, 0.2s;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative; }
  #wrapper .image-spot:not(.flex-layout).round-spot .image-spot-element-image:hover img {
    /*@include scale(1.1);
        @include rotate(-10);
        @include translate(0,0);
        Not used. AFAIK Note. The three statements override eachother so only the third remains... Making the image make a small flicker.
        */ }
  #wrapper .image-spot:not(.flex-layout).square-spot {
    box-sizing: border-box; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-image img {
      display: inline-block;
      border-bottom: 8px solid #005da9;
      box-sizing: border-box;
      transition: all .2s linear; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-image:hover img {
      -webkit-transform: scale(1.1, 1.1);
      -moz-transform: scale(1.1, 1.1);
      -ms-transform: scale(1.1, 1.1);
      -o-transform: scale(1.1, 1.1);
      transform: scale(1.1, 1.1);
      transform-origin: center;
      border-bottom: 8px solid transparent; }
  #wrapper .image-spot:not(.flex-layout) .image-spot-element {
    padding: 0 0.4em .1em 0.4em;
    box-sizing: border-box; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-element:first-child {
      padding: 0 0.8em 0 0; }
    #wrapper .image-spot:not(.flex-layout) .image-spot-element:last-child {
      padding: 0 0 0 0.8em; }
  #wrapper .image-spot:not(.flex-layout).has-active .image-spot-elements .image-spot-element.active {
    color: #005da9; }
    #wrapper .image-spot:not(.flex-layout).has-active .image-spot-elements .image-spot-element.active .image-spot-element-image img {
      background-color: #005da9; }
  #wrapper .image-spot:not(.flex-layout).slim .component-content {
    width: 60rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper .image-spot:not(.flex-layout).accordion {
    overflow: hidden; }
    #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-elements {
      text-align: inherit; }
    #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) {
      clear: both;
      float: none;
      margin-bottom: 2rem;
      position: relative;
      padding: 0;
      width: 100%; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-header {
        font-family: "Proxima N W01 Bold", sans-serif;
        font-size: 2.5rem;
        margin: 0;
        padding: 0 0 0.6em 0;
        line-height: 1;
        color: #005da9;
        display: block;
        max-width: none;
        text-align: left;
        color: #005da9;
        padding: .5rem 0;
        border-bottom: thin solid #005da9;
        margin-bottom: 2rem;
        cursor: pointer; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .spacer, #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .mask {
        display: none; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-image {
        margin-bottom: 1rem; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-content {
        position: absolute;
        left: 200%;
        opacity: 0; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-text {
        margin: 0;
        max-width: none; }
        #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n) .image-spot-element-text * {
          margin: 0; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n).open {
        margin-bottom: 3rem; }
        #wrapper .image-spot:not(.flex-layout).accordion .component-content li.image-spot-element:nth-child(n).open .image-spot-element-content {
          position: relative;
          left: 0;
          opacity: 1; }
    @media only screen and (min-width: 992px) {
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .spacer {
        display: block;
        width: 0px;
        float: left; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .image-spot-element-image {
        float: left;
        clear: left;
        margin: 0 1rem 0 0; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .image-spot-element-content {
        transition: opacity 600ms ease-in; }
        #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element .image-spot-element-content .mask {
          position: absolute;
          top: -1rem;
          left: 0;
          bottom: -2rem;
          right: 0;
          background-color: #ffffff;
          transition: top 450ms ease-in, left 450ms ease-in; }
      #wrapper .image-spot:not(.flex-layout).accordion .component-content .image-spot-element.open .mask {
        left: 100%; }
      #wrapper .image-spot:not(.flex-layout).accordion.image-right .component-content .image-spot-element .spacer,
      #wrapper .image-spot:not(.flex-layout).accordion.image-right .component-content .image-spot-element .image-spot-element-image {
        float: right; }
      #wrapper .image-spot:not(.flex-layout).accordion.image-right .component-content .image-spot-element .image-spot-element-image {
        clear: right;
        margin: 1rem 0 0 1rem; } }

@media only screen and (min-width: 601px) {
  #wrapper .image-spot:not(.flex-layout) .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10),
    #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context {
    display: none !important; } }

@media only screen and (max-width: 600px) {
  #wrapper .image-spot:not(.flex-layout) .component-content > ul > li {
    width: 100%;
    margin-bottom: 3rem;
    float: none; }
  #wrapper .image-spot:not(.flex-layout) .component-content > ul > li:last-child {
    margin-bottom: 0; }
  #wrapper .image-spot:not(.flex-layout) .component-content > ul .image-spot-element {
    padding: 0; }
  #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10),
    #wrapper .image-spot.no-stack:not(.flex-layout) .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
  #wrapper .image-spot.no-mobile-image .image-spot-element-image {
    display: none; }
  #wrapper .image-spot:not(.no-stack):not(.flex-layout) {
    background-image: none !important; }
    #wrapper .image-spot:not(.no-stack):not(.flex-layout) .context-container {
      display: none !important; }
  #wrapper .image-spot-element-link a {
    margin-top: 0;
    margin-bottom: 4em; } }

#wrapper .image-spot:not(.flex-layout) .clickable:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity, 0.2s; }

#wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-selector {
  height: 0; }

#wrapper .image-spot:not(.flex-layout) .component-content .image-spot-element-context-content {
  border: 0; }

#wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements {
  text-align: left; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
    padding: 0.5em; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-header,
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
    margin-top: 0;
    margin-bottom: 0; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-header * {
    padding: 0.5em 0; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
    text-align: center;
    padding-top: .7em; }
    #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
      position: relative;
      padding-right: 1em;
      font-size: 1.25rem; }
      #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link a::after {
        position: absolute;
        font-family: "retail-icon-font";
        content: "";
        font-weight: bold;
        font-size: 1.2em;
        line-height: 1;
        vertical-align: bottom;
        color: #e40049;
        transition: right 0.1s;
        right: -0.5em; }
      #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element .image-spot-element-link a:hover::after {
        right: -0.8em; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element-context-selector {
    background-color: transparent; }
  #wrapper .image-spot:not(.flex-layout) .component-content .image-spot-elements .image-spot-element-context-content {
    border-top: 0; }

#wrapper .image-spot:not(.flex-layout):not(.as-slider) .image-spot-element-text * {
  padding: 0; }

#wrapper .image-spot:not(.flex-layout):not(.as-slider) .image-spot-element-image {
  text-align: center; }

#wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-link a, #wrapper .image-spot:not(.flex-layout).link-under-image .image-spot-element-link a {
  text-indent: -600em;
  display: inline-block;
  height: 2.7em;
  width: 2.7em; }
  #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-element-link a::before, #wrapper .image-spot:not(.flex-layout).link-under-image .image-spot-element-link a::before {
    font-family: "retail-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 2.2em;
    display: inline-block;
    content: "";
    font-size: 1.2em;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 2.2em;
    height: 2.2em;
    background-color: #a7d3ac;
    border-radius: 50%;
    color: #ffffff;
    text-indent: 0.3em;
    transition: all 0.2s; }

#wrapper .image-spot:not(.flex-layout).square-spot {
  text-align: left; }
  #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element {
    position: relative; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-image {
      padding: 0; }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-image img {
        border-bottom: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-text {
      margin-left: 0;
      max-width: 80%; }
      @media only screen and (min-width: 1200px) {
        #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-text {
          max-width: 85%; } }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-link {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      bottom: -1em;
      padding-top: 0; }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-link a {
        margin-bottom: 0;
        padding-right: 0; }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-link * {
        padding: 0.5em 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header {
      margin-left: 0;
      display: flex;
      align-items: center;
      max-width: 80%; }
      @media only screen and (min-width: 1200px) {
        #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header {
          max-width: 85%; } }
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h3,
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h4,
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h5,
      #wrapper .image-spot:not(.flex-layout).square-spot .image-spot-elements .image-spot-element .image-spot-element-header h6 {
        margin-top: 0.5em; }

@media only screen and (max-width: 479px) {
  #wrapper .image-spot:not(.flex-layout):not(.square-spot):not(.as-slider):not(.as-tab):not(.as-faq):not(.isizeoriginal) .image-spot-element-image img {
    max-width: 16rem;
    max-height: 16rem; } }

#wrapper .image-spot:not(.flex-layout).link-under-image a > .image-spot-element-image {
  text-align: center; }
  #wrapper .image-spot:not(.flex-layout).link-under-image a > .image-spot-element-image::before {
    font-family: "retail-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 2.2em;
    display: inline-block;
    content: "";
    font-size: 1.2em;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 2.2em;
    height: 2.2em;
    background-color: #005da9;
    border-radius: 50%;
    color: #ffffff;
    text-indent: 0.3em;
    transition: all 0.2s;
    bottom: 0;
    top: auto;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1; }
  #wrapper .image-spot:not(.flex-layout).link-under-image a > .image-spot-element-image:hover {
    opacity: 0.7; }

#wrapper .image-spot:not(.flex-layout).link-under-image .image-spot-element-link {
  display: none; }

#wrapper .image-spot:not(.flex-layout).as-tab, #wrapper .image-spot:not(.flex-layout).as-faq {
  background-color: #f0f0f0; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element {
    text-align: center;
    background-color: #dddddd;
    border-right: 1px solid #f0f0f0;
    opacity: 0.4;
    filter: grayscale(100%);
    position: relative; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element > a, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element > a {
      width: 100%;
      box-sizing: border-box;
      opacity: 1;
      display: block;
      padding: 1em; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element.active, #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element:hover, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element.active, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element:hover {
      background-color: #ffffff;
      opacity: 1;
      filter: grayscale(0%);
      transition: .25s ease-in-out; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element:last-child, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element:last-child {
      border-right: none; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
      display: inline-block;
      caption-side: bottom;
      padding-bottom: .5em; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header *, #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header *, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
        font-family: "Proxima N W01 Bold", sans-serif;
        color: #333333; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-text, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
      display: none; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-image, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
      padding: 0 !important;
      width: 100%;
      vertical-align: middle;
      text-align: center; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-image img, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-image img {
        position: relative; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-link, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
      display: none; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .context-container, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .context-container {
    background-color: #ffffff;
    margin-top: 0;
    position: relative;
    top: -1px; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content {
    margin-top: 0;
    padding: 4.6em 0 4.6em 6em;
    box-sizing: border-box; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content img.image-spot-context-img-centered, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content img.image-spot-context-img-centered {
      margin: 0 2rem; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table {
      width: 100%;
      table-layout: fixed; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr {
        width: 100%; }
        #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td {
          /* text */
          /* image */ }
          #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(1), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(1) {
            vertical-align: middle;
            padding-right: 4em; }
            #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(1) h3, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(1) h3 {
              padding-top: 0; }
          #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(2), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(2) {
            text-align: right; }
            #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content table tr td:nth-child(2) img, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content table tr td:nth-child(2) img {
              width: 100% !important;
              height: auto !important; }

#wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element {
  opacity: 0.8; }

#wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content {
  padding: 2em; }

#wrapper .image-spot:not(.flex-layout).as-slider {
  padding: 0; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content {
    width: 100%;
    max-width: 100%; }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul {
      display: block;
      overflow: hidden; }
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element {
        width: 100% !important;
        padding: 0;
        margin: 0; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element:not(:first-child) {
          display: none; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-image {
          padding: 0; }
          #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-image img {
            display: block;
            width: 100%; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-header,
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-link {
          display: none; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0; }
          #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
            position: relative;
            width: 74rem;
            max-width: 90%;
            margin: 0 auto;
            padding-right: 37rem;
            box-sizing: border-box; }
            @media only screen and (min-width: 992px) and (max-width: 1199px) {
              #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
                padding-right: 31rem; } }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs {
      position: absolute;
      bottom: 3.625em;
      left: 0;
      right: 0; }
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content {
        position: relative;
        width: 74rem;
        max-width: 90%;
        margin: 0 auto;
        text-align: center; }
        #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-left: .75em;
          border-radius: 50%;
          background-color: #003765;
          cursor: pointer;
          user-select: none; }
          #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab.active-tab, #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab:hover {
            background-color: #ffffff; }
  #wrapper .image-spot:not(.flex-layout).as-slider.slider-white-text .component-content > ul > li.image-spot-element .slide-content p {
    color: white; }
  #wrapper .image-spot:not(.flex-layout).as-slider a, #wrapper .image-spot:not(.flex-layout).as-slider a span {
    transition: none !important; }

#wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
  padding: 0;
  text-align: center; }

#wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
  margin-top: -2.5em;
  margin-bottom: 1em;
  position: relative; }
  #wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #005da9; }
    #wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a:hover {
      opacity: 0.7; }
    @media only screen and (min-width: 0px) {
      #wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a {
        margin-bottom: 0; } }

#wrapper .image-spot:not(.flex-layout).corporate-link .image-spot-elements .image-spot-element .image-spot-element-link a,
#wrapper .image-spot:not(.flex-layout).corporate-link .image-spot-elements .image-spot-element .image-spot-element-link a::after {
  color: #005da9;
  font-size: 1.25rem; }

#wrapper .image-spot:not(.flex-layout).green-link .image-spot-elements .image-spot-element .image-spot-element-link a,
#wrapper .image-spot:not(.flex-layout).green-link .image-spot-elements .image-spot-element .image-spot-element-link a::after {
  color: #a7d3ac; }

#wrapper .image-spot:not(.flex-layout).text-link-under-image .component-content .image-spot-elements .image-spot-element .image-spot-element-link a::after {
  content: none; }

#wrapper .image-spot:not(.flex-layout).image-text-left-aligned .image-spot-element-text {
  text-align: center; }

#wrapper .image-spot.image-description-bg-corporate:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #005da9;
  color: #ffffff; }

#wrapper .image-spot.image-description-bg-green:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #a7d3ac;
  color: inherit; }

#wrapper .image-spot.image-description-bg-green-dark:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #96bd9a;
  color: inherit; }

#wrapper .image-spot.image-description-bg-nounce-green:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #d3e9d5;
  color: inherit; }

#wrapper .image-spot.image-description-bg-nounce-blue:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #68afd6;
  color: inherit; }

#wrapper .image-spot.image-description-bg-light-grey:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #f1f1f1;
  color: inherit; }

#wrapper .image-spot.image-description-bg-light-red:not(.flex-layout) .image-spot-elements .image-spot-element .image-spot-element-image .image-spot-element-image-description {
  background-color: #ec4d80;
  color: inherit; }

@media only screen and (min-width: 601px) {
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2) ~ li {
    width: 47%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(2) ~ li:not(:last-child) {
      margin-right: 6%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li {
    width: 29%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li:not(:last-child) {
      margin-right: 6.5%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li {
    width: 29%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(3) ~ li:not(:last-child) {
      margin-right: 6.5%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4) ~ li {
    width: 22%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(4) ~ li:not(:last-child) {
      margin-right: 4%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5) ~ li {
    width: 18%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(5) ~ li:not(:last-child) {
      margin-right: 2.5%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6) ~ li {
    width: 15%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(6) ~ li:not(:last-child) {
      margin-right: 1.6666%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7),
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li {
    width: 12.5%;
    padding: 0; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7):not(:last-child),
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li:not(:last-child) {
      margin-right: 2%; }
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) .image-spot-element-header,
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) .image-spot-element-text,
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li .image-spot-element-header,
    #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li:first-child:nth-last-child(7) ~ li .image-spot-element-text {
      max-width: 80%; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li .image-spot-element-image {
    padding: 0; }
  #wrapper .image-spot:not(.flex-layout).square-spot .component-content > ul > li .image-spot-element-text {
    margin-bottom: 0; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
    padding-bottom: 0; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element .image-spot-element-header *, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element .image-spot-element-header * {
      padding-top: 0; } }

@media only screen and (min-width: 601px) and (max-width: 1020px) {
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-element-context-content, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-element-context-content {
    padding: 3em 0 3em 3em; } }

@media only screen and (max-width: 1020px) {
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
    padding-right: 0;
    /** {
                                    max-width: 100%;
                                }*/ }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slider-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(221, 221, 221, 0.4); }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    margin-top: .8em; }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab.active-tab, #wrapper .image-spot:not(.flex-layout).as-slider .component-content .slider-tabs .tabs-content .slider-tab:hover {
      background-color: #a7d3ac; }
  #wrapper .image-spot:not(.flex-layout).as-slider.slider-white-text .component-content > ul > li.image-spot-element .slider-overlay {
    background-color: rgba(51, 51, 51, 0.6); } }

@media only screen and (max-width: 479px) {
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-image .image-spot-element-image-description {
    margin-top: -2px; }
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-context-content table,
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-context-content tr,
  #wrapper .image-spot:not(.flex-layout) .image-spot-element-context-content td {
    display: block; }
  #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context {
    width: 100%; }
    #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content {
      padding: 2em 2em 2em 2em;
      box-sizing: border-box;
      width: 100%;
      background-color: #ffffff; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content img.image-spot-context-img-centered, #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content img.image-spot-context-img-centered {
        margin: 0 0 2rem 0; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(1), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(1) {
        vertical-align: top;
        padding: 0 0 1em 0; }
      #wrapper .image-spot:not(.flex-layout).as-tab .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(2), #wrapper .image-spot:not(.flex-layout).as-faq .component-content .image-spot-elements .image-spot-element-context .image-spot-element-context-content table tr td:nth-child(2) {
        margin-right: -2em; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .image-spot-element-image img {
    display: none; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content {
    position: static;
    top: auto;
    transform: none; }
    #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text {
      padding-top: 1em;
      padding-bottom: 1em; }
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text .btn,
      #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slide-content .image-spot-element-text [class*="btn-"] {
        margin-bottom: 1rem; }
  #wrapper .image-spot:not(.flex-layout).as-slider .component-content > ul > li.image-spot-element .slider-overlay {
    background-color: rgba(221, 221, 221, 0.4); }
  #wrapper .image-spot:not(.flex-layout).as-slider.slider-white-text .component-content > ul > li.image-spot-element .slider-overlay {
    background-color: rgba(51, 51, 51, 0.7); } }

@media only screen and (min-width: 601px) {
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(-n+10),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(-n+10) ~ li {
    float: left;
    width: auto; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(1) {
    max-width: 100%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(2),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(2) ~ li {
    max-width: 50%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(3),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(3) ~ li {
    max-width: 33.3333%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(4),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(4) ~ li {
    max-width: 25%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(5),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(5) ~ li {
    max-width: 20%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(6),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(6) ~ li {
    max-width: 16.6666%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(7),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(7) ~ li {
    max-width: 14.2857%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(8),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(8) ~ li {
    max-width: 12.5%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(9),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(9) ~ li {
    max-width: 11.1111%; }
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(10),
  #wrapper .image-spot.image-spot-align-left:not(.flex-layout) .component-content ul > li:first-child:nth-last-child(10) ~ li {
    max-width: 10%; } }

#wrapper .intro-banner:not(.flex-layout) {
  padding: 0;
  margin: 0;
  width: 100% !important;
  max-width: 100% !important;
  position: relative;
  overflow: hidden; }
  #wrapper .intro-banner:not(.flex-layout) .component-content {
    position: relative; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-image {
    display: none; }
    #wrapper .intro-banner:not(.flex-layout) .intro-banner-image img {
      top: 0;
      left: 0;
      width: 100%;
      background-size: cover;
      background-position: 50% 0%;
      background-repeat: no-repeat;
      display: block; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-video {
    width: 100%;
    height: auto; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    #wrapper .intro-banner:not(.flex-layout) .intro-banner-content .intro-banner-text > *:first-child {
      margin-top: 0;
      padding-top: 0; }
  #wrapper .intro-banner:not(.flex-layout).img-fallback .intro-banner-image {
    display: inherit; }
  #wrapper .intro-banner:not(.flex-layout).img-fallback .intro-banner-video {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout).img-fallback .intro-banner-video-external {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout) .yt-player.hidden {
    visibility: hidden; }
  #wrapper .intro-banner:not(.flex-layout) .sticky-downlink {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }
    #wrapper .intro-banner:not(.flex-layout) .sticky-downlink p {
      margin-bottom: 0;
      margin-top: 0; }
    #wrapper .intro-banner:not(.flex-layout) .sticky-downlink a {
      margin-bottom: 2rem;
      margin-top: 0; }
  #wrapper .intro-banner:not(.flex-layout).slim .component-content {
    width: 100%;
    max-width: 100%;
    margin: 0; }
    #wrapper .intro-banner:not(.flex-layout).slim .component-content .outer-pos-wrapper {
      width: 60rem;
      max-width: 90%;
      margin: 0 auto; }
  #wrapper .intro-banner:not(.flex-layout):not(.has-list-spot) .intro-banner-list-spot-container {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 29.6rem;
    max-width: 36%;
    -webkit-transform: translateX(7.4rem);
    -moz-transform: translateX(7.4rem);
    -ms-transform: translateX(7.4rem);
    -o-transform: translateX(7.4rem);
    transform: translateX(7.4rem);
    color: #000000 !important; }
    #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
      box-sizing: border-box;
      border-left: 1em solid #005da9;
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding: 3rem;
      right: 0; }
      #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content ul {
        padding: 0; }
        #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content ul li:last-child {
          margin-bottom: 0;
          padding-bottom: 0; }
          #wrapper .intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content ul li:last-child *:last-child {
            margin-bottom: 0; }
  #wrapper .intro-banner:not(.flex-layout) .intro-banner-text {
    display: inline-block;
    width: 100%; }
  #wrapper .intro-banner:not(.flex-layout).wide-image .component-content .intro-banner-image img {
    height: auto;
    width: 100%; }
  #wrapper .intro-banner:not(.flex-layout).tall-image .component-content .intro-banner-image img {
    height: 100%;
    width: auto; }
  #wrapper .intro-banner:not(.flex-layout).wide-video .component-content .intro-banner-video {
    height: auto;
    width: 100%; }
  #wrapper .intro-banner:not(.flex-layout).tall-video .component-content .intro-banner-video {
    height: 100%;
    width: auto; }
  #wrapper .intro-banner:not(.flex-layout).slide-index::after {
    font-size: 2rem;
    background-color: #ffffff;
    opacity: .8;
    padding: 2rem;
    content: attr(data-index);
    position: absolute;
    bottom: 0;
    right: 0; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-content, #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-link, #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .sticky-downlink {
    position: static; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-content {
    margin-top: 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .sticky-downlink {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: 1rem 5% 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 90%; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    position: static;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: 1rem 5% 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper {
    width: 74rem !important;
    max-width: 90% !important; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > * {
      margin-bottom: 0; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      max-width: 100% !important;
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      color: black;
      position: static;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none; }
      #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text * {
        margin-top: 0;
        margin-bottom: 1rem; }
        #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:first-child:not(li):not(a) {
          margin-top: 1rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    position: static; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
      position: relative;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
      width: 100%;
      background-color: #f5f5f5;
      margin-bottom: 2rem; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-text td {
    width: 100% !important;
    display: block; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-text li {
    text-align: left; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right,
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down {
    font-size: 1.8em;
    width: 1em;
    height: 1em;
    line-height: 1;
    border: 2px solid #f0f0f0;
    display: inline-block;
    overflow: hidden;
    color: #f0f0f0;
    padding: 0.4em;
    border-radius: 50%;
    position: relative;
    margin: 0.7em auto; }
    @media only screen and (min-width: 992px) {
      #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right,
      #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down {
        border: 3px solid #f0f0f0; } }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right:hover,
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down:hover {
      background-color: #f0f0f0;
      color: #ffffff; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right span,
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down span {
      font-family: "Oticon-icon-www";
      color: #f0f0f0;
      text-decoration: none;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      position: absolute;
      top: 0.1em;
      left: 0;
      bottom: 0;
      right: 0;
      width: 1em;
      height: 1em;
      line-height: 1;
      margin: auto;
      animation: cta-down 0.4s; }
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-right:hover span,
    #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) a.disc-down:hover span {
      color: #ffffff;
      animation: cta-down-hover 0.4s; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile) .intro-banner-link a {
    color: #005da9 !important; }
  #wrapper .intro-banner:not(.flex-layout):not(.slide-on-mobile).content-overflow:not(.hide-text-mobile).hide-image-mobile .intro-banner-image {
    display: none; }
  #wrapper .intro-banner:not(.flex-layout).content-overflow.hide-text-mobile .intro-banner-content {
    display: none; }

#wrapper .intro-banner:not(.flex-layout).parallax .parallax-image {
  overflow: hidden; }
  #wrapper .intro-banner:not(.flex-layout).parallax .parallax-image .parallax-image-back {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    transform: translateZ(-1px) scale(1.1);
    z-index: -1;
    width: 100%;
    height: 100%; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-content, #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-link, #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .sticky-downlink {
  position: static; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-content {
  margin-top: 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .sticky-downlink {
  display: none; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: 1rem 5% 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 90%; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  position: static;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: 1rem 5% 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper {
  width: 74rem !important;
  max-width: 70% !important; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > * {
    margin-bottom: 0; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100% !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: black;
    position: static;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
    #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text * {
      margin-top: 0;
      margin-bottom: 1rem; }
      #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:first-child:not(li):not(a) {
        margin-top: 1rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  position: static; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
    position: relative;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: 2rem; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-text td {
  width: 100% !important;
  display: block; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-text li {
  text-align: left; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right,
#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down {
  font-size: 1.8em;
  width: 1em;
  height: 1em;
  line-height: 1;
  border: 2px solid #f0f0f0;
  display: inline-block;
  overflow: hidden;
  color: #f0f0f0;
  padding: 0.4em;
  border-radius: 50%;
  position: relative;
  margin: 0.7em auto; }
  @media only screen and (min-width: 992px) {
    #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right,
    #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down {
      border: 3px solid #f0f0f0; } }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right:hover,
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down:hover {
    background-color: #f0f0f0;
    color: #ffffff; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right span,
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down span {
    font-family: "Oticon-icon-www";
    color: #f0f0f0;
    text-decoration: none;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    position: absolute;
    top: 0.1em;
    left: 0;
    bottom: 0;
    right: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    margin: auto;
    animation: cta-down 0.4s; }
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-right:hover span,
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) a.disc-down:hover span {
    color: #ffffff;
    animation: cta-down-hover 0.4s; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .intro-banner-link a {
  color: #005da9 !important; }

#wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile).hide-image-mobile .intro-banner-image {
  display: none; }

#wrapper .intro-banner .component-content .outer-pos-wrapper {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  min-height: 100%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    width: 100%; }

#wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 10%;
  width: 10%; }

#wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 20%;
  width: 20%; }

#wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 30%;
  width: 30%; }

#wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 40%;
  width: 40%; }

#wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 50%;
  width: 50%; }

#wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 60%;
  width: 60%; }

#wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 70%;
  width: 70%; }

#wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 80%;
  width: 80%; }

#wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 90%;
  width: 90%; }

#wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  left: 0;
  padding: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute; }

#wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  left: inherit; }

#wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  right: 0; }

#wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 45%; }

#wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 40%; }

#wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 35%; }

#wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 30%; }

#wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 25%; }

#wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 20%; }

#wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 15%; }

#wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 10%; }

#wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 5%; }

#wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  top: 2rem;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

#wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  bottom: 2rem;
  top: inherit;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

@media only screen and (max-width: 1150px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 19%;
    width: 19%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 28%;
    width: 28%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 37%;
    width: 37%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 46%;
    width: 46%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 55%;
    width: 55%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 64%;
    width: 64%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 73%;
    width: 73%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 91%;
    width: 91%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 40.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 36%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 31.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 27%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 22.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 18%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 13.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 1100px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 28%;
    width: 28%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 36%;
    width: 36%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 44%;
    width: 44%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 52%;
    width: 52%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 60%;
    width: 60%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 68%;
    width: 68%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 84%;
    width: 84%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 36%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 32%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 28%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 24%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 20%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 16%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 8%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 1050px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 37%;
    width: 37%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 44%;
    width: 44%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 51%;
    width: 51%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 58%;
    width: 58%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 65%;
    width: 65%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 72%;
    width: 72%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 79%;
    width: 79%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 86%;
    width: 86%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 93%;
    width: 93%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 31.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 28%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 24.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 21%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 17.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 14%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 1000px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 46%;
    width: 46%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 52%;
    width: 52%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 58%;
    width: 58%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 64%;
    width: 64%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 70%;
    width: 70%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 27%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 24%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 21%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 18%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 15%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 950px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 55%;
    width: 55%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 60%;
    width: 60%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 65%;
    width: 65%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 70%;
    width: 70%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 75%;
    width: 75%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 80%;
    width: 80%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 85%;
    width: 85%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 90%;
    width: 90%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 95%;
    width: 95%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 22.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 20%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 17.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 15%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 5%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 900px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 64%;
    width: 64%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 68%;
    width: 68%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 72%;
    width: 72%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 80%;
    width: 80%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 84%;
    width: 84%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 96%;
    width: 96%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 18%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 16%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 14%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 8%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 950px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 73%;
    width: 73%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 76%;
    width: 76%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 79%;
    width: 79%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 85%;
    width: 85%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 91%;
    width: 91%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 97%;
    width: 97%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 13.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 12%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 10.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 800px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 82%;
    width: 82%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 84%;
    width: 84%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 86%;
    width: 86%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 88%;
    width: 88%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 90%;
    width: 90%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 96%;
    width: 96%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 98%;
    width: 98%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 9%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 8%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 7%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 6%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 750px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 91%;
    width: 91%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 92%;
    width: 92%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 93%;
    width: 93%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 94%;
    width: 94%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 95%;
    width: 95%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 96%;
    width: 96%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 97%;
    width: 97%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 98%;
    width: 98%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 99%;
    width: 99%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4.5%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 4%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3.5%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 3%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2.5%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 2%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1.5%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 1%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0.5%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

@media only screen and (max-width: 700px) {
  #wrapper .intro-banner .component-content .outer-pos-wrapper {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100%; }
    #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
      width: 100%; }
  #wrapper .intro-banner.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    max-width: 100%;
    width: 100%; }
  #wrapper .intro-banner .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    left: 0;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    position: absolute; }
  #wrapper .intro-banner.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    left: inherit; }
  #wrapper .intro-banner.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    float: none;
    right: 0; }
  #wrapper .intro-banner.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    margin: auto 0%; }
  #wrapper .intro-banner.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    top: 2rem;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; }
  #wrapper .intro-banner.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    padding: 0;
    bottom: 2rem;
    top: inherit;
    -webkit-transform: inherit;
    -moz-transform: inherit;
    -ms-transform: inherit;
    -o-transform: inherit;
    transform: inherit; } }

#wrapper .intro-banner.force-width .component-content .outer-pos-wrapper {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  min-height: 100%; }
  #wrapper .intro-banner.force-width .component-content .outer-pos-wrapper > .inner-pos-wrapper {
    width: 100%; }

#wrapper .intro-banner.force-width.w10 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 10%;
  width: 10%; }

#wrapper .intro-banner.force-width.w20 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 20%;
  width: 20%; }

#wrapper .intro-banner.force-width.w30 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 30%;
  width: 30%; }

#wrapper .intro-banner.force-width.w40 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 40%;
  width: 40%; }

#wrapper .intro-banner.force-width.w50 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 50%;
  width: 50%; }

#wrapper .intro-banner.force-width.w60 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 60%;
  width: 60%; }

#wrapper .intro-banner.force-width.w70 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 70%;
  width: 70%; }

#wrapper .intro-banner.force-width.w80 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 80%;
  width: 80%; }

#wrapper .intro-banner.force-width.w90 > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  max-width: 90%;
  width: 90%; }

#wrapper .intro-banner.force-width .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  left: 0;
  padding: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute; }

#wrapper .intro-banner.force-width.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper,
#wrapper .intro-banner.force-width.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  left: inherit; }

#wrapper .intro-banner.force-width.pos-right > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  float: none;
  right: 0; }

#wrapper .intro-banner.force-width.w10.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 45%; }

#wrapper .intro-banner.force-width.w20.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 40%; }

#wrapper .intro-banner.force-width.w30.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 35%; }

#wrapper .intro-banner.force-width.w40.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 30%; }

#wrapper .intro-banner.force-width.w50.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 25%; }

#wrapper .intro-banner.force-width.w60.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 20%; }

#wrapper .intro-banner.force-width.w70.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 15%; }

#wrapper .intro-banner.force-width.w80.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 10%; }

#wrapper .intro-banner.force-width.w90.pos-center > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin: auto 5%; }

#wrapper .intro-banner.force-width.pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  top: 2rem;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

#wrapper .intro-banner.force-width.pos-bottom > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  padding: 0;
  bottom: 2rem;
  top: inherit;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
  transform: inherit; }

#wrapper > #content .slider-stack-wrapper > .component.component-ready.animate.slider-stack {
  opacity: 0;
  left: 100%;
  transition: opacity 1.3s, left 0s linear 1.5s; }

#wrapper > #content .slider-stack-wrapper > .component.component-ready.animate.slider-stack.active-slide {
  opacity: 1;
  left: 0;
  transition: opacity 1.3s, left 0s linear 0s; }

/*
@media only screen and (max-width: 400px) {
    #wrapper {
        .intro-banner {
            // REQUIRED BY OTICON US. MOVE TO OTICON IF CONFLICTS WITH OTHER BRANDS
            @include content-overflow();
        }
    }
}
*/
@media screen and (min-width: 1025px) {
  #wrapper .intro-banner:not(.flex-layout).semi-full-height {
    height: 90vh; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 921.6px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 691.2px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 432px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 288px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 511.2px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 288px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 600.3px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 337.5px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 662.4px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).semi-full-height {
      height: 372.6px; } }

@media screen and (min-width: 1025px) {
  #wrapper .intro-banner:not(.flex-layout).full-height {
    height: 100vh; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 1024px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 768px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 480px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 320px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 568px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 320px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 667px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 375px) and (device-height: 667px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 375px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: portrait) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 736px; } }
  @media only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 414px) and (device-height: 736px) and (orientation: landscape) {
    #wrapper .intro-banner:not(.flex-layout).full-height {
      height: 414px; } }

@media screen and (min-width: 1025px) {
  #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content, #wrapper .intro-banner:not(.flex-layout).full-height .component-content {
    height: 100%; }
    #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content .intro-banner-image, #wrapper .intro-banner:not(.flex-layout).full-height .component-content .intro-banner-image {
      height: 100%; }
      #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content .intro-banner-image img, #wrapper .intro-banner:not(.flex-layout).full-height .component-content .intro-banner-image img {
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50.01%);
        -moz-transform: translateX(-50.01%);
        -ms-transform: translateX(-50.01%);
        -o-transform: translateX(-50.01%);
        transform: translateX(-50.01%);
        max-width: none;
        bottom: 0; }
    #wrapper .intro-banner:not(.flex-layout).semi-full-height .component-content .intro-banner-video, #wrapper .intro-banner:not(.flex-layout).full-height .component-content .intro-banner-video {
      height: 100%;
      width: auto;
      position: relative;
      left: 50%;
      -webkit-transform: translatex(-50.01%);
      -moz-transform: translatex(-50.01%);
      -ms-transform: translatex(-50.01%);
      -o-transform: translatex(-50.01%);
      transform: translatex(-50.01%); } }

#wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text {
  background-color: transparent; }
  #wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text a, #wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text a span {
    transition: none !important; }

#wrapper .component.intro-banner:not(.flex-layout) .intro-banner-link p {
  margin: 0;
  padding: 0;
  line-height: 1; }

#wrapper .component.intro-banner:not(.flex-layout) .intro-banner-link br {
  display: none; }

#wrapper .component.intro-banner:not(.flex-layout).content-overflow .intro-banner-content,
#wrapper .component.intro-banner:not(.flex-layout).content-overflow .intro-banner-link,
#wrapper .component.intro-banner:not(.flex-layout).content-overflow .sticky-downlink {
  position: static; }

#wrapper .component.intro-banner:not(.flex-layout).pos-top > .component-content .outer-pos-wrapper > .inner-pos-wrapper {
  top: 6em; }

#wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text * {
  margin-bottom: 0; }
  #wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:first-child:not(li):not(a) {
    margin-top: 1rem; }

#wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text .btn,
#wrapper .component.intro-banner:not(.flex-layout).content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text [class*="btn-"] {
  margin-bottom: 1rem; }

#wrapper .component.intro-banner:not(.flex-layout).force-center .inner-pos-wrapper {
  margin: 0 auto;
  transform: translate(-50%, -50%);
  left: 50%; }

#wrapper .component.intro-banner:not(.flex-layout).force-center.pos-top .inner-pos-wrapper {
  transform: translate(-50%, 0%); }

#wrapper .component.intro-banner:not(.flex-layout).negative-margin {
  overflow: visible; }
  #wrapper .component.intro-banner:not(.flex-layout).negative-margin .inner-pos-wrapper {
    bottom: -15em;
    top: auto;
    transform: translate(0%, 0%);
    z-index: 2; }
    #wrapper .component.intro-banner:not(.flex-layout).negative-margin .inner-pos-wrapper .negative-image {
      max-width: 90%; }
  #wrapper .component.intro-banner:not(.flex-layout).negative-margin.force-center .inner-pos-wrapper {
    transform: translate(-50%, 0%); }
  #wrapper .component.intro-banner:not(.flex-layout).negative-margin.content-overflow {
    margin-bottom: -16%;
    position: relative;
    z-index: 100; }

#wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content {
  position: static; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    position: static;
    transform: none;
    left: auto;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 100%; }
    #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container .intro-banner-list-spot-content {
      position: static;
      transform: none;
      top: 0;
      padding: 2.2rem; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
    position: static;
    transform: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    /*.intro-banner-text {

								*:last-child:not(a):not(span) {
									margin-bottom: 0;
									padding-bottom: 0;
								}
							}*/ }

#wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot.list-left .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
  margin-bottom: 2rem; }

#wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot.list-left .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
  margin-bottom: 0; }

#wrapper .component.intro-banner:not(.flex-layout).has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper .intro-banner-text *:last-child:not(a):not(span) {
  margin-bottom: 0;
  padding-bottom: 0; }

#wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content {
  border-left: none;
  background-color: #a7d3ac; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul {
    list-style-type: none;
    display: table; }
    #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li {
      margin: 0 0 1.5em 0;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      display: table-row;
      font-family: "Proxima N W01 Reg", sans-serif;
      line-height: 1.5; }
      @media only screen and (max-width: 479px) {
        #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li {
          font-size: 1.1rem; } }
      #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li p {
        padding-bottom: .5em; }
      #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:last-child {
        padding-bottom: 0; }
      #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:before {
        width: .8em;
        content: "•";
        font-family: "retail-icon-font";
        color: #27251F;
        font-size: 1.3em;
        display: table-cell;
        text-align: right;
        speak: none;
        padding-right: .7em;
        vertical-align: top;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:first-child * {
      margin-left: -1em;
      padding: 0 0 .5em 0;
      color: #27251F; }
    #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .intro-banner-list-spot-container .intro-banner-list-spot-content > ul li:first-child:before {
      content: " "; }

#wrapper .component.intro-banner:not(.flex-layout).has-list-spot.content-overflow .intro-banner-list-spot-container {
  max-width: 100%; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot.content-overflow .intro-banner-list-spot-container .intro-banner-list-spot-content {
    margin-bottom: 0; }

#wrapper .component.intro-banner:not(.flex-layout) table td {
  vertical-align: middle;
  padding: .4em; }

@media only screen and (max-width: 767px) {
  #wrapper .slider-stack-wrapper.slide-on-mobile .content-overflow:not(.hide-text-mobile) .component-content .outer-pos-wrapper {
    padding-bottom: 1.2rem; } }

@media only screen and (min-width: 1024px) {
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    width: 47%;
    max-width: 47%;
    left: 43%; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
    width: 47%;
    max-width: 47%; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content {
    position: static; }
    #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper {
      white-space: nowrap; }
      #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
        width: 47%;
        max-width: 47%;
        margin-left: 6%;
        white-space: normal;
        margin-bottom: 0; }
      #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot .component-content .intro-banner-content .outer-pos-wrapper > .inner-pos-wrapper {
        position: static;
        transform: none;
        width: 47%;
        max-width: 47%;
        white-space: normal; }
  #wrapper .component.intro-banner:not(.flex-layout).no-image.has-list-spot.list-left .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    margin-left: 0%;
    margin-right: 6%; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot.list-left:not(.no-image) .component-content .intro-banner-content .outer-pos-wrapper .intro-banner-list-spot-container {
    left: 0;
    transform: none; }
  #wrapper .component.intro-banner:not(.flex-layout).has-list-spot.list-left:not(.no-image) .component-content .intro-banner-content .outer-pos-wrapper .inner-pos-wrapper {
    left: 53%; }
  #wrapper .component.intro-banner:not(.flex-layout) .intro-banner-text *:last-child:not(a):not(span) {
    margin-bottom: 0;
    padding-bottom: 0; } }

@media only screen and (max-width: 1023px) {
  #wrapper .component.intro-banner:not(.flex-layout).hide-image-mobile:not(.hide-text-mobile) .intro-banner-image {
    display: none; } }

#wrapper #header .component.language-selector-spot {
  -webkit-transition: all, 0.2s;
  -moz-transition: all, 0.2s;
  -o-transition: all, 0.2s;
  transition: all, 0.2s;
  opacity: 0;
  padding: 1.7em 0 0 1em;
  width: auto;
  display: inline-block;
  float: right; }
  #wrapper #header .component.language-selector-spot .component-content::before {
    content: "";
    font-family: "retail-icon-font";
    display: inline-block;
    font-size: 1rem;
    margin-right: 0.5rem;
    color: #005da9;
    float: left; }
  #wrapper #header .component.language-selector-spot ul {
    float: left;
    height: 1.2rem;
    overflow: hidden; }
    #wrapper #header .component.language-selector-spot ul li {
      transition: margin-top 0.3s;
      position: relative;
      margin-top: -1.2rem;
      overflow: hidden;
      height: 1.2rem !important;
      background-color: #ffffff; }
      #wrapper #header .component.language-selector-spot ul li a {
        color: #005da9;
        font-family: "Proxima N W01 Reg", sans-serif; }
      #wrapper #header .component.language-selector-spot ul li.active {
        margin-top: 0;
        z-index: 1; }
    #wrapper #header .component.language-selector-spot ul.open {
      height: auto; }
      #wrapper #header .component.language-selector-spot ul.open li {
        margin-top: 0; }
  #wrapper #header .component.language-selector-spot.animate {
    opacity: 1; }

#wrapper #header.burger-switch .component.language-selector-spot {
  display: block;
  float: none;
  position: absolute;
  left: -4.9rem;
  top: 3.9rem;
  padding: 1.2em;
  z-index: 1; }
  #wrapper #header.burger-switch .component.language-selector-spot .component-content:before {
    font-size: 1.9rem; }
  #wrapper #header.burger-switch .component.language-selector-spot .component-content ul li {
    display: none; }

#wrapper .lattice-spot .lattice-spot-item {
  max-width: 50%;
  width: 100%;
  float: left; }
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-image {
    max-width: 50%;
    width: 100%;
    height: 100%; }
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text {
    background-color: #f5f5f5;
    padding: 2rem;
    position: relative;
    box-sizing: border-box;
    /** {
            margin-top: 0;
            padding-bottom: 0;
            margin-bottom: 1rem;
        }*/ }
  #wrapper .lattice-spot .lattice-spot-item .lattice-spot-image {
    background-position: center center;
    background-size: cover; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-image, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-image {
    float: left; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-text::before, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-text::before {
    border-bottom: 12px solid transparent;
    border-left: 12px solid #f5f5f5;
    border-top: 12px solid transparent;
    border-right: none;
    content: "";
    height: 0;
    position: absolute;
    right: -12px;
    top: 3em;
    width: 0; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-image, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-text,
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-image {
    float: right; }
  #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-text::before, #wrapper .lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-text::before {
    border-bottom: 12px solid transparent;
    border-right: 12px solid #f5f5f5;
    border-top: 12px solid transparent;
    border-left: none;
    content: "";
    height: 0;
    left: -12px;
    position: absolute;
    top: 3em;
    width: 0; }

#wrapper .lattice-spot .lattice-spot-clear {
  clear: both; }

#wrapper .lattice-spot ul {
  list-style: disc; }

@media only screen and (max-width: 750px) {
  #wrapper .lattice-spot .lattice-spot-item {
    max-width: 100%; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+1) .lattice-spot-text,
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+1) .lattice-spot-image {
      float: left; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+1) .lattice-spot-text::before {
      border-bottom: 12px solid transparent;
      border-left: 12px solid #f5f5f5;
      border-top: 12px solid transparent;
      border-right: none;
      content: "";
      height: 0;
      position: absolute;
      right: -12px;
      left: unset;
      top: 3em;
      width: 0; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+2) .lattice-spot-text,
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+2) .lattice-spot-image {
      float: right; }
    #wrapper .lattice-spot .lattice-spot-item:nth-child(2n+2) .lattice-spot-text::before {
      border-bottom: 12px solid transparent;
      border-right: 12px solid #f5f5f5;
      border-top: 12px solid transparent;
      border-left: none;
      content: "";
      height: 0;
      left: -12px;
      right: unset;
      position: absolute;
      top: 3em;
      width: 0; } }

@media only screen and (max-width: 480px) {
  #wrapper .lattice-spot .lattice-spot-item {
    height: auto !important; }
    #wrapper .lattice-spot .lattice-spot-item .lattice-spot-image {
      display: none; }
    #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text {
      max-width: 100%; }
      #wrapper .lattice-spot .lattice-spot-item .lattice-spot-text::before {
        display: none; } }

#wrapper .component.lattice-spot ul {
  list-style: disc;
  margin-left: 0;
  line-height: 1.5; }
  #wrapper .component.lattice-spot ul li:before {
    color: #ffffff !important; }

#wrapper .component.lattice-spot .lattice-spot-item .lattice-spot-text {
  background-color: #005da9; }
  #wrapper .component.lattice-spot .lattice-spot-item .lattice-spot-text *:not(a) {
    color: #ffffff !important; }

#wrapper .component.lattice-spot a {
  color: #e40049;
  font-family: "Proxima N W01 Bold", sans-serif;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  color: #a7d3ac; }
  #wrapper .component.lattice-spot a:hover {
    opacity: 0.7; }

#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+3) .lattice-spot-text::before,
#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+4) .lattice-spot-text::before {
  border-right: 12px solid #005da9; }

#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+1) .lattice-spot-text::before,
#wrapper .component.lattice-spot .lattice-spot-item:nth-child(4n+2) .lattice-spot-text::before {
  border-left: 12px solid #005da9; }

#wrapper .component.link-list.horizontal .component-content {
  text-align: center; }
  #wrapper .component.link-list.horizontal .component-content h3 {
    display: none; }
  #wrapper .component.link-list.horizontal .component-content ul {
    margin: 0 auto;
    display: inline-block; }
    #wrapper .component.link-list.horizontal .component-content ul li {
      float: left; }
      #wrapper .component.link-list.horizontal .component-content ul li a {
        display: block;
        font-size: 1rem;
        color: #005da9;
        padding: 1.67em;
        transition: background-color 0.5s; }

#wrapper .news-archive-spot {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  #wrapper .news-archive-spot .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
    #wrapper .news-archive-spot .component-content ul {
      padding: 0; }
      #wrapper .news-archive-spot .component-content ul li {
        background-color: #f5f5f5;
        padding: 2em 0 2em 2.4em;
        margin-bottom: 1rem;
        border-left: 0.6em solid #005da9; }
        #wrapper .news-archive-spot .component-content ul li div {
          margin-right: 1em;
          line-height: 1.5; }
        #wrapper .news-archive-spot .component-content ul li h3, #wrapper .news-archive-spot .component-content ul li .news-header {
          font-family: "Proxima N W01 Bold", sans-serif;
          font-size: 2.5rem;
          margin: 0;
          padding: 0 0 0.6em 0;
          line-height: 1;
          color: #005da9;
          display: block;
          margin: 0 1em 1em 0; }
        #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date, #wrapper .news-archive-spot .component-content ul li .news-text {
          margin-bottom: 0.5em; }
        #wrapper .news-archive-spot .component-content ul li .news-link {
          margin-top: 1.5em; }

#wrapper .news-archive-spot .component-content ul li {
  /*background-color: $grey-1000;*/
  padding: 2em 2em 2em 2.4em;
  border-left: 0;
  border-top: 1px solid #a7d3ac; }
  #wrapper .news-archive-spot .component-content ul li h3, #wrapper .news-archive-spot .component-content ul li .news-header {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2.5rem;
    margin: 0;
    padding: 0 0 0.6em 0;
    line-height: 1;
    color: #005da9;
    display: block; }
  #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date, #wrapper .news-archive-spot .component-content ul li .news-text {
    margin-bottom: 0; }
  #wrapper .news-archive-spot .component-content ul li .news-text, #wrapper .news-archive-spot .component-content ul li .news-text * {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0; }
    @media only screen and (max-width: 479px) {
      #wrapper .news-archive-spot .component-content ul li .news-text, #wrapper .news-archive-spot .component-content ul li .news-text * {
        font-size: 1.1rem; } }
  #wrapper .news-archive-spot .component-content ul li .news-text *:last-child:not(a) {
    padding-bottom: 0; }
  #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date {
    font-family: "Proxima N W01 Reg", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0 0 1.2em 0;
    margin: 0; }
    @media only screen and (max-width: 479px) {
      #wrapper .news-archive-spot .component-content ul li .news-location, #wrapper .news-archive-spot .component-content ul li .news-date {
        font-size: 1.1rem; } }
    #wrapper .news-archive-spot .component-content ul li .news-location:empty, #wrapper .news-archive-spot .component-content ul li .news-date:empty {
      display: none; }
  #wrapper .news-archive-spot .component-content ul li .news-link {
    margin-top: 0.5em; }
    #wrapper .news-archive-spot .component-content ul li .news-link .link {
      color: #e40049;
      font-family: "Proxima N W01 Bold", sans-serif;
      text-decoration: none;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s;
      color: #a7d3ac; }
      #wrapper .news-archive-spot .component-content ul li .news-link .link:hover {
        opacity: 0.7; }

#wrapper .news-list-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .news-list-spot:not(.jobs) {
  padding-bottom: 2rem;
  padding-top: 2rem; }
  #wrapper .news-list-spot:not(.jobs) .component-content > ul {
    padding: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul > li {
      position: relative;
      visibility: hidden;
      vertical-align: top;
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      text-align: left;
      margin-bottom: 1.5rem;
      padding-bottom: 2rem;
      background-color: #f1f1f1;
      cursor: pointer;
      overflow: hidden; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        box-shadow: none;
        width: 0px; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li:hover .news-header-wrapper::after {
        width: 1.5rem; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-item-wrapper {
        padding: 1.5rem 0; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-image {
        overflow: hidden;
        background-size: cover;
        background-position: center; }
        #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-image img {
          display: none; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link {
        text-align: center;
        z-index: 100; }
        #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link {
          color: #e40049;
          font-family: "Proxima N W01 Bold", sans-serif;
          text-decoration: none;
          cursor: pointer;
          opacity: 1;
          transition: opacity 0.2s;
          transition: padding 0.1s;
          position: relative;
          padding-right: 1.5em;
          color: #005da9; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link:hover {
            opacity: 0.7; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link:after {
            font-family: "retail-icon-font";
            speak: none;
            font-style: normal;
            font-weight: bold;
            font-variant: normal;
            text-transform: none;
            display: inline-block;
            content: "";
            font-size: 1.1em;
            margin: 0;
            position: absolute;
            right: 0;
            top: .1em; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link:hover {
            opacity: 0.7;
            padding-right: 1.7em; }
          #wrapper .news-list-spot:not(.jobs) .component-content > ul > li .news-link .link * {
            color: #005da9; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-header .news-header-wrapper {
      font-family: "Proxima N W01 Bold", sans-serif;
      font-size: 2rem;
      margin: 0;
      padding: 0 0 0.8em 0;
      line-height: 1;
      color: #005da9;
      display: block;
      line-height: 1.1;
      padding: 0 2rem 1.5rem 2rem;
      text-align: center;
      position: relative;
      width: 100%;
      box-sizing: border-box; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-header .news-header-wrapper::after {
        width: 0rem;
        content: " ";
        display: inline-block;
        height: 2px;
        background: #005da9;
        transition: width .2s;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
        bottom: .9rem; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-location {
      display: none; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-date h4 {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      font-size: 0.9rem;
      line-height: 1.3rem;
      padding-bottom: 0;
      color: #b0b0b0;
      text-align: center;
      text-transform: uppercase; }
      @media only screen and (max-width: 479px) {
        #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-date h4 {
          font-size: 1.1rem; } }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-text {
      position: relative;
      padding: .2rem 2rem; }
      #wrapper .news-list-spot:not(.jobs) .component-content > ul .news-text *:last-child:not(a) {
        padding-bottom: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content > ul::after {
      content: '';
      display: inline-block;
      width: 100%;
      line-height: 0;
      font-size: 0;
      margin: 0;
      padding: 0;
      height: 0; }
  #wrapper .news-list-spot:not(.jobs) .component-content .news-list-link {
    margin: 4rem 0 0; }

#wrapper .news-list-spot.jobs .news-header {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 2.5rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block; }
  #wrapper .news-list-spot.jobs .news-header a {
    color: #005da9; }

#wrapper .news-list-spot.jobs .component-content > ul > li {
  display: block;
  vertical-align: top;
  margin-bottom: 4rem; }

#wrapper .news-list-spot.jobs .news-date h4 {
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  color: #b0b0b0; }
  @media only screen and (max-width: 479px) {
    #wrapper .news-list-spot.jobs .news-date h4 {
      font-size: 1.1rem; } }

#wrapper .news-list-spot.jobs .news-location h4 {
  color: #a7d3ac;
  font-family: "Proxima N W01 Reg", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 0 1.2em 0;
  margin: 0; }
  @media only screen and (max-width: 479px) {
    #wrapper .news-list-spot.jobs .news-location h4 {
      font-size: 1.1rem; } }

#wrapper .news-list-spot.jobs .news-link a {
  background-color: #e40049;
  color: #ffffff;
  display: inline-block;
  padding: 1em 2.5em;
  line-height: 1;
  border-radius: 2em;
  border: 0;
  font-size: 1rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  background-color: #a7d3ac;
  color: #005da9; }
  #wrapper .news-list-spot.jobs .news-link a:hover {
    opacity: 0.7; }

@media only screen and (min-width: 768px) {
  #wrapper .news-list-spot:not(.jobs) .component-content ul li {
    width: 29%;
    margin-bottom: 3rem;
    white-space: nowrap;
    float: left;
    margin-right: 6.5%;
    padding: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li:nth-child(3n+3) {
      margin-right: 0; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li * {
      white-space: normal; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li::after {
      box-shadow: none;
      width: 0px; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li:first-child::after {
      box-shadow: none; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-item-wrapper {
      position: absolute;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      overflow: hidden;
      padding: 0; }
      #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-item-wrapper:before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 65%;
        pointer-events: none;
        background: linear-gradient(to top, #f1f1f1 0.5rem, transparent);
        z-index: 99; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%; }
      #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-image + .news-item-wrapper {
        transform: none;
        bottom: 3.5rem; }
    #wrapper .news-list-spot:not(.jobs) .component-content ul li .news-link {
      position: absolute;
      bottom: 1.7rem;
      left: 0;
      right: 0; }
  #wrapper .news-list-spot.jobs .component-content > ul > li {
    display: inline-block;
    width: 45%;
    vertical-align: top;
    margin-bottom: 4rem; }
    #wrapper .news-list-spot.jobs .component-content > ul > li:nth-child(odd) {
      margin-right: 5%; } }

#wrapper .component.online-booking .online-booking-loader {
  height: 2rem;
  width: 2rem;
  margin: 0 auto 1rem auto;
  animation: rotate 0.8s infinite linear;
  border: 0.5rem solid #005da9;
  border-right-color: transparent;
  border-radius: 50%;
  z-index: 10000; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

#wrapper .component.online-booking.component-ready.animate .booking-body {
  -webkit-transition: opacity 2s;
  -moz-transition: opacity 2s;
  -o-transition: opacity 2s;
  transition: opacity 2s;
  opacity: 1; }

#wrapper .component.online-booking .indicator-timeslots {
  position: absolute;
  background: #ffffff;
  opacity: 0.8;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  #wrapper .component.online-booking .indicator-timeslots .online-booking-loader-label {
    font-family: "Proxima N W01 Smbd", sans-serif;
    color: #333333;
    white-space: normal;
    max-width: 80%;
    text-align: center; }

#wrapper .component.online-booking .request-callback-info {
  border: 1px solid #eeeeee;
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column; }

#wrapper .component.online-booking .component-content {
  background-position: left bottom;
  background-repeat: repeat-x; }
  #wrapper .component.online-booking .component-content .booking-header {
    position: relative;
    background-color: #a7d3ac;
    padding-bottom: 4rem; }
    #wrapper .component.online-booking .component-content .booking-header .online-booking-loader {
      margin-top: 4rem;
      margin-bottom: -2rem; }
    #wrapper .component.online-booking .component-content .booking-header::before {
      background-image: url("/design/build/retail/assets/ear.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      content: "1";
      display: inline-block;
      width: 3.25rem;
      height: 3.6rem;
      color: #ffffff;
      position: absolute;
      top: -.9em;
      left: 50%;
      transform: translate(-50%, 0%);
      text-align: center;
      font-size: 1.8rem;
      padding-top: .8rem;
      line-height: 1;
      font-family: "Proxima N W01 Bold", sans-serif; }
    #wrapper .component.online-booking .component-content .booking-header .header-center-content {
      width: 33.333rem;
      max-width: 90%;
      margin: 0 auto; }
      #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-select-center {
        padding-top: 1.3em; }
      #wrapper .component.online-booking .component-content .booking-header .header-center-content .center-provider-list {
        padding-top: 1.3em;
        position: relative;
        display: none; }
        #wrapper .component.online-booking .component-content .booking-header .header-center-content .center-provider-list::after {
          content: "";
          font-family: "retail-icon-font";
          position: absolute;
          right: 18px;
          top: 14px;
          font-size: 24px;
          color: #005da9;
          pointer-events: none;
          top: auto;
          bottom: 0.7rem; }
      #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60,
      #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid {
        margin-top: 1rem; }
        #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form,
        #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .booking-form-field-radiobutton,
        #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form,
        #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .booking-form-field-radiobutton {
          width: 80%;
          margin: 0 auto;
          display: flex; }
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form label,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .booking-form-field-radiobutton label,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form label,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .booking-form-field-radiobutton label {
            margin-right: 1rem;
            width: calc(50% - 1rem);
            display: flex;
            align-items: center; }
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form label span,
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .booking-form-field-radiobutton label span,
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form label span,
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .booking-form-field-radiobutton label span {
              margin: 0;
              line-height: 1.4; }
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form label input,
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .booking-form-field-radiobutton label input,
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form label input,
            #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .booking-form-field-radiobutton label input {
              flex: 0 0 27px; }
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form label + label > input,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .booking-form-field-radiobutton label + label > input,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form label + label > input,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .booking-form-field-radiobutton label + label > input {
            margin-left: 1.5rem; }
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form .form-age60-above-error,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .booking-form-field-radiobutton .form-age60-above-error,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form .form-age60-above-error,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .booking-form-field-radiobutton .form-age60-above-error {
            margin-top: 0.5rem; }
        @media screen and (max-width: 375px) {
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form label + label,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form label + label {
            width: 10em; } }
        @media screen and (min-width: 1401px) {
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-age-above-60 .above-60-form label + label,
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-wearing-hearing-aid .above-60-form label + label {
            width: 12.8em; } }
      #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-field-radiobutton label > input {
        margin-left: 7.3rem; }
        @media screen and (max-width: 394px) {
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-field-radiobutton label > input {
            margin-left: 14vw; } }
        @media screen and (min-width: 395px) and (max-width: 498px) {
          #wrapper .component.online-booking .component-content .booking-header .header-center-content .booking-form-field-radiobutton label > input {
            margin-left: 17vw; } }
    #wrapper .component.online-booking .component-content .booking-header .ui-widget.autocomplete {
      display: none;
      position: relative; }
      #wrapper .component.online-booking .component-content .booking-header .ui-widget.autocomplete label {
        visibility: hidden; }
      #wrapper .component.online-booking .component-content .booking-header .ui-widget.autocomplete input {
        font-size: 1rem;
        padding-right: 2.5rem; }
        @media screen and (max-width: 750px) {
          #wrapper .component.online-booking .component-content .booking-header .ui-widget.autocomplete input {
            font-size: 16px; } }
      #wrapper .component.online-booking .component-content .booking-header .ui-widget.autocomplete .autocomplete-clear {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 33%;
        font-size: 0; }
        #wrapper .component.online-booking .component-content .booking-header .ui-widget.autocomplete .autocomplete-clear:before {
          content: "";
          font-family: "retail-icon-font";
          font-size: 20px;
          color: #005da9; }
    #wrapper .component.online-booking .component-content .booking-header .center-selector {
      position: relative; }
      #wrapper .component.online-booking .component-content .booking-header .center-selector--hide-elements select, #wrapper .component.online-booking .component-content .booking-header .center-selector--hide-elements::after {
        display: none; }
      #wrapper .component.online-booking .component-content .booking-header .center-selector::after {
        content: "";
        font-family: "retail-icon-font";
        position: absolute;
        right: 18px;
        top: 14px;
        font-size: 24px;
        color: #005da9;
        pointer-events: none; }
      #wrapper .component.online-booking .component-content .booking-header .center-selector .center-address-container {
        background-color: #005da9;
        padding: 1rem;
        color: #ffffff; }
        #wrapper .component.online-booking .component-content .booking-header .center-selector .center-address-container:before {
          font-family: "retail-icon-font";
          display: inline-block;
          content: "";
          color: #ffffff;
          font-size: 2rem;
          width: 1.9em;
          height: 1.9em;
          border: 1px solid #ffffff;
          border-radius: 50%;
          text-align: center;
          line-height: 1.9;
          margin-right: 0.35em;
          vertical-align: middle; }
        #wrapper .component.online-booking .component-content .booking-header .center-selector .center-address-container > div:first-child {
          display: inline-block;
          vertical-align: middle;
          width: 75%; }
        #wrapper .component.online-booking .component-content .booking-header .center-selector .center-address-container .center-address {
          line-height: 1.3; }
      #wrapper .component.online-booking .component-content .booking-header .center-selector .no-address-found {
        display: none;
        padding-top: 1rem;
        color: #e40049; }
  #wrapper .component.online-booking .component-content .booking-body {
    opacity: 0;
    background: transparent url("/design/build/retail/assets/dotted-bg.png") bottom left repeat-x; }
    #wrapper .component.online-booking .component-content .booking-body .body-content {
      width: 33.333rem;
      max-width: 90%;
      margin: 0 auto; }
      #wrapper .component.online-booking .component-content .booking-body .body-content:last-child {
        margin-bottom: 7.5rem; }
      #wrapper .component.online-booking .component-content .booking-body .body-content .calendar-container {
        position: relative; }
      #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-types-container,
      #wrapper .component.online-booking .component-content .booking-body .body-content .calendar-container,
      #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container {
        -webkit-transition: filter 0.4s, opacity 0.7s, height 0.4s;
        -moz-transition: filter 0.4s, opacity 0.7s, height 0.4s;
        -o-transition: filter 0.4s, opacity 0.7s, height 0.4s;
        transition: filter 0.4s, opacity 0.7s, height 0.4s;
        opacity: 1;
        height: auto;
        opacity: 1;
        -webkit-transition: opacity 0.7s, height 0.4s;
        -moz-transition: opacity 0.7s, height 0.4s;
        -o-transition: opacity 0.7s, height 0.4s;
        transition: opacity 0.7s, height 0.4s; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-types-container.disabled:not(.two-step),
        #wrapper .component.online-booking .component-content .booking-body .body-content .calendar-container.disabled:not(.two-step),
        #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container.disabled:not(.two-step) {
          filter: blur(3px);
          opacity: 0.4;
          pointer-events: none; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-types-container.two-step,
        #wrapper .component.online-booking .component-content .booking-body .body-content .calendar-container.two-step,
        #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container.two-step {
          height: 0;
          opacity: 0;
          padding: 0;
          overflow: hidden;
          margin: 0; }
      #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-types-container {
        padding-top: 7.5rem; }
      #wrapper .component.online-booking .component-content .booking-body .body-content .booking-appointment-type {
        position: relative;
        padding-top: 1.6em; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .booking-appointment-type::before {
          background-image: url("/design/build/retail/assets/ear.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: contain;
          content: "2";
          display: inline-block;
          width: 3.25rem;
          height: 3.6rem;
          color: #ffffff;
          position: absolute;
          top: -.9em;
          left: 50%;
          transform: translate(-50%, 0%);
          text-align: center;
          font-size: 1.8rem;
          padding-top: .8rem;
          line-height: 1;
          font-family: "Proxima N W01 Bold", sans-serif; }
      #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-type-table {
        width: 80%;
        margin: 0 auto 1rem auto;
        table-layout: fixed; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-type-table td {
          vertical-align: top; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-type-table td input {
            margin-top: -0.4em; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-type-table td .appointment-type-title {
            font-family: "Proxima N W01SC Reg It", sans-serif;
            color: #333333;
            white-space: normal;
            max-width: 80%; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-type-table td .appointment-type-description {
            font-family: "Proxima N W01 Reg", sans-serif;
            font-size: 1.125rem;
            line-height: 1.5;
            padding: 0 0 1.2em 0;
            margin: 0;
            white-space: normal; }
            @media only screen and (max-width: 479px) {
              #wrapper .component.online-booking .component-content .booking-body .body-content .appointment-type-table td .appointment-type-description {
                font-size: 1.1rem; } }
      #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker {
        background-color: #eeeeee;
        padding-bottom: 1rem; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header {
          position: relative; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
            position: absolute;
            top: 1rem;
            text-align: center; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span,
            #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-next span {
              display: none; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-prev.ui-state-disabled::before,
            #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-next.ui-state-disabled::before {
              color: #bbbbbb;
              cursor: auto; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
            left: 0.6rem; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
              font-family: "retail-icon-font";
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 2.2em;
              display: inline-block;
              content: "";
              font-size: 1.5em;
              width: 2.2em;
              height: 2.2em;
              color: #333333;
              transition: all 0.2s;
              speak: none; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
            right: 0.6rem; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
              font-family: "retail-icon-font";
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 2.2em;
              display: inline-block;
              content: "";
              font-size: 1.5em;
              width: 2.2em;
              height: 2.2em;
              color: #333333;
              transition: all 0.2s;
              speak: none; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
            font-family: "Proxima N W01 Bold", sans-serif;
            font-size: 2rem;
            margin: 0;
            padding: 0 0 0.8em 0;
            line-height: 1;
            color: #005da9;
            display: block;
            color: #333333;
            text-align: center;
            padding: 1.8rem 0 0.7rem 0; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar {
          width: 90%;
          margin: 0 auto;
          table-layout: fixed;
          user-select: none; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar tr {
            height: 3.3rem; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar th,
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar td {
            text-align: center;
            vertical-align: middle;
            font-family: "Proxima N W01 Bold", sans-serif;
            font-size: 100%; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar th {
            color: #333333; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar td .ui-state-default {
            color: #005da9; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar td.ui-state-disabled .ui-state-default {
            color: #bbbbbb; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today span {
            margin-top: -.2em;
            display: inline-block;
            background-color: #ffffff;
            border-radius: 50%;
            height: 1.7em;
            width: 1.7em;
            line-height: 1.9; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .ui-datepicker .ui-datepicker-calendar td .ui-state-active {
            margin-top: -.2em;
            display: inline-block;
            background-color: #a7d3ac;
            border-radius: 50%;
            height: 1.7em;
            width: 1.7em;
            line-height: 1.9;
            color: #005da9; }
      #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container {
        background-color: #dddddd; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table {
          width: 90%;
          margin: 0 auto;
          user-select: none; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li {
            display: inline-block;
            padding-bottom: 1rem; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-slot {
              width: 21%;
              margin-right: 4%;
              /* first four */ }
              #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-slot:nth-child(4n) {
                margin-right: 0; }
              #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-slot:nth-child(-n + 4) {
                margin-top: 1rem; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-span {
              width: 47%;
              margin-right: 5%;
              /* first two */ }
              #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-span:nth-child(2n) {
                margin-right: 0; }
              #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-span:nth-child(-n + 2) {
                margin-top: 1rem; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-slot, #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-span {
              white-space: nowrap; }
              #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-slot .time-item, #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-span .time-item {
                padding: 1em 0.5em;
                text-overflow: ellipsis;
                overflow: hidden; }
                #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-slot .time-item.disabled, #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-span .time-item.disabled {
                  opacity: 0.4;
                  cursor: default; }
            #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li a {
              background-color: #e40049;
              color: #ffffff;
              display: inline-block;
              padding: 1em 2.5em;
              line-height: 1;
              border-radius: 2em;
              border: 0;
              font-size: 1rem;
              font-family: "Proxima N W01 Bold", sans-serif;
              cursor: pointer;
              width: auto;
              text-decoration: none;
              text-align: center;
              box-sizing: border-box;
              opacity: 1;
              transition: opacity 0.2s;
              -webkit-appearance: none;
              background-color: transparent;
              border: 2px solid #005da9;
              color: #005da9;
              padding: 1em;
              width: 100%; }
              #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li a:hover {
                opacity: 0.7; }
              #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li a.active {
                background-color: #e40049;
                color: #ffffff;
                display: inline-block;
                padding: 1em 2.5em;
                line-height: 1;
                border-radius: 2em;
                border: 0;
                font-size: 1rem;
                font-family: "Proxima N W01 Bold", sans-serif;
                cursor: pointer;
                width: auto;
                text-decoration: none;
                text-align: center;
                box-sizing: border-box;
                opacity: 1;
                transition: opacity 0.2s;
                -webkit-appearance: none;
                background-color: #005da9;
                width: 100%;
                padding: 1em;
                border: 2px solid #005da9; }
                #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li a.active:hover {
                  opacity: 0.7; }
  #wrapper .component.online-booking .component-content .booking-form .online-booking-loader {
    margin: -1rem auto 0.5rem auto; }
  #wrapper .component.online-booking .component-content .booking-form h2 {
    padding: 3rem 0 2em 0;
    position: relative; }
    #wrapper .component.online-booking .component-content .booking-form h2::before {
      background-image: url("/design/build/retail/assets/ear.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      content: "3";
      display: inline-block;
      width: 3.25rem;
      height: 3.6rem;
      color: #ffffff;
      position: absolute;
      top: -.9em;
      left: 50%;
      transform: translate(-50%, 0%);
      text-align: center;
      font-size: 1.8rem;
      padding-top: .8rem;
      line-height: 1;
      font-family: "Proxima N W01 Bold", sans-serif; }
  #wrapper .component.online-booking .component-content .booking-form input[type="radio"],
  #wrapper .component.online-booking .component-content .booking-form input[type="checkbox"] {
    box-shadow: inset 0 0 0 12px #ffffff;
    height: 22px;
    width: 22px;
    float: none;
    margin-bottom: 4px;
    vertical-align: middle; }
    #wrapper .component.online-booking .component-content .booking-form input[type="radio"]:checked,
    #wrapper .component.online-booking .component-content .booking-form input[type="checkbox"]:checked {
      background-color: #005da9;
      border-color: transparent;
      box-shadow: inset 0 0; }
  #wrapper .component.online-booking .component-content .booking-form .form-container {
    background-color: #a7d3ac;
    padding-bottom: 1.5rem;
    -webkit-transition: filter 0.4s, opacity 0.7s, height 0.4s;
    -moz-transition: filter 0.4s, opacity 0.7s, height 0.4s;
    -o-transition: filter 0.4s, opacity 0.7s, height 0.4s;
    transition: filter 0.4s, opacity 0.7s, height 0.4s;
    opacity: 1; }
    #wrapper .component.online-booking .component-content .booking-form .form-container.two-step h2::before {
      background-image: url("/design/build/retail/assets/ear.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      content: "2";
      display: inline-block;
      width: 3.25rem;
      height: 3.6rem;
      color: #ffffff;
      position: absolute;
      top: -.9em;
      left: 50%;
      transform: translate(-50%, 0%);
      text-align: center;
      font-size: 1.8rem;
      padding-top: .8rem;
      line-height: 1;
      font-family: "Proxima N W01 Bold", sans-serif; }
    #wrapper .component.online-booking .component-content .booking-form .form-container.disabled:not(.two-step) {
      filter: blur(3px);
      opacity: 0.4;
      pointer-events: none; }
    #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-wrapper {
      width: 33.333rem;
      max-width: 90%;
      margin: 0 auto; }
    #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields {
      overflow: hidden; }
      #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields .booking-form-field-radiobutton {
        margin-bottom: 1rem; }
      #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields .booking-form-field {
        width: 48.5%;
        margin-right: 3%;
        margin-bottom: 1rem;
        float: left; }
        #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields .booking-form-field:nth-child(even) {
          margin-right: 0%;
          float: right; }
        #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields .booking-form-field input[type="text"] {
          width: 100%; }
      #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields .booking-form-address {
        margin-bottom: 1rem; }
      #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields .form-error {
        font-family: "Proxima N W01 Reg", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        padding: 0 0 1.2em 0;
        margin: 0;
        font-family: "Proxima N W01SC Reg It", sans-serif;
        padding-top: 0.5em;
        padding-left: 1.2em;
        text-align: left;
        color: #e40049; }
        @media only screen and (max-width: 479px) {
          #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields .form-error {
            font-size: 1.1rem; } }
    #wrapper .component.online-booking .component-content .booking-form .form-container .policy-text .form-error {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      font-family: "Proxima N W01SC Reg It", sans-serif;
      padding-top: 0.5em;
      padding-left: 1.2em;
      text-align: left;
      color: #e40049; }
      @media only screen and (max-width: 479px) {
        #wrapper .component.online-booking .component-content .booking-form .form-container .policy-text .form-error {
          font-size: 1.1rem; } }
    #wrapper .component.online-booking .component-content .booking-form .form-container .policy-text #policy-check-box {
      display: none; }
    #wrapper .component.online-booking .component-content .booking-form .form-container .policy-text-no-checkbox {
      display: inline-block;
      margin-top: 1rem;
      color: #005da9; }
    #wrapper .component.online-booking .component-content .booking-form .form-container .btn {
      margin-top: 3.3rem;
      margin-bottom: 3.3rem; }
    #wrapper .component.online-booking .component-content .booking-form .form-container .btn.submit-disabled {
      opacity: 0.3; }
      #wrapper .component.online-booking .component-content .booking-form .form-container .btn.submit-disabled:hover {
        cursor: progress; }
  #wrapper .component.online-booking .component-content .booking-result {
    background-color: #dddddd; }
    #wrapper .component.online-booking .component-content .booking-result .result-container {
      margin-top: 1em;
      width: 33.333rem;
      max-width: 90%;
      margin: 0 auto; }
      #wrapper .component.online-booking .component-content .booking-result .result-container .booking-error {
        color: #e40049;
        padding-top: 4rem; }
      #wrapper .component.online-booking .component-content .booking-result .result-container .error-message {
        font-family: "Proxima N W01 Reg", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        padding: 0 0 1.2em 0;
        margin: 0;
        text-align: center;
        padding-bottom: 4rem; }
        @media only screen and (max-width: 479px) {
          #wrapper .component.online-booking .component-content .booking-result .result-container .error-message {
            font-size: 1.1rem; } }
  #wrapper .component.online-booking .component-content h2 {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2.5rem;
    margin: 0;
    padding: 0 0 0.6em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    text-align: center; }
  #wrapper .component.online-booking .component-content h3 {
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 2rem;
    margin: 0;
    padding: 0 0 0.8em 0;
    line-height: 1;
    color: #005da9;
    display: block;
    text-align: center; }
  #wrapper .component.online-booking .component-content .booking-select-date,
  #wrapper .component.online-booking .component-content .booking-select-time {
    display: none; }
  #wrapper .component.online-booking .component-content .center-provider-find-text {
    margin-top: 10px;
    text-align: center; }

#wrapper .component.online-booking .field-hide {
  display: none;
  clear: both;
  margin-bottom: 1rem; }

#wrapper .component.online-booking.booking-form-address .booking-form-address,
#wrapper .component.online-booking.booking-form-postcode .booking-form-postcode,
#wrapper .component.online-booking.booking-form-visit .booking-form-visit,
#wrapper .component.online-booking.booking-form-18years .booking-form-18years,
#wrapper .component.online-booking.booking-form-contact-method .booking-form-contact-method,
#wrapper .component.online-booking.booking-form-comment .booking-form-comment,
#wrapper .component.online-booking.booking-form-worn-hearingaids .booking-form-worn-hearingaids,
#wrapper .component.online-booking.booking-form-what-prompted-youtovisit .booking-form-what-prompted-youtovisit,
#wrapper .component.online-booking.booking-form-difficulty-hearing .booking-form-difficulty-hearing,
#wrapper .component.online-booking.booking-form-phonenumber-type .booking-form-phonenumber-type,
#wrapper .component.online-booking.booking-form-additional .booking-form-additional,
#wrapper .component.online-booking.booking-form-privacy-checkbox .component-content .booking-form .form-container .policy-text #policy-check-box {
  display: block; }

#wrapper .component.online-booking.booking-form-privacy-checkbox .component-content .booking-form .form-container .policy-text #policy-check-box {
  float: left; }

#wrapper .component.online-booking.booking-form-phonenumber-type .booking-form-phonenumber-type {
  margin-top: 1rem;
  margin-bottom: 0;
  display: flex;
  flex-wrap: nowrap; }
  #wrapper .component.online-booking.booking-form-phonenumber-type .booking-form-phonenumber-type span {
    display: flex;
    align-items: center;
    flex-wrap: nowrap; }
    #wrapper .component.online-booking.booking-form-phonenumber-type .booking-form-phonenumber-type span:last-of-type {
      margin-left: 1rem; }
    #wrapper .component.online-booking.booking-form-phonenumber-type .booking-form-phonenumber-type span input[type="radio"] {
      margin: 0;
      margin-right: 13px; }

#wrapper .component.online-booking.auto-suggest .component-content .booking-header .ui-widget.autocomplete {
  display: block; }

#wrapper .component.online-booking.disable-booking-calendar .component-content .booking-form h2::before {
  content: "2"; }

#wrapper .component.online-booking.blueprint-italy .appointment-type-table {
  display: none; }

@media only screen and (max-width: 767px) {
  #wrapper .component.online-booking.booking-form-phonenumber-type .booking-form-phonenumber-type input[type="radio"]:last-of-type {
    margin-left: 0.5rem; }
  #wrapper .component.online-booking .component-content {
    padding-top: 2rem; }
    #wrapper .component.online-booking .component-content .booking-body {
      padding: 0; }
      #wrapper .component.online-booking .component-content .booking-body .body-content {
        max-width: 100%; }
        #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li {
          font-size: 0.9em; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-slot {
            width: 23%;
            margin-right: 1.5%; }
          #wrapper .component.online-booking .component-content .booking-body .body-content .availability-container .time-table li.time-span {
            width: 47%;
            margin-right: 5%; }
    #wrapper .component.online-booking .component-content .booking-form .form-container .booking-form-fields input[type="text"] {
      width: 100%;
      margin-right: 0%; }
    #wrapper .component.online-booking .component-content .booking-form .form-container .btn {
      margin-top: 2rem;
      margin-bottom: 2rem; } }

.ui-autocomplete.active-centers {
  border: 0;
  max-height: 18.2rem;
  overflow-y: auto; }

.ui-autocomplete.active-centers .ui-menu-item {
  background-color: #005da9;
  color: #ffffff;
  padding: 1.1em 1em 1em 1.4em;
  overflow: hidden;
  white-space: nowrap; }
  .ui-autocomplete.active-centers .ui-menu-item:before {
    font-family: "retail-icon-font";
    display: inline-block;
    content: "";
    color: #ffffff;
    font-size: 1em;
    width: 1.9em;
    height: 1.9em;
    border: 1px solid #ffffff;
    border-radius: 50%;
    text-align: center;
    line-height: 1.9;
    margin-right: 0.35em;
    vertical-align: middle; }
  .ui-autocomplete.active-centers .ui-menu-item > div:first-child {
    display: inline-block;
    vertical-align: middle;
    width: 75%; }
  .ui-autocomplete.active-centers .ui-menu-item:before {
    margin-right: 1.3em; }

.ui-autocomplete.active-centers .ui-menu-item.ui-state-focus {
  background-color: #003765;
  color: #ffffff; }

.ui-autocomplete.online-booking-result {
  max-height: 30vh;
  overflow-y: scroll; }
  .ui-autocomplete.online-booking-result li {
    cursor: pointer;
    padding: 0.25rem 1.2rem; }

#wrapper #content .component.promotion .component-content:not(:empty) {
  margin-top: 1rem; }

#wrapper #content .component.promotion .promotion-banner {
  padding: 0; }
  #wrapper #content .component.promotion .promotion-banner img {
    width: 100%;
    height: auto; }

#wrapper #content .component.promotion .promotion-header {
  padding: 1rem;
  text-align: center; }
  #wrapper #content .component.promotion .promotion-header:first-child {
    padding: 2rem 1rem 1rem; }

#wrapper #content .component.promotion .promotion-text {
  padding: 0 1rem 1rem;
  text-align: center; }

#wrapper #content .component.promotion .promotion-contentimage {
  padding: 1rem;
  text-align: center; }
  #wrapper #content .component.promotion .promotion-contentimage img {
    width: 60%;
    height: auto; }

#wrapper #content .component.promotion .promotion-action {
  padding: 1rem;
  text-align: center; }

#wrapper .component.promotion .promotion-header {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 2.5rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block;
  padding: 1rem; }

#wrapper .component.promotion .promotion-action {
  padding-bottom: 2rem; }

#wrapper .component.promotion.blue-bg .promotion-action *, #wrapper .component.promotion.green-bg .promotion-action *, #wrapper .component.promotion.corporate-bg .promotion-action *, #wrapper .component.promotion.navy-bg .promotion-action * {
  color: #ffffff; }

#wrapper .component.promotion.green-bg .promotion-header,
#wrapper .component.promotion.green-bg .promotion-text {
  color: #ffffff; }

#wrapper .product-tool-conntainer {
  overflow: hidden; }
  #wrapper .product-tool-conntainer > .component-content #product_logo,
  #wrapper .product-tool-conntainer > .component-content .ProductToolMenu {
    display: none; }
  #wrapper .product-tool-conntainer > .component-content .productInfoDiv {
    height: auto; }
    #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolDescription {
      width: auto !important;
      margin-left: 0;
      margin-bottom: 0; }
      #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolDescription .component-content #product_title {
        font-family: "Proxima N W01 Bold", sans-serif;
        font-size: 3rem;
        margin: 0;
        padding: 0 0 0.4em 0;
        line-height: 1;
        color: #005da9;
        display: block;
        padding: 0.3em 0 0.3em 0; }
      #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolDescription .component-content #product_description .innerWrapTing ul li {
        font-family: "Proxima N W01 Reg", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        padding: 0 0 1.2em 0;
        margin: 0;
        padding: 0; }
        @media only screen and (max-width: 479px) {
          #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolDescription .component-content #product_description .innerWrapTing ul li {
            font-size: 1.1rem; } }
    #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolSpinningHead {
      margin-bottom: 2em; }
      #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolSpinningHead .model-selector {
        width: 100%; }
        #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolSpinningHead .model-selector .head_images div:not(:only-of-type) {
          margin-bottom: 0; }
        #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolSpinningHead .model-selector .drag_text {
          top: auto;
          bottom: 0;
          z-index: 1;
          height: 10px; }
          #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolSpinningHead .model-selector .drag_text .head_slider {
            margin-top: 0;
            height: 10px;
            background: #e6e6e6;
            border-radius: 5px; }
            #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolSpinningHead .model-selector .drag_text .head_slider .ui-slider-handle {
              top: -18px;
              width: 66px;
              height: 46px;
              background-position-x: 10px !important;
              background-repeat: no-repeat !important;
              overflow: visible;
              margin-left: -20px; }
  #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .colour, #wrapper .product-tool-conntainer .internal_full_popup_content_colourselector .id {
    color: #000000; }

@media only screen and (min-width: 768px) {
  #wrapper .product-tool-conntainer > .component-content .productInfoDiv .ProductToolDescription {
    margin-left: 2em; } }

body.mode-normal #wrapper .redirect-notification-spot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: none; }
  body.mode-normal #wrapper .redirect-notification-spot .component-content {
    user-select: none;
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #ffffff;
    margin-top: 4rem;
    padding: 3rem;
    border: 1px solid #005da9; }
    body.mode-normal #wrapper .redirect-notification-spot .component-content:after {
      font-family: "retail-icon-font";
      content: "";
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      font-size: 2em; }
    body.mode-normal #wrapper .redirect-notification-spot .component-content .redirect-notification-image {
      padding-top: 1rem;
      padding-bottom: 1rem; }
      body.mode-normal #wrapper .redirect-notification-spot .component-content .redirect-notification-image:empty {
        display: none; }
    body.mode-normal #wrapper .redirect-notification-spot .component-content .redirect-notification-message {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0 0 1.2em 0;
      margin: 0;
      padding-bottom: 0; }
      @media only screen and (max-width: 479px) {
        body.mode-normal #wrapper .redirect-notification-spot .component-content .redirect-notification-message {
          font-size: 1.1rem; } }

body.on-page-editor .redirect-notification-spot {
  display: block !important;
  padding: 0.5rem;
  position: static; }

#wrapper .component.rich-text:not(.right-side) {
  position: relative;
  z-index: 2; }
  #wrapper .component.rich-text:not(.right-side) .component-content img + img {
    margin-left: 0.7rem; }
  #wrapper .component.rich-text:not(.right-side) > .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }

#wrapper .component.rich-text p, #wrapper .component.rich-text a, #wrapper .component.rich-text span, #wrapper .component.rich-text h1, #wrapper .component.rich-text h2, #wrapper .component.rich-text h3, #wrapper .component.rich-text h4, #wrapper .component.rich-text h5, #wrapper .component.rich-text h6 {
  overflow-wrap: break-word; }

#wrapper .component.rich-text:not(.right-side).w10 > .component-content {
  max-width: 10%;
  width: 10%; }

#wrapper .component.rich-text:not(.right-side).w20 > .component-content {
  max-width: 20%;
  width: 20%; }

#wrapper .component.rich-text:not(.right-side).w30 > .component-content {
  max-width: 30%;
  width: 30%; }

#wrapper .component.rich-text:not(.right-side).w40 > .component-content {
  max-width: 40%;
  width: 40%; }

#wrapper .component.rich-text:not(.right-side).w50 > .component-content {
  max-width: 50%;
  width: 50%; }

#wrapper .component.rich-text:not(.right-side).w60 > .component-content {
  max-width: 60%;
  width: 60%; }

#wrapper .component.rich-text:not(.right-side).w70 > .component-content {
  max-width: 70%;
  width: 70%; }

#wrapper .component.rich-text:not(.right-side).w80 > .component-content {
  max-width: 80%;
  width: 80%; }

#wrapper .component.rich-text:not(.right-side).w90 > .component-content {
  max-width: 90%;
  width: 90%; }

@media only screen and (max-width: 1150px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 18%;
    width: 18%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 27%;
    width: 27%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 36%;
    width: 36%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 45%;
    width: 45%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 54%;
    width: 54%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 63%;
    width: 63%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 72%;
    width: 72%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 81%;
    width: 81%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 1100px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 26%;
    width: 26%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 34%;
    width: 34%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 42%;
    width: 42%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 50%;
    width: 50%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 58%;
    width: 58%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 74%;
    width: 74%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 1050px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 34%;
    width: 34%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 41%;
    width: 41%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 48%;
    width: 48%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 55%;
    width: 55%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 62%;
    width: 62%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 69%;
    width: 69%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 76%;
    width: 76%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 83%;
    width: 83%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 1000px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 42%;
    width: 42%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 48%;
    width: 48%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 54%;
    width: 54%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 60%;
    width: 60%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 72%;
    width: 72%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 950px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 50%;
    width: 50%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 55%;
    width: 55%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 60%;
    width: 60%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 65%;
    width: 65%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 70%;
    width: 70%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 75%;
    width: 75%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 80%;
    width: 80%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 85%;
    width: 85%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 900px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 58%;
    width: 58%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 62%;
    width: 62%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 70%;
    width: 70%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 74%;
    width: 74%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 86%;
    width: 86%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 950px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 66%;
    width: 66%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 69%;
    width: 69%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 72%;
    width: 72%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 75%;
    width: 75%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 81%;
    width: 81%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 87%;
    width: 87%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 800px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 74%;
    width: 74%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 76%;
    width: 76%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 78%;
    width: 78%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 80%;
    width: 80%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 86%;
    width: 86%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 88%;
    width: 88%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 750px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 82%;
    width: 82%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 83%;
    width: 83%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 84%;
    width: 84%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 85%;
    width: 85%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 86%;
    width: 86%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 87%;
    width: 87%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 88%;
    width: 88%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 89%;
    width: 89%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

@media only screen and (max-width: 700px) {
  #wrapper .component.rich-text:not(.right-side).w10 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w20 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w30 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w40 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w50 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w60 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w70 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w80 > .component-content {
    max-width: 90%;
    width: 90%; }
  #wrapper .component.rich-text:not(.right-side).w90 > .component-content {
    max-width: 90%;
    width: 90%; } }

.on-page-editor #wrapper > #content .component.rich-text {
  min-height: 100px; }

@media only screen and (max-width: 1400px) {
  #wrapper .component.rich-text .component-content img {
    margin-left: 0; } }

#wrapper .component.rich-text .component-content a.btn,
#wrapper .component.rich-text .component-content a[class*="btn-"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem; }

#wrapper .component.rich-text.dark-blue-bg .component-content h1, #wrapper .component.rich-text.dark-blue-bg .component-content h2, #wrapper .component.rich-text.dark-blue-bg .component-content h3, #wrapper .component.rich-text.dark-blue-bg .component-content h4, #wrapper .component.rich-text.dark-blue-bg .component-content h5, #wrapper .component.rich-text.dark-blue-bg .component-content h6, #wrapper .component.rich-text.dark-blue-bg .component-content p, #wrapper .component.rich-text.dark-blue-bg .component-content ol, #wrapper .component.rich-text.dark-blue-bg .component-content ul,
#wrapper .component.rich-text.audika-blue-bg .component-content h1,
#wrapper .component.rich-text.audika-blue-bg .component-content h2,
#wrapper .component.rich-text.audika-blue-bg .component-content h3,
#wrapper .component.rich-text.audika-blue-bg .component-content h4,
#wrapper .component.rich-text.audika-blue-bg .component-content h5,
#wrapper .component.rich-text.audika-blue-bg .component-content h6,
#wrapper .component.rich-text.audika-blue-bg .component-content p,
#wrapper .component.rich-text.audika-blue-bg .component-content ol,
#wrapper .component.rich-text.audika-blue-bg .component-content ul {
  color: #ffffff; }
  #wrapper .component.rich-text.dark-blue-bg .component-content h1 span, #wrapper .component.rich-text.dark-blue-bg .component-content h2 span, #wrapper .component.rich-text.dark-blue-bg .component-content h3 span, #wrapper .component.rich-text.dark-blue-bg .component-content h4 span, #wrapper .component.rich-text.dark-blue-bg .component-content h5 span, #wrapper .component.rich-text.dark-blue-bg .component-content h6 span, #wrapper .component.rich-text.dark-blue-bg .component-content p span, #wrapper .component.rich-text.dark-blue-bg .component-content ol span, #wrapper .component.rich-text.dark-blue-bg .component-content ul span,
  #wrapper .component.rich-text.audika-blue-bg .component-content h1 span,
  #wrapper .component.rich-text.audika-blue-bg .component-content h2 span,
  #wrapper .component.rich-text.audika-blue-bg .component-content h3 span,
  #wrapper .component.rich-text.audika-blue-bg .component-content h4 span,
  #wrapper .component.rich-text.audika-blue-bg .component-content h5 span,
  #wrapper .component.rich-text.audika-blue-bg .component-content h6 span,
  #wrapper .component.rich-text.audika-blue-bg .component-content p span,
  #wrapper .component.rich-text.audika-blue-bg .component-content ol span,
  #wrapper .component.rich-text.audika-blue-bg .component-content ul span {
    color: #ffffff; }

@media only screen and (max-width: 479px) {
  #wrapper .component.rich-text .component-content .btn,
  #wrapper .component.rich-text .component-content [class*="btn-"] {
    margin-bottom: 1rem; } }

#wrapper .component.rich-text-list.usps {
  background-color: #005da9; }
  #wrapper .component.rich-text-list.usps .component-content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    width: 100%;
    padding: 1rem 0;
    display: table; }
    #wrapper .component.rich-text-list.usps .component-content ul {
      display: table-row;
      text-align: center; }
      #wrapper .component.rich-text-list.usps .component-content ul > li {
        font-size: 1.2rem;
        font-family: "Proxima N W01 Reg", sans-serif;
        padding: .4rem;
        color: #ffffff;
        display: table-cell; }
        #wrapper .component.rich-text-list.usps .component-content ul > li:not(:last-child) {
          border-right: 1px solid #f1f1f1; }
        #wrapper .component.rich-text-list.usps .component-content ul > li * {
          padding: 0;
          display: inline-block; }
        #wrapper .component.rich-text-list.usps .component-content ul > li:before {
          content: "";
          font-family: "retail-icon-font";
          display: inline-block;
          font-size: 1.3rem;
          color: #ffffff;
          font-weight: bold;
          transform: scale(0.6, 1);
          vertical-align: middle; }

@media only screen and (max-width: 767px) {
  #wrapper .component.rich-text-list.usps .component-content {
    padding: 0; }
    #wrapper .component.rich-text-list.usps .component-content ul > li {
      padding: 1.1rem 0;
      display: block; }
      #wrapper .component.rich-text-list.usps .component-content ul > li:not(:last-child) {
        border-right: 0;
        border-bottom: 1px solid #f1f1f1; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #wrapper .component.rich-text-list.usps .component-content {
    padding: 0; }
    #wrapper .component.rich-text-list.usps .component-content ul > li {
      padding: 1.1rem 0;
      display: inline-block;
      width: 48%; }
      #wrapper .component.rich-text-list.usps .component-content ul > li:nth-child(-n+2) {
        border-bottom: 1px solid #f1f1f1; }
      #wrapper .component.rich-text-list.usps .component-content ul > li:nth-child(even) {
        border-right: 0; } }

.ui-helper-hidden-accessible > div {
  display: none; }

#wrapper .search-result-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .blur-overlay {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: fixed;
  z-index: 15;
  background-color: #000000;
  top: 0; }

#wrapper .search-field-spot .search-field input {
  display: none;
  position: absolute;
  top: 10em;
  left: 0; }
  #wrapper .search-field-spot .search-field input::-ms-clear {
    display: none; }

#wrapper .search-field-spot.search-open .search-field input {
  display: block; }
  #wrapper .search-field-spot.search-open .search-field input::-ms-clear {
    display: none; }

#wrapper .search-field-spot.search-open .search-icon {
  display: block; }

#wrapper .component.search-result-spot:not([data-no-results="true"]) + .component.no-search-result {
  display: none; }

#wrapper .component.search-field-spot .search-field input[type="text"], #wrapper .component.search-result-spot .search-field input[type="text"] {
  border: 1px solid #005da9;
  width: 100%; }

#wrapper .component.search-field-spot .search-result, #wrapper .component.search-result-spot .search-result {
  padding-top: 3em; }
  #wrapper .component.search-field-spot .search-result h3 > a, #wrapper .component.search-field-spot .search-result .search-summary, #wrapper .component.search-result-spot .search-result h3 > a, #wrapper .component.search-result-spot .search-result .search-summary {
    color: #27251F;
    display: block;
    padding-bottom: 0.1em; }
  #wrapper .component.search-field-spot .search-result ul > li, #wrapper .component.search-result-spot .search-result ul > li {
    padding-bottom: 1.3em; }
    #wrapper .component.search-field-spot .search-result ul > li > a, #wrapper .component.search-result-spot .search-result ul > li > a {
      display: block;
      padding-bottom: 0.3em; }
    #wrapper .component.search-field-spot .search-result ul > li h3, #wrapper .component.search-result-spot .search-result ul > li h3 {
      padding: 0; }

#wrapper .component.search-result-spot .search-result-count {
  color: #bbbbbb;
  margin-top: -2.6em;
  z-index: 2;
  line-height: 2em;
  position: absolute;
  right: 3.5em; }
  #wrapper .component.search-result-spot .search-result-count span:after {
    cursor: pointer;
    transition: transform 0.2s;
    position: absolute;
    margin-left: 0.7em;
    font-family: "retail-icon-font";
    content: "";
    font-size: 1.4em; }

#wrapper .component.search-field-spot {
  float: right;
  display: inline-block;
  cursor: pointer; }
  #wrapper .component.search-field-spot::before {
    font-family: "retail-icon-font";
    content: "";
    color: #005da9;
    font-size: 1.5rem;
    position: relative;
    font-weight: 600;
    top: 1.2rem;
    opacity: 1;
    -webkit-transition: opacity, 0.2s;
    -moz-transition: opacity, 0.2s;
    -o-transition: opacity, 0.2s;
    transition: opacity, 0.2s;
    margin-left: 1em; }
  #wrapper .component.search-field-spot:hover::before {
    opacity: 0.6; }
  #wrapper .component.search-field-spot.search-open .search-field * {
    z-index: 10; }
  #wrapper .component.search-field-spot .search-field input {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0; }

#wrapper .component.no-search-result.rich-text .component-content {
  max-width: 90%;
  margin: 0 auto;
  width: 50em; }

#wrapper #header.burger-switch .row .grid-12 > div .component.search-field-spot::before {
  position: absolute;
  right: 1.2em;
  top: 0.8em; }

#wrapper #header.burger-switch .row .grid-12 > div .component.search-field-spot .search-field input {
  width: 100%; }

.ui-autocomplete {
  padding: 0;
  margin: 0;
  border: 1px solid #005da9;
  border-top: none;
  background: none;
  margin-left: 1.5em;
  z-index: 16; }
  .ui-autocomplete .ui-menu-item {
    background: #ffffff;
    font-size: 1em;
    padding: 1em 1.2em 0.9em 1.2em; }
    .ui-autocomplete .ui-menu-item.ui-state-focus {
      background-color: #f0f0f0;
      border: none;
      color: #005da9; }

#wrapper .component.screening-test-spot:not(.flex-layout) {
  text-align: center;
  background-color: #005da9;
  overflow-x: hidden; }
  #wrapper .component.screening-test-spot:not(.flex-layout) .intro-description,
  #wrapper .component.screening-test-spot:not(.flex-layout) .question-description,
  #wrapper .component.screening-test-spot:not(.flex-layout) .result-description {
    font-family: "Proxima N W01 Light", sans-serif;
    font-size: 1.5rem;
    line-height: 1.4;
    padding: 0 0 1.2em 0;
    margin: 0;
    padding: 0 10% 2em 10%;
    color: #ffffff;
    font-family: "Proxima N W01 Light", sans-serif; }
  #wrapper .component.screening-test-spot:not(.flex-layout) .result-description {
    padding: 0 7% 0 7%; }
    #wrapper .component.screening-test-spot:not(.flex-layout) .result-description + div > a.button {
      background-color: #e40049;
      color: #ffffff;
      display: inline-block;
      padding: 1em 2.5em;
      line-height: 1;
      border-radius: 2em;
      border: 0;
      font-size: 1rem;
      font-family: "Proxima N W01 Bold", sans-serif;
      cursor: pointer;
      width: auto;
      text-decoration: none;
      text-align: center;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      margin-top: 2rem; }
      #wrapper .component.screening-test-spot:not(.flex-layout) .result-description + div > a.button:hover {
        opacity: 0.7; }
  #wrapper .component.screening-test-spot:not(.flex-layout) .questions-intro .dynamic-header > *, #wrapper .component.screening-test-spot:not(.flex-layout) .questions-intro h2, #wrapper .component.screening-test-spot:not(.flex-layout) .questions .dynamic-header > *, #wrapper .component.screening-test-spot:not(.flex-layout) .questions h2 {
    font-size: 3.5rem;
    color: #ffffff;
    padding-top: .3em;
    padding-bottom: .4em;
    font-family: "Proxima N W01 Bold", sans-serif;
    line-height: 1; }
    @media only screen and (max-width: 479px) {
      #wrapper .component.screening-test-spot:not(.flex-layout) .questions-intro .dynamic-header > *, #wrapper .component.screening-test-spot:not(.flex-layout) .questions-intro h2, #wrapper .component.screening-test-spot:not(.flex-layout) .questions .dynamic-header > *, #wrapper .component.screening-test-spot:not(.flex-layout) .questions h2 {
        font-size: 2.5rem; } }
  #wrapper .component.screening-test-spot:not(.flex-layout) .questions-intro {
    padding-top: 2em; }
    #wrapper .component.screening-test-spot:not(.flex-layout) .questions-intro:before {
      background-image: url("/design/build/retail/assets/ear.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      content: "?";
      display: inline-block;
      width: 3.25rem;
      height: 3.6rem;
      color: #ffffff;
      position: absolute;
      top: -.9em;
      left: 50%;
      transform: translate(-50%, 0%);
      text-align: center;
      font-size: 1.8rem;
      padding-top: .8rem;
      line-height: 1;
      font-family: "Proxima N W01 Bold", sans-serif;
      background-image: url("/design/build/retail/assets/ear.svg");
      color: #005da9; }
  #wrapper .component.screening-test-spot:not(.flex-layout) .questions ul > li {
    padding-top: 2em; }
    #wrapper .component.screening-test-spot:not(.flex-layout) .questions ul > li:before {
      background-image: url("/design/build/retail/assets/ear.svg");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      content: "?";
      display: inline-block;
      width: 3.25rem;
      height: 3.6rem;
      color: #ffffff;
      position: absolute;
      top: -.9em;
      left: 50%;
      transform: translate(-50%, 0%);
      text-align: center;
      font-size: 1.8rem;
      padding-top: .8rem;
      line-height: 1;
      font-family: "Proxima N W01 Bold", sans-serif;
      background-image: url("/design/build/retail/assets/ear.svg");
      color: #005da9; }
  #wrapper .component.screening-test-spot:not(.flex-layout) .screening-test-result {
    display: none;
    padding-top: 2em; }
  #wrapper .component.screening-test-spot:not(.flex-layout) .button {
    background-color: #e40049;
    color: #ffffff;
    display: inline-block;
    padding: 1em 2.5em;
    line-height: 1;
    border-radius: 2em;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    cursor: pointer;
    width: auto;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s;
    -webkit-appearance: none;
    background-color: #a7d3ac;
    color: #005da9; }
    #wrapper .component.screening-test-spot:not(.flex-layout) .button:hover {
      opacity: 0.7; }
    #wrapper .component.screening-test-spot:not(.flex-layout) .button:last-child {
      margin-left: 1rem; }

@media only screen and (min-width: 1024px) {
  #wrapper .component.screening-test-spot .intro-description,
  #wrapper .component.screening-test-spot .question-description,
  #wrapper .component.screening-test-spot .result-description {
    padding: 0 25% 2em 25%; }
  #wrapper .component.screening-test-spot .result-description {
    padding: 0 15% 0 15%; } }

#wrapper .testimonial-spot {
  position: relative;
  overflow: hidden;
  min-height: 50px; }
  #wrapper .testimonial-spot * {
    box-sizing: border-box; }
  #wrapper .testimonial-spot .component-content .testimonial-container {
    padding: 4em 0;
    overflow: hidden;
    position: relative; }
    #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-spot-image-container {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 2rem;
      bottom: 2rem; }
      #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-spot-image-container .testimonial-spot-image {
        bottom: auto;
        width: 40%;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
        #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-spot-image-container .testimonial-spot-image img {
          display: inline-block;
          border-radius: 50%;
          margin: 0 auto;
          float: right; }
    #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-spot-text-container {
      position: absolute;
      width: 74rem;
      max-width: 90%;
      left: 50%;
      transform: translateX(-50%);
      top: 2rem;
      bottom: 2rem;
      pointer-events: none; }
      #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-spot-text-container .testimonial-spot-text {
        bottom: auto;
        width: 60%;
        left: 40%;
        padding-left: 2rem;
        padding-right: 0;
        pointer-events: all;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
    #wrapper .testimonial-spot .component-content .testimonial-container:nth-child(2n-1) {
      background-color: #f1f1f1; }
    #wrapper .testimonial-spot .component-content .testimonial-container:nth-child(2n) .testimonial-spot-image {
      left: 60%; }
      #wrapper .testimonial-spot .component-content .testimonial-container:nth-child(2n) .testimonial-spot-image img {
        float: left; }
    #wrapper .testimonial-spot .component-content .testimonial-container:nth-child(2n) .testimonial-spot-text {
      left: 0; }
    #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-statement {
      font-family: "Proxima N W01 Light", sans-serif;
      font-size: 1.5rem;
      line-height: 1.4;
      padding: 0 0 1.2em 0;
      margin: 0;
      padding: 4rem 0 1.6rem 4rem !important;
      position: relative;
      background-image: url("/design/build/retail/assets/quote.svg");
      background-size: 2.5em;
      background-repeat: no-repeat;
      background-position: 0 0; }
    #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-author,
    #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-additional-info {
      color: rgba(39, 37, 31, 0.6);
      display: block;
      padding-left: 4rem;
      padding-bottom: 0; }

@media only screen and (max-width: 767px) {
  #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-container-content .testimonial-spot-image-container {
    position: static;
    max-width: 90%;
    width: 100%;
    margin: auto;
    height: auto;
    transform: none;
    overflow: visible;
    margin-top: 2em; }
    #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-container-content .testimonial-spot-image-container .testimonial-spot-image {
      position: static;
      width: auto;
      padding: 0px 0px 1rem;
      transform: none;
      text-align: center; }
      #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-container-content .testimonial-spot-image-container .testimonial-spot-image img {
        float: none; }
  #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-container-content .testimonial-spot-text-container {
    position: static;
    width: 100%;
    max-width: 90%;
    margin: auto;
    transform: none; }
    #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-container-content .testimonial-spot-text-container .testimonial-spot-text {
      position: static;
      width: auto;
      padding: 0px;
      transform: none; } }

@media only screen and (max-width: 479px) {
  #wrapper .testimonial-spot .component-content .testimonial-container .testimonial-container-content .testimonial-spot-image-container img {
    max-width: 16rem;
    max-height: 16rem; } }

/* Available styles
.iright

.inormal
.islim

.ialignborder

.iw60
.iw40

.iposmiddle
.iposbottom


.tslim

.tw60
.tw40

.tposmiddle
.tposbottom

.tforceheight
*/
.on-page-editor #wrapper > #content .component.text-image-spot {
  min-height: 100px; }

#wrapper > #content .component.text-image-spot .text-image-spot-image-container a {
  display: block;
  text-decoration: none; }
  #wrapper > #content .component.text-image-spot .text-image-spot-image-container a::before, #wrapper > #content .component.text-image-spot .text-image-spot-image-container a::after {
    content: ' ';
    display: none; }

#wrapper > #content .component.text-image-spot:not(.version2) {
  position: relative;
  overflow: hidden; }
  #wrapper > #content .component.text-image-spot:not(.version2) * {
    box-sizing: border-box; }
  #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
    bottom: 2rem; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container .text-image-spot-image {
      position: absolute;
      top: 0;
      bottom: auto;
      width: 50%;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden; }
  #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container {
    position: absolute;
    width: 74rem;
    max-width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
    bottom: 2rem;
    pointer-events: none; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text {
      position: absolute;
      top: 0;
      bottom: auto;
      width: 50%;
      padding-left: 2rem;
      padding-right: 0;
      left: 50%;
      pointer-events: all; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text > *:first-child {
        margin-top: 0;
        padding-top: 0; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark {
        background-color: #f0f0f0;
        color: #bbbbbb; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-light {
        background-color: #ffffff;
        color: #bbbbbb; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate {
        background-color: #005da9;
        color: #ffffff; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark,
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white,
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate {
        width: 10em;
        height: 10em;
        padding: .5em;
        margin: 2em 1em 1em 0; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark h3, #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white h3,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate h3,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate .bigger {
          padding: 1em .3em;
          margin: 0;
          font-size: 1em;
          line-height: 1.333em; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark hr,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white hr,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate hr {
          padding: 0;
          margin: 0; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-dark .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-white .bigger,
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text .generic-spot-fact-corporate .bigger {
          font-size: 2.25em !important; }
  #wrapper > #content .component.text-image-spot:not(.version2).iright .text-image-spot-image {
    padding-left: 0;
    padding-right: 0;
    left: 50%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iright .text-image-spot-text {
    left: 0;
    padding-right: 2rem;
    padding-left: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).ialignborder .text-image-spot-image > img {
    float: left; }
  #wrapper > #content .component.text-image-spot:not(.version2).ialignborder.iright .text-image-spot-image > img {
    float: right; }
  #wrapper > #content .component.text-image-spot:not(.version2):not(.ialignborder) .text-image-spot-image > img {
    float: right; }
  #wrapper > #content .component.text-image-spot:not(.version2):not(.ialignborder).iright .text-image-spot-image > img {
    float: left; }
  #wrapper > #content .component.text-image-spot:not(.version2).inormal .text-image-spot-image-container {
    width: 74rem;
    max-width: 90%; }
  #wrapper > #content .component.text-image-spot:not(.version2).islim .text-image-spot-image-container {
    width: 60rem;
    max-width: 90%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw60 .text-image-spot-image {
    width: 60%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw60.iright .text-image-spot-image {
    left: 40%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw40 .text-image-spot-image {
    width: 40%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iw40.iright .text-image-spot-image {
    left: 60%; }
  #wrapper > #content .component.text-image-spot:not(.version2).iposmiddle .text-image-spot-image {
    bottom: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #wrapper > #content .component.text-image-spot:not(.version2).iposbottom .text-image-spot-image {
    bottom: 0;
    top: auto;
    transform: none; }
  #wrapper > #content .component.text-image-spot:not(.version2).tforceheight .text-image-spot-image-container {
    overflow: hidden; }
  @media only screen and (min-width: 992px) {
    #wrapper > #content .component.text-image-spot:not(.version2).tforceheight.small-image .text-image-spot-image {
      height: 100%; }
      #wrapper > #content .component.text-image-spot:not(.version2).tforceheight.small-image .text-image-spot-image img {
        height: 100%;
        width: auto;
        max-width: none; } }
  #wrapper > #content .component.text-image-spot:not(.version2).inoborder .text-image-spot-image-container {
    top: 0;
    bottom: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).inoborder:not(.tforceheight) .text-image-spot-text-container {
    top: 0;
    bottom: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).tslim .text-image-spot-text-container {
    width: 60rem;
    max-width: 90%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw60 .text-image-spot-text {
    width: 60%;
    left: 40%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw60.iright .text-image-spot-text {
    left: 0; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw40 .text-image-spot-text {
    width: 40%;
    left: 60%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tw40.iright .text-image-spot-text {
    left: 0%; }
  #wrapper > #content .component.text-image-spot:not(.version2).tposmiddle .text-image-spot-text {
    bottom: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #wrapper > #content .component.text-image-spot:not(.version2).tposbottom .text-image-spot-text {
    bottom: 0;
    top: auto;
    transform: none; }

@media only screen and (max-width: 800px) {
  #wrapper > #content .component.text-image-spot:not(.version2) {
    min-height: 0 !important; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container {
      position: static;
      max-width: 90%;
      width: 100%;
      margin: auto;
      height: auto;
      transform: none;
      overflow: visible;
      margin-top: 2em; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container .text-image-spot-image {
        position: static;
        width: auto;
        padding: 0px 0px 1rem;
        transform: none;
        text-align: center; }
        #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-image-container .text-image-spot-image img {
          float: none !important; }
    #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container {
      position: static;
      width: 100%;
      max-width: 90%;
      margin: auto;
      transform: none; }
      #wrapper > #content .component.text-image-spot:not(.version2) .text-image-spot-text-container .text-image-spot-text {
        position: static;
        width: auto;
        padding: 0px;
        transform: none; }
    #wrapper > #content .component.text-image-spot:not(.version2).no-mobile-image .text-image-spot-image-container {
      display: none; } }

#wrapper > #content .component.text-image-spot.version2 .text-image-spot-image-container {
  background-size: 0px; }

#wrapper > #content .component.text-image-spot.version2 .text-image-spot-text-container {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  padding: 1rem 0; }

#wrapper > #content .component.text-image-spot.version2.readmore .text-image-spot-readmore-open {
  display: block; }
  #wrapper > #content .component.text-image-spot.version2.readmore .text-image-spot-readmore-open:after {
    transform: rotate(45deg); }

#wrapper > #content .component.text-image-spot.version2.readmore + .text-image-spot-readmore {
  display: block;
  height: 0;
  overflow: hidden;
  color: #ffffff;
  background-color: #404040; }
  #wrapper > #content .component.text-image-spot.version2.readmore + .text-image-spot-readmore .text-image-spot-readmore-text {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    padding: 5rem 0; }
  #wrapper > #content .component.text-image-spot.version2.readmore + .text-image-spot-readmore .text-image-spot-readmore-close {
    display: block;
    text-align: center; }

#wrapper > #content .component.text-image-spot.version2.readmore--open .text-image-spot-readmore-open {
  display: none; }

#wrapper > #content .component.text-image-spot.version2.readmore--open .text-image-spot-readmore-less {
  display: block; }

#wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-open,
#wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-less,
#wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-close {
  display: none; }
  .mode-editing #wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-open, .mode-editing
  #wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-less, .mode-editing
  #wrapper > #content .component.text-image-spot.version2 .text-image-spot-readmore-close {
    display: inline-block;
    width: 100%; }

#wrapper > #content .text-image-spot-readmore {
  display: none; }
  .mode-editing #wrapper > #content .text-image-spot-readmore {
    display: block;
    color: #ffffff;
    background-color: #404040; }
    .mode-editing #wrapper > #content .text-image-spot-readmore .text-image-spot-readmore-text {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      padding: 5rem 0; }

#wrapper > #content .text-image-spot-readmore-less,
#wrapper > #content .text-image-spot-readmore-open,
#wrapper > #content .text-image-spot-readmore-close {
  text-align: center;
  padding-bottom: 4rem;
  margin-top: 2rem;
  font-size: 1rem; }
  #wrapper > #content .text-image-spot-readmore-less:after,
  #wrapper > #content .text-image-spot-readmore-open:after,
  #wrapper > #content .text-image-spot-readmore-close:after {
    font-family: "Oticon-icon-www";
    content: "";
    line-height: 4;
    font-size: .5rem;
    background-color: #c6168d;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    position: absolute;
    left: 50%;
    top: 2rem;
    margin-left: -20px; }

#wrapper > #content .text-image-spot-readmore-less:after {
  text-indent: 2px; }

#wrapper > #content .corporate-bg .text-image-spot-readmore-less,
#wrapper > #content .corporate-bg .text-image-spot-readmore-open,
#wrapper > #content .corporate-bg .text-image-spot-readmore-close {
  color: #c6168d; }
  #wrapper > #content .corporate-bg .text-image-spot-readmore-less:after,
  #wrapper > #content .corporate-bg .text-image-spot-readmore-open:after,
  #wrapper > #content .corporate-bg .text-image-spot-readmore-close:after {
    background-color: #ffffff;
    color: #ffffff; }

@media only screen and (min-width: 768px) {
  #wrapper > #content .component.text-image-spot.version2 {
    display: table;
    width: 100%;
    padding: 2rem 0;
    table-layout: fixed; }
    #wrapper > #content .component.text-image-spot.version2 .component-content {
      display: table-row;
      width: 100%; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image-container,
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text-container {
      display: table-cell;
      width: 50%; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text {
      position: relative; }
    #wrapper > #content .component.text-image-spot.version2:not(.iright) .text-image-spot-text {
      float: left;
      padding: 0 0 0 2rem; }
    #wrapper > #content .component.text-image-spot.version2.iright {
      direction: rtl; }
      #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-image-container {
        direction: ltr; }
      #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-text-container {
        direction: ltr; }
      #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-text {
        float: right;
        padding: 0 2rem 0 0; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text {
      box-sizing: border-box;
      width: 37rem;
      max-width: 90%; }
    #wrapper > #content .component.text-image-spot.version2.tslim .text-image-spot-text {
      width: 30rem; }
    #wrapper > #content .component.text-image-spot.version2.tw40 .text-image-spot-text {
      position: relative;
      width: 29.6rem;
      max-width: 72%;
      left: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tw40.iright .text-image-spot-text {
      left: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tw40.tslim .text-image-spot-text {
      width: 24rem;
      left: 6rem; }
    #wrapper > #content .component.text-image-spot.version2.tw60 .text-image-spot-text {
      position: relative;
      width: 44.4rem;
      max-width: 108%;
      left: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tw60.tslim .text-image-spot-text {
      width: 36rem;
      left: -6rem; }
    #wrapper > #content .component.text-image-spot.version2.tw60.iright .text-image-spot-text {
      left: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-text-container {
      vertical-align: top; }
    #wrapper > #content .component.text-image-spot.version2.tposmiddle .text-image-spot-text-container {
      vertical-align: middle; }
    #wrapper > #content .component.text-image-spot.version2.tposbottom .text-image-spot-text-container {
      vertical-align: bottom; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image {
      box-sizing: border-box;
      width: 100%;
      float: left; }
      #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image img {
        float: right; }
    #wrapper > #content .component.text-image-spot.version2.inormal .text-image-spot-image img {
      width: 37rem;
      max-width: 90%; }
    #wrapper > #content .component.text-image-spot.version2.islim .text-image-spot-image img {
      width: 30rem;
      max-width: 90%; }
    #wrapper > #content .component.text-image-spot.version2.iright .text-image-spot-image img {
      float: left; }
    #wrapper > #content .component.text-image-spot.version2.ialignborder:not(.iright) .text-image-spot-image img {
      float: right; }
    #wrapper > #content .component.text-image-spot.version2.iw40 .text-image-spot-image {
      width: 80%; }
    #wrapper > #content .component.text-image-spot.version2.iw40.iright .text-image-spot-image {
      margin-left: 20%; }
    #wrapper > #content .component.text-image-spot.version2.iw40.inormal .text-image-spot-image {
      width: 29.6rem; }
    #wrapper > #content .component.text-image-spot.version2.iw40.inormal.iright .text-image-spot-image {
      margin-left: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.iw40.islim .text-image-spot-image {
      width: 24rem; }
    #wrapper > #content .component.text-image-spot.version2.iw40.islim.iright .text-image-spot-image {
      margin-left: 6rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60 .text-image-spot-image {
      width: 120%; }
    #wrapper > #content .component.text-image-spot.version2.iw60.iright .text-image-spot-image {
      margin-left: -20%; }
    #wrapper > #content .component.text-image-spot.version2.iw60.inormal .text-image-spot-image {
      width: 44.4rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60.inormal.iright .text-image-spot-image {
      margin-left: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60.islim .text-image-spot-image {
      width: 36rem; }
    #wrapper > #content .component.text-image-spot.version2.iw60.islim.iright .text-image-spot-image {
      margin-left: -6rem; }
    #wrapper > #content .component.text-image-spot.version2 .text-image-spot-image-container {
      vertical-align: top; }
    #wrapper > #content .component.text-image-spot.version2.iposmiddle .text-image-spot-image-container {
      vertical-align: middle; }
    #wrapper > #content .component.text-image-spot.version2.iposbottom .text-image-spot-image-container {
      vertical-align: bottom; }
    #wrapper > #content .component.text-image-spot.version2.inoborder {
      padding: 0; }
      #wrapper > #content .component.text-image-spot.version2.inoborder .text-image-spot-text-container {
        padding: 2rem 0; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight .text-image-spot-image-container {
      background-size: cover;
      background-position: center; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight .text-image-spot-image img {
      display: none; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).inormal .text-image-spot-image {
      left: auto;
      right: 0; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).inormal.iw60 .text-image-spot-image {
      right: -7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).inormal.iw40 .text-image-spot-image {
      right: 7.4rem; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).islim .text-image-spot-image {
      left: auto;
      right: 0; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).islim.iw60 .text-image-spot-image {
      right: -6rem; }
    #wrapper > #content .component.text-image-spot.version2.tforceheight:not(.iright).islim.iw40 .text-image-spot-image {
      right: 6rem; } }

#wrapper .component.text-image-spot .text-image-spot-image .text-image-spot-image-description {
  padding: 0.5rem;
  float: left;
  width: 100%; }

#wrapper .component.text-image-spot .text-image-spot-text a, #wrapper .component.text-image-spot .text-image-spot-text a span {
  transition: none !important; }

@media only screen and (max-width: 767px) {
  #wrapper .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  #wrapper .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

#wrapper .component.text-image-spot.image-description-bg-corporate .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-corporate .text-image-spot-image .text-image-spot-image-description {
    background-color: #005da9;
    color: #ffffff; }

#wrapper .component.text-image-spot.image-description-bg-green .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-green .text-image-spot-image .text-image-spot-image-description {
    background-color: #a7d3ac;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-green-dark .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-green-dark .text-image-spot-image .text-image-spot-image-description {
    background-color: #96bd9a;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-nounce-green .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-nounce-green .text-image-spot-image .text-image-spot-image-description {
    background-color: #d3e9d5;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-nounce-blue .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-nounce-blue .text-image-spot-image .text-image-spot-image-description {
    background-color: #68afd6;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-light-grey .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-light-grey .text-image-spot-image .text-image-spot-image-description {
    background-color: #f1f1f1;
    color: inherit; }

#wrapper .component.text-image-spot.image-description-bg-light-red .text-image-spot-image {
  overflow: hidden; }
  #wrapper .component.text-image-spot.image-description-bg-light-red .text-image-spot-image .text-image-spot-image-description {
    background-color: #ec4d80;
    color: inherit; }

@media only screen and (min-width: 768px) {
  #wrapper > #content .component.text-image-spot.alignment.version2 .component-content {
    display: block;
    width: 0;
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }
  #wrapper > #content .component.text-image-spot.alignment.version2.tw60 .text-image-spot-text {
    left: 0; }
  #wrapper > #content .component.text-image-spot.alignment.version2.tw60.iright .text-image-spot-text {
    left: 0; } }

@media only screen and (max-width: 479px) {
  #wrapper .component.text-image-spot .text-image-spot-image .text-image-spot-image-description {
    margin-top: -2px; }
  #wrapper .component.text-image-spot .component-content .text-image-spot-text .btn,
  #wrapper .component.text-image-spot .component-content .text-image-spot-text [class*="btn-"] {
    margin-bottom: 1rem; } }

#wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content {
  display: flex;
  flex-direction: row;
  align-items: stretch; }
  #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-image-container {
    margin-right: 1px;
    display: flex;
    align-items: stretch; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-image-container .text-image-spot-image img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container {
    padding: 0;
    margin-left: 1px;
    display: block; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container .text-image-spot-text {
      padding: 0;
      width: 100%;
      max-width: 100%; }
      #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container .text-image-spot-text img {
        object-fit: cover;
        display: block; }

@media only screen and (max-width: 767px) {
  #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content {
    flex-direction: column; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-image-container {
      margin-right: 0; }
    #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container {
      margin-left: 0;
      padding: 0;
      width: 100%;
      max-width: 100%;
      margin-top: 2px; }
      #wrapper > #content .component.text-image-spot.version2.text-image-spot-two-images .component-content .text-image-spot-text-container .text-image-spot-text {
        width: 100%;
        max-width: 100%; } }

#wrapper #content .component.two-section-grid .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

@media only screen and (max-width: 767px) {
  #wrapper #content .component.two-section-grid.mobile-no-image {
    background-image: none !important; } }

#wrapper #content .component.two-section-grid.background-center {
  background-position: center center; }

#wrapper #content .component.two-section-grid.blog--two-col > .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  @media only screen and (min-width: 992px) {
    #wrapper #content .component.two-section-grid.blog--two-col > .component-content .section-one {
      width: 65%; }
    #wrapper #content .component.two-section-grid.blog--two-col > .component-content .section-two {
      width: 29%;
      margin-left: 6%; } }

#wrapper #content .component.two-section-grid.two-col-padding {
  padding-left: 0;
  padding-right: 0; }

#wrapper #content .component.two-section-grid.form-two-column {
  min-height: auto !important;
  background-position: center; }
  #wrapper #content .component.two-section-grid.form-two-column .component-content {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 100%; }
    #wrapper #content .component.two-section-grid.form-two-column .component-content > div .component.form {
      padding-right: 10%;
      padding-left: 10%;
      width: 100%; }
      #wrapper #content .component.two-section-grid.form-two-column .component-content > div .component.form .data8-container {
        flex-wrap: wrap; }
        #wrapper #content .component.two-section-grid.form-two-column .component-content > div .component.form .data8-container input[type=text],
        #wrapper #content .component.two-section-grid.form-two-column .component-content > div .component.form .data8-container .postcodeLookup {
          flex-basis: 50%; }
    #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one,
    #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two {
      width: 50%;
      margin: 0;
      display: flex;
      align-items: center; }
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one .component.image,
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two .component.image {
        display: none; }
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one .component.form.padding-top7,
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two .component.form.padding-top7 {
        padding-top: 7rem; }
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one .component.form.padding-top4,
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two .component.form.padding-top4 {
        padding-top: 4rem; }
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one .component.form.padding-top2,
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two .component.form.padding-top2 {
        padding-top: 2rem; }

@media (max-width: 991px) {
  #wrapper #content .component.two-section-grid.form-two-column {
    background-image: none !important; }
    #wrapper #content .component.two-section-grid.form-two-column .component-content {
      flex-direction: column; }
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one,
      #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two {
        width: 100%; }
        #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one .component.image,
        #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two .component.image {
          display: block;
          width: 100%; }
          #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one .component.image a,
          #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two .component.image a {
            font-size: 0;
            width: 100%; }
            #wrapper #content .component.two-section-grid.form-two-column .component-content .section-one .component.image a:hover,
            #wrapper #content .component.two-section-grid.form-two-column .component-content .section-two .component.image a:hover {
              opacity: 1;
              cursor: default; } }

#wrapper .video-spot.no-data-source {
  display: none !important; }

#wrapper .video-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .video-spot .component-content .video-spot-container .video-spot-video {
    position: relative;
    display: inline-block;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video:not(:last-child) {
      margin-bottom: 1rem; }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video .embed-code {
      display: none; }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-image {
      cursor: pointer; }
      #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-image img {
        display: block; }
    #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn {
      cursor: pointer;
      background-color: #005da9;
      width: 7em;
      height: 7em;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none; }
      #wrapper .video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn::after {
        font-family: "Oticon-icon-www";
        content: '\ea96';
        font-size: 4.5em;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
  #wrapper .video-spot .component-content .video-spot-container .inline-video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

#wrapper .video-spot.play-inline .component-content .video-spot-container .video-spot-video {
  visibility: hidden; }

#wrapper .video-spot.full-width-image .component-content .video-spot-container .video-spot-video {
  width: 100%; }
  #wrapper .video-spot.full-width-image .component-content .video-spot-container .video-spot-video .video-spot-image img {
    width: 100%;
    height: auto; }

#wrapper .video-spot-overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; }
  #wrapper .video-spot-overlay .video-spot-overlay-close {
    width: 3em;
    height: 3em;
    position: fixed;
    top: 3em;
    right: 3em;
    cursor: pointer; }
    #wrapper .video-spot-overlay .video-spot-overlay-close::after {
      content: '\eae3';
      position: absolute;
      top: 0;
      left: 0;
      font-family: "Oticon-icon-www";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 3em;
      color: rgba(255, 255, 255, 0.5); }
    #wrapper .video-spot-overlay .video-spot-overlay-close:hover::after {
      color: #ffffff; }
  #wrapper .video-spot-overlay .video-spot-overlay-video {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

#wrapper .component.video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn {
  background-color: #e40049;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem; }
  #wrapper .component.video-spot .component-content .video-spot-container .video-spot-video .video-spot-play-btn:after {
    font-family: "retail-icon-font";
    left: 54%;
    transform: translate(-45%, -50%);
    font-size: 3.5rem;
    content: "";
    font-weight: 300; }

#wrapper .component.video-spot.hide-btn .video-spot-play-btn {
  display: none; }

#wrapper .video-spot-overlay {
  background-color: rgba(0, 0, 0, 0.3); }
  #wrapper .video-spot-overlay .video-spot-overlay-close::after {
    content: "";
    font-family: "retail-icon-font"; }

.component.geo-ip-prompt {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }
  .component.geo-ip-prompt.show {
    display: flex !important; }
  .component.geo-ip-prompt .component-content {
    background-color: #005da9;
    max-width: 45rem;
    padding: 1rem;
    position: relative;
    text-align: center;
    width: 70%; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-dismiss {
      background-color: transparent;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      font-size: 0;
      height: 1.2rem;
      position: absolute;
      right: 1rem;
      top: 0.9rem;
      padding: 0.1rem;
      width: 1.2rem; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-title {
      font-family: "Proxima N W01 Bold", sans-serif;
      font-size: 1.6rem;
      margin: 0.5rem 0 1rem 0; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-intro {
      font-size: 1rem;
      margin: 0 auto 1rem auto;
      width: 80%; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-countryspecificsite a,
    .component.geo-ip-prompt .component-content .geo-ip-prompt-globalsite a,
    .component.geo-ip-prompt .component-content .geo-ip-prompt-currentsite a {
      border: 0.2rem solid #3b3c3d;
      color: #5f5f61;
      cursor: pointer;
      display: block;
      font-family: inherit;
      height: 100%;
      margin: 0.5rem auto;
      max-width: 20rem;
      padding: 1rem;
      text-align: center;
      width: 100%; }

#wrapper #content .component.geo-ip-prompt {
  align-items: flex-start;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    #wrapper #content .component.geo-ip-prompt {
      align-items: center; } }
  #wrapper #content .component.geo-ip-prompt .component-content {
    background-color: #f7f6f4;
    max-width: 100%;
    width: 100%;
    padding: 5%; }
    @media only screen and (min-width: 768px) {
      #wrapper #content .component.geo-ip-prompt .component-content {
        max-width: 50rem;
        width: 70%;
        padding: 1rem; } }
    #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-dismiss {
      display: none; }
    #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-title {
      color: #005da9; }
    #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-intro {
      color: #005da9;
      width: 100%; }
      @media only screen and (min-width: 768px) {
        #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-intro {
          width: 80%; } }
    #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-countryspecificsite a,
    #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-globalsite a,
    #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-currentsite a {
      background-color: #e40049;
      color: #ffffff;
      display: inline-block;
      padding: 1em 2.5em;
      line-height: 1;
      border-radius: 2em;
      border: 0;
      font-size: 1rem;
      font-family: "Proxima N W01 Bold", sans-serif;
      cursor: pointer;
      width: auto;
      text-decoration: none;
      text-align: center;
      box-sizing: border-box;
      opacity: 1;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      padding: 0.6428rem 1.7142rem;
      color: #ffffff;
      border: 0.07143rem solid;
      border-color: #005da9;
      border-radius: 1.2rem;
      background-color: #005da9;
      width: 100%; }
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-countryspecificsite a:hover,
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-globalsite a:hover,
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-currentsite a:hover {
        opacity: 0.7; }
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-countryspecificsite a:not(.disabled):not(:disabled):active, #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-countryspecificsite a.nice-select.open:not(.disabled):not(:disabled),
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-globalsite a:not(.disabled):not(:disabled):active,
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-globalsite a.nice-select.open:not(.disabled):not(:disabled),
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-currentsite a:not(.disabled):not(:disabled):active,
      #wrapper #content .component.geo-ip-prompt .component-content .geo-ip-prompt-currentsite a.nice-select.open:not(.disabled):not(:disabled) {
        box-shadow: inset 0 0 0.095rem 0.095rem rgba(0, 0, 0, 0.1);
        background-color: #68afd6; }

body.mode-normal #wrapper .site-notification-spot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: none; }
  body.mode-normal #wrapper .site-notification-spot .component-content {
    user-select: none;
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #ffffff;
    margin-top: 4rem;
    padding: 3rem;
    border: 1px solid #005da9; }
    body.mode-normal #wrapper .site-notification-spot .component-content:after {
      font-family: "retail-icon-font";
      content: "";
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      font-size: 2em; }

#wrapper .rich-content-spot .component-content {
  max-width: none;
  width: 100% !important;
  padding: 2rem 0 2rem; }
  #wrapper .rich-content-spot .component-content .content {
    position: relative;
    width: 74rem;
    max-width: 90%;
    margin: 0 auto; }

#wrapper .rich-content-spot.slim .component-content .content {
  width: 60rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper .rich-content-container {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }

#wrapper > #content .component.form .data8-container input[type="text"] {
  min-width: 0; }

#wrapper > #content .component.form .data8-postcodelookup-dropdown {
  top: 3.1rem !important;
  left: 0 !important; }
  #wrapper > #content .component.form .data8-postcodelookup-dropdown .data8-postcodelookup-ok {
    width: 6rem;
    margin-right: 1rem;
    color: #000000; }
  #wrapper > #content .component.form .data8-postcodelookup-dropdown .data8-postcodelookup-cancel {
    width: 6rem;
    margin-right: 1rem;
    color: #000000; }
  #wrapper > #content .component.form .data8-postcodelookup-dropdown .data8-postcodelookup-list option {
    color: #000000; }

#wrapper > #content .component.form input.postcodeLookup {
  min-width: 6.5rem;
  background-color: #e6e6e6;
  border: 1px solid #D5D5D5;
  border-radius: 0;
  -webkit-appearance: button;
  color: #000000; }

.component.hearing-test-spot .step-manager {
  position: relative;
  width: 100%;
  overflow: hidden; }

.component.hearing-test-spot .progress-bar {
  position: relative;
  z-index: 10; }
  .component.hearing-test-spot .progress-bar__blocks {
    display: flex;
    flex-direction: row; }
  .component.hearing-test-spot .progress-bar__block {
    height: 6px;
    background-color: grey; }
    .component.hearing-test-spot .progress-bar__block--active {
      background-color: #a7d3ac; }
  .component.hearing-test-spot .progress-bar__balloon {
    display: none;
    position: absolute;
    top: 10px;
    padding: 5px;
    background-color: #a7d3ac;
    border-radius: 4px;
    transform: translateX(-50%);
    transition: left 0.3s ease-in-out; }
    @media only screen and (min-width: 568px) {
      .component.hearing-test-spot .progress-bar__balloon {
        display: block; } }
    .component.hearing-test-spot .progress-bar__balloon:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #a7d3ac; }
  .component.hearing-test-spot .progress-bar__text {
    color: white;
    white-space: nowrap; }

#wrapper .component.hearing-test-spot .step-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto 1rem; }
  #wrapper .component.hearing-test-spot .step-header__icon-wrapper div div {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem; }
  #wrapper .component.hearing-test-spot .step-header__icon {
    width: 100%;
    max-height: 50px;
    fill: white; }
  #wrapper .component.hearing-test-spot .step-header__title {
    font-size: 1.5rem;
    text-align: center;
    color: #a7d3ac;
    text-transform: none;
    margin: 0; }

#wrapper .component.hearing-test-spot .step {
  display: flex;
  position: relative;
  width: 100%;
  transition: transform 300ms ease-in-out;
  color: white;
  background: white no-repeat bottom;
  box-sizing: border-box;
  align-items: center; }
  @media only screen and (min-width: 568px) {
    #wrapper .component.hearing-test-spot .step {
      padding: 3rem 2rem;
      width: 100%; } }
  #wrapper .component.hearing-test-spot .step__wrapper {
    width: 70%;
    margin: 0 auto; }
  #wrapper .component.hearing-test-spot .step__content {
    display: block;
    margin: 0 auto 5rem auto;
    text-align: center;
    max-width: 900px;
    padding: 1rem 2rem;
    background: #ffffff; }
  #wrapper .component.hearing-test-spot .step__title {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #333333;
    font-family: "Proxima N W01 Reg", sans-serif;
    line-height: 3rem;
    padding: 0; }
  #wrapper .component.hearing-test-spot .step__body {
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 2rem;
    text-align: center;
    padding: 0; }
  #wrapper .component.hearing-test-spot .step-enter {
    transform: translate3d(100vw, 0, 0); }
  #wrapper .component.hearing-test-spot .step-enter-active {
    position: relative;
    transform: translate3d(0, 0, 0); }
  #wrapper .component.hearing-test-spot .step-exit {
    position: absolute;
    transform: translate3d(0, 0, 0); }
  #wrapper .component.hearing-test-spot .step-exit-active {
    transform: translate3d(-100vw, 0, 0); }
  #wrapper .component.hearing-test-spot .step--button .step__title {
    margin-bottom: 2rem; }
  #wrapper .component.hearing-test-spot .step:not(.step--button) h1.step__title {
    font-family: "Proxima N W01 Bold", sans-serif;
    color: #333333; }
  #wrapper .component.hearing-test-spot .step--environment {
    background-position: center;
    background-size: cover;
    align-items: flex-end; }
    #wrapper .component.hearing-test-spot .step--environment .step__content {
      padding: 2rem 2rem 3rem 2rem;
      margin: 0 auto; }
      #wrapper .component.hearing-test-spot .step--environment .step__content .step-header__title {
        padding: 0; }
      #wrapper .component.hearing-test-spot .step--environment .step__content h1.step__title {
        padding: 0;
        margin-bottom: 1.5rem; }
      #wrapper .component.hearing-test-spot .step--environment .step__content .step__body {
        width: 80%;
        margin: 0 auto 2rem auto;
        padding: 0; }
      #wrapper .component.hearing-test-spot .step--environment .step__content .tone-player .circular-progress-bar__wrapper {
        margin-bottom: 0; }
      #wrapper .component.hearing-test-spot .step--environment .step__content .step__environment--questions {
        margin-bottom: -.5rem; }
        #wrapper .component.hearing-test-spot .step--environment .step__content .step__environment--questions .step__environment--answers {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; }
          #wrapper .component.hearing-test-spot .step--environment .step__content .step__environment--questions .step__environment--answers .step-button {
            background: #ffffff;
            color: #005da9;
            border: 1px solid #005da9;
            flex-grow: 1;
            margin: .5rem; }
  #wrapper .component.hearing-test-spot .step--tone-calibration-intro h1.step__title {
    padding: 0; }
  #wrapper .component.hearing-test-spot .step--tone-calibration-intro .step__body {
    width: 80%;
    margin: 0 auto 2rem auto; }
  #wrapper .component.hearing-test-spot .step--tone-calibration-intro .step-button {
    margin-top: 2rem; }

#wrapper .component.hearing-test-spot .step-button {
  color: white;
  background: #005da9;
  border: 0;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0 1rem;
  margin: 0 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 1.75rem;
  min-width: 10rem;
  font-family: "Proxima N W01 Bold", sans-serif; }
  #wrapper .component.hearing-test-spot .step-button:focus {
    background: #005da9; }
  #wrapper .component.hearing-test-spot .step-button:hover {
    background: rgba(0, 93, 169, 0.5); }

#wrapper .component.hearing-test-spot .step-circle {
  border: 2px solid #005da9;
  width: 3rem;
  height: 3rem;
  font-size: 2.5rem;
  line-height: 2.9rem;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  margin: 0 auto 0.5rem;
  color: #005da9; }

body.flex-header #wrapper .component.hearing-test-spot .step {
  min-height: calc(100vh - 3.75rem - 3.7rem); }

body:not(.flex-header) #wrapper .component.hearing-test-spot .step {
  min-height: calc(100vh - 8.765rem); }

@media only screen and (max-width: 991px) {
  #wrapper .component.hearing-test-spot .step {
    padding-bottom: 6rem; }
    #wrapper .component.hearing-test-spot .step__wrapper {
      width: 90%; }
    #wrapper .component.hearing-test-spot .step__content {
      margin: 0 auto;
      padding: 2rem 0; }
    #wrapper .component.hearing-test-spot .step--environment {
      padding: 2rem 0;
      display: flex;
      align-items: flex-end; }
      #wrapper .component.hearing-test-spot .step--environment .step__content {
        padding: 2rem 1rem; }
    #wrapper .component.hearing-test-spot .step .step-button {
      min-width: 9rem;
      height: 3.5rem;
      border-radius: 1.75rem; }
  body.flex-header #wrapper .component.hearing-test-spot .step {
    min-height: calc(100vh - 3.4rem - 4.25rem); }
  body:not(.flex-header) #wrapper .component.hearing-test-spot .step {
    min-height: calc(100vh - 3.2rem); } }

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb; }

.rc-slider-handle {
  position: absolute;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x; }

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none; }

.rc-slider-handle:hover {
  border-color: #57c5f7; }

.rc-slider-handle:active, .rc-slider-handle.nice-select.open {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
  transform: translateX(-50%); }
  .rc-slider-mark-text:first-child {
    left: 20%; }
  .rc-slider-mark-text:last-child {
    left: 80%; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.component.hearing-test-spot .step--slider .slider__wrapper {
  width: 100%;
  max-width: 400px;
  padding: 0;
  margin: 0 auto 3rem;
  position: relative; }

.component.hearing-test-spot .step--slider .slider__container {
  display: inline-block;
  width: 65%;
  margin: 0 1.5rem; }

.component.hearing-test-spot .step--slider .step-circle {
  cursor: pointer;
  background-color: white;
  line-height: 1.7rem;
  font-size: 1.8rem;
  overflow: hidden;
  padding: 0;
  user-select: none;
  position: absolute;
  top: -0.7rem;
  left: 0;
  color: #005da9; }
  .component.hearing-test-spot .step--slider .step-circle:last-child {
    left: auto;
    right: 0; }

.component.hearing-test-spot .step--slider .rc-slider {
  display: block; }
  .component.hearing-test-spot .step--slider .rc-slider-handle {
    border-color: #a7d3ac; }
    .component.hearing-test-spot .step--slider .rc-slider-handle:focus, .component.hearing-test-spot .step--slider .rc-slider-handle:active, .component.hearing-test-spot .step--slider .rc-slider-handle.nice-select.open {
      box-shadow: 0 0 5px #a7d3ac; }
  .component.hearing-test-spot .step--slider .rc-slider-track {
    background-color: #a7d3ac; }
  .component.hearing-test-spot .step--slider .rc-slider-mark-text {
    color: white;
    white-space: nowrap; }

.component.hearing-test-spot .volume-controls {
  display: inline-flex;
  align-items: center;
  margin-bottom: 3rem; }
  .component.hearing-test-spot .volume-controls .step-circle {
    cursor: pointer;
    background-color: white;
    line-height: 1.7rem;
    font-size: 1.8rem;
    overflow: hidden;
    padding: 0;
    user-select: none;
    position: absolute;
    top: -0.7rem;
    left: 0;
    color: #005da9;
    position: initial;
    margin: 0 !important; }
    .component.hearing-test-spot .volume-controls .step-circle:last-child {
      left: auto;
      right: 0; }

.component.hearing-test-spot .volume-bars {
  display: inline-block;
  width: auto;
  margin: 0 2rem; }

.component.hearing-test-spot .volume-bar {
  display: inline-block;
  width: 8px;
  margin: 0 1.5px;
  cursor: pointer;
  border: 2px solid white;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0; }
  .component.hearing-test-spot .volume-bar.filled {
    background-color: #005da9; }
  .component.hearing-test-spot .volume-bar.empty {
    background-color: rgba(0, 93, 169, 0.5); }

.component.hearing-test-spot .volume-bar:nth-child(1) {
  height: 7px; }

.component.hearing-test-spot .volume-bar:nth-child(2) {
  height: 14px; }

.component.hearing-test-spot .volume-bar:nth-child(3) {
  height: 21px; }

.component.hearing-test-spot .volume-bar:nth-child(4) {
  height: 28px; }

.component.hearing-test-spot .volume-bar:nth-child(5) {
  height: 35px; }

.component.hearing-test-spot .volume-bar:nth-child(6) {
  height: 42px; }

.component.hearing-test-spot .volume-bar:nth-child(7) {
  height: 49px; }

.component.hearing-test-spot .volume-bar:nth-child(8) {
  height: 56px; }

.component.hearing-test-spot .circular-progress-bar__background, .component.hearing-test-spot .circular-progress-bar__progress {
  fill: none; }

.component.hearing-test-spot .circular-progress-bar__background {
  stroke: #a7d3ac; }

.component.hearing-test-spot .circular-progress-bar__progress {
  stroke: #005da9;
  stroke-linecap: round;
  stroke-linejoin: round; }

.component.hearing-test-spot .circular-progress-bar__text {
  font-size: 1em;
  font-weight: bold;
  fill: #a7d3ac; }

.component.hearing-test-spot .tone-player .circular-progress-bar__wrapper {
  position: relative;
  margin-bottom: 2rem;
  margin-top: 2rem; }

.component.hearing-test-spot .tone-player__control {
  cursor: pointer; }
  .component.hearing-test-spot .tone-player__control:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #005da9;
    font-family: "retail-icon-font";
    font-size: 3rem; }
  .component.hearing-test-spot .tone-player__control--play:after {
    content: "";
    left: 50.5%; }
  .component.hearing-test-spot .tone-player__control--stop:after {
    content: ""; }

#wrapper #content .component.hearing-test-intro > .component-content,
#wrapper #content .component.hearing-test-result > .component-content {
  padding: 1.5rem; }
  @media only screen and (min-width: 992px) {
    #wrapper #content .component.hearing-test-intro > .component-content,
    #wrapper #content .component.hearing-test-result > .component-content {
      padding: 2.5rem; } }
  #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text,
  #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text {
    padding: 0; }
    #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text .component-content,
    #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text .component-content {
      width: 100%;
      max-width: 100%; }
      #wrapper #content .component.hearing-test-intro > .component-content > .component.rich-text .component-content h1,
      #wrapper #content .component.hearing-test-result > .component-content > .component.rich-text .component-content h1 {
        font-family: "Proxima N W01 Bold", sans-serif;
        font-size: 2.5rem;
        margin: 0;
        padding: 0 0 0.6em 0;
        line-height: 1;
        color: #005da9;
        display: block; }

#wrapper #content .component.hearing-test-intro .component.image-spot {
  padding-top: 2rem; }
  @media only screen and (min-width: 992px) {
    #wrapper #content .component.hearing-test-intro .component.image-spot {
      padding-top: 3rem; } }
  #wrapper #content .component.hearing-test-intro .component.image-spot .component-content {
    width: 100%;
    max-width: 100%; }
    #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element {
      padding-right: 1rem;
      margin-bottom: 0; }
      @media only screen and (max-width: 991px) {
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element {
          width: 100%;
          display: flex;
          margin-bottom: 1.5rem; }
          #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            padding: 0;
            text-align: left; }
          #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
            max-width: 100%;
            display: flex;
            align-items: center;
            margin: 0 0 0 1.5rem;
            text-align: left; }
          #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements .image-spot-element .image-spot-element-text {
            display: none; } }
    #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements img {
      max-width: 50px; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .component.hearing-test-intro .component.image-spot .component-content .image-spot-elements img {
          max-width: 80px; } }

@media only screen and (max-width: 991px) {
  #wrapper #content .component.hearing-test-intro .component.rich-text:last-of-type .component-content h3 {
    display: none; }
  #wrapper #content .component.hearing-test-intro .component.rich-text:last-of-type .component-content h3 + p {
    margin-top: 0; } }

#wrapper #content .component.hearing-test-result .component.rich-text .component-content h3 {
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 1.5rem;
  margin: 0;
  padding: 0 0 0.6em 0;
  line-height: 1;
  color: #005da9;
  display: block; }

#wrapper #content .component.hearing-test-result .component.rich-text .component-content .like-orange-button-large {
  margin-top: 0.5rem; }
  #wrapper #content .component.hearing-test-result .component.rich-text .component-content .like-orange-button-large:first-of-type {
    margin-right: 1rem; }

#wrapper #content .component.hearing-test-result .component.rich-text .component-content p:last-of-type {
  width: 100%; }

#wrapper .component.job-list-spot .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto; }
  #wrapper .component.job-list-spot .component-content ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between; }
    #wrapper .component.job-list-spot .component-content ul li {
      display: flex;
      flex-direction: column;
      width: 49%;
      background: #EFEFEF;
      padding: 1.25rem;
      box-sizing: border-box;
      margin-bottom: .75rem; }
      #wrapper .component.job-list-spot .component-content ul li .job-header {
        padding: 0;
        font-size: 1.125rem;
        margin-bottom: .25rem; }
      #wrapper .component.job-list-spot .component-content ul li div img {
        display: none; }
      #wrapper .component.job-list-spot .component-content ul li div .location {
        color: #000000;
        font-family: "Proxima N W01 Reg", sans-serif; }

@media (max-width: 991px) {
  #wrapper .component.job-list-spot .component-content ul li {
    width: 100%; } }

#wrapper .component.job-details .component-content {
  position: relative;
  width: 74rem;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start; }
  #wrapper .component.job-details .component-content .job-details-content {
    width: 60%; }
    #wrapper .component.job-details .component-content .job-details-content * {
      font-size: 1.125rem; }
    #wrapper .component.job-details .component-content .job-details-content small,
    #wrapper .component.job-details .component-content .job-details-content small * {
      font-size: 0.8rem; }
    #wrapper .component.job-details .component-content .job-details-content .job-header {
      font-size: 3rem; }
    #wrapper .component.job-details .component-content .job-details-content h1 {
      font-size: 3rem; }
      #wrapper .component.job-details .component-content .job-details-content h1 * {
        font-size: 3rem; }
    #wrapper .component.job-details .component-content .job-details-content h2 {
      font-size: 2.5rem; }
      #wrapper .component.job-details .component-content .job-details-content h2 * {
        font-size: 2.5rem; }
    #wrapper .component.job-details .component-content .job-details-content h3 {
      font-size: 2rem; }
      #wrapper .component.job-details .component-content .job-details-content h3 * {
        font-size: 2rem; }
    #wrapper .component.job-details .component-content .job-details-content h4 {
      font-size: 1.5rem; }
      #wrapper .component.job-details .component-content .job-details-content h4 * {
        font-size: 1.5rem; }
    #wrapper .component.job-details .component-content .job-details-content ul {
      list-style: inside;
      padding: 0 0 1.2em 0; }
      #wrapper .component.job-details .component-content .job-details-content ul li {
        line-height: 1.5rem; }
  #wrapper .component.job-details .component-content .right-content {
    width: 38%;
    background: #E1F0E3;
    border-top: 3px solid #A7D3AC; }
    #wrapper .component.job-details .component-content .right-content .job-details-facts {
      padding: 1.875rem;
      display: flex;
      flex-direction: column; }
      #wrapper .component.job-details .component-content .right-content .job-details-facts img {
        display: none; }
      #wrapper .component.job-details .component-content .right-content .job-details-facts .job-details-facts-inner {
        display: flex;
        flex-wrap: wrap; }
        #wrapper .component.job-details .component-content .right-content .job-details-facts .job-details-facts-inner h2 {
          font-size: 2rem;
          color: #005da9;
          width: 100%; }
        #wrapper .component.job-details .component-content .right-content .job-details-facts .job-details-facts-inner .fact-key,
        #wrapper .component.job-details .component-content .right-content .job-details-facts .job-details-facts-inner .fact-value {
          width: 50%;
          font-size: 1.125rem;
          display: inline-flex;
          line-height: 1.5rem; }
        #wrapper .component.job-details .component-content .right-content .job-details-facts .job-details-facts-inner .fact-value {
          font-family: "Proxima N W01 Bold", sans-serif;
          flex-direction: row-reverse;
          text-align: right; }
    #wrapper .component.job-details .component-content .right-content .button {
      margin: 0 1.875rem 1.875rem 1.875rem;
      background: #005da9;
      color: #ffffff;
      font-size: 1.25rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1.5rem; }
  #wrapper .component.job-details .component-content .clearfix {
    display: none; }

#wrapper .intro-banner.job-intro-banner h4 {
  padding: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #E1F0E3; }

#wrapper .intro-banner.job-intro-banner h1 {
  color: #ffffff;
  font-size: 4rem; }

@media (max-width: 991px) {
  #wrapper .component.job-details .component-content {
    flex-direction: column-reverse; }
    #wrapper .component.job-details .component-content .job-details-content,
    #wrapper .component.job-details .component-content .right-content {
      width: 100%; } }

/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* Secondary Btn Blue */
/************* COMPONENT VARS *************/
/************* COMPONENT VARS END *************/
.flex-layout .container, .flex-header-box .container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .flex-layout .container, .flex-header-box .container {
      max-width: 42rem; } }
  @media (min-width: 768px) {
    .flex-layout .container, .flex-header-box .container {
      max-width: 52rem; } }
  @media (min-width: 992px) {
    .flex-layout .container, .flex-header-box .container {
      max-width: 64rem; } }
  @media (min-width: 1200px) {
    .flex-layout .container, .flex-header-box .container {
      max-width: 74rem; } }

.flex-layout .container-fluid, .flex-header-box .container-fluid {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

.flex-layout .flex-row, .flex-header-box .flex-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.component.flex-layout,
.flex-header-box {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

.component.flex-layout *,
.component.flex-layout *::before,
.component.flex-layout *::after,
.flex-header-box *,
.flex-header-box *::before,
.flex-header-box *::after {
  box-sizing: inherit; }

#wrapper .container.flex-layout .container-wrapper {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

@media (min-width: 568px) {
  #wrapper .container.flex-layout.eight-columns .component-content, #wrapper .container.flex-layout.ten-columns .component-content {
    width: 42rem;
    position: relative;
    max-width: 100%;
    margin: 0 auto; } }

@media (min-width: 768px) {
  #wrapper .container.flex-layout.eight-columns .component-content, #wrapper .container.flex-layout.ten-columns .component-content {
    width: 34rem; }
    #wrapper .container.flex-layout.eight-columns .component-content .container-wrapper, #wrapper .container.flex-layout.ten-columns .component-content .container-wrapper {
      padding-left: 0;
      padding-right: 0; } }

@media (min-width: 991px) {
  #wrapper .container.flex-layout.eight-columns .component-content, #wrapper .container.flex-layout.ten-columns .component-content {
    width: 64rem; } }

@media (min-width: 1200px) {
  #wrapper .container.flex-layout.eight-columns .component-content, #wrapper .container.flex-layout.ten-columns .component-content {
    width: 74rem; } }

@media (min-width: 992px) {
  #wrapper .container.flex-layout.eight-columns .component-content .container-wrapper, #wrapper .container.flex-layout.ten-columns .component-content .container-wrapper {
    margin-left: auto;
    margin-right: auto; }
    #wrapper .container.flex-layout.eight-columns .component-content .container-wrapper > .component, #wrapper .container.flex-layout.ten-columns .component-content .container-wrapper > .component {
      padding-left: 0;
      padding-right: 0; }
  #wrapper .container.flex-layout.eight-columns .component-content .container-wrapper {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  #wrapper .container.flex-layout.ten-columns .component-content .container-wrapper {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

#wrapper .component.flex-layout.twelve-columns .component-content {
  width: 74rem;
  margin: 0 auto; }
  #wrapper .component.flex-layout.twelve-columns .component-content > .container {
    max-width: 74rem; }

@media (max-width: 768px) {
  #wrapper .container.flex-layout.eight-columns .component-content .container-wrapper, #wrapper .container.flex-layout.ten-columns .component-content .container-wrapper {
    padding-left: 0;
    padding-right: 0; }
  #wrapper .component.flex-layout.twelve-columns .component-content {
    width: 100vw; } }

#wrapper .two-section-grid.flex-layout .columns-row {
  margin-left: -2rem;
  margin-right: -2rem; }

#wrapper .two-section-grid.flex-layout .rich-text .col-md-8 {
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 768px) {
  #wrapper .two-section-grid.flex-layout.ten-columns .sections-container {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  #wrapper .two-section-grid.flex-layout.twelve-columns .sections-container {
    flex: 0 0 100%;
    max-width: 100%; } }

#wrapper .three-section-grid.flex-layout .columns-row {
  margin-left: -2rem;
  margin-right: -2rem; }

#wrapper .three-section-grid.flex-layout .rich-text .col-md-8 {
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 768px) {
  #wrapper .three-section-grid.flex-layout.ten-columns .sections-container {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  #wrapper .three-section-grid.flex-layout.twelve-columns .sections-container {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 767px) {
  #wrapper .three-section-grid.flex-layout .columns-row {
    margin-left: -.5rem;
    margin-right: -.5rem; } }

#wrapper .intro-banner.flex-layout .container-fluid .flex-banner-image {
  width: 100%;
  max-width: 100%;
  align-self: center; }

#wrapper .intro-banner.flex-layout .container-fluid .flex-banner-content {
  padding: 4rem 8.33333% 0;
  background: #ffffff;
  text-align: center; }
  #wrapper .intro-banner.flex-layout .container-fluid .flex-banner-content h1 {
    font-size: 3.551rem;
    margin-bottom: 1rem; }
  #wrapper .intro-banner.flex-layout .container-fluid .flex-banner-content p {
    font-size: 1.33rem; }
  #wrapper .intro-banner.flex-layout .container-fluid .flex-banner-content:after {
    content: " ";
    border-top: 5px solid #005da9;
    display: inline-block;
    width: 16.66666%;
    text-align: center;
    margin-bottom: .5rem;
    margin-top: 1rem; }

#wrapper .intro-banner.flex-layout .container-fluid .flex-banner-iframe-video {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 46.875%; }
  #wrapper .intro-banner.flex-layout .container-fluid .flex-banner-iframe-video iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden; }

#wrapper .intro-banner.flex-layout .container-fluid .flex-banner-video {
  width: 100%;
  height: 70%;
  position: relative; }
  #wrapper .intro-banner.flex-layout .container-fluid .flex-banner-video video {
    width: 100%;
    height: 100%;
    overflow: hidden; }

#wrapper .intro-banner.flex-layout.no-bannerborder .container-fluid .flex-banner-content {
  margin-bottom: -2rem; }
  #wrapper .intro-banner.flex-layout.no-bannerborder .container-fluid .flex-banner-content:after {
    display: none; }

#wrapper .flex-layout.intro-banner.parallax .parallax-image {
  width: 100vw;
  height: calc(100vw / 3);
  overflow: hidden; }
  #wrapper .flex-layout.intro-banner.parallax .parallax-image .parallax-image-back {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    transform: translateZ(-1px) scale(1.1);
    z-index: -1;
    width: 100%;
    height: 100%; }

@media (min-width: 768px) {
  #wrapper .intro-banner.flex-layout.twelve-columns .flex-banner-content {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 991px) {
  #wrapper .intro-banner.flex-layout .container-fluid .flex-banner-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }

#wrapper .intro-banner.flex-layout.accent-corporate .container-fluid .flex-banner-content:after {
  border-top: 5px solid rgba(0, 93, 169, 0.3); }

#wrapper .intro-banner.flex-layout.accent-green .container-fluid .flex-banner-content:after {
  border-top: 5px solid rgba(167, 211, 172, 0.3); }

#wrapper .intro-banner.flex-layout.accent-grey .container-fluid .flex-banner-content:after {
  border-top: 5px solid rgba(187, 187, 187, 0.3); }

#wrapper .rich-text.flex-layout .rich-text-content {
  padding: 2rem 0 0 0; }
  #wrapper .rich-text.flex-layout .rich-text-content p,
  #wrapper .rich-text.flex-layout .rich-text-content ul,
  #wrapper .rich-text.flex-layout .rich-text-content li,
  #wrapper .rich-text.flex-layout .rich-text-content a {
    font-size: 1.125rem; }
  #wrapper .rich-text.flex-layout .rich-text-content ul,
  #wrapper .rich-text.flex-layout .rich-text-content ol {
    padding: 0 0 1.2em 1.25rem;
    list-style-position: outside; }
    #wrapper .rich-text.flex-layout .rich-text-content ul li,
    #wrapper .rich-text.flex-layout .rich-text-content ol li {
      line-height: 1.5;
      padding: 0 0 0 .25rem; }
    #wrapper .rich-text.flex-layout .rich-text-content ul ul, #wrapper .rich-text.flex-layout .rich-text-content ul ol,
    #wrapper .rich-text.flex-layout .rich-text-content ol ul,
    #wrapper .rich-text.flex-layout .rich-text-content ol ol {
      padding-bottom: 0; }
  #wrapper .rich-text.flex-layout .rich-text-content ol {
    list-style-type: decimal; }
    #wrapper .rich-text.flex-layout .rich-text-content ol li:before {
      content: ""; }
  #wrapper .rich-text.flex-layout .rich-text-content h1 {
    font-size: 4rem; }
  #wrapper .rich-text.flex-layout .rich-text-content h2 {
    font-size: 3rem; }
  #wrapper .rich-text.flex-layout .rich-text-content h3 {
    font-size: 2.5rem; }
  #wrapper .rich-text.flex-layout .rich-text-content h4 {
    font-size: 2rem; }
  #wrapper .rich-text.flex-layout .rich-text-content h5 {
    font-size: 1.5rem; }
  #wrapper .rich-text.flex-layout .rich-text-content h6 {
    font-size: 1.25rem; }
  #wrapper .rich-text.flex-layout .rich-text-content .huge,
  #wrapper .rich-text.flex-layout .rich-text-content .bigger {
    font-family: "Proxima N W01 Light", sans-serif; }
    #wrapper .rich-text.flex-layout .rich-text-content .huge.huge,
    #wrapper .rich-text.flex-layout .rich-text-content .bigger.huge {
      font-size: 1.66rem; }
    #wrapper .rich-text.flex-layout .rich-text-content .huge.bigger,
    #wrapper .rich-text.flex-layout .rich-text-content .bigger.bigger {
      font-size: 1.33rem; }
  #wrapper .rich-text.flex-layout .rich-text-content .highlight {
    background: #005da9;
    color: #ffffff; }
  #wrapper .rich-text.flex-layout .rich-text-content .quote {
    border-top: 3px solid rgba(0, 93, 169, 0.3);
    border-bottom: 3px solid rgba(0, 93, 169, 0.3);
    color: #005da9;
    font-family: "Proxima N W01 Light", sans-serif;
    font-style: italic;
    font-size: 2.2rem;
    display: inline-block;
    text-align: center;
    padding: 2rem 4rem;
    width: 100%;
    margin: 1rem 0; }
    #wrapper .rich-text.flex-layout .rich-text-content .quote:before, #wrapper .rich-text.flex-layout .rich-text-content .quote:after {
      content: ""; }
  @media (max-width: 767px) {
    #wrapper .rich-text.flex-layout .rich-text-content .quote {
      padding: 2rem; } }
  #wrapper .rich-text.flex-layout .rich-text-content .cta-auto-width,
  #wrapper .rich-text.flex-layout .rich-text-content .cta-full-width {
    background: rgba(0, 93, 169, 0.3);
    color: #404040;
    font-family: "Proxima N W01 Reg", sans-serif;
    display: inline-flex;
    border-radius: 2px;
    height: 5.333rem;
    padding: 0.5rem 3rem 0.5rem 1rem;
    align-items: center;
    position: relative; }
    #wrapper .rich-text.flex-layout .rich-text-content .cta-auto-width:after,
    #wrapper .rich-text.flex-layout .rich-text-content .cta-full-width:after {
      font-family: "retail-icon-font";
      content: "";
      position: absolute;
      height: 5.333rem;
      background: #005da9;
      width: 2rem;
      top: 0;
      right: 0;
      border-radius: 0 2px 2px 0;
      text-align: center;
      line-height: 5.333rem;
      color: #ffffff;
      font-size: 1.333rem; }
  #wrapper .rich-text.flex-layout .rich-text-content .cta-full-width {
    display: flex; }
  #wrapper .rich-text.flex-layout .rich-text-content p:last-child,
  #wrapper .rich-text.flex-layout .rich-text-content ul:last-child,
  #wrapper .rich-text.flex-layout .rich-text-content ol:last-child {
    padding-bottom: 0; }

#wrapper .rich-text.flex-layout.fact-box .rich-text-content {
  padding: 2rem;
  background: rgba(0, 93, 169, 0.3);
  border-top: 3px solid #005da9;
  margin-top: 2rem; }

#wrapper .rich-text.flex-layout.rich-text-no-top-padding .rich-text-content {
  padding-top: 0; }

@media (min-width: 768px) {
  #wrapper .rich-text.flex-layout.ten-columns .rich-text-content {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  #wrapper .rich-text.flex-layout.twelve-columns .rich-text-content {
    flex: 0 0 100%;
    max-width: 100%; } }

#wrapper .rich-text.flex-layout.accent-corporate .highlight {
  color: #ffffff;
  background: #005da9; }

#wrapper .rich-text.flex-layout.accent-corporate .quote {
  border-top-color: rgba(0, 93, 169, 0.3);
  border-bottom-color: rgba(0, 93, 169, 0.3); }

#wrapper .rich-text.flex-layout.accent-corporate .handwriting--small,
#wrapper .rich-text.flex-layout.accent-corporate .handwriting--medium,
#wrapper .rich-text.flex-layout.accent-corporate .handwriting--large {
  color: #005da9; }

#wrapper .rich-text.flex-layout.accent-corporate .cta-auto-width,
#wrapper .rich-text.flex-layout.accent-corporate .cta-full-width {
  background: rgba(0, 93, 169, 0.3); }
  #wrapper .rich-text.flex-layout.accent-corporate .cta-auto-width:after,
  #wrapper .rich-text.flex-layout.accent-corporate .cta-full-width:after {
    background: #005da9; }

#wrapper .rich-text.flex-layout.fact-box.accent-corporate .rich-text-content {
  background: rgba(0, 93, 169, 0.3);
  border-top: 3px solid #005da9; }

#wrapper .rich-text.flex-layout.accent-green .highlight {
  color: #ffffff;
  background: #a7d3ac; }

#wrapper .rich-text.flex-layout.accent-green .quote {
  border-top-color: rgba(167, 211, 172, 0.3);
  border-bottom-color: rgba(167, 211, 172, 0.3); }

#wrapper .rich-text.flex-layout.accent-green .handwriting--small,
#wrapper .rich-text.flex-layout.accent-green .handwriting--medium,
#wrapper .rich-text.flex-layout.accent-green .handwriting--large {
  color: #a7d3ac; }

#wrapper .rich-text.flex-layout.accent-green .cta-auto-width,
#wrapper .rich-text.flex-layout.accent-green .cta-full-width {
  background: rgba(167, 211, 172, 0.3); }
  #wrapper .rich-text.flex-layout.accent-green .cta-auto-width:after,
  #wrapper .rich-text.flex-layout.accent-green .cta-full-width:after {
    background: #a7d3ac; }

#wrapper .rich-text.flex-layout.fact-box.accent-green .rich-text-content {
  background: rgba(167, 211, 172, 0.3);
  border-top: 3px solid #a7d3ac; }

#wrapper .rich-text.flex-layout.accent-grey .highlight {
  color: #ffffff;
  background: #bbbbbb; }

#wrapper .rich-text.flex-layout.accent-grey .quote {
  border-top-color: rgba(187, 187, 187, 0.3);
  border-bottom-color: rgba(187, 187, 187, 0.3); }

#wrapper .rich-text.flex-layout.accent-grey .handwriting--small,
#wrapper .rich-text.flex-layout.accent-grey .handwriting--medium,
#wrapper .rich-text.flex-layout.accent-grey .handwriting--large {
  color: #bbbbbb; }

#wrapper .rich-text.flex-layout.accent-grey .cta-auto-width,
#wrapper .rich-text.flex-layout.accent-grey .cta-full-width {
  background: rgba(187, 187, 187, 0.3); }
  #wrapper .rich-text.flex-layout.accent-grey .cta-auto-width:after,
  #wrapper .rich-text.flex-layout.accent-grey .cta-full-width:after {
    background: #bbbbbb; }

#wrapper .rich-text.flex-layout.fact-box.accent-grey .rich-text-content {
  background: rgba(187, 187, 187, 0.3);
  border-top: 3px solid #bbbbbb; }

#wrapper .rich-text.flex-layout.accent-blue-gray .highlight {
  color: #ffffff;
  background: #BEE1E6; }

#wrapper .rich-text.flex-layout.accent-blue-gray .quote {
  border-top-color: rgba(190, 225, 230, 0.3);
  border-bottom-color: rgba(190, 225, 230, 0.3); }

#wrapper .rich-text.flex-layout.accent-blue-gray .handwriting--small,
#wrapper .rich-text.flex-layout.accent-blue-gray .handwriting--medium,
#wrapper .rich-text.flex-layout.accent-blue-gray .handwriting--large {
  color: #BEE1E6; }

#wrapper .rich-text.flex-layout.accent-blue-gray .cta-auto-width,
#wrapper .rich-text.flex-layout.accent-blue-gray .cta-full-width {
  background: rgba(190, 225, 230, 0.3); }
  #wrapper .rich-text.flex-layout.accent-blue-gray .cta-auto-width:after,
  #wrapper .rich-text.flex-layout.accent-blue-gray .cta-full-width:after {
    background: #BEE1E6; }

#wrapper .rich-text.flex-layout.fact-box.accent-blue-gray .rich-text-content {
  background: rgba(190, 225, 230, 0.3);
  border-top: 3px solid #BEE1E6; }

#wrapper .rich-text.flex-layout.accent-light-orange .highlight {
  color: #ffffff;
  background: #ffa000; }

#wrapper .rich-text.flex-layout.accent-light-orange .quote {
  border-top-color: rgba(255, 160, 0, 0.3);
  border-bottom-color: rgba(255, 160, 0, 0.3); }

#wrapper .rich-text.flex-layout.accent-light-orange .handwriting--small,
#wrapper .rich-text.flex-layout.accent-light-orange .handwriting--medium,
#wrapper .rich-text.flex-layout.accent-light-orange .handwriting--large {
  color: #ffa000; }

#wrapper .rich-text.flex-layout.accent-light-orange .cta-auto-width,
#wrapper .rich-text.flex-layout.accent-light-orange .cta-full-width {
  background: rgba(255, 160, 0, 0.3); }
  #wrapper .rich-text.flex-layout.accent-light-orange .cta-auto-width:after,
  #wrapper .rich-text.flex-layout.accent-light-orange .cta-full-width:after {
    background: #ffa000; }

#wrapper .rich-text.flex-layout.fact-box.accent-light-orange .rich-text-content {
  background: rgba(255, 160, 0, 0.3);
  border-top: 3px solid #ffa000; }

#wrapper .screening-test-spot.flex-layout.try-hearing-aid,
#wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test {
  width: 100%; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-header,
  #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-header {
    color: #001C32;
    font-size: 3rem;
    line-height: 1.2; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-description,
  #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-description {
    color: #00427A;
    font-size: 1.5rem;
    font-family: "Proxima N W01 Light", sans-serif; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-description *,
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-description * {
      color: #001C32;
      font-size: 1.5rem; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-description .btn,
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-description .btn {
      color: #ffffff; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-footer,
  #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-footer {
    color: #ffffff;
    font-family: "Proxima N W01SC Reg It", sans-serif;
    margin-top: 2rem;
    font-size: 0.9rem; }

body.disable-forms #wrapper .form {
  display: none; }

@media (max-width: 991px) {
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-header {
    line-height: 1.1;
    padding: 0 0 .3em 0; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-description {
    color: #001C32;
    font-size: 1.5rem;
    font-family: "Proxima N W01 Light", sans-serif; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .test-description * {
      color: #001C32;
      font-size: 1.5rem; } }

#wrapper .screening-test-spot.flex-layout.try-hearing-aid {
  margin: 2rem 0; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid.cta-red .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span label {
    color: #ffffff;
    background: #e40049;
    border: 1px solid #e40049; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid.cta-red .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span label:hover {
      cursor: pointer;
      color: #ffffff;
      background: #b10039;
      border: 1px solid #b10039; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row {
    text-align: center; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box {
      display: none; }
      #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active {
        border-radius: 0.5rem;
        display: block;
        background: #ffffff;
        padding: 1.75rem 2.375rem 2.25rem 2.375rem; }
        #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-step {
          font-size: 1.125rem;
          color: #90B494;
          text-transform: uppercase;
          font-family: "Proxima N W01 Bold", sans-serif;
          padding-bottom: 0.75rem; }
        #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active p:not(.question-step) {
          font-size: 1.5rem;
          font-family: "Proxima N W01 Light", sans-serif; }
        #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container {
          display: flex; }
          #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span {
            height: 4rem;
            justify-content: space-around;
            display: flex;
            flex-grow: 1;
            margin: 0 1rem; }
            #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span:first-of-type {
              margin-left: 0; }
            #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span:last-of-type {
              margin-right: 0; }
            #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span input[type="radio"] {
              display: none; }
            #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span label {
              height: 4rem;
              width: 100%;
              border-radius: 2rem;
              line-height: 3.8rem;
              font-size: 1.5rem;
              font-family: "Proxima N W01 Bold", sans-serif;
              border: 2px solid #005da9;
              color: #005da9; }
              #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span label:hover {
                cursor: pointer;
                background: #005da9;
                color: #ffffff; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list.test-results .test-header {
      font-size: 2.25rem;
      position: relative;
      margin-top: 8rem; }
      #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list.test-results .test-header:before {
        content: "";
        padding-left: .5rem;
        font-family: "retail-icon-font";
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 3.125rem;
        position: absolute;
        display: flex;
        background: #a7d3ac;
        color: #ffffff;
        justify-content: center;
        font-size: 2.875rem;
        line-height: 7rem;
        font-weight: bold;
        left: calc(50% - 3.125rem);
        top: -8rem; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid.try-hearing-aid-result-option-two .screening-test-container .columns-row .questions-list.test-results .test-header {
    font-size: 3rem;
    margin-top: 0;
    padding-bottom: 0.75rem; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid.try-hearing-aid-result-option-two .screening-test-container .columns-row .questions-list.test-results .test-header:before {
      content: "";
      display: none; }
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid.try-hearing-aid-result-option-two .screening-test-container .columns-row .questions-list.test-results .test-description p {
    color: #00427A;
    font-size: 1.5rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    padding-bottom: 1.5rem; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid.try-hearing-aid-result-option-two .screening-test-container .columns-row .questions-list.test-results .test-description p:before {
      content: "";
      padding-left: .5rem;
      font-family: "retail-icon-font";
      width: 6.25rem;
      height: 6.25rem;
      border-radius: 3.125rem;
      position: absolute;
      display: flex;
      background: #a7d3ac;
      color: #ffffff;
      justify-content: center;
      font-size: 2.875rem;
      line-height: 7rem;
      font-weight: bold;
      left: calc(50% - 3.125rem);
      top: -8rem; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid.try-hearing-aid-result-option-two .screening-test-container .columns-row .questions-list.test-results .test-description p:first-of-type:before {
      font-size: 1rem;
      width: 2.25rem;
      height: 2.25rem;
      line-height: 2.5rem;
      padding-left: 0;
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      margin-right: 0.5rem; }

@media (max-width: 991px) {
  #wrapper .screening-test-spot.flex-layout.try-hearing-aid {
    margin: 1.5rem 0 2.5rem 0; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active {
      padding: 1rem 1.25rem 2rem 1.25rem; }
      #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list .question-box.active .question-answers-container span {
        margin: 0 0.5rem; }
    #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list.test-results .test-header {
      margin-top: 0; }
      #wrapper .screening-test-spot.flex-layout.try-hearing-aid .screening-test-container .columns-row .questions-list.test-results .test-header:before {
        top: -10rem; } }

#wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test {
  margin: 3rem 0; }
  #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container {
    text-align: center; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-header,
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-description {
      color: #ffffff; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-header {
      font-size: 2.25rem; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-description {
      font-size: 1.25rem;
      font-family: "Proxima N W01 Light", sans-serif;
      padding-bottom: 2rem; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-description * {
        font-size: 1.25rem;
        font-family: "Proxima N W01 Light", sans-serif;
        color: #ffffff; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .questions-list.test-results .test-description p {
      padding: 0; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .questions-list.test-results .test-description p small {
        font-size: .95rem; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .questions-list.test-results .test-description p:last-of-type {
        line-height: 1.2;
        margin-top: 1rem; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .questions-list.test-results .test-footer {
      margin-top: 0;
      padding: 0; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box {
      padding: 1rem 2.375rem;
      background: #e6e6e6;
      border-top: 1px solid #dddddd; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-step {
        display: none; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box p {
        padding-bottom: 0; }
        #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box p.step-done {
          position: relative; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box p.step-done:after {
            font-family: "retail-icon-font";
            content: "";
            color: #2CA53A;
            font-weight: bold;
            font-size: 1.25rem;
            position: absolute;
            right: -1rem; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box:first-of-type {
        border-radius: .5rem .5rem 0 0; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box:last-of-type {
        border-radius: 0 0 .5rem .5rem; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container {
        display: none;
        flex-direction: row; }
        #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container span {
          flex-grow: 1;
          display: inline-block;
          margin: 0 .5rem; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container span:first-of-type {
            margin-left: 0; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container span:last-of-type {
            margin-right: 0; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container span input[type="radio"] {
            display: none; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container span label {
            background-color: #ffffff;
            width: 100%;
            height: 2.75rem;
            color: #005da9;
            border: 1px solid #005da9;
            z-index: 1;
            border-radius: 1.375rem;
            display: flex;
            font-size: 1.25rem;
            align-items: center;
            justify-content: center;
            font-family: "Proxima N W01 Smbd", sans-serif; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container span label:hover {
            background-color: #005da9;
            color: white;
            cursor: pointer; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box .question-answers-container span input[type="radio"]:checked + label {
            background-color: #005da9;
            color: #ffffff; }
      #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box.active {
        background: #ffffff;
        padding: 1.5625rem 2.375rem 1.875rem 2.375rem;
        position: relative; }
        #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box.active:after {
          content: " ";
          width: 0;
          position: absolute;
          bottom: -.5rem;
          left: calc(50% - .25rem);
          height: 0;
          border-style: solid;
          border-width: .5rem .5rem 0 .5rem;
          border-color: #ffffff transparent transparent transparent; }
        #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box.active:last-of-type {
          box-shadow: none; }
          #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box.active:last-of-type:after {
            content: none; }
        #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box.active .question-answers-container {
          display: flex; }
        #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box.active p {
          font-family: "Proxima N W01 Bold", sans-serif;
          margin-bottom: 1rem;
          font-size: 1.25rem;
          line-height: 1.2; }

@media (max-width: 991px) {
  #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test {
    margin: 2rem 0; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .test-header {
      font-size: 2rem; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box p.step-done:after {
      right: -1.75rem; }
    #wrapper .screening-test-spot.flex-layout.should-i-get-hearing-test .screening-test-container .columns-row .question-box.active {
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }

#wrapper .image-spot-container {
  margin-top: 2rem; }
  #wrapper .image-spot-container .columns-row {
    margin-left: -2rem;
    margin-right: -2rem; }
    #wrapper .image-spot-container .columns-row .image-spot-box {
      padding: 1rem;
      background-color: #f1f1f1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: space-between; }
      #wrapper .image-spot-container .columns-row .image-spot-box .image-spot-image {
        margin: -1rem -1rem 1rem -1rem;
        overflow: hidden; }
        #wrapper .image-spot-container .columns-row .image-spot-box .image-spot-image img {
          object-fit: cover;
          width: 100%; }
      #wrapper .image-spot-container .columns-row .image-spot-box .image-spot-link {
        text-align: center;
        padding-bottom: 0;
        font-size: 1rem; }
      #wrapper .image-spot-container .columns-row .image-spot-box .image-spot-text p, #wrapper .image-spot-container .columns-row .image-spot-box .image-spot-header p {
        font-size: 1.125rem; }
      #wrapper .image-spot-container .columns-row .image-spot-box .image-spot-text {
        flex-grow: 1; }

@media (min-width: 768px) {
  #wrapper .image-spot.flex-layout.ten-columns .image-spot-container {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  #wrapper .image-spot.flex-layout.twelve-columns .image-spot-container {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 767px) {
  #wrapper .image-spot-container .columns-row {
    margin-left: -1rem;
    margin-right: -1rem; }
    #wrapper .image-spot-container .columns-row .col-md.col-sm-12 {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      margin-bottom: 1.5rem; }
      #wrapper .image-spot-container .columns-row .col-md.col-sm-12:last-child {
        margin-bottom: 0; }
    #wrapper .image-spot-container .columns-row .image-spot-box .image-spot-image img {
      height: auto; } }

#wrapper .image-spot.flex-layout.accent-audika-blue .image-spot-container .image-spot-box {
  background-color: #005da9; }
  #wrapper .image-spot.flex-layout.accent-audika-blue .image-spot-container .image-spot-box .image-spot-text *,
  #wrapper .image-spot.flex-layout.accent-audika-blue .image-spot-container .image-spot-box .image-spot-header *,
  #wrapper .image-spot.flex-layout.accent-audika-blue .image-spot-container .image-spot-box .image-spot-link * {
    color: #ffffff; }

#wrapper .image-spot.flex-layout.accent-corporate .image-spot-container .image-spot-box {
  background-color: #005da9; }
  #wrapper .image-spot.flex-layout.accent-corporate .image-spot-container .image-spot-box .image-spot-text *,
  #wrapper .image-spot.flex-layout.accent-corporate .image-spot-container .image-spot-box .image-spot-header *,
  #wrapper .image-spot.flex-layout.accent-corporate .image-spot-container .image-spot-box .image-spot-link * {
    color: #ffffff; }

#wrapper .image-spot.flex-layout.accent-nuance-green .image-spot-container .image-spot-box {
  background-color: #d3e9d5; }

#wrapper .image-spot.flex-layout.accent-grey .image-spot-container .image-spot-box {
  background-color: #bbbbbb; }

.flex-header #wrapper #header {
  border: 0;
  opacity: 1;
  top: 0;
  position: fixed;
  z-index: 9999;
  background: #ffffff; }
  .flex-header #wrapper #header .flex-header-box .header-section-top .header-section-top-content {
    width: 100%;
    height: 3.75rem;
    display: flex; }
    .flex-header #wrapper #header .flex-header-box .header-section-top .header-section-top-content .header-logo {
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .flex-header #wrapper #header .flex-header-box .header-section-top .header-section-top-content .header-logo a img {
        max-height: 1.75rem;
        height: 1.75rem; }
  .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items {
    display: flex;
    flex-direction: row-reverse; }
    .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text {
      margin-left: 2rem; }
      .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a {
        line-height: 3.75rem;
        color: #00427A;
        font-size: 1rem; }
        .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a[class^="social-icon"], .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a[class^="social-icon"] {
          font-size: 0; }
          .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a[class^="social-icon"]:before, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a[class^="social-icon"]:before {
            font-family: "retail-icon-font";
            font-size: 1.5rem; }
        .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a.social-icon-facebook:before, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a.social-icon-facebook:before {
          content: ""; }
        .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a.social-icon-youtube:before, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a.social-icon-youtube:before {
          content: ""; }
        .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a.social-icon-twitter:before, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a.social-icon-twitter:before {
          content: ""; }
        .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a.social-icon-linkedin:before, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a.social-icon-linkedin:before {
          content: ""; }
        .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.link a.social-icon-instagram:before, .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.rich-text a.social-icon-instagram:before {
          content: ""; }
    .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation {
      display: flex;
      height: 100%;
      align-items: center;
      margin-right: -1rem; }
      .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper {
        display: flex; }
        .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item {
          height: 100%;
          display: flex;
          align-items: center;
          padding-right: 1rem; }
          .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item:first-of-type {
            margin-left: 1rem; }
          .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item > button {
            font-size: 0;
            background: none;
            border: 0;
            height: 3.75rem;
            width: 2.3rem;
            align-items: center;
            justify-content: center;
            display: flex; }
            .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item > button:before {
              font-family: "retail-icon-font";
              content: "";
              font-size: .9rem;
              font-weight: bold;
              margin-top: 2px; }
            .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item > button:hover {
              background: rgba(241, 241, 241, 0.5);
              cursor: pointer; }
          .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item > a {
            color: #00427A;
            font-size: 1rem;
            display: flex;
            padding: 0;
            height: 3.75rem;
            align-items: center; }
            .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item > a.standalone-link:before {
              display: none; }
          .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu {
            transition: max-height 0.5s ease-in-out;
            position: absolute;
            width: 100%;
            background: #ffffff;
            height: 0;
            max-height: 0;
            overflow: hidden;
            left: 0;
            top: 3.75rem; }
            .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container {
              padding: 0; }
              .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .flex-row {
                flex-direction: column; }
              .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-one {
                color: #00427A;
                font-size: 1rem;
                display: inline-block;
                margin-bottom: 1rem; }
                .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-one:hover:before {
                  font-family: "retail-icon-font";
                  content: "";
                  color: #005da9;
                  position: absolute;
                  margin-left: -1rem;
                  font-weight: bold; }
                .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-one.single {
                  display: block;
                  margin-bottom: 1.5rem; }
              .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-two {
                margin-bottom: 1rem; }
                .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-two li {
                  border-bottom: 1px solid #dce5ec;
                  padding-bottom: .75rem; }
                  .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-two li a {
                    color: #005da9;
                    font-family: "Proxima N W01 Reg", sans-serif;
                    margin-top: .75rem;
                    display: inline-block; }
                    .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-two li a:hover:before {
                      font-family: "retail-icon-font";
                      content: "";
                      color: #005da9;
                      position: absolute;
                      margin-left: -1rem;
                      font-weight: bold; }
                  .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-two li:last-child {
                    border-bottom: 0;
                    padding-bottom: 0; }
                  .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item .mega-menu .container .mega-menu-level-two li:first-of-type a {
                    margin-top: 0; }
          .flex-header #wrapper #header .flex-header-box .header-section-top .header-menu-items .header-item.navigation .mega-menu-wrapper .nav-item.active .mega-menu {
            height: auto;
            max-height: 90vh;
            padding: 2rem 0 1rem 0;
            box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25); }
  .flex-header #wrapper #header .flex-header-box .header-section-bottom {
    background: #00427A; }
    .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content {
      height: 3.125rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%; }
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a {
        color: #ffffff;
        font-size: 1rem;
        display: flex;
        padding: 0 1rem;
        line-height: 3.125rem;
        font-family: "Proxima N W01 Light", sans-serif;
        border-left: 1px solid #005da9; }
        .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a:hover {
          background: #005da9;
          opacity: 1; }
        .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a:before {
          font-family: "retail-icon-font";
          font-size: 1rem;
          margin-right: .5rem;
          display: inline-block; }
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a.header-icon-headset,
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item .header-icon-headset a {
        display: flex;
        align-items: center; }
        .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a.header-icon-headset:before,
        .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item .header-icon-headset a:before {
          content: "";
          display: block;
          height: 1.25rem;
          width: 1.25rem;
          background: url("/design/build/retail/assets/icon-headset.svg") no-repeat;
          background-size: 1.25rem 1.25rem; }
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a.header-icon-calendar,
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item .header-icon-calendar a {
        background: #e40049;
        height: 3.125rem;
        display: inline-flex;
        align-items: center;
        margin: 0;
        border-left: 0;
        border-radius: 0;
        font-family: "Proxima N W01 Smbd", sans-serif; }
        .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a.header-icon-calendar:before,
        .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item .header-icon-calendar a:before {
          content: ""; }
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item a.header-icon-marker:before,
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item .header-icon-marker a:before {
        content: ""; }
      .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item.rich-text {
        flex-grow: 1;
        order: -1;
        align-items: center;
        display: flex;
        font-family: "Proxima N W01 Light", sans-serif; }
        .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item.rich-text .header-icon-phone {
          font-size: 1.25rem;
          color: #ffffff;
          border-left: 0; }
          .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item.rich-text .header-icon-phone a {
            padding-left: 0;
            font-size: 1.25rem;
            border-left: 0; }
            .flex-header #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content .header-item.rich-text .header-icon-phone a:hover {
              cursor: default;
              background: none; }

.flex-header #wrapper #content {
  margin-top: 6.875rem; }

.flex-header.mode-editing #wrapper #header {
  position: initial; }

.flex-header.mode-editing #wrapper #content {
  margin-top: 0; }

@media only screen and (min-width: 992px) {
  .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu {
    display: none !important;
    transition: none; } }

@media only screen and (max-width: 991px) {
  .flex-header #wrapper #header .row .flex-header-box .header-section-top {
    box-shadow: 0 0.65rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2); }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content {
      height: 3.5rem;
      display: flex;
      align-items: center;
      padding: 0 .4rem; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger {
        padding-right: 0; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger {
          height: 3rem;
          display: flex;
          cursor: pointer;
          transition-property: opacity, filter;
          transition-duration: 0.15s;
          transition-timing-function: linear;
          background-color: transparent;
          border: 0;
          margin: 0 0 0 -.25rem;
          overflow: visible;
          align-items: center;
          padding: 0; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box {
            width: 20px;
            height: 15px;
            display: flex;
            align-items: center;
            position: relative; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-name {
              color: #00427A;
              font-size: .95rem;
              margin-left: 1.75rem;
              font-family: "Proxima N W01 Reg", sans-serif;
              height: 3rem;
              display: flex;
              align-items: center; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner {
              display: block;
              top: auto;
              bottom: 0;
              margin-top: -1.5px;
              transition-duration: 0.13s;
              transition-delay: 0.13s;
              transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner, .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner::before, .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner::after {
                width: 20px;
                height: 3px;
                background-color: #00427A;
                position: absolute;
                transition-property: transform;
                transition-duration: 0.15s;
                transition-timing-function: ease; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner::before, .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner::after {
                content: "";
                display: block; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner::before {
                top: -6px;
                transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-burger .burger .burger-box .burger-inner::after {
                bottom: -6px;
                top: -12px;
                transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-logo {
        display: flex;
        justify-content: center;
        padding: 0; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-logo a img {
          max-height: 1.8rem;
          height: 1.8rem; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu {
        height: calc(100vh - 3.5rem);
        max-height: calc(100vh - 3.5rem);
        width: 100%;
        left: -100vw;
        position: fixed;
        top: 3.5rem;
        overflow: hidden;
        transition: left 0.3s ease-in-out;
        background: #ffffff;
        display: flex;
        flex-direction: column; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 18vh; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-mobile-phone-cta {
            display: flex;
            justify-content: center;
            margin-top: 2rem; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-mobile-phone-cta .cta-section {
              display: none; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-mobile-phone-cta .navigation-section,
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-mobile-phone-cta a {
              text-align: center;
              font-size: 1.8rem;
              font-family: "Proxima N W01 Reg", sans-serif;
              color: #00427A; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container {
            width: 100%; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item {
              min-height: 3.4rem;
              line-height: 3.4rem;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: center;
              align-content: center;
              margin: 0 1.4rem;
              border-bottom: 1px solid #dce5ec;
              font-size: 1.3rem; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi > span {
                display: flex;
                align-items: center;
                width: 100%;
                min-height: 3.4rem;
                justify-content: space-between; }
                .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi > span a {
                  color: #00427A; }
                  .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi > span a:first-of-type {
                    flex-grow: 3; }
                  .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi > span a:last-of-type {
                    flex-grow: 1; }
                .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi > span .burger-sub-item-toggle {
                  min-width: 3rem;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  font-size: 0; }
                  .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi > span .burger-sub-item-toggle:before {
                    font-family: "retail-icon-font";
                    content: "";
                    color: #00427A;
                    font-size: 1.4rem;
                    font-weight: bold;
                    margin-top: .25rem; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi .burger-sub-item {
                height: 0;
                max-height: 0;
                overflow: hidden;
                line-height: 1; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.multi.standalone-link .burger-sub-item-toggle {
                display: none; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item > a {
                color: #00427A; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav.multi > span .burger-sub-item-toggle:before {
                content: ""; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav .burger-sub-item {
                height: auto;
                max-height: 400rem;
                overflow: visible;
                border-top: 1px solid #dce5ec;
                padding: .5rem 0 .5rem 1rem;
                width: 100%; }
                .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav .burger-sub-item .burger-sub-item-main {
                  padding-bottom: 1rem; }
                  .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav .burger-sub-item .burger-sub-item-main > a {
                    padding: .5rem 0;
                    display: inline-block;
                    min-width: 90%; }
                  .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav .burger-sub-item .burger-sub-item-main .burger-sub-item-minor li {
                    padding: 0; }
                    .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav .burger-sub-item .burger-sub-item-main .burger-sub-item-minor li a {
                      font-family: "Proxima N W01 Reg", sans-serif, sans-serif;
                      min-width: 90%;
                      padding: .5rem 0;
                      display: inline-block; }
                .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav .burger-sub-item li {
                  padding: .5rem 0; }
                  .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-menu-container .burger-item.expanded-nav .burger-sub-item li a {
                    color: #005da9;
                    line-height: 1.3;
                    font-size: 1.3rem; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-extra-container {
            padding-bottom: 3rem;
            padding-top: 3rem;
            max-width: 80%;
            margin: 0 auto; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-extra-container > a {
              color: #ffffff;
              font-size: 1.4rem;
              display: flex;
              align-items: center;
              margin-top: 2rem;
              height: 4rem;
              background: #00427A;
              border-radius: 2rem;
              width: 100%;
              padding: 0 2.5rem; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-extra-container span {
              margin-top: 2rem; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content .header-extra-options .header-mobile-mega-menu .header-mobile-mega-menu-content .header-burger-extra-container span a {
                color: #00427A;
                font-size: 2rem;
                font-family: "Proxima N W01 Reg", sans-serif, sans-serif; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content.is-active .header-burger .burger .burger-inner,
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content.is-active .header-burger .burger .burger-inner::before,
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content.is-active .header-burger .burger .burger-inner::after {
        background-color: #00427A; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content.is-active .header-burger .burger .burger-inner {
        transform: translate3d(0, -6px, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content.is-active .header-burger .burger .burger-inner::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content.is-active .header-burger .burger .burger-inner::before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top .header-section-top-content.is-active .header-extra-options .header-mobile-mega-menu {
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        overflow-x: hidden;
        box-shadow: inset 0 0.65rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2); }
  .flex-header #wrapper #header .row .flex-header-box .header-section-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container {
      max-width: 100%; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content {
        height: auto;
        padding: 0; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item {
          align-items: center;
          display: flex;
          flex-grow: 1;
          justify-content: center; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item a {
            padding: 2.6rem .5rem .75rem .5rem;
            text-align: center;
            position: relative;
            line-height: 1;
            font-size: 1.15rem;
            width: 100%;
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
            border-left: 0; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item a:before {
              top: 0;
              position: absolute;
              left: 50%;
              margin-top: .75rem;
              transform: translate(-50%);
              font-size: 1.4rem; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item a.header-icon-calendar {
              background: #e40049;
              font-family: "Proxima N W01 Reg", sans-serif; }
              .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item a.header-icon-calendar:before {
                margin-top: .75rem; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item:last-of-type a {
            border-right: 0; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item span {
            width: 100%; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item a.header-icon-headset:before,
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item .header-icon-headset a:before {
            height: 1.3rem;
            width: 1.3rem;
            background-size: 1.3rem 1.3rem; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item a.header-icon-phone,
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item .header-icon-phone a {
            white-space: nowrap; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item a.header-icon-phone:before,
            .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item .header-icon-phone a:before {
              content: ""; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item .header-icon-phone {
            height: 100%; }
            .flex-header #wrapper #header .row .flex-header-box .header-section-bottom .container .header-section-bottom-content .header-item .header-icon-phone .navigation-section {
              display: none; }
  .flex-header #wrapper #content {
    margin-top: 3.5rem; }
  .flex-header .cookie-warning {
    top: 3.5rem;
    bottom: inherit;
    animation: none; }
  .flex-header.cta-on-mobile-disabled #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content {
    display: none; } }

.flex-header #wrapper #header .row {
  height: 100%;
  width: 100%; }
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-search-icon,
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-language-selector-icon,
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-search-icon,
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon {
    font-size: 0;
    margin-left: 2.5rem;
    background: none;
    border: 0;
    padding: 0; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-search-icon:hover,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-language-selector-icon:hover,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-search-icon:hover,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon:hover {
      cursor: pointer;
      opacity: .7; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-search-icon:before,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-language-selector-icon:before,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-search-icon:before,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon:before {
      font-family: "retail-icon-font";
      color: #00427A;
      font-weight: bold; }
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-language-selector-icon,
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon {
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    display: flex;
    align-items: center;
    color: #00427A; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-language-selector-icon:before,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon:before {
      font-size: 1rem;
      margin-right: .5rem;
      content: ""; }
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-search-icon:before,
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-search-icon:before {
    font-size: 1.5rem;
    content: ""; }
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container,
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container {
    position: fixed;
    transition: left 0.2s ease-in-out;
    background: rgba(0, 66, 122, 0.96);
    height: calc(100vh - 6.875rem);
    top: 6.875rem;
    left: -100vw;
    width: 100%;
    padding: 5rem;
    z-index: 2; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container.is-active,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container.is-active {
      display: block;
      left: 0; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;
      width: 100%; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content h1,
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content h1 {
        color: #ffffff;
        font-size: 1.75rem;
        text-align: center; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-extra-cancel,
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-extra-cancel {
        color: #ffffff;
        font-size: 1.5rem;
        font-family: "Proxima N W01 Reg", sans-serif;
        align-items: baseline;
        border: 0;
        background: none; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-extra-cancel:before,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-extra-cancel:before {
          font-family: "retail-icon-font";
          content: "";
          margin-right: .5rem;
          font-size: 1rem; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-extra-cancel:hover,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-extra-cancel:hover {
          cursor: pointer;
          opacity: .75; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-language-selector-items,
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-language-selector-items {
        display: flex;
        align-items: center;
        justify-content: center; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-language-selector-items li a,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-language-selector-items li a {
          color: #ffffff;
          font-size: 1.75rem;
          line-height: 4.375rem;
          border-radius: 2.1875rem;
          border: 1px solid #ffffff;
          display: block;
          padding: 0 2.875rem;
          font-family: "Proxima N W01 Reg", sans-serif;
          margin: 1.875rem; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-search-form,
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form {
        display: flex;
        width: 100%;
        margin: 1.875rem 0; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-search-form input,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form input {
          flex-grow: 1;
          height: 4.375rem;
          background: #ffffff;
          border-radius: 2.1875rem 0 0 2.1875rem;
          font-size: 1.5rem;
          padding: 0 3rem;
          font-family: "Proxima N W01 Light", sans-serif; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-search-form input::placeholder,
          .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form input::placeholder {
            color: #b0b0b0; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-search-form button,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form button {
          background: #e40049;
          border-radius: 0 2.1875rem 2.1875rem 0;
          color: #ffffff;
          text-transform: uppercase;
          font-family: "Proxima N W01 Bold", sans-serif;
          font-size: 1.8125rem;
          padding: 0 2.5rem 0 2rem; }
          .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-search-form button:hover,
          .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form button:hover {
            cursor: pointer; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-search-form input,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-menu-items .header-extra-container .header-extra-content .header-search-form button,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form input,
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form button {
          border: 0; }

@media only screen and (max-width: 991px) {
  .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options {
    justify-content: flex-end;
    padding-left: 0;
    display: flex; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-search-icon,
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon {
      margin: 0;
      font-size: 0; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-search-icon:before,
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon:before {
        font-size: 1.35rem;
        color: #00427A; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-language-selector-icon {
      display: inline-block;
      margin-right: 1rem; }
    .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container {
      top: 3.5rem;
      padding: 2rem 1rem;
      height: auto; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content h1 {
        font-size: 1.25rem; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-extra-cancel {
        font-size: 1.25rem; }
        .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-extra-cancel:before {
          font-size: .7rem;
          margin-right: .2rem; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-language-selector-items li a {
        margin: 1.875rem .5rem;
        font-size: 1rem;
        line-height: 2.5rem;
        padding: 0 1.475rem; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form input {
        padding: 0 0 0 1rem;
        height: 2.5rem;
        font-size: 1.2rem;
        margin: 0; }
      .flex-header #wrapper #header .row .flex-header-box .header-section-top-content .header-extra-options .header-extra-container .header-extra-content .header-search-form button {
        font-size: 1.25rem;
        height: 2.5rem;
        border: 0;
        margin: 0;
        padding: 0 1.5rem 0 1rem; } }

.flex-header #wrapper #header .row .grid-12 {
  opacity: 0; }

#wrapper .hearing-test-invitation.flex-layout .invitation-container {
  background: #d3e9d5;
  margin-top: 2rem;
  padding: 4rem 0; }
  #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form {
    margin-left: -1rem;
    margin-right: -1rem; }
    #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column {
      display: flex;
      flex-direction: column; }
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group {
        margin-top: 1rem; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group:first-child {
          margin-top: 0; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group label {
          margin-bottom: .3rem;
          display: inline-block; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group input,
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group select,
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group textarea {
          background: #ffffff;
          border: 0;
          padding: .5rem 1rem;
          font-size: .9rem;
          font-family: "Proxima N W01SC Reg It", sans-serif;
          color: #515151; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group input,
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group select {
          height: 2.5rem; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group.form-message {
          flex-grow: 1;
          display: flex;
          flex-direction: column; }
          #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group.form-message textarea {
            flex-grow: 1;
            padding: 1rem; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group .select-wrapper {
          position: relative; }
          #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group .select-wrapper:after {
            font-family: "retail-icon-font";
            content: "";
            position: absolute;
            right: .5rem;
            top: .8rem;
            color: #333333;
            font-size: 1rem;
            pointer-events: none; }
    #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-submit {
      margin-top: 2rem;
      display: flex;
      align-items: center; }
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-submit .form-group {
        flex-grow: 1;
        text-align: right; }
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-submit .policy-text {
        font-size: .8rem;
        color: #515151;
        padding-bottom: 0; }
  #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form-errors {
    background: #e40049;
    margin: 0 -1rem 2rem -1rem;
    color: #ffffff;
    padding: 1rem; }
    #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form-errors p {
      padding: 0; }
    #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form-errors h1 {
      color: #ffffff;
      font-size: 2rem; }
  #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-confirm-error-box {
    margin-left: -1rem;
    margin-right: -1rem; }
    #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-confirm-error-box p:last-child {
      padding: 0; }

@media (max-width: 991px) {
  #wrapper .hearing-test-invitation.flex-layout .invitation-container {
    padding: 2rem 0; }
    #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form {
      margin-left: 0;
      margin-right: 0; }
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group.form-message {
        margin-top: 1rem; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group.form-message textarea {
          height: 12rem; }
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group label,
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group input,
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group select,
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group textarea {
        font-size: 1.25rem; }
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group input,
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-column .form-group select {
        height: 3.5rem; }
      #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-submit {
        flex-direction: column-reverse; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-submit .policy-text {
          margin-top: 1rem;
          font-size: 1rem; }
        #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-submit .form-group {
          width: 100%; }
          #wrapper .hearing-test-invitation.flex-layout .invitation-container .invitation-form .invitation-form-submit .form-group .btn {
            width: 100%; }
  #wrapper .hearing-test-invitation.flex-layout .invitation-form-errors {
    margin: 0 0 2rem 0; } }

#wrapper #content .two-section-grid.form-wizard-wrapper {
  min-height: auto !important;
  background-position: top center; }
  #wrapper #content .two-section-grid.form-wizard-wrapper .component-content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex; }
    #wrapper #content .two-section-grid.form-wizard-wrapper .component-content div[class*="section-"] {
      width: 50%;
      margin: 0;
      align-items: center; }
      #wrapper #content .two-section-grid.form-wizard-wrapper .component-content div[class*="section-"]:first-child {
        padding-left: 5%; }
      #wrapper #content .two-section-grid.form-wizard-wrapper .component-content div[class*="section-"]:last-child {
        padding-right: 5%; }
    #wrapper #content .two-section-grid.form-wizard-wrapper .component-content .section-one .image .component-content a img {
      display: none; }
  #wrapper #content .two-section-grid.form-wizard-wrapper.should-i-get-hearing-test {
    background-position: center; }
    #wrapper #content .two-section-grid.form-wizard-wrapper.should-i-get-hearing-test div[class*="section-"]:last-child {
      padding-left: 5%;
      background: #001C32; }

@media (max-width: 991px) {
  #wrapper #content .two-section-grid.form-wizard-wrapper {
    background-position: left center; }
    #wrapper #content .two-section-grid.form-wizard-wrapper .component-content {
      flex-direction: column; }
      #wrapper #content .two-section-grid.form-wizard-wrapper .component-content div[class*="section-"] {
        width: 100%; }
        #wrapper #content .two-section-grid.form-wizard-wrapper .component-content div[class*="section-"]:first-child, #wrapper #content .two-section-grid.form-wizard-wrapper .component-content div[class*="section-"]:last-child {
          padding: 0; }
      #wrapper #content .two-section-grid.form-wizard-wrapper .component-content .section-one .image .component-content a:hover {
        opacity: 1;
        cursor: initial; }
      #wrapper #content .two-section-grid.form-wizard-wrapper .component-content .section-one .image .component-content a img {
        display: block;
        width: 100%; }
      #wrapper #content .two-section-grid.form-wizard-wrapper .component-content .section-two {
        background-color: #E1E3E6; } }

#wrapper > #content .wffm-container .form {
  display: block; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .scfValidationSummary,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfValidationSummary {
  font-size: 1rem;
  background: #ffffff;
  border-radius: .5rem;
  padding: 1rem;
  margin-bottom: 2rem;
  line-height: 1.5rem; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container {
  display: flex;
  flex-direction: column; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder {
    position: relative; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder input[type=submit],
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder input[type=submit] {
      width: 100%;
      font-size: 1.5rem;
      background: #005da9; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container .scfFooterBorder,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container .scfFooterBorder {
    font-size: 1.5rem;
    color: #001C32;
    font-family: "Proxima N W01 Light", sans-serif; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container .scfFooterBorder *,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container .scfFooterBorder * {
      width: 100%;
      font-size: 1.5rem;
      color: #001C32;
      font-family: "Proxima N W01 Light", sans-serif; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container .scfFooterBorder p,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container .scfFooterBorder p {
      padding-top: 0; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item input:not([type='checkbox']):not([type=radio]):not([type='submit']):not([type=button]),
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item select,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item textarea,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item input:not([type='checkbox']):not([type=radio]):not([type='submit']):not([type=button]),
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item select,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item textarea {
  border: 1px solid #D5D5D5;
  background: #f1f1f1;
  height: 3.5rem;
  line-height: 3.5rem;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  padding: 0 1rem;
  font-style: normal; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item textarea,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item textarea {
  height: auto; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfValidator,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfValidator {
  float: none; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .requiredIndicator,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .requiredIndicator {
  display: inline-block;
  color: #e40049;
  margin-left: .25rem; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfSingleLineTextUsefulInfo,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfTelephoneUsefulInfo,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfEmailUsefulInfo,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfSingleLineTextUsefulInfo,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfTelephoneUsefulInfo,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfEmailUsefulInfo {
  display: none; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDropListGeneralPanel,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfDropListGeneralPanel {
  position: relative; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDropListGeneralPanel:after,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfDropListGeneralPanel:after {
    content: "";
    font-family: "retail-icon-font";
    color: #404040;
    font-size: 1rem;
    right: 1rem;
    top: 1.25rem;
    position: absolute;
    pointer-events: none; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonListLabel,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxListLabel,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonListLabel,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxListLabel,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonListLabel,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxListLabel,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonListLabel,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxListLabel {
  font-family: "Proxima N W01 Reg", sans-serif;
  width: 100%;
  float: none;
  margin-bottom: 1rem; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonListLabel *,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxListLabel *,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonListLabel *,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxListLabel *,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonListLabel *,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxListLabel *,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonListLabel *,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxListLabel * {
    font-family: "Proxima N W01 Reg", sans-serif; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList {
  width: 100%;
  table-layout: fixed; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr {
    vertical-align: initial; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td {
      width: auto;
      display: inline-flex;
      align-items: center;
      margin-right: 1rem; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="radio"],
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="checkbox"],
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="radio"],
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="checkbox"],
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="radio"],
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="checkbox"],
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="radio"],
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="checkbox"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="radio"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="checkbox"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="radio"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="checkbox"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="radio"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="checkbox"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="radio"],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="checkbox"] {
        border: 1px solid #D5D5D5;
        box-shadow: inset 0 0 0 12px #f1f1f1;
        background-color: #f1f1f1;
        margin-bottom: 0; }
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="checkbox"]:checked,
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="checkbox"]:checked,
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="checkbox"]:checked,
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="checkbox"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td input[type="checkbox"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td input[type="checkbox"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td input[type="checkbox"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="radio"]:checked,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td input[type="checkbox"]:checked {
          box-shadow: inset 0 0 0 4px #f1f1f1;
          background-color: #005da9;
          border: 1px solid #005da9; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td label,
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td label,
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td label,
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td label,
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfRadioButtonList tbody tr td label,
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder .scfCheckBoxList tbody tr td label,
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfRadioButtonList tbody tr td label,
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder .scfCheckBoxList tbody tr td label {
        width: auto;
        display: inline-flex;
        font-size: 1rem;
        font-family: "Proxima N W01 Reg", sans-serif; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item label,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item label {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-family: "Proxima N W01 Reg", sans-serif;
  color: #27251F;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  white-space: normal;
  float: none; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container,
#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container {
  flex-wrap: wrap;
  position: relative; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container input[type=text],
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container input[type=text] {
    border-radius: .2rem 0 0 .2rem;
    border-right: 0;
    max-width: 50%; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container input[type=button],
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container input[type=button] {
    background: #005da9;
    border: 0;
    color: #ffffff;
    font-size: 1.25rem;
    font-family: "Proxima N W01 Light", sans-serif;
    border-radius: 0 .2rem .2rem 0;
    height: 3.5rem;
    max-width: 50%; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container input[type=button]:hover,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container input[type=button]:hover {
      cursor: pointer; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown {
    position: relative !important;
    top: initial !important;
    left: initial !important;
    padding: 0 !important;
    margin-top: .5rem;
    width: 100%; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown select,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown select {
      width: 100%;
      min-height: 10rem;
      font-size: 1rem;
      padding: 0; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div {
      display: flex;
      margin-top: .5rem !important;
      flex-direction: row-reverse; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div input[type=button],
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div input[type=button] {
        height: 2.5rem; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-ok,
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-ok {
        margin-right: 0; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-cancel,
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item .data8-container .data8-postcodelookup-dropdown > div .data8-postcodelookup-cancel {
        margin-right: 0;
        border-radius: .25rem 0 0 .25rem;
        background: #ffffff;
        color: #005da9;
        border: 1px solid #005da9; }

#wrapper #content .rich-text.try-hearing-aid,
#wrapper #content .rich-text.should-i-get-hearing-test {
  flex: 1; }
  #wrapper #content .rich-text.try-hearing-aid .component-content,
  #wrapper #content .rich-text.should-i-get-hearing-test .component-content {
    text-align: center;
    flex-direction: column;
    padding: 4rem 0; }
    #wrapper #content .rich-text.try-hearing-aid .component-content h1,
    #wrapper #content .rich-text.should-i-get-hearing-test .component-content h1 {
      color: #00427A;
      font-size: 3rem;
      line-height: 1.2;
      padding-bottom: 2rem;
      position: relative;
      margin-top: 9rem; }
      #wrapper #content .rich-text.try-hearing-aid .component-content h1:before,
      #wrapper #content .rich-text.should-i-get-hearing-test .component-content h1:before {
        content: "";
        padding-left: .5rem;
        font-family: "retail-icon-font";
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 3.125rem;
        position: absolute;
        display: flex;
        background: #a7d3ac;
        color: #ffffff;
        justify-content: center;
        font-size: 2.875rem;
        line-height: 7rem;
        font-weight: bold;
        left: calc(50% - 3.125rem);
        top: -8rem; }
    #wrapper #content .rich-text.try-hearing-aid .component-content p,
    #wrapper #content .rich-text.should-i-get-hearing-test .component-content p {
      color: #001C32;
      font-size: 1.5rem;
      font-family: "Proxima N W01 Light", sans-serif;
      display: flex;
      justify-content: center; }
      #wrapper #content .rich-text.try-hearing-aid .component-content p a,
      #wrapper #content .rich-text.should-i-get-hearing-test .component-content p a {
        color: #00427A;
        border: 2px solid #00427A;
        font-size: 1.5rem;
        font-family: "Proxima N W01 Reg", sans-serif;
        height: 4rem;
        display: flex;
        align-items: center;
        border-radius: 2rem;
        padding: 0 2rem; }

@media (max-width: 991px) {
  #wrapper #content .rich-text.try-hearing-aid .component-content,
  #wrapper #content .rich-text.should-i-get-hearing-test .component-content {
    padding: 0 1rem;
    box-sizing: border-box; }
    #wrapper #content .rich-text.try-hearing-aid .component-content h1,
    #wrapper #content .rich-text.should-i-get-hearing-test .component-content h1 {
      margin-top: 1.5rem;
      padding-bottom: 1rem; }
      #wrapper #content .rich-text.try-hearing-aid .component-content h1:before,
      #wrapper #content .rich-text.should-i-get-hearing-test .component-content h1:before {
        content: "";
        padding-left: .5rem;
        font-family: "retail-icon-font";
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 3.125rem;
        position: absolute;
        display: flex;
        background: #a7d3ac;
        color: #ffffff;
        justify-content: center;
        font-size: 2.875rem;
        line-height: 7rem;
        font-weight: bold;
        left: calc(50% - 3.125rem);
        top: -8rem; }
      #wrapper #content .rich-text.try-hearing-aid .component-content h1:before,
      #wrapper #content .rich-text.should-i-get-hearing-test .component-content h1:before {
        top: -10rem; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item input:not([type='checkbox']):not([type=radio]):not([type='submit']):not([type=button]),
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item select,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item textarea,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item input:not([type='checkbox']):not([type=radio]):not([type='submit']):not([type=button]),
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item select,
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .wizard-form-item textarea {
    font-size: 1.4rem; } }

#wrapper #content .cta-red .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder input[type=submit],
#wrapper #content .cta-red .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder input[type=submit],
#wrapper #content .cta-red .form.try-hearing-aid .component-content .scfForm .form-wizard-navigation .form-wizard-continue {
  color: #ffffff;
  background: #e40049;
  border: 1px solid #e40049; }
  #wrapper #content .cta-red .form.try-hearing-aid .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder input[type=submit]:hover,
  #wrapper #content .cta-red .form.should-i-get-hearing-test .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder input[type=submit]:hover,
  #wrapper #content .cta-red .form.try-hearing-aid .component-content .scfForm .form-wizard-navigation .form-wizard-continue:hover {
    cursor: pointer;
    color: #ffffff;
    background: #b10039;
    border: 1px solid #b10039; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .scfIntroBorder p {
  font-size: 1.5rem;
  color: #00427A;
  font-family: "Proxima N W01 Light", sans-serif; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item,
#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox {
  display: none;
  margin: 0; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfSectionBorderAsFieldSet,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfSectionBorderAsFieldSet {
    display: block !important;
    margin-top: 0; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .data8-container {
    display: block !important; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container input[type="text"],
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .data8-container input[type="text"] {
      border-radius: 0.2rem 0 0 0.2rem !important;
      border-right: 0 !important;
      width: 50%; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container input[type="button"],
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .data8-container input[type="button"] {
      background: #005da9;
      border: 0;
      color: #ffffff;
      font-size: 1.25rem;
      font-family: "Proxima N W01 Light", sans-serif;
      border-radius: 0 0.2rem 0.2rem 0;
      width: 50%;
      height: 3.5rem; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .data8-container .scfValidator,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .data8-container .scfValidator {
      width: 100%; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .halfAvailableWidth,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .halfAvailableWidth {
    display: inline-flex;
    float: none;
    flex-direction: column;
    flex-grow: 1;
    width: 48.5%;
    margin-bottom: 1rem; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .halfAvailableWidth:last-of-type,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .halfAvailableWidth:last-of-type {
      margin-right: 0; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .halfAvailableWidth:first-of-type,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .halfAvailableWidth:first-of-type {
      margin-right: 3%; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .form-step,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .form-step {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-family: "Proxima N W01 Bold", sans-serif, sans-serif;
    padding-bottom: 0;
    text-align: center;
    color: #90B494;
    width: 100%; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.active,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.active {
    display: block;
    border-radius: 0.5rem 0.5rem 0 0;
    background: #ffffff;
    padding: 1.75rem 2.375rem 1rem 2.375rem; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.form-wizard-submit-container {
    background: #ffffff;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0 2.375rem 2rem 2.375rem;
    align-items: flex-start;
    justify-content: center; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container .form-step,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.form-wizard-submit-container .form-step {
      display: none; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container input,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.form-wizard-submit-container input {
      height: 4rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container .scfFooterBorder,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.form-wizard-submit-container .scfFooterBorder {
      font-size: 1rem; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container .scfFooterBorder *,
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.form-wizard-submit-container .scfFooterBorder * {
        font-size: 1rem; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item label,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox label {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListGeneralPanel label,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListGeneralPanel label,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListGeneralPanel label,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfRadioButtonListGeneralPanel label {
    margin: 0; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListGeneralPanel {
    float: none; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListGeneralPanel .scfCheckbox,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListGeneralPanel .scfCheckbox {
      width: 100%;
      margin-top: 0;
      display: flex; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListGeneralPanel .scfCheckbox .form-step,
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListGeneralPanel .scfCheckbox .form-step {
        margin-bottom: 1rem; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListGeneralPanel .scfCheckbox input,
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListGeneralPanel .scfCheckbox input {
        margin-top: 0.25rem; }
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListGeneralPanel .scfCheckbox input.checkbox-invalid:not(:checked),
        #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListGeneralPanel .scfCheckbox input.checkbox-invalid:not(:checked) {
          border: 2px solid #e40049;
          box-shadow: 0 0 10px #e40049; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListGeneralPanel .scfCheckbox label,
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListGeneralPanel .scfCheckbox label {
        width: 100%;
        display: block;
        text-align: left;
        font-size: 1rem;
        font-family: "Proxima N W01 Light", sans-serif;
        line-height: 1.5;
        margin-bottom: 0.5rem; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder.radios-invalid .scfRadioButtonListGeneralPanel .scfRadioButtonList input,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfRadioButtonListBorder.radios-invalid .scfRadioButtonListGeneralPanel .scfRadioButtonList input {
    border: 2px solid #e40049;
    box-shadow: 0 0 10px #e40049; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDropListBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfEmailBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfMultipleLineTextBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfSingleLineTextBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfPasswordBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfNumberBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDatePickerBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDateBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfListBoxBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCheckBoxListBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfFileUploadBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDateSelectorBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCreditCardBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfConfirmPasswordBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCaptchaBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfTelephoneBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDropListGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfSmsTelephoneBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDropListBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfEmailBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfMultipleLineTextBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfSingleLineTextBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfPasswordBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfNumberBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDatePickerBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDateBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfRadioButtonListBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfListBoxBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCheckBoxListBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfFileUploadBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDateSelectorBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCreditCardBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfConfirmPasswordBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCaptchaBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfTelephoneBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDropListGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfSmsTelephoneBorder {
    margin: 0 0 1rem 0; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfEmailGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfMultipleLineGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfSingleLineGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfPasswordGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfNumberGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDatePickerGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDateGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfRadioButtonListGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfFileUploadGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfDateSelectorGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCreditCardGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfConfirmPasswordGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfCaptchaGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfTelephoneGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfSmsTelephoneGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .scfFooterBorder,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfEmailGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfMultipleLineGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfSingleLineGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfPasswordGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfNumberGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDatePickerGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDateGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfRadioButtonListGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfFileUploadGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfDateSelectorGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCreditCardGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfConfirmPasswordGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfCaptchaGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfTelephoneGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfSmsTelephoneGeneralPanel,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox .scfFooterBorder {
    margin: 0; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.data8-feature,
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.data8-feature {
    border-radius: 0.5rem; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.data8-feature div[class*="name.Address"],
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.data8-feature div[class*="name.City"],
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.data8-feature div[class*="name.Address"],
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.data8-feature div[class*="name.City"] {
      display: none; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.data8-feature .scfRadioButtonListBorder,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.data8-feature .scfCheckBoxListBorder,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.data8-feature .scfRadioButtonListBorder,
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox.data8-feature .scfCheckBoxListBorder {
      display: none; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item-checkbox {
  background: #ffffff;
  padding: 0 2.375rem; }

#wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-navigation {
  background: #ffffff;
  border-radius: 0 0 0.5rem 0.5rem;
  align-items: flex-start;
  justify-content: center;
  padding: 0 2.375rem;
  height: 6.125rem; }
  #wrapper #content .form.try-hearing-aid .component-content .scfForm .form-wizard-navigation .form-wizard-continue {
    height: 4rem;
    border: 2px solid #005da9;
    width: 100%;
    border-radius: 2rem;
    font-size: 1.5rem;
    color: #005da9;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center; }

#wrapper #content .form.try-hearing-aid.form-wizard-version-one .component-content .scfForm .wizard-form-item label {
  justify-content: flex-start; }

#wrapper #content .form.try-hearing-aid.form-wizard-version-two .component-content .scfForm .wizard-form-item .form-step {
  margin-bottom: 1.25rem; }

#wrapper #content .form.try-hearing-aid.form-wizard-version-two .component-content .scfForm .wizard-form-item label {
  display: none; }

#wrapper #content .form.try-hearing-aid.cta-red .component-content .scfForm .form-wizard-navigation .form-wizard-continue {
  background: #e40049;
  border: 2px solid #e40049;
  color: #ffffff; }

#wrapper #content .form.try-hearing-aid.cta-red .component-content .scfForm .form-wizard-submit-container .scfSubmitButtonBorder input[type=submit] {
  border: 2px solid #e40049;
  background: #e40049; }

@media (max-width: 991px) {
  #wrapper #content .form.try-hearing-aid {
    padding: 0; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .scfSectionBorder .form-wizard-navigation {
      height: 5.875rem; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item .halfAvailableWidth {
      width: 100%;
      display: block;
      margin-left: 0;
      margin-right: 0; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container {
      padding-bottom: 2.375rem; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container .scfSubmitButtonBorder {
        margin-top: 0; }
      #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.form-wizard-submit-container .scfFooterBorder p {
        margin-bottom: 2rem; }
    #wrapper #content .form.try-hearing-aid .component-content .scfForm .wizard-form-item.active {
      padding-top: 1rem; } }

#wrapper #content .form.should-i-get-hearing-test .component-content .scfForm {
  background: #ffffff;
  border-radius: .5rem;
  padding: 2rem; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionUsefulInfo {
    display: block; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfTitleBorder {
    font-size: 1.5rem;
    text-align: left;
    color: #001C32; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfIntroBorder {
    text-align: left; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfIntroBorder p {
      font-size: 1.25rem;
      padding-bottom: 0.5rem; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfValidationSummary {
    display: none; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfValidator {
    padding: 0; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfValidator:last-of-type {
      margin-bottom: 1rem; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionBorderAsFieldSet {
    padding: 0;
    margin: 0;
    border: 0; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionBorderAsFieldSet .scfSectionContent {
      display: flex; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent {
    font-family: "Proxima N W01 Reg", sans-serif; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfDropListBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfEmailBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfMultipleLineTextBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfSingleLineTextBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfPasswordBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfNumberBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfDatePickerBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfDateBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfRadioButtonListBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfListBoxBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfFileUploadBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfDateSelectorBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCreditCardBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfConfirmPasswordBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCaptchaBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfTelephoneBorder,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfSmsTelephoneBorder {
      margin: 0 0 1rem 0; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfEmailGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfMultipleLineGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfSingleLineGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfPasswordGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfNumberGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfDatePickerGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfDateGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfRadioButtonListGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfFileUploadGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfDateSelectorGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCreditCardGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfConfirmPasswordGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCaptchaGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfTelephoneGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfSmsTelephoneGeneralPanel,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfFooterBorder {
      margin: 0; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListGeneralPanel .scfCheckbox {
      width: 100%;
      margin-top: 0;
      display: flex; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListGeneralPanel .scfCheckbox input {
        margin-top: .15rem; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListGeneralPanel .scfCheckbox input.checkbox-invalid:not(:checked) {
          border: 2px solid #e40049;
          box-shadow: 0 0 10px #e40049; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListGeneralPanel .scfCheckbox label {
        width: 100%;
        display: block;
        font-size: .9rem;
        line-height: 1.4; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfRadioButtonListLabel {
      white-space: normal;
      width: 100%;
      float: none;
      font-size: 1rem; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfRadioButtonListGeneralPanel table,
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListGeneralPanel table {
      width: auto; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfRadioButtonListGeneralPanel table tbody tr td,
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListGeneralPanel table tbody tr td {
        padding-right: 1rem; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfRadioButtonListGeneralPanel table tbody tr td label,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent .scfCheckBoxListGeneralPanel table tbody tr td label {
          display: inline-block; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div {
      width: 100%;
      margin-bottom: 1rem; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div.halfAvailableWidth {
        display: inline-flex;
        flex-grow: 1;
        width: 48%; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div label {
        display: none; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div .scfRequired {
        display: none; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div {
        width: 100%; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type=text],
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type=email],
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type=tel],
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div select,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div textarea {
          border-radius: .2rem;
          padding: 0 1rem;
          border: 1px solid #D5D5D5;
          background: #f1f1f1;
          height: 3.5rem;
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          font-style: normal; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type=text] ~ span,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type=email] ~ span,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type=tel] ~ span,
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div textarea ~ span {
          display: none; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div textarea {
          padding: .5rem 1rem;
          height: auto;
          line-height: 1.5rem; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type="radio"],
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type="checkbox"] {
          border: 1px solid #D5D5D5;
          box-shadow: inset 0 0 0 12px #f1f1f1;
          background-color: #f1f1f1; }
          #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type="radio"]:checked,
          #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type="checkbox"]:checked {
            box-shadow: inset 0 0 0 4px #f1f1f1;
            background-color: #005da9;
            border: 1px solid #005da9; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div > div input[type="checkbox"]:checked {
          box-shadow: inset 0 0 0 0 #f1f1f1; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div .scfDropListGeneralPanel {
        position: relative; }
        #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div .scfDropListGeneralPanel:before {
          content: "";
          font-family: "retail-icon-font";
          position: absolute;
          color: #404040;
          font-size: 1rem;
          top: 1.25rem;
          right: 1rem; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div .data8-container input[type=text] {
        border-radius: .2rem 0 0 .2rem;
        border-right: 0; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div .data8-container input[type=button] {
        background: #005da9;
        border: 0;
        color: #ffffff;
        font-size: 1.25rem;
        font-family: "Proxima N W01 Light", sans-serif;
        border-radius: 0 .2rem .2rem 0; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSubmitButtonBorder {
    position: relative;
    margin-top: 1rem; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSubmitButtonBorder input[type=submit] {
      height: 3.875rem;
      border-radius: 1.9375rem;
      background: #005da9;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 100%;
      color: #ffffff;
      font-size: 1.25rem;
      font-family: "Proxima N W01 Bold", sans-serif; }
  #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfFooterBorder {
    background: #ffffff;
    box-sizing: border-box; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfFooterBorder * {
      color: #000000;
      padding: 0;
      width: 100%;
      box-sizing: border-box; }

#wrapper #content .rich-text.should-i-get-hearing-test .component-content h1 {
  color: #ffffff; }

#wrapper #content .rich-text.should-i-get-hearing-test .component-content p {
  color: #ffffff; }
  #wrapper #content .rich-text.should-i-get-hearing-test .component-content p a {
    color: #ffffff;
    border: 2px solid #ffffff; }

@media (max-width: 991px) {
  #wrapper #content .form.should-i-get-hearing-test {
    padding-top: 0; }
    #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent {
      flex-direction: column; }
      #wrapper #content .form.should-i-get-hearing-test .component-content .scfForm .scfSectionContent > div.halfAvailableWidth {
        display: flex;
        width: 100%; } }

#wrapper .video-player.component {
  padding: 3rem 0; }
  #wrapper .video-player.component .video-player__container .container {
    padding: 0; }
  #wrapper .video-player.component .video-player__container .video-player__content {
    color: #27251F; }
    #wrapper .video-player.component .video-player__container .video-player__content p:last-of-type {
      padding-bottom: 0; }
  #wrapper .video-player.component .video-player__container .video-player__thumbnail {
    position: relative; }
    #wrapper .video-player.component .video-player__container .video-player__thumbnail button {
      font-size: 0;
      color: #ffffff;
      position: absolute;
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #005da9;
      border: 0;
      border-radius: 50%; }
      #wrapper .video-player.component .video-player__container .video-player__thumbnail button:before {
        content: '►';
        font-size: 2.5rem;
        margin-left: .5rem;
        line-height: 5rem;
        font-family: Arial, Helvetica, sans-serif; }
      #wrapper .video-player.component .video-player__container .video-player__thumbnail button:hover {
        cursor: pointer;
        background: #007DBD; }
  #wrapper .video-player.component .video-player__container .video-player__overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0; }
    #wrapper .video-player.component .video-player__container .video-player__overlay button {
      position: absolute;
      top: 2rem;
      right: 2rem;
      font-size: 0;
      background: none;
      border: 0; }
      #wrapper .video-player.component .video-player__container .video-player__overlay button:before {
        font-family: "retail-icon-font";
        font-size: 3rem;
        color: #ffffff;
        content: ""; }
      #wrapper .video-player.component .video-player__container .video-player__overlay button:hover {
        cursor: pointer; }
    #wrapper .video-player.component .video-player__container .video-player__overlay .video-player__iframe {
      max-height: calc(100vh - 8rem);
      width: calc(100vw - 16rem); }
      #wrapper .video-player.component .video-player__container .video-player__overlay .video-player__iframe iframe {
        max-height: calc(100vh - 8rem); }
  #wrapper .video-player.component.video-player-right-side .video-player__container .video-player__row {
    flex-direction: row-reverse !important; }
  #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content {
    color: #ffffff; }
    #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h1, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h2, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h3, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h4, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h5, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h6, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h1, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h2, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h3, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h4, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h5, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h6 {
      color: #ffffff; }
      #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h1 span, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h2 span, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h3 span, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h4 span, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h5 span, #wrapper .video-player.component.dark-blue-bg .video-player__container .video-player__content h6 span, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h1 span, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h2 span, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h3 span, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h4 span, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h5 span, #wrapper .video-player.component.audika-blue-bg .video-player__container .video-player__content h6 span {
        color: #ffffff; }
  #wrapper .video-player.component.video-player-in-the-middle .video-player__container .video-player__iframe {
    align-items: center;
    display: flex; }
  #wrapper .video-player.component.video-player-text-in-the-middle .video-player__container .video-player__content {
    justify-content: center;
    display: flex;
    flex-direction: column; }
  #wrapper .video-player.component.video-player-text-align-right .video-player__container .video-player__content {
    text-align: right; }
  #wrapper .video-player.component.video-player-text-align-center .video-player__container .video-player__content {
    text-align: center; }

@media (max-width: 769px) {
  #wrapper .video-player.component .video-player__container .video-player__content {
    margin-top: 2rem;
    text-align: center; }
  #wrapper .video-player.component .video-player__container .video-player__overlay {
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    #wrapper .video-player.component .video-player__container .video-player__overlay .video-player__iframe {
      max-height: none;
      width: 100vw; }
      #wrapper .video-player.component .video-player__container .video-player__overlay .video-player__iframe iframe {
        max-height: none; } }

body.online-booking-component .component.form {
  display: none; }

#wrapper .diary-management-online-booking {
  padding-bottom: 1rem; }
  #wrapper .diary-management-online-booking > .component-content > div:not(.progress-bar-wrapper):not(.container):not(.online-booking-wrapper) {
    display: none; }
  #wrapper .diary-management-online-booking .online-booking-wrapper {
    margin-left: -2rem;
    margin-right: -2rem; }
    #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 {
      text-align: center; }
      #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 h1 {
        font-size: 3rem;
        color: #404040;
        padding-bottom: 1rem; }
      #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 h2 {
        font-size: 3rem; }
      #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 h3 {
        font-size: 2.25rem;
        font-family: "Proxima N W01 Reg", sans-serif; }
      #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 p {
        font-size: 1.25rem;
        white-space: pre-line; }
      #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 a {
        color: #005da9; }
    #wrapper .diary-management-online-booking .online-booking-wrapper .extra-links {
      margin-top: 2rem; }
      #wrapper .diary-management-online-booking .online-booking-wrapper .extra-links a {
        font-family: "Proxima N W01 Reg", sans-serif;
        font-size: 1rem; }
        #wrapper .diary-management-online-booking .online-booking-wrapper .extra-links a:not(:first-child) {
          margin-left: .5rem; }
          #wrapper .diary-management-online-booking .online-booking-wrapper .extra-links a:not(:first-child):before {
            content: "|";
            margin-right: .5rem; }

@media (max-width: 991px) {
  #wrapper .diary-management-online-booking {
    height: auto;
    min-height: calc(100vh - 6.875rem); }
    #wrapper .diary-management-online-booking .online-booking-wrapper {
      margin-left: -1rem;
      margin-right: -1rem; }
      #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 h1 {
        font-size: 2rem;
        padding-bottom: 1rem; }
      #wrapper .diary-management-online-booking .online-booking-wrapper > .col-12 p {
        font-size: 1.25rem;
        line-height: 1.25; }
  .flex-header.online-booking-component #wrapper #header .flex-header-box .header-section-bottom .header-section-bottom-content {
    display: none; } }

.center-details-marker {
  width: 1rem;
  height: 1rem;
  background: #005da9; }

@media print {
  html, body {
    height: auto;
    overflow: hidden; }
  .header-section-bottom, .header-menu-items, #footer, .progress-bar-wrapper, .confirmation-links {
    display: none !important; }
  .flex-header #wrapper #header {
    position: relative; }
  .flex-header #wrapper #header .flex-header-box .header-section-top .header-section-top-content {
    height: auto; }
  .flex-header #wrapper #header .flex-header-box .header-section-top .header-section-top-content .header-logo a img {
    max-height: none; }
  .header-logo {
    margin-bottom: 1rem;
    margin-top: 2rem;
    width: 100%;
    text-align: center; }
    .header-logo a {
      display: block;
      margin: 0 auto; }
      .header-logo a img {
        margin: 0 auto;
        width: 100%; }
  #content {
    margin: 2rem 0 0 0 !important;
    height: auto; }
  .review-booking-sections > div {
    width: 33.33333% !important; }
    .review-booking-sections > div:before {
      content: none !important; }
    .review-booking-sections > div > div {
      border: 0 !important;
      padding: 0 !important; }
      .review-booking-sections > div > div p {
        font-size: .9rem !important; }
        .review-booking-sections > div > div p br {
          display: none; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper form,
#wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm {
  position: relative; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="tel"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="text"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="number"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="email"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form select,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form textarea,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="tel"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="text"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="number"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="email"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm select,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm textarea {
    border: 1px solid #999999;
    border-radius: 0.25rem;
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.25rem;
    font-family: "Proxima N W01 Reg", sans-serif;
    padding: 0 1.25rem;
    height: 3.125rem;
    line-height: 3.125rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="tel"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="text"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="number"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="email"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form select:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form textarea:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="tel"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="text"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="number"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="email"]:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm select:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm textarea:focus {
      border-color: #999999; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="tel"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="text"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="number"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="email"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form select::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form textarea::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="tel"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="text"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="number"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="email"]::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm select::-webkit-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm textarea::-webkit-input-placeholder {
      color: #cccccc; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="tel"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="text"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="number"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="email"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form select:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form textarea:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="tel"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="text"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="number"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="email"]:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm select:-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm textarea:-moz-placeholder {
      color: #cccccc; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="tel"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="text"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="number"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="email"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form select::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form textarea::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="tel"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="text"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="number"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="email"]::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm select::-moz-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm textarea::-moz-placeholder {
      color: #cccccc; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="tel"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="text"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="number"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="email"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form select:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form textarea:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="tel"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="text"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="number"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm input[type="email"]:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm select:-ms-input-placeholder,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm textarea:-ms-input-placeholder {
      color: #cccccc; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .dropdown-wrapper,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .scfDropListGeneralPanel,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .dropdown-wrapper,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .scfDropListGeneralPanel {
    position: relative; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .dropdown-wrapper:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .scfDropListGeneralPanel:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .dropdown-wrapper:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .scfDropListGeneralPanel:after {
      font-family: "retail-icon-font";
      content: "";
      position: absolute;
      right: 0.75rem;
      top: 1rem;
      color: #333333;
      font-size: 1rem;
      pointer-events: none; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form textarea,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm textarea {
    height: auto; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form.geosuggest-form--enable-scroll .geosuggest__suggests-wrapper .geosuggest__suggests,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm.geosuggest-form--enable-scroll .geosuggest__suggests-wrapper .geosuggest__suggests {
    max-height: 16.3rem;
    overflow-y: auto; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-search,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-clear,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-search,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-clear {
    position: absolute;
    border: 0;
    background: none;
    font-size: 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-search:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-clear:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-search:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-clear:after {
      font-size: 1.5rem;
      font-family: "retail-icon-font";
      content: "";
      color: #005da9; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-search:hover,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-clear:hover,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-search:hover,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-clear:hover {
      cursor: pointer; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-search,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-search {
    height: 100%;
    top: 0;
    right: 0;
    background: #005da9;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    border-radius: 0 0.25rem 0.25rem 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-search:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-search:after {
      color: #ffffff; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-clear,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-clear {
    right: 4.8rem;
    top: 1.1rem;
    display: none; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest-clear:after,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest-clear:after {
      content: "";
      color: #515151;
      font-size: 1rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__input-wrapper,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__input-wrapper {
    position: relative; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__input-wrapper input:focus,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__input-wrapper input:focus {
      border-radius: 0.25rem 0.25rem 0 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest input[type="text"],
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest input[type="text"] {
    padding-right: 4rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__suggests-wrapper,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__suggests-wrapper {
    position: absolute;
    width: 100%; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests {
      box-shadow: 0 0 1.5rem #cccccc;
      background: #ffffff; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests.geosuggest__suggests--hidden,
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests.geosuggest__suggests--hidden {
        display: none; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item,
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item {
        font-size: 1.25rem;
        font-family: "Proxima N W01 Reg", sans-serif;
        padding: 0 1.25rem;
        height: 3.25rem;
        line-height: 3.25rem;
        color: #005da9;
        border: 1px solid #cccccc;
        border-top: 0;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover,
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover {
          cursor: pointer;
          background: #f1f1f1; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item,
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item {
          padding-right: 3rem;
          position: relative; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item:after,
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item:after {
            content: "";
            background-image: url("/design/build/retail/assets/ear.svg");
            background-size: 27px 31px;
            width: 27px;
            height: 31px;
            right: 0.5rem;
            top: calc(1.575rem - 15px);
            position: absolute; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form.show-clear-button .geosuggest-clear,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .scfForm.show-clear-button .geosuggest-clear {
    display: block; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper form {
    margin-top: 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="tel"],
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="text"],
    #wrapper .component.diary-management-online-booking .online-booking-wrapper form input[type="email"] {
      height: 3.5rem;
      line-height: 3.5rem;
      font-size: 1.4rem; } }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default,
#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .scfSubmitButtonBorder input[type=submit] {
  font-size: 1.25rem;
  color: #ffffff;
  background: #005da9;
  height: 3.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.875rem;
  font-family: "Proxima N W01 Bold", sans-serif;
  padding: 0 4rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default.button-disabled,
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .scfSubmitButtonBorder input[type=submit].button-disabled {
    background: #dddddd;
    color: #bbbbbb; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default.button-long,
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .scfSubmitButtonBorder input[type=submit].button-long {
    padding: 0 8rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default.button-hollow,
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .scfSubmitButtonBorder input[type=submit].button-hollow {
    background: #ffffff;
    font-family: "Proxima N W01 Reg", sans-serif;
    color: #005da9;
    border: 1px solid #005da9;
    margin: 2rem .5rem 0 .5rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default:hover,
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .scfSubmitButtonBorder input[type=submit]:hover {
    cursor: pointer; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default.active,
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .scfSubmitButtonBorder input[type=submit].active {
    background: #005da9;
    color: #ffffff; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-desktop-first {
  margin-top: 0;
  margin-bottom: 2rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-selection {
  margin-top: 2rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-selection h3 {
    font-size: 1.875rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    color: #404040;
    margin-bottom: 0;
    padding: 0; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .confirmation-links .button-default {
  font-size: 1.125rem;
  height: 3.125rem;
  padding: 0 2rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .confirmation-links .button-default:hover {
    cursor: pointer; }

@media (max-width: 991px) {
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default {
    padding: 0;
    width: 100%; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-default.button-hollow {
      margin: 1rem 0 0 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-selection {
    margin-top: 2rem; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .button-selection h3 {
      font-size: 1.25rem;
      margin-top: 1rem; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .find-center-map {
  margin-top: 2rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .find-center-map p {
    font-size: 1.25rem;
    padding-bottom: 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .find-center-map img {
    border-radius: 5rem;
    margin: 0.5rem 0; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .geosuggest-form .geosuggest .geosuggest__suggests-wrapper {
  display: none; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .geosuggest-form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.geosuggest__item--active {
    background: #f1f1f1; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .geosuggest-form.suggests-visible .geosuggest .geosuggest__suggests-wrapper {
  display: block; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .geosuggest .geosuggest__input-wrapper:after {
    top: 1.5rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-wrapper .find-center-map p {
    padding-bottom: 0; } }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .request-callback-wrapper {
  margin-top: 2rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form {
  padding: 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content {
    width: 100%;
    max-width: 100%; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSubmitButtonBorder {
      position: initial;
      display: flex;
      justify-content: center; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfValidationSummary,
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSubmitSummary {
      display: none; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSectionContent div label {
      float: none; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSectionContent .scfRadioButtonList input {
      border: 2px solid #7f7f7f; }
      #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSectionContent .scfRadioButtonList input:focus, #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSectionContent .scfRadioButtonList input:checked {
        border: 2px solid #005da9; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSectionContent .scfCheckBoxListGeneralPanel .scfCheckbox {
      width: 100%; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfFooterBorder > * {
      width: 100%;
      white-space: initial; }

@media (max-width: 600px) {
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .halfAvailableWidth {
    display: block; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSectionContent > div:first-of-type {
    margin: 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .wffm-container .component.form .component-content .scfSectionContent > div:not(:first-of-type) {
    margin-top: 2rem; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table {
  width: 100%;
  margin-top: 2rem;
  font-size: 1.25rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr td {
    border-bottom: 1px solid #a7d3ac;
    padding: 0.75rem;
    vertical-align: middle; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr td:first-child {
      border-left: 1px solid #a7d3ac;
      border-right: 1px solid #a7d3ac;
      color: #005da9;
      text-align: center; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr td:last-child {
      border-right: 1px solid #a7d3ac;
      text-align: right; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr td:last-child button {
        background: none;
        color: #ffffff;
        background: #005da9;
        font-family: "Proxima N W01 Bold", sans-serif;
        border-radius: 0.9375rem;
        padding: 0 1rem;
        font-size: 1rem;
        white-space: nowrap;
        border: 1px solid #005da9;
        height: 1.875rem; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr td:last-child button:hover {
          cursor: pointer;
          background: #ffffff;
          color: #005da9; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr:first-child td {
    border-top: 1px solid #a7d3ac; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr:first-child td:first-child {
      border-radius: 0.2rem 0 0 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr:first-child td:last-child {
      border-radius: 0 0.2rem 0 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 0.2rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr:last-child td:last-child {
    border-radius: 0 0 0.2rem 0; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map {
  margin-top: 3rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map p {
    font-size: 1.25rem;
    padding-bottom: 0.5rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map img {
    border-radius: 5rem;
    margin: 0.5rem 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map--simple {
    margin-top: 1rem;
    margin-bottom: -2rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map--simple p {
      font-size: 1rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map--simple a,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map--simple a:link,
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map--simple a:visited {
      text-decoration: underline; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper table tbody tr td:last-child button {
    white-space: initial;
    height: auto; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map {
    margin-top: 3rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map p {
      padding-bottom: 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .pick-center-from-list-wrapper .find-center-map--simple {
      margin-top: 1rem;
      margin-bottom: -1.5rem; } }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .call-placeholder {
  padding-bottom: 1rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .description-container p {
  padding-bottom: 1rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-choice-content {
  font-size: 1.25rem;
  margin-bottom: 1.2em;
  line-height: 1.5; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-choice-content p:last-child {
    padding: 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-choice-content a.button-default {
    margin: 0 .5rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-selector-container {
  margin: 2rem 0 2.5rem 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-selector-container .flow-selector-item .button-default.button-hollow {
    margin-top: 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-selector-container .flow-selector-item img {
    border-radius: 50%;
    margin-bottom: -2rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-selector-container .flow-selector-separator {
    font-size: 1.5rem;
    color: #96bd9a; }

@media (max-width: 768px) {
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-choice-content a.button-default {
    margin: 0 0 1.2em 0; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-choice-content a.button-default:last-child {
      margin-bottom: 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-selector-container .flow-selector-separator {
    margin: 1.5rem 0; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .flow-choice-wrapper .flow-selector-container .flow-selector-item .button-default.button-hollow {
    margin-top: 1rem; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .appointment-types-wrapper .appointment-types-container > div > div {
  display: flex;
  margin: 2rem 0 2.5rem 0;
  justify-content: center; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .appointment-types-wrapper .appointment-types-container > div > div a {
    margin: 0 .5rem; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .appointment-types-wrapper .appointment-types-container > div > div {
    flex-direction: column; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .appointment-types-wrapper .appointment-types-container > div > div a {
      margin-bottom: 1rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .appointment-types-wrapper .appointment-types-container > div > div a:last-of-type {
        margin-bottom: 0; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details-marker {
  width: 64px;
  height: 78px;
  background: url("/design/build/retail/assets/icon-center-primary-selected.png"); }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .clinic-map {
  width: 100%;
  min-height: 300px; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details {
  margin-top: 1rem;
  overflow: hidden; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details img {
    width: 100%; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-image {
    padding: 0;
    display: flex;
    align-items: center;
    background: #005da9; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info {
    background: #E4F1E5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info * {
      max-width: 90%; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info h1 {
      font-size: 2.25rem;
      margin-top: 1rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info h2 {
      color: #84BF8A;
      font-size: 1.25rem;
      margin-top: 1rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info p {
      padding-bottom: 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info a {
      font-size: 1.25rem;
      text-decoration: underline;
      font-family: "Proxima N W01 Reg", sans-serif;
      margin-top: 2rem;
      display: inline-block;
      margin-bottom: 1rem; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-image {
    display: none; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info {
    padding: 2rem 0;
    margin: 0 1rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .center-details-wrapper .center-details .center-details-info h1 {
      font-size: 2rem; } }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .triage-wrapper > p {
  padding-bottom: 0; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .triage-wrapper .triage-section {
  margin-top: 2rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .triage-wrapper .triage-section h2 {
    color: #404040;
    font-size: 1.5rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .triage-wrapper .triage-section .button-default {
    margin-top: .5rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .triage-wrapper .triage-appoitment-selected .button-default {
  margin-top: .5rem; }

#wrapper .component.diary-management-online-booking {
  padding-bottom: 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper.pick-center-on-map {
    margin-left: 0;
    margin-right: 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper > .flex-row {
    position: relative;
    height: calc(100vh - 20rem); }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .action-buttons {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .action-buttons button {
      background: none;
      color: #ffffff;
      background: #005da9;
      font-family: "Proxima N W01 Bold", sans-serif;
      border-radius: .9375rem;
      padding: 0 1rem;
      font-size: 1rem;
      margin-bottom: .25rem;
      white-space: nowrap;
      border: 1px solid #005da9;
      height: 1.875rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .action-buttons button:hover {
        cursor: pointer;
        background: #00427A;
        border: 1px solid #00427A; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .action-buttons .button-link {
      font-family: "Proxima N W01 Reg", sans-serif;
      text-decoration: underline;
      padding: .5rem .5rem 0 .5rem;
      background: none;
      border: 0;
      color: #005da9; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .action-buttons .button-link:hover {
        background: none;
        border: 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper h1 {
    margin-bottom: .5rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map {
    width: 100%;
    padding: 0;
    position: relative;
    height: 100%; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .cluster img {
      display: none; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .cluster div {
      display: flex;
      border-radius: 50%;
      background: #005da9;
      font-family: "Proxima N W01 Reg", sans-serif;
      height: 100%;
      font-size: .8rem;
      align-items: center;
      justify-content: center; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .cluster div:hover {
        background: #00427A; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-fullscreen-control {
      display: flex;
      justify-content: center;
      align-items: center; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-fullscreen-control:before {
        content: "";
        width: 16px;
        height: 16px;
        display: inline-block;
        background: url("/design/build/retail/assets/google-maps-spritesheet.png") -19px -1px; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-fullscreen-control:hover:before {
        background-position: -1px -1px; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-fullscreen-control img {
        display: none; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-bundled-control button img {
      display: none; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-bundled-control button:before {
      width: 16px;
      height: 16px;
      display: inline-block;
      content: "";
      background: url("/design/build/retail/assets/google-maps-spritesheet.png"); }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-bundled-control button:first-of-type:before {
      background-position: -37px -19px; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-bundled-control button:first-of-type:hover:before {
      background-position: -37px -42px; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-bundled-control button:last-of-type:before {
      height: 4px;
      background-position: -19px -37px; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .gm-style .gm-bundled-control button:last-of-type:hover:before {
      background-position: -1px -37px; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .my-location-container {
      display: none; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .google-map-center {
      position: absolute;
      top: .75rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
      border-radius: 2px;
      background: #ffffff;
      border: 0;
      padding: .5rem;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .google-map-center:hover {
        cursor: pointer; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search {
    background: #f1f1f1;
    padding: .75rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form {
      margin-top: 0; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest-search {
        height: 2.75rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input {
        border-radius: .25rem;
        box-shadow: none;
        height: 2.75rem;
        font-size: 1rem;
        padding-right: 3.5rem;
        display: flex;
        align-items: center;
        font-family: "Proxima N W01 Reg", sans-serif; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__input-wrapper:after {
        top: 1rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper {
        position: relative;
        width: 100%;
        border: 1px solid #f1f1f1;
        max-height: none;
        overflow: hidden;
        display: none; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests {
          background: #ffffff;
          z-index: 2;
          width: 100%;
          top: 1px;
          box-shadow: 0 0 1.5rem #dddddd; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item {
            font-size: 1rem;
            border: 0;
            border-top: 1px solid #f1f1f1;
            height: 2.75rem;
            line-height: 2.75rem; }
            #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:after {
              top: calc(1.275rem - 15px); }
            #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover, #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.geosuggest__item--active {
              cursor: pointer;
              background: #f1f1f1; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form.show-clear-button .geosuggest .geosuggest__input-wrapper .geosuggest__input {
        padding-right: 6.4rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form.show-clear-button .geosuggest-clear {
        right: 4.5rem;
        top: .9rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form.suggests-visible .geosuggest__suggests-wrapper {
        display: block; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu {
    position: absolute;
    top: 4.25rem;
    left: -50rem;
    padding: 0;
    z-index: 1;
    background: #ffffff;
    transition: all 0.5s ease;
    height: calc(100% - 4.25rem);
    background: #ffffff; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu.expanded {
      left: 0;
      position: relative; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item {
        text-align: left;
        display: flex;
        flex-direction: row;
        padding: 1rem 0;
        border-bottom: 1px solid #dce5ec; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item:hover, #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item.hovered-center {
          background: #a7d3ac;
          cursor: pointer; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item .distance-label {
          padding-left: 1rem;
          text-align: center;
          line-height: 1.4; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item .distance-label span {
            color: #005da9;
            font-family: "Proxima N W01 Bold", sans-serif; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item .center-info {
          flex-grow: 1;
          padding-left: 1rem; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item .center-info h2,
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item .center-info p {
            font-size: 1rem;
            padding: 0;
            line-height: 1.3; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list .map-centers-list-item .center-info h2 {
            color: #27251F; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: #ffffff;
      z-index: 3;
      text-align: left;
      overflow-y: auto;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25); }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-header {
        background: #005da9;
        display: flex;
        margin: 0 -1rem;
        height: 5.25rem;
        padding: 0 1rem;
        align-items: center; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-header h1 {
          flex-grow: 1;
          color: #ffffff;
          font-size: 1.5rem;
          padding: 0;
          margin-bottom: 0; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-header button {
          background: none;
          border: 0;
          font-size: 0; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-header button:before {
            color: #ffffff;
            content: url("/design/build/retail/assets/icon_close.svg"); }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-header button:hover {
            cursor: pointer; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content {
        display: flex;
        flex-direction: row;
        padding: 1rem 0; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content.opening-hours {
          flex-direction: column; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content.opening-hours h2 {
            font-size: 1rem; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content.opening-hours ul {
            margin-top: .5rem; }
            #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content.opening-hours ul li {
              line-height: 1.5; }
              #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content.opening-hours ul li span {
                display: inline-block; }
                #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content.opening-hours ul li span:first-of-type {
                  width: 30%; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content.opening-hours .iframe-widget {
            padding-top: .5rem;
            margin-top: .5rem;
            border-top: 1px solid #e6e6e6; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content .column {
          flex-grow: 1;
          width: 50%; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content .column:last-of-type {
            flex-grow: 1;
            margin-left: 2rem; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content .column p {
            font-size: 1rem;
            padding: 0;
            line-height: 1.4; }
            #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content .column p span {
              font-family: "Proxima N W01SC Reg It", sans-serif;
              color: #b0b0b0; }
            #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content .column p a {
              font-family: "Proxima N W01 Reg", sans-serif;
              text-decoration: underline; }
            #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content .column p .map-center-details-regular {
              color: black;
              text-decoration: none; }
            #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-content .column p .get-directions-link:before {
              content: '⤶';
              display: inline-block;
              transform: rotate(180deg);
              margin-right: .25rem;
              font-size: 1.25rem;
              line-height: 1rem; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .iframe-widget {
        padding-top: .5rem;
        margin-top: .5rem;
        border-top: 1px solid #e6e6e6; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .iframe-widget iframe {
          width: 100%; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-book {
        background: #f1f1f1;
        margin: 0 -1rem;
        padding: 1rem;
        align-items: flex-start;
        flex-direction: row; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-book button {
          margin: 0;
          height: 2.25rem;
          border-radius: 1.125rem; }
          #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details .map-center-details-book button.get-directions {
            background: none;
            color: #005da9;
            border: 2px solid #005da9;
            margin-left: 1rem;
            padding-left: 2rem;
            padding-right: 2rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .iframe-widget .podium-reviews__header,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .iframe-widget .review-us-btn-container {
    display: none; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .iframe-widget .podium-reviews__content h3 {
    font-size: 1.5rem;
    font-family: "Proxima N W01 Bold", sans-serif; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .google-map-my-location {
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    border-radius: 2px;
    background: #ffffff;
    border: 0;
    font-size: 0;
    padding: 0;
    display: flex;
    width: 40px;
    height: 40px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    color: #515151; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .google-map-my-location:hover {
      cursor: pointer; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .google-map-my-location:hover:before {
        background-position: -1px -19px; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .google-map-my-location:before {
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;
      background: url("/design/build/retail/assets/google-maps-spritesheet.png") -19px -19px; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper > .flex-row {
    height: auto; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu {
    position: relative;
    top: 0;
    left: 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-centers-list {
      z-index: 0;
      display: none; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-menu .map-center-details {
      height: calc(100vh - 5.625rem - 3.25rem - 2rem); }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search {
    position: relative; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input {
      font-size: 1.4rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map {
    height: calc(100vh - 5.625rem - 3.25rem - 2rem); }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .google-map .google-map-center {
      display: none !important; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .button-hollow {
    font-size: 1.25rem;
    background: #005da9;
    color: #ffffff;
    height: 3.375rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.875rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    border: 0;
    margin: 1rem 1rem 0 1rem;
    width: 100%; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .button-hollow.hide-button {
      z-index: 4; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .hide-button {
    display: none; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper .show-button {
    display: block; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper.mobile-open .map-menu .map-centers-list {
    display: block;
    height: calc(100vh - 5.625rem - 3.25rem - 2rem);
    position: absolute;
    background: #ffffff; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper.mobile-open .hide-button {
    display: block; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .find-center-on-map-wrapper.mobile-open .show-button {
    display: none; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .button-default {
  background: #005da9;
  min-width: 16rem; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .small-size {
  font-size: 1.25rem; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list {
  margin: 2rem 0 4rem 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list h2,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list p {
    font-size: 1.25rem;
    padding: 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list .employee-item {
    position: relative;
    margin-bottom: 1rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list .employee-item img {
      border-radius: 50%; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list .employee-item button {
      background: #ffffff;
      border: 1px solid #005da9;
      height: 2.125rem;
      line-height: 2.025rem;
      color: #005da9;
      font-size: 1.25rem;
      border-radius: 1.1rem;
      width: 7rem;
      position: absolute;
      bottom: 0;
      left: calc(50% - 3.5rem); }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list .employee-item button:hover {
        cursor: pointer; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list .employee-item button.active {
        background: #005da9;
        color: #ffffff; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employees-list {
    margin: 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employee-confirm-box {
    position: fixed;
    bottom: 1rem;
    background: #ffffff;
    width: calc(100% - 2rem);
    z-index: 10;
    padding: 1rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.24); }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .choose-employee-wrapper .employee-confirm-box .button-default {
      width: 100%; } }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .appointment-container {
  width: 100%;
  padding: 0 1rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .appointment-container__info {
    text-align: center;
    padding-bottom: 0;
    margin-top: .5rem;
    font-size: 1rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .appointment-container__no-suitable {
    font-size: 1rem;
    border-top: 1px solid #eeeeee;
    margin-top: 1.5rem;
    padding-top: 1rem; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .appointment-container__no-suitable a {
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1rem;
      text-decoration: underline; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .button-default {
  margin-top: 1rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar button {
  background: none;
  border: 0;
  font-size: 1.125rem;
  font-family: "Proxima N W01 Reg", sans-serif;
  width: 12.2857%;
  flex-basis: 12.2857% !important; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__navigation .react-calendar__navigation__next2-button,
#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button {
  display: none; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
  color: #404040;
  font-family: "Proxima N W01 Bold", sans-serif;
  font-size: 1.5rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
  flex-basis: 14.2857%;
  max-width: 14.2857%;
  overflow: hidden;
  font-size: 2rem;
  line-height: 1;
  margin: 0 1%;
  color: #005da9;
  font-family: "Proxima N W01 Bold", sans-serif; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:hover {
    cursor: pointer; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__month-view__weekdays__weekday {
  padding: 1rem 0;
  display: inline-block;
  width: 12.2857%;
  flex-basis: 12.2857% !important;
  margin: 0 1%; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-family: "Proxima N W01 Bold", sans-serif;
    font-size: 1.125rem;
    color: #999999; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__month-view__days .react-calendar__month-view__days__day {
  color: #999999;
  padding: .25rem;
  margin: .25rem 1%;
  max-width: 12.2857% !important; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__month-view__days .react-calendar__month-view__days__day.available {
    border: 1px solid #005da9;
    border-radius: .25rem;
    color: #005da9; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__month-view__days .react-calendar__month-view__days__day.available:hover, #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__month-view__days .react-calendar__month-view__days__day.available.selected {
      cursor: pointer;
      background: #005da9;
      color: #ffffff; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth {
  opacity: .2; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .time-picker p {
  padding-bottom: 1rem; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .time-picker .time-slots {
  display: flex;
  flex-wrap: wrap; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .time-picker .time-slots button {
    border: 1px solid #005da9;
    border-radius: .25rem;
    background: none;
    font-size: 1.125rem;
    font-family: "Proxima N W01 Reg", sans-serif;
    padding: .4rem;
    margin: 0 .3rem .5rem .3rem;
    min-width: 17%;
    color: #005da9; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .time-picker .time-slots button:hover, #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .time-picker .time-slots button.active {
      cursor: pointer;
      background: #005da9;
      color: #ffffff; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .button-default.button-hollow {
  margin-top: 0; }

#wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots {
  margin-top: 2rem;
  position: relative; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots:before {
    background-image: url("/design/build/retail/assets/ob-clock.png");
    content: "";
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: -25px;
    position: absolute; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots__content {
    border: 1px solid #a7d3ac;
    height: 100%;
    padding: 3rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots__content h2 {
      font-size: 1.25rem;
      color: #404040; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots__content p {
      font-size: 1rem;
      margin-top: .5rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots__phone-number {
    display: inline-block; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots__actions {
    display: flex;
    align-items: center;
    justify-content: center; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots__actions a {
      padding: 0 1.5rem; }

@media (max-width: 991px) {
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
    font-size: 1.25rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .time-picker {
    margin-top: 2rem; }
    #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .time-picker button {
      width: 31%;
      padding: .2rem .4rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .button-default.button-hollow {
    margin-top: 1rem; }
  #wrapper #content .component.diary-management-online-booking .online-booking-wrapper .select-date-time-wrapper .no-timeslots__actions {
    flex-direction: column; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .input-error {
  color: #e40049;
  font-size: 1.25rem;
  display: block;
  text-align: left;
  margin-top: -1.25rem;
  margin-bottom: 1.5rem; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .dropdown-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 1.5rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .dropdown-container label {
    margin-bottom: 0.5rem; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .dropdown-wrapper {
  display: flex; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .comment {
  line-height: 1.5rem;
  padding-top: 0.7rem;
  font-size: 1.4rem;
  height: 8rem; }
  @media screen and (min-width: 768px) and (max-width: 993px) {
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .comment {
      height: 3.5rem;
      line-height: 1.8rem; } }
  @media screen and (min-width: 994px) {
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .comment {
      height: 3.15rem;
      font-size: 1.25rem; } }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .date-of-birth-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    display: inline-block;
    margin-right: 1rem; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .input-error {
    font-size: 1.25rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    margin-right: 0; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .date-of-birth-container {
    display: flex;
    flex-wrap: nowrap;
    width: 50%; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .date-of-birth-container .date-of-birth-small {
      display: flex;
      flex-direction: column;
      width: calc(30% - 0.5rem);
      margin-right: 1rem;
      text-align: left; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .date-of-birth-container .date-of-birth-large {
      display: flex;
      flex-direction: column;
      width: 40%;
      text-align: left; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .date-of-birth-container label {
      margin-bottom: 0.5rem;
      font-size: 1.25rem; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .date-of-birth-container input {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: center; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form input,
#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form textarea {
  margin-bottom: 1.5rem; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .button-default {
  margin-top: 2rem; }

#wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy {
  text-align: left; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label {
    display: flex;
    align-items: center; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label span {
      line-height: 1.4; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label input {
      border: 1px solid #005da9;
      border-radius: 0.1rem;
      background: #ffffff;
      color: #005da9;
      margin: 0 0.5rem 0 0;
      min-width: 1.5rem;
      max-width: 1.5rem;
      min-height: 1.5rem;
      max-height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label input:checked {
        background: #005da9; }
        #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label input:checked:before {
          content: "";
          color: #ffffff;
          font-family: "retail-icon-font"; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label a {
      font-family: "Proxima N W01 Reg", sans-serif; }

@media screen and (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth {
    flex-direction: column; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth span {
      margin-right: 0; }
    #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .date-of-birth .date-of-birth-container {
      width: 100%; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label,
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .policy label a {
    font-size: 1rem;
    line-height: 1.5; }
  #wrapper .component.diary-management-online-booking .online-booking-wrapper .about-you-wrapper form .button-default {
    margin-top: 2rem; } }

#wrapper .component.diary-management-online-booking .review-booking-wrapper .button-default {
  margin-top: 2rem; }

#wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div {
  margin-top: 2rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div:before {
    content: "";
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: -25px;
    position: absolute; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div .review-box {
    border: 1px solid #a7d3ac;
    height: 100%;
    padding: 3rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column; }
    #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div .review-box__comment {
      margin-top: 0.5rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div h2 {
    color: #404040;
    font-size: 1.5rem;
    margin-bottom: .5rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div h3 {
    color: #404040;
    font-size: 1.25rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    margin-top: 1.5rem;
    padding-bottom: 0.25rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div p,
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div a {
    font-size: 1.125rem;
    padding-bottom: 0; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div p {
    word-break: break-word; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div a {
    font-family: "Proxima N W01 Reg", sans-serif;
    text-decoration: underline;
    padding-bottom: .5rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div .review-change-link {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-specialist a {
    margin-top: 1rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-specialist img {
    width: 60%;
    border-radius: 50%;
    margin-top: 2rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-appointment:before {
    background-image: url("/design/build/retail/assets/ob-home.png"); }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-appointment a {
    margin-top: .5rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-details:before {
    background-image: url("/design/build/retail/assets/ob-user.png"); }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-details h3:first-of-type {
    margin-top: 0; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-details a {
    margin-top: .5rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div.review-booking-date:before {
    background-image: url("/design/build/retail/assets/ob-clock.png"); }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .review-booking-wrapper > p {
    padding: 0; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper > .button-default {
    margin-bottom: 1rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div {
    text-align: center; }
    #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div h2 {
      margin-bottom: 0;
      font-size: 1.25rem; }
    #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections > div h3 {
      font-size: 1.25rem; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections .review-booking-appointment {
    order: -1; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections .review-booking-specialist {
    order: 0; }
  #wrapper .component.diary-management-online-booking .review-booking-wrapper .review-booking-sections .review-booking-details {
    order: 1; } }

#wrapper #content .diary-management-online-booking .online-booking-wrapper .add-to-calendar {
  margin-top: 1rem; }
  #wrapper #content .diary-management-online-booking .online-booking-wrapper .add-to-calendar__btn {
    height: 2.5rem;
    border-radius: 1.25rem;
    background: #005da9;
    color: #ffffff;
    border: 0;
    font-size: 1rem;
    font-family: "Proxima N W01 Bold", sans-serif;
    padding: 0 1rem; }
    #wrapper #content .diary-management-online-booking .online-booking-wrapper .add-to-calendar__btn:hover {
      cursor: pointer; }
  #wrapper #content .diary-management-online-booking .online-booking-wrapper .add-to-calendar__dropdown {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    #wrapper #content .diary-management-online-booking .online-booking-wrapper .add-to-calendar__dropdown a {
      padding: .35rem 0;
      display: block;
      width: 100%; }
      #wrapper #content .diary-management-online-booking .online-booking-wrapper .add-to-calendar__dropdown a:hover {
        background: #f0f0f0; }

#wrapper .component.diary-management-online-booking .progress-bar-wrapper {
  background: #f1f1f1;
  height: 5.75rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0; }
  #wrapper .component.diary-management-online-booking .progress-bar-wrapper > p {
    font-size: 1rem;
    color: #515151;
    font-family: "Proxima N W01 Bold", sans-serif;
    padding: 0;
    margin-bottom: 0.5rem; }
  #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0 0 0;
    max-width: 35rem; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a {
      font-family: "Proxima N W01 Reg", sans-serif;
      color: #005da9; }
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:before {
        border: 1px solid #005da9;
        color: #ffffff;
        background: #005da9; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span {
      color: #bbbbbb; }
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:before {
        border: 1px solid #bbbbbb;
        background: #f1f1f1; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span {
      margin: 0 1.5rem;
      position: relative;
      width: 33.333333%;
      text-align: center; }
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:first-child,
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:first-child {
        margin-left: 0; }
        #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:first-child:after,
        #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:first-child:after {
          content: "";
          height: 1px;
          position: absolute;
          top: calc(-1rem + 1px);
          background: #bbbbbb;
          width: calc(50% + 1.5rem);
          left: calc(50% + 0.6rem); }
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:last-child,
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:last-child {
        margin-right: 0; }
        #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:last-child:after,
        #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:last-child:after {
          content: "";
          height: 1px;
          position: absolute;
          top: calc(-1rem + 1px);
          background: #bbbbbb;
          width: calc(50% + 1.5rem);
          right: calc(50% + 0.6rem); }
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:not(:last-child):not(:first-child):after,
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:not(:last-child):not(:first-child):after {
        content: "";
        height: 1px;
        position: absolute;
        top: calc(-1rem + 1px);
        background: #bbbbbb;
        width: calc(100% + 1.8rem);
        left: -0.9rem; }
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:before,
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:before {
        content: "";
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        display: inline-flex;
        text-align: center;
        font-size: 0.8rem;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -1.6rem;
        font-family: "Proxima N W01 Reg", sans-serif;
        left: calc(50% - 0.6rem);
        z-index: 1000; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:nth-child(1):before,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:nth-child(1):before {
      content: "1"; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:nth-child(2):before,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:nth-child(2):before {
      content: "2"; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:nth-child(3):before,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:nth-child(3):before {
      content: "3"; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:nth-child(4):before,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:nth-child(4):before {
      content: "4"; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-3 .pb-item-1:before, #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 .pb-item-1:before, #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 .pb-item-1:before {
      content: "";
      font-family: "retail-icon-font";
      font-weight: 900;
      padding-left: 0.05rem;
      padding-top: 0.15rem;
      font-size: 0.6rem; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-3 a:first-child:after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-3 span:first-child:after, #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 a:first-child:after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 span:first-child:after, #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 a:first-child:after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 span:first-child:after {
      background: #005da9; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-3 a:not(:last-child):not(:first-child):after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-3 span:not(:last-child):not(:first-child):after {
      background: linear-gradient(90deg, #005da9 0%, #005da9 50%, #bbbbbb 50%, #bbbbbb 100%); }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 .pb-item-3:before, #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 .pb-item-3:before {
      content: "";
      font-family: "retail-icon-font";
      font-weight: 900;
      padding-left: 0.05rem;
      padding-top: 0.15rem;
      font-size: 0.6rem; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 a:not(:last-child):not(:first-child):after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 span:not(:last-child):not(:first-child):after, #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 a:not(:last-child):not(:first-child):after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 span:not(:last-child):not(:first-child):after {
      background: #005da9; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 a:last-child:after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-4 span:last-child:after, #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 a:last-child:after,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 span:last-child:after {
      background: #005da9; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar.pb-indicator-5 .pb-item-4:before {
      content: "";
      font-family: "retail-icon-font";
      font-weight: 900;
      padding-left: 0.05rem;
      padding-top: 0.15rem;
      font-size: 0.6rem; }

@media (max-width: 991px) {
  #wrapper .component.diary-management-online-booking .progress-bar-wrapper {
    height: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a,
    #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span {
      flex-grow: 1;
      font-size: 1.25rem;
      text-align: center; }
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar a:before,
      #wrapper .component.diary-management-online-booking .progress-bar-wrapper .progress-bar span:before {
        font-size: 0.9rem;
        left: calc(50% - 0.65rem); } }

.loading-spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5rem 0; }
  .loading-spinner-wrapper .loading-spinner {
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    margin: 0 auto 1rem auto; }
    .loading-spinner-wrapper .loading-spinner .double-bounce1,
    .loading-spinner-wrapper .loading-spinner .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #005da9;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out; }
    .loading-spinner-wrapper .loading-spinner .double-bounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s; }

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }
  .loading-spinner-wrapper .loading-spinner-label {
    color: #005da9;
    font-family: "Proxima N W01 Bold", sans-serif; }

#wrapper .center-locator.flex-layout .loading-spinner {
  margin: 5rem auto; }

#wrapper .center-locator.flex-layout .center-locator-wrapper {
  height: 75vh;
  position: relative; }
  #wrapper .center-locator.flex-layout .center-locator-wrapper .toggle-clinics-container,
  #wrapper .center-locator.flex-layout .center-locator-wrapper .my-location-container {
    display: none; }
  #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu,
  #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map {
    height: 75vh;
    overflow: hidden;
    padding: 0; }
  #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map {
    width: 100%; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .cluster img {
      display: none; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .cluster div {
      display: flex;
      border-radius: 50%;
      background: #005da9;
      font-family: "Proxima N W01 Reg", sans-serif;
      height: 100%;
      font-size: 0.8rem;
      align-items: center;
      justify-content: center; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .cluster div:hover {
        background: #00427A; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-fullscreen-control {
      display: flex;
      justify-content: center;
      align-items: center; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-fullscreen-control:before {
        content: "";
        width: 16px;
        height: 16px;
        display: inline-block;
        background-image: url("/design/build/retail/assets/hcl/retail/google-maps-spritesheet.png");
        background-position: -19px -1px; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-fullscreen-control:hover:before {
        background-position: -1px -1px; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-fullscreen-control img {
        display: none; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button img {
      display: none; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:before {
      width: 16px;
      height: 16px;
      display: inline-block;
      content: "";
      background-image: url("/design/build/retail/assets/hcl/retail/google-maps-spritesheet.png"); }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:first-of-type:before {
      background-position: -37px -19px; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:first-of-type:hover:before {
      background-position: -37px -42px; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:last-of-type:before {
      height: 4px;
      background-position: -19px -37px; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:last-of-type:hover:before {
      background-position: -1px -37px; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center {
      position: absolute;
      top: 0.75rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center,
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location,
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map {
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
      border-radius: 2px;
      background: #ffffff;
      border: 0;
      padding: 0.5rem;
      font-family: "Proxima N W01 Reg", sans-serif;
      font-size: 1rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center:hover,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location:hover,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map:hover {
        cursor: pointer; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-centers:before,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-centers:before,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-centers:before {
        content: url("/design/build/retail/assets/hcl/retail/map-marker-primary.svg"); }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-partners,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-partners,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-partners {
        margin-top: 0; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-partners:before,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-partners:before,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-partners:before {
          content: url("/design/build/retail/assets/hcl/retail/map-marker-secondary.svg"); }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location {
      font-size: 0;
      padding: 0;
      display: flex;
      width: 40px;
      height: 40px;
      margin: 10px;
      align-items: center;
      justify-content: center;
      color: #515151; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location:before {
        content: "";
        display: inline-block;
        height: 16px;
        width: 16px;
        background-image: url("/design/build/retail/assets/hcl/retail/google-maps-spritesheet.png");
        background-position: -19px -19px; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location:hover:before {
        background-position: -1px -19px; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      min-width: 13rem;
      margin: 10px; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map .toggle-text {
        flex-grow: 1;
        text-align: left; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map .toggle-icon {
        font-size: 0;
        text-align: right;
        width: 1.25rem;
        height: 1.25rem;
        background: #005da9;
        display: flex;
        align-items: center;
        justify-content: center; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map .toggle-icon:before {
          content: "";
          font-family: "retail-icon-font";
          font-size: 0.8rem;
          color: #ffffff;
          margin-left: 2px; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map .toggle-icon.icon-disabled {
          background: #f1f1f1;
          border: 1px solid #D5D5D5; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map .toggle-icon.icon-disabled:before {
            content: ""; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map:before {
        margin-right: 1rem;
        margin-top: 0.25rem; }
  #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search {
    background: #f1f1f1;
    padding: 0.75rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form {
      margin-top: 0;
      position: relative; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-search,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-clear {
        position: absolute;
        border: 0;
        background: none;
        font-size: 0; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-search:after,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-clear:after {
          font-size: 1.5rem;
          font-family: "retail-icon-font";
          content: "";
          color: #005da9; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-search:hover,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-clear:hover {
          cursor: pointer; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-search {
        height: 2.75rem;
        top: 0;
        right: 0;
        background: #005da9;
        padding: 0 1.25rem;
        display: flex;
        align-items: center;
        border-radius: 0 0.25rem 0.25rem 0; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-search:after {
          color: #ffffff; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-clear {
        right: 4.5rem;
        top: 0.9rem;
        display: none; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest-clear:after {
          content: "";
          color: #515151;
          font-size: 1rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input {
        border-radius: 0.25rem;
        box-shadow: none;
        height: 2.75rem;
        font-family: "Proxima N W01 Reg", sans-serif;
        font-size: 1rem;
        padding-right: 5.5rem;
        border: 1px solid #7f7f7f;
        display: flex;
        align-items: center; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input::-webkit-input-placeholder {
          color: #dddddd; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input:-moz-placeholder {
          color: #dddddd; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input::-moz-placeholder {
          color: #dddddd; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input:-ms-input-placeholder {
          color: #dddddd; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__input-wrapper:after {
        top: 1rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper {
        position: relative;
        width: 100%;
        display: none; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests {
          box-shadow: 0 0 1.5rem #dddddd;
          background: #ffffff; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests.geosuggest__suggests--hidden {
            display: none; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item {
            font-size: 1rem;
            font-family: "Proxima N W01 Reg", sans-serif;
            padding: 0 1.25rem;
            height: 2.75rem;
            line-height: 2.75rem;
            color: #005da9;
            border: 0;
            border-bottom: 1px solid #f1f1f1;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; }
            #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover, #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.geosuggest__item--active {
              cursor: pointer;
              background: #f1f1f1; }
            #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item {
              padding-right: 3rem;
              position: relative; }
              #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item:after {
                content: "";
                background-image: url("/design/build/retail/assets/ear.svg");
                background-size: 27px 31px;
                width: 27px;
                height: 31px;
                right: 0.5rem;
                top: calc(1.575rem - 18px);
                position: absolute; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form.show-clear-button .geosuggest .geosuggest__input-wrapper .geosuggest__input {
        padding-right: 6.4rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form.show-clear-button .geosuggest-clear {
        display: block; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form.suggests-visible .geosuggest .geosuggest__suggests-wrapper {
        display: block; }
  #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details {
    overflow: auto;
    height: 67.75vh;
    background: #ffffff;
    z-index: 3;
    text-align: left;
    top: 4.25rem;
    position: relative; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-header {
      background: #005da9;
      display: flex;
      margin: 0 -1rem;
      height: 5.25rem;
      padding: 0 1rem;
      align-items: center; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-header h1 {
        flex-grow: 1;
        color: #ffffff;
        font-size: 1.5rem;
        padding: 0;
        margin-bottom: 0; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-header button {
        background: none;
        border: 0;
        font-size: 0; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-header button:before {
          color: #ffffff;
          content: url("/design/build/retail/assets/hcl/icon_close.svg"); }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-header button:hover {
          cursor: pointer; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content {
      display: flex;
      flex-direction: row;
      padding: 1rem 0; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content.opening-hours {
        flex-direction: column; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content.opening-hours h2 {
          font-size: 1rem; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content.opening-hours ul {
          margin-top: 0.5rem; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content.opening-hours ul li {
            line-height: 1.5; }
            #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content.opening-hours ul li span {
              display: inline-block; }
              #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content.opening-hours ul li span:first-of-type {
                width: 30%; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content .column:last-of-type {
        flex-grow: 1;
        margin-left: 2rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content .column p {
        font-size: 1rem;
        padding: 0;
        line-height: 1.4; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content .column p span {
          font-family: "Proxima N W01SC Reg It", sans-serif;
          color: #b0b0b0; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content .column a {
        color: #000000;
        font-family: "Proxima N W01 Reg", sans-serif; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content .column a.map-center-details-link {
          color: #005da9;
          text-decoration: underline; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content .column a.map-center-details-link.get-directions-link:before {
            content: "⤶";
            display: inline-block;
            transform: rotate(180deg);
            margin-right: 0.25rem;
            font-size: 1.25rem;
            line-height: 1rem; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .iframe-widget {
      padding-top: 0.5rem;
      margin-top: 0.5rem;
      border-top: 1px solid #e6e6e6; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .iframe-widget iframe {
        width: 100%; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .iframe-widget .podium-reviews__header {
        display: none; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .iframe-widget .podium-reviews__content h3 {
        font-size: 1.5rem;
        font-family: "Proxima N W01 Bold", sans-serif; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .center-info {
      margin-top: 1rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .center-info h2,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .center-info p {
        font-size: 1rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .center-info p {
        margin-top: 0.5rem; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description {
      padding: 1rem 0 0 0; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description * {
        font-size: 1rem;
        padding-bottom: 0.5rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h1,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h2,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h3,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h4,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h5,
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h6 {
        font-size: 1.25rem; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h1 span,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h2 span,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h3 span,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h4 span,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h5 span,
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-description h6 span {
          font-size: 1.25rem; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-book {
      background: #f1f1f1;
      margin: 0 -1rem;
      padding: 1rem;
      align-items: flex-start;
      flex-direction: row; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-book button {
        margin: 0; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-book button.get-directions {
          background: none;
          color: #005da9;
          border: 2px solid #005da9;
          margin-left: 1rem;
          padding-left: 2rem;
          padding-right: 2rem; }
  #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu {
    position: absolute;
    top: 4.25rem;
    left: -50rem;
    z-index: 1;
    background: #ffffff;
    transition: all 0.5s ease;
    height: 67.75vh; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu.expanded {
      left: 0;
      position: relative; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item {
        text-align: left;
        display: flex;
        flex-direction: row;
        padding: 1rem 0;
        border-bottom: 1px solid #dce5ec; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item:hover {
          background: #a7d3ac;
          cursor: pointer; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .distance-label {
          padding-left: 1rem;
          text-align: center;
          line-height: 1.4; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .distance-label span {
            color: #005da9;
            font-family: "Proxima N W01 Bold", sans-serif; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .center-info {
          flex-grow: 1;
          padding: 0 1rem; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .center-info h2,
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .center-info p {
            font-size: 1rem;
            padding: 0;
            line-height: 1.3; }
            #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .center-info h2 a,
            #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .center-info p a {
              color: #27251F;
              font-family: "Proxima N W01 Reg", sans-serif; }
          #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .center-info h2 {
            color: #27251F;
            display: flex;
            align-items: center; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item.list-item-focused {
          background: #a7d3ac; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li .map-centers-list-item .action-buttons button {
          height: 1.875rem;
          border-radius: 0.9375rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2 {
        position: relative;
        padding-left: 20px !important; }
        #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2:before {
          background-image: url("/design/build/retail/assets/ear.svg");
          background-size: 16px 16px;
          content: "";
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 0.25rem;
          position: absolute;
          left: 0; }
  #wrapper .center-locator.flex-layout .center-locator-wrapper .action-buttons {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .action-buttons button {
      background: none;
      color: #ffffff;
      background: #005da9;
      font-family: "Proxima N W01 Bold", sans-serif;
      border-radius: 1.125rem;
      padding: 0 1rem;
      font-size: 1rem;
      margin-bottom: 0.25rem;
      white-space: nowrap;
      border: 1px solid #005da9;
      height: 2.25rem; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .action-buttons button:hover {
        cursor: pointer;
        background: #00427A;
        border: 1px solid #00427A; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .action-buttons .button-link {
      font-family: "Proxima N W01 Reg", sans-serif;
      text-decoration: underline;
      padding: 0.5rem 0.5rem 0 0.5rem;
      background: none;
      border: 0;
      color: #005da9; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .action-buttons .button-link:hover {
        background: none;
        border: 0; }

@media (max-width: 991px) {
  #wrapper .center-locator.flex-layout .center-locator-wrapper {
    height: auto; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .button-toggle {
      background: #005da9;
      color: #ffffff;
      height: 3.375rem;
      border: 0;
      width: 100%;
      border-radius: 1.875rem;
      margin: 1rem 1rem 0 1rem;
      font-size: 1.25rem;
      font-family: "Proxima N W01 Bold", sans-serif; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .hide-button {
      display: none; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .show-button {
      display: block; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu {
      position: relative;
      top: 0;
      left: 0; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list {
        display: none; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search {
      position: relative; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__input-wrapper .geosuggest__input {
        font-size: 1.4rem; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details {
      top: 0; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map {
      display: block;
      height: 57vh; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper.mobile-open .hide-button {
      display: block; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper.mobile-open .show-button {
      display: none; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper.mobile-open .map-menu {
      overflow: hidden; }
      #wrapper .center-locator.flex-layout .center-locator-wrapper.mobile-open .map-menu .map-centers-list {
        display: block; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper.mobile-open .google-map {
      display: none; }
    #wrapper .center-locator.flex-layout .center-locator-wrapper:not(.mobile-open) .map-menu {
      height: auto !important;
      overflow: unset; } }

body.center-locator-full-height {
  overflow-y: hidden; }
  body.center-locator-full-height #footer {
    display: none; }

/* ACUSTICA MEDICA */
body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-fullscreen-control:before {
  background-image: url("/design/build/retail/assets/hcl/acusticamedica/google-maps-spritesheet.png"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:before {
  background-image: url("/design/build/retail/assets/hcl/acusticamedica/google-maps-spritesheet.png"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-centers:before,
body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-centers:before,
body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-centers:before {
  content: url("/design/build/retail/assets/hcl/acusticamedica/map-marker-primary.svg"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-partners:before,
body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-partners:before,
body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-partners:before {
  content: url("/design/build/retail/assets/hcl/acusticamedica/map-marker-secondary.svg"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location:before {
  background-image: url("/design/build/retail/assets/hcl/acusticamedica/google-maps-spritesheet.png"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-address:before {
  content: url("/design/build/retail/assets/hcl/acusticamedica/icon_location.svg"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-phone:before {
  content: url("/design/build/retail/assets/hcl/acusticamedica/icon_phone.svg"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-email:before {
  content: url("/design/build/retail/assets/hcl/acusticamedica/icon_email.svg"); }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2 {
  padding-left: 0 !important; }
  body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2:before {
    background-image: none; }

body.acusticamedica #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item:after {
  background-image: none; }

/* SLCUHMED */
body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-fullscreen-control:before {
  background-image: url("/design/build/retail/assets/hcl/sluchmed/google-maps-spritesheet.png"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:before {
  background-image: url("/design/build/retail/assets/hcl/sluchmed/google-maps-spritesheet.png"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-centers:before,
body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-centers:before,
body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-centers:before {
  content: url("/design/build/retail/assets/hcl/sluchmed/map-marker-primary.svg"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-partners:before,
body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-partners:before,
body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-partners:before {
  content: url("/design/build/retail/assets/hcl/sluchmed/map-marker-secondary.svg"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location:before {
  background-image: url("/design/build/retail/assets/hcl/sluchmed/google-maps-spritesheet.png"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-address:before {
  content: url("/design/build/retail/assets/hcl/sluchmed/icon_location.svg"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-phone:before {
  content: url("/design/build/retail/assets/hcl/sluchmed/icon_phone.svg"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-email:before {
  content: url("/design/build/retail/assets/hcl/sluchmed/icon_email.svg"); }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2 {
  padding-left: 0 !important; }
  body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2:before {
    background-image: none; }

body.sluchmed #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item:after {
  background-image: none; }

/* AUDIKA FR */
body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-fullscreen-control:before {
  background-image: url("/design/build/retail/assets/hcl/audikafr/google-maps-spritesheet.png"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .gm-style .gm-bundled-control button:before {
  background-image: url("/design/build/retail/assets/hcl/audikafr/google-maps-spritesheet.png"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-centers:before,
body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-centers:before,
body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-centers:before {
  content: url("/design/build/retail/assets/hcl/audikafr/map-marker-primary.svg"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-center.toggle-partners:before,
body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location.toggle-partners:before,
body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .toggle-map.toggle-partners:before {
  content: url("/design/build/retail/assets/hcl/audikafr/map-marker-secondary.svg"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .google-map .google-map-my-location:before {
  background-image: url("/design/build/retail/assets/hcl/audikafr/google-maps-spritesheet.png"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-address:before {
  content: url("/design/build/retail/assets/hcl/audikafr/icon_location.svg"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-phone:before {
  content: url("/design/build/retail/assets/hcl/audikafr/icon_phone.svg"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .map-center-details .map-center-details-content > div.details-email:before {
  content: url("/design/build/retail/assets/hcl/audikafr/icon_email.svg"); }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2 {
  padding-left: 0 !important; }
  body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .map-menu .map-centers-list li.main-clinic h2:before {
    background-image: none; }

body.audikafr #wrapper .center-locator.flex-layout .center-locator-wrapper .map-search form .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item.fixture-item:after {
  background-image: none; }

/* Secondary Btn Blue */
/************* COMPONENT VARS *************/
/************* COMPONENT VARS END *************/
#wrapper #content .flex-spot ol {
  font-size: 1rem;
  counter-reset: list;
  text-align: left; }
  #wrapper #content .flex-spot ol li {
    list-style: none;
    padding-left: 0.8rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    position: relative;
    padding-left: 50px;
    line-height: 40px; }
    @media only screen and (max-width: 1020px) {
      #wrapper #content .flex-spot ol li {
        line-height: 20px;
        padding-left: 30px; } }
    #wrapper #content .flex-spot ol li::before {
      content: counter(list);
      counter-increment: list;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 40px;
      display: inline-block;
      text-align: center;
      background: #005da9;
      color: #ffffff;
      font-family: "Soho Gothic W01 Bold", "Arial", sans-serif;
      font-size: 1rem;
      position: absolute;
      top: 0;
      left: 0; }
      @media only screen and (max-width: 1020px) {
        #wrapper #content .flex-spot ol li::before {
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 20px; } }

#wrapper #content .flex-spot a.link {
  color: #23C3EA !important;
  font-size: 0.9rem;
  font-family: "Soho Gothic W01 Bold", "Arial", sans-serif;
  margin-top: 0.5rem; }
  #wrapper #content .flex-spot a.link:before {
    color: #23C3EA; }

#wrapper #content .flex-spot sup {
  vertical-align: text-top; }

#wrapper #content .flex-spot .button.video-icon {
  border: none;
  display: flex; }
  #wrapper #content .flex-spot .button.video-icon:hover {
    color: #5BAC95 !important;
    background: none; }
  #wrapper #content .flex-spot .button.video-icon:after {
    content: "";
    font-family: "Oticon-icon-www";
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1rem;
    padding-left: 1rem;
    color: inhertit; }

#wrapper #content .flex-spot.flex-spot {
  display: none; }
  #wrapper #content .flex-spot.flex-spot .headlines {
    padding-top: 4rem;
    text-align: center;
    max-width: 90%;
    width: 60rem;
    margin: 0 auto; }
    @media only screen and (max-width: 1020px) {
      #wrapper #content .flex-spot.flex-spot .headlines {
        width: 100%;
        max-width: 100%; } }
    @media only screen and (max-width: 1020px) {
      #wrapper #content .flex-spot.flex-spot .headlines {
        padding-top: 1.5rem; } }

#wrapper #content .flex-spot small {
  font-size: 80%; }

.flex-spot-advanced {
  /* Secondary Btn Blue */
  /************* COMPONENT VARS *************/
  /************* COMPONENT VARS END *************/
  /* Secondary Btn Blue */
  /************* COMPONENT VARS *************/
  /************* COMPONENT VARS END *************/ }
  .flex-spot-advanced + .anchor {
    scroll-margin-top: 30px; }
  .flex-spot-advanced .component-content {
    display: block;
    scroll-margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem; }
    @media only screen and (min-width: 1020px) {
      .flex-spot-advanced .component-content {
        max-width: 95rem; } }
  .flex-spot-advanced.flex-spot-intro .component-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    @media only screen and (min-width: 1020px) {
      .flex-spot-advanced.flex-spot-intro .component-content {
        max-width: 74rem; } }
  .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
    @media only screen and (max-width: 1020px) {
      .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container {
        padding-top: 4rem;
        background-size: contain;
        background-position-y: center; } }
    .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      margin-bottom: 5rem;
      position: relative;
      flex-wrap: nowrap; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements {
          flex-wrap: wrap; } }
      .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 25%;
        height: 100%;
        cursor: pointer; }
        @media only screen and (max-width: 1020px) {
          .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element {
            width: 50%;
            justify-content: flex-start; } }
        .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element:hover .flex-spot-intro__container__elements__element__image {
          transform: scale(1.1); }
          .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element:hover .flex-spot-intro__container__elements__element__image img {
            transform: scale(1.02); }
        .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__image {
          height: 18rem;
          width: 18rem;
          border-radius: 18rem;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease-in-out; }
          @media only screen and (min-width: 601px) {
            .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__image .show-on-mobile-only {
              display: none; } }
          @media only screen and (max-width: 601px) {
            .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__image {
              height: 10rem;
              width: 10rem; }
              .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__image.hide-on-mobile {
                display: none; } }
          .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__image img {
            width: auto;
            padding: 0.1rem;
            box-sizing: border-box;
            transition: all 0.3s ease-in-out;
            max-height: 100% !important; }
        .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__desc {
          text-align: center;
          padding-top: 0.85rem;
          min-height: 10rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          max-width: 100%; }
          @media only screen and (max-width: 601px) {
            .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__desc {
              margin-bottom: 1rem;
              min-height: 6rem; } }
          .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__desc p {
            font-size: 1.5rem;
            margin: 0;
            max-width: 95%;
            margin: 0 auto;
            color: #005da9; }
            @media only screen and (max-width: 601px) {
              .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__desc p {
                margin-bottom: 0.4rem;
                font-size: 1rem; } }
            .flex-spot-advanced.flex-spot-intro .flex-spot-intro__container__elements__element__desc p .section-title {
              margin-bottom: 2rem; }
  .flex-spot-advanced .foldout {
    display: none;
    scroll-margin-top: 45px;
    /* one item */
    /* two items */ }
    @media only screen and (max-width: 1020px) {
      .flex-spot-advanced .foldout {
        margin-top: 2rem; } }
    .flex-spot-advanced .foldout.show {
      display: block; }
    .flex-spot-advanced .foldout__element {
      display: flex;
      width: 100%; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced .foldout__element {
          flex-direction: column; } }
      .flex-spot-advanced .foldout__element.white {
        background-color: #ffffff;
        padding-top: 2rem; }
      .flex-spot-advanced .foldout__element.has-image .foldout__item, .flex-spot-advanced .foldout__element.has-video .foldout__item {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media only screen and (min-width: 1020px) {
          .flex-spot-advanced .foldout__element.has-image .foldout__item:last-of-type.text, .flex-spot-advanced .foldout__element.has-video .foldout__item:last-of-type.text {
            padding-right: 5%; } }
        @media only screen and (min-width: 1020px) {
          .flex-spot-advanced .foldout__element.has-image .foldout__item:first-of-type.text, .flex-spot-advanced .foldout__element.has-video .foldout__item:first-of-type.text {
            padding-left: 5%; } }
      @media only screen and (min-width: 1020px) {
        .flex-spot-advanced .foldout__element {
          max-width: 80%;
          margin: 0 auto; } }
    .flex-spot-advanced .foldout__item {
      width: 50%;
      box-sizing: border-box; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced .foldout__item {
          width: 100%; }
          .flex-spot-advanced .foldout__item:last-of-type.image {
            order: 1; }
          .flex-spot-advanced .foldout__item.text {
            order: 2; } }
      @media only screen and (min-width: 1020px) {
        .flex-spot-advanced .foldout__item.video {
          min-height: 300px; }
          .flex-spot-advanced .foldout__item.video iframe {
            min-height: 100%; } }
      .flex-spot-advanced .foldout__item img {
        width: 100%;
        height: auto;
        display: block; }
      .flex-spot-advanced .foldout__item.text {
        padding: 1rem; }
    .flex-spot-advanced .foldout__item:first-child:nth-last-child(1) {
      width: 100%; }
      .flex-spot-advanced .foldout__item:first-child:nth-last-child(1).text {
        text-align: center; }
    .flex-spot-advanced .foldout__item:first-child:nth-last-child(2),
    .flex-spot-advanced .foldout__item:first-child:nth-last-child(2) ~ .flex-spot-advanced .foldout__item {
      width: 100%; }
      @media only screen and (min-width: 601px) {
        .flex-spot-advanced .foldout__item:first-child:nth-last-child(2),
        .flex-spot-advanced .foldout__item:first-child:nth-last-child(2) ~ .flex-spot-advanced .foldout__item {
          width: 50%; } }
    .flex-spot-advanced .foldout__button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media only screen and (min-width: 1020px) {
        .flex-spot-advanced .foldout__button .button {
          min-width: 12rem; } }
  .flex-spot-advanced.flex-spot-three-column .section-container__content {
    display: flex;
    justify-content: space-between;
    position: relative; }
    @media only screen and (max-width: 1020px) {
      .flex-spot-advanced.flex-spot-three-column .section-container__content {
        flex-direction: column; } }
    .flex-spot-advanced.flex-spot-three-column .section-container__content__column {
      height: 100%; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced.flex-spot-three-column .section-container__content__column {
          width: 100%; } }
      .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 24%;
        flex: 0 0 auto; }
        @media only screen and (max-width: 1020px) {
          .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text {
            max-width: 100%;
            width: 100%;
            margin-top: 1.2rem; } }
        @media only screen and (max-width: 1020px) {
          .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text h3 {
            text-align: center; } }
        #wrapper .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text h3 {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          width: 100%;
          max-width: 100%;
          flex-shrink: 0;
          height: auto; }
        .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text p {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0;
          max-width: 100%; }
          @media only screen and (max-width: 1020px) {
            .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text p {
              text-align: left; } }
        .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text:nth-of-type(3) {
          text-align: right; }
          .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text:nth-of-type(3) h3 {
            text-align: right; }
            @media only screen and (max-width: 1020px) {
              .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text:nth-of-type(3) h3 {
                text-align: left;
                align-items: flex-start; } }
            .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text:nth-of-type(3) h3 img {
              display: flex;
              align-self: flex-end;
              width: auto; }
              @media only screen and (max-width: 1020px) {
                .flex-spot-advanced.flex-spot-three-column .section-container__content__column__text:nth-of-type(3) h3 img {
                  align-self: flex-start; } }
      .flex-spot-advanced.flex-spot-three-column .section-container__content__column__image {
        max-width: 50%; }
        @media only screen and (max-width: 1020px) {
          .flex-spot-advanced.flex-spot-three-column .section-container__content__column__image {
            max-width: 100%; } }
        .flex-spot-advanced.flex-spot-three-column .section-container__content__column__image img {
          max-width: 100%; }
  @media only screen and (max-width: 1020px) {
    .flex-spot-advanced.flex-spot-three-column .component-content .section-container__content__column__text {
      order: 2; }
      .flex-spot-advanced.flex-spot-three-column .component-content .section-container__content__column__text h3 {
        text-align: left; } }
  @media only screen and (min-width: 1020px) {
    .flex-spot-advanced.flex-spot-three-column .component-content .section-container__content__column__text:nth-of-type(3) {
      text-align: right; }
      .flex-spot-advanced.flex-spot-three-column .component-content .section-container__content__column__text:nth-of-type(3) h3 {
        align-items: flex-end; } }
  @media only screen and (max-width: 1020px) {
    .flex-spot-advanced.flex-spot-three-column .component-content .section-container__content__column__image {
      order: 1; } }
  .flex-spot-advanced .flex-spot-footer {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1020px) {
      .flex-spot-advanced .flex-spot-footer {
        flex-direction: column-reverse;
        justify-content: center; } }
    .flex-spot-advanced .flex-spot-footer__thumbnails-container {
      display: flex;
      width: 50%; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced .flex-spot-footer__thumbnails-container {
          width: 100%;
          justify-content: center;
          margin-top: 1rem; } }
      .flex-spot-advanced .flex-spot-footer__thumbnails-container__item {
        display: flex;
        margin: 5px; }
        @media only screen and (max-width: 1020px) {
          .flex-spot-advanced .flex-spot-footer__thumbnails-container__item {
            padding-bottom: 2rem; } }
        .flex-spot-advanced .flex-spot-footer__thumbnails-container__item__image {
          border-radius: 50%;
          width: 57px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.4; }
          @media only screen and (max-width: 1020px) {
            .flex-spot-advanced .flex-spot-footer__thumbnails-container__item__image {
              width: 40px;
              height: 40px; } }
          .flex-spot-advanced .flex-spot-footer__thumbnails-container__item__image img {
            max-width: 100%;
            max-height: 100%; }
        .flex-spot-advanced .flex-spot-footer__thumbnails-container__item__desc {
          font-size: 0.8rem;
          color: #005da9;
          font-family: "Proxima N W01 Reg", sans-serif;
          padding-left: 0.9rem;
          display: flex;
          align-items: center;
          text-align: left;
          max-width: 9rem;
          margin: 0;
          display: none; }
          @media only screen and (max-width: 1020px) {
            .flex-spot-advanced .flex-spot-footer__thumbnails-container__item__desc {
              display: none !important; } }
    .flex-spot-advanced .flex-spot-footer__button-container {
      display: flex;
      width: 50%;
      justify-content: flex-end;
      align-items: center; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced .flex-spot-footer__button-container {
          width: 100%;
          justify-content: center; } }
      .flex-spot-advanced .flex-spot-footer__button-container .button.hidden {
        max-height: 0;
        overflow: hidden;
        padding: 0 !important;
        border: none !important;
        display: none; }
    .flex-spot-advanced .flex-spot-footer .button-hollow {
      color: #fff;
      background: none;
      border: 1px solid #e40049;
      height: 3.375rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 1.875rem;
      padding: 0 4rem;
      margin-left: 1rem; }
  .flex-spot-advanced.flex-spot-section-1 .flex-spot-footer-1 .flex-spot-footer__thumbnails-container__item__image {
    opacity: 1; }
  .flex-spot-advanced.flex-spot-section-1 .flex-spot-footer-1 .flex-spot-footer__thumbnails-container__item__desc {
    display: flex; }
  .flex-spot-advanced.flex-spot-section-2 .flex-spot-footer-2 .flex-spot-footer__thumbnails-container__item__image {
    opacity: 1; }
  .flex-spot-advanced.flex-spot-section-2 .flex-spot-footer-2 .flex-spot-footer__thumbnails-container__item__desc {
    display: flex; }
  .flex-spot-advanced.flex-spot-section-3 .flex-spot-footer-3 .flex-spot-footer__thumbnails-container__item__image {
    opacity: 1; }
  .flex-spot-advanced.flex-spot-section-3 .flex-spot-footer-3 .flex-spot-footer__thumbnails-container__item__desc {
    display: flex; }
  .flex-spot-advanced.flex-spot-section-4 .flex-spot-footer-4 .flex-spot-footer__thumbnails-container__item__image {
    opacity: 1; }
  .flex-spot-advanced.flex-spot-section-4 .flex-spot-footer-4 .flex-spot-footer__thumbnails-container__item__desc {
    display: flex; }
  .flex-spot-advanced.flex-spot-slider .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden; }
    @media only screen and (max-width: 1020px) {
      .flex-spot-advanced.flex-spot-slider .content {
        flex-direction: column; } }
  .flex-spot-advanced.flex-spot-slider .content-label {
    flex-grow: 1;
    user-select: none;
    pointer-events: none;
    padding-right: 2rem; }
    .flex-spot-advanced.flex-spot-slider .content-label:last-child {
      padding-left: 2rem; }
    .flex-spot-advanced.flex-spot-slider .content-label:last-child {
      text-align: right;
      padding-right: 0; }
  .flex-spot-advanced.flex-spot-slider .content-labels {
    display: flex;
    justify-content: space-between;
    max-width: 100%; }
    .flex-spot-advanced.flex-spot-slider .content-labels .content-label {
      padding: 0;
      word-spacing: 1000px; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced.flex-spot-slider .content-labels .content-label {
          word-spacing: normal;
          padding-right: 0.5rem;
          overflow: hidden;
          width: 50%; }
          #wrapper .flex-spot-advanced.flex-spot-slider .content-labels .content-label h3 {
            font-size: 1.5rem; }
          .flex-spot-advanced.flex-spot-slider .content-labels .content-label:last-child {
            padding-right: 0;
            padding-left: 0.5rem; } }
  .flex-spot-advanced.flex-spot-slider .slider-content {
    width: 100%;
    max-width: 100%;
    position: relative; }
    .flex-spot-advanced.flex-spot-slider .slider-content .slider-images {
      width: 100%;
      height: 100%;
      user-select: none;
      -ms-user-select: none;
      pointer-events: none; }
      .flex-spot-advanced.flex-spot-slider .slider-content .slider-images .slider-image {
        -ms-user-select: none;
        user-select: none;
        pointer-events: none; }
        .flex-spot-advanced.flex-spot-slider .slider-content .slider-images .slider-image img {
          -ms-user-select: none;
          user-select: none;
          pointer-events: none;
          max-width: 100%;
          height: auto; }
        .flex-spot-advanced.flex-spot-slider .slider-content .slider-images .slider-image + .slider-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
    .flex-spot-advanced.flex-spot-slider .slider-content .slider {
      width: 100px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      pointer-events: none; }
    .flex-spot-advanced.flex-spot-slider .slider-content .slider-track {
      width: 3px;
      background-color: #23C3EA;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none; }
    .flex-spot-advanced.flex-spot-slider .slider-content .slider-thumb {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 57px;
      height: 57px;
      color: #ffffff;
      background-color: #23C3EA;
      border-radius: 50%;
      box-sizing: border-box;
      padding: 1rem; }
      @media only screen and (max-width: 1020px) {
        .flex-spot-advanced.flex-spot-slider .slider-content .slider-thumb {
          width: 40px;
          height: 40px; } }
      .flex-spot-advanced.flex-spot-slider .slider-content .slider-thumb div {
        pointer-events: none;
        position: absolute;
        top: 18px;
        left: 14px;
        width: 20px;
        height: 20px;
        border-top: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
        transform: rotate(-45deg); }
        .flex-spot-advanced.flex-spot-slider .slider-content .slider-thumb div + div {
          transform: rotate(135deg);
          left: 22px; }
        @media only screen and (max-width: 1020px) {
          .flex-spot-advanced.flex-spot-slider .slider-content .slider-thumb div {
            top: 14px;
            left: 10px;
            width: 10px;
            height: 10px; }
            .flex-spot-advanced.flex-spot-slider .slider-content .slider-thumb div + div {
              left: 18px; } }
  .flex-spot-advanced.flex-spot-background-grey {
    background: #f1f1f1; }

/*# sourceMappingURL=main.[hash].css.map */